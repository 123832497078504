import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "중국어 간체",
        dialogDetermine: "확신하는",
        dreivatives: "배달",
        markets: "인용 부호",
        assets: "자산",
        liquidity: "유동성 채굴",
        helpCentre: "지원 센터",
        announcementCenter: "공지사항 센터",
        about: "~에대한",
        bulletinCentre: "공지사항 센터",
        serviceAgreement: "약관 동의",
        support: "지원하다",
        onlineSupportCustomerService: "온라인 서비스",
        suggestionsAndFeedback: "제안 및 피드백",
        service: "제공하다",
        download: "다운로드",
        coinApplication: "코인 상장 신청",
        privacyStatement: "개인 정보 보호 정책",
        trade: "계약 거래",
        standardConteact: "지속 і 능한",
        signOut: "로그아웃",
        assetWallets: "자산 지갑",
        financeRecords: "계정 변경 기록",
        identification: "입증",
        personalCenter: "개인 센터",
        chooseLanguage: "언어를 선택하세요",
        tips: "힌트",
        dark: "다크 모드",
        light: "주간 모드",
        languageTitle: "언어",
        convertCurrency: "통화 변환",
        cancel: "취소",
        aboutUs: "회사 소개",
        standard: "표준의 연속성",
        share: "공유하다",
        introduce: "플랫폼 소개",
        set: "설정",
        changePassword: "로그인 비밀번호 변경",
        denomination: "호ラ통화",
        color: "색상 구성",
        code: "버전 번호",
        color1: "붉은색 상승과 녹색 하락",
        color2: "녹색 상승 및 빨간색 하락",
        setLangue: "언어 설정",
        vip: "VIP 권한 및 혜택",
        vip2: "현재 VIP 레벨",
        safeMenu: "보안센터",
        conventional: "전통적인",
        other: "나머지",
        quickTips: "바로 가기 기능 버튼 최대 3개 추가하기",
        quickTransaction: "빠른 거래",
        safeConvenient: "안전하고 편리한",
        timeSharing: "시간 공유",
        currentLevel: "현재 레벨",
        levelBenefits: "%{level}소득세 과세 구간",
        teamLevel: "팀 레벨",
        levelLower: "%{level}하위",
        rules: "규칙에 대한 설명",
        bindWithdrawalAddress: "구속력 있는 출금 주소",
        tipsBindWithdrawalAddress: "출금 주소를 바인딩하지 않으셨으니 먼저 바인딩하러 가세요!",
        walletAddressFormatIncorrect: "잘못된 형식의 지갑 주소",
        tradeRules1: "미래 기간의 상승 또는 하락을 예측합니다. 주문 시점의 가격이 시작가, 예측 기간의 마지막 1초 시점의 가격이 정산가이며, 정산가가 시작가보다 크면 상승, 정산가가 시작가보다 작으면 하락입니다.。",
        tradeRules2: "1.계약이란 무엇인가요?",
        tradeRules3: "이 플랫폼은 USDT로 표시된 무기한 계약을 제공하며, 사용자는 상승과 하락을 판단하고 디지털 자산 가격 상승/하락의 이익을 얻기 위해 롱 또는 숏 계약을 매수하거나 매도할 수 있습니다. 무기한 계약에는 인도 날짜가 없으며, 사용자는 언제든지 언제든지。",
        tradeRules4: "2.마진이란?",
        tradeRules5: "가상 계약 시장에서 사용자는 계약 이행에 대한 재정적 보증으로 일정 비율에 따라 계약 가격에 따라 소액의 돈만 지불하면 계약 구매 및 판매에 참여할 수 있으며, 이러한 종류의 돈이 가상 계약 마진입니다. 엔북 플랫폼의 계약은 포지션별 분할 계좌 마진 모델을 사용합니다. 각 통화는 계약 계좌에 해당하며, 서로 다른 계약 통화의 계좌 자산과 포지션은 서로 독립적이며, 서로 다른 계약 계좌 간의 이체 및 거래는 서로 영향을 미치지 않습니다. 사용자의 계약 계좌가 개설되면 계약 계좌에 있는 모든 포지션의 위험과 수익이 합산됩니다.。",
        tradeRules6: "3.증거금 계산 방법",
        tradeRules7: "포지션 개시 증거금 = 계약 명목가 * 포지션 수 / 레버리지 \n 동결 증거금 = 현재 주문 내에서 성공적으로 체결된 모든 포지션 개시 주문의 증거금 합계 \n 사용 가능한 증거금 = 계좌 잔고 - 포지션 개시 증거금 - 동결 증거금",
        tradeRules8: "4.증거금률 계산 방법",
        tradeRules9: "증거금률은 사용자 자산의 위험도를 나타내는 지표입니다. \증거금률 = (미실현 손익 + 포지션 증거금 + 사용가능 증거금 + 동결 증거금 - 청산 수수료) / 포지션 증거금 \n 증거금률이 작을수록 계좌의 위험도가 높습니다. 증거금률이 0% 이하일 경우 포지션 강제 청산이 트리거됩니다.。",
        tradeRules10: "5.예상되는 강력한 패리티는 무엇입니까?",
        tradeRules11: "예상 강세 종가, 즉 시스템이 이론적으로 포지션 강제 청산을 트리거하기 위해 계산한 가격은 참고용이며 실제 값과 약간 다를 수 있습니다.。",
        tradeRules12: "6.계좌의 총 자본, 미실현 이익 및 손실이란 무엇인가요?",
        tradeRules13: "총 계정 자산 = 계정 잔액 + 미실현 손익\n미실현 손익은 사용자가 통화 계약에서 현재 보유하고 있는 포지션의 손익입니다. 미실현 손익은 최신 거래 가격 변동에 따라 변경됩니다. \n매수 포지션 미실현 손익 = (1/평균 포지션 가격 - 1/최근 거래 가격) * 매수 포지션 계약 수 * 계약 액면가 * 최근 거래 가격 \n 매도 포지션 미실현 손익 = (1/ 최근 거래 가격 - 1/ 평균 포지션 가격) * 매도 계약 수 * 계약 액면가 * 최근 거래 가격",
        text1: "계정 보안",
        text2: "낮과 밤 모드",
        text3: "캐시 지우기",
        text4: "정말로 로그아웃하시겠습니까?？",
        text5: "캐시를 지우시겠습니까?？",
        text6: "다국어",
        text7: "보안을 보호하려면 하나 이상의 이중 인증을 켜는 것이 좋습니다."
    },
    login: {
        login: "로그인",
        register: "등록하다",
        title1: "언제 어디서나 거래하고 실시간 시장 상황을 확인하세요",
        title2: "언제 어디서나 거래하고 실시간 시장 상황을 확인하세요",
        title3: "가장 신뢰받는 암호화폐 거래 플랫폼",
        formTitle1: "계정 로그인",
        formTitle2: "돌아온 것을 환영합니다! 이메일, 전화번호로 로그인하세요.",
        btn1: "휴대폰",
        btn2: "우편",
        btn3: "로그인",
        btn4: "등록하다",
        btn5: "비밀번호를 잊어버려？",
        field1: "전화 번호",
        field2: "비밀번호",
        field3: "우편",
        placeholder1: "국가",
        placeholder2: "전화번호를 입력해주세요",
        placeholder3: "비밀번호를 입력 해주세요",
        placeholder4: "이메일을 입력해주세요",
        content12: "전화번호를 입력해주세요",
        content13: "비밀번호를 입력 해주세요",
        content14: "이메일을 입력해주세요",
        content16: "디지털 자산 금융 서비스",
        loginSuccess: "성공적 로그인 ",
        chooseArea: "지역번호를 선택해주세요",
        placeholder5: "국가 코드를 선택하세요.",
        placeholder6: "내 비밀번호 기억해 줘",
        placeholder7: "앱 다운로드",
        placeholder8: "고객 서비스에 문의하세요",
        placeholder9: "국가 또는 지역 선택",
        placeholder10: "지역 코드",
        placeholder11: "지갑 로그인",
        placeholder12: "작업이 실패했습니다. 다음으로 전환하세요.",
        placeholder13: "지갑"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "~에 대한",
        aboutCoinbeadCenterDetails: "세계 최고의 블록체인 자산 거래 플랫폼을 보유하고 전체 생태계를 운영하고 있습니다.。",
        footDetails1: "일일 거래량",
        footDetails2: "트랜잭션/초",
        footDetails3: "지원 센터",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "비트코인(BTC), 라이트코인(LTC), 이더리움(ETH) 등 디지털 자산의 코인 및 파생상품을 주로 글로벌 사용자에게 제공하는 세계적으로 유명한 디지털 자산 국제 웹사이트입니다.",
        coinbeadCenterDetailsm: "GSLB, 네트워크 서버, 네트워크 트랜잭션, 네트워크, 다중 기계 고속 메모리 거래 엔진, 콜드 지갑, 지능형 오프라인 핫 지갑 등과 같은 첨단 기술을 채택하여 웹 휴대폰 및 PC와 같은 다양한 단말기로 고객에게 서비스를 제공합니다. .안전하고 안정적이며 신뢰할 수 있는 디지털 관세 거래 서비스를 제공합니다. 동시에 우리는 사용자 제안과 모든 고객에게 더 나은 서비스를 제공하기 위한 요구 사항을 기반으로 제품과 서비스를 개선하고 업그레이드하며 혁신적인 사용자 경험 개선에는 끝이 없습니다.。",
        infrastructureCenterTitle: "하부 구조",
        infrastructureCenterDetails: "우리의 임무는 전체 블록체인 생태계에 인프라 서비스를 제공하는 것입니다.",
        freeCenterTitle: "무료",
        freeCenterDetails: "우리의 비전은 전 세계에 돈이 자유롭게 흐르도록 하는 것입니다. 우리는 이러한 자유를 널리 퍼뜨리면 세상을 더 나은 곳으로 만들 수 있다고 믿습니다.",
        futureCenterTitle: "기술의 미래를 개척하다",
        futureCenterDetails: "Open up the future of technology",
    },
    applicationForListing: {
        title: "코인 상장 신청",
        projectName: "프로젝트 이름",
        tokenName: "토큰 이름",
        projectDescription: "프로젝트 설명",
        contractAddress: "계약 주소",
        officialWebsite: "공식 웹 사이트",
        ContactAndEmail: "이메일 연락처",
        ContactAndName: "담당자 이름",
        file: "첨부파일 업로드",
        pleaseEnter: "들어 오세요",
        enterFile: "파일을 업로드해주세요",
        upload1: "현재 1개의 파일 선택으로 제한되어 있습니다.，",
        upload2: "이번에 제가 선택한",
        upload3: "파일，",
        upload4: "선택한 합계",
        upload5: "파일",
    },
    public: {
        submit: "제출하다",
        copySuccessfully: "성공적으로 복사되었습니다",
        copyFailed: "복사 실패",
        noDate: "데이터 없음",
        submitSuccessfully: "성공적으로 제출되었습니다",
        goBack: "마지막 페이지로 돌아가기",
        cancel: "취소",
        home: "첫 장",
        loading: "로드 중...",
        confirm: "확인하다",
        loadingFailed: "네트워크 이상",
        nomore: "더 이상은 없어",
        tryAgain: "페이지를 새로 고치거나 프로그램을 종료한 후 다시 업로드하세요.!",
        hot: "인기 지역",
    },
    inviteFriends: {
        inviteFriends: "친구와 공유하기",
        freedomOfWealth: "윈윈 협력",
        myInvitationCode: "내 초대 코드",
        clickCopy: "복사하려면 클릭하세요.",
        myInvitationLink: "내 초대 링크",
        clicktoSaveTheQRCode: "QR 코드를 저장하려면 클릭하세요.",
        saveQRCode: "QR 코드 초대",
        numberOfSubordinates: "부하 직원 수",
        rebateIncome: "리베이트 수입",
        people: "사람들",
        myPromotionRevenue: "나의 판촉수입",
        numberOfDirectSubordinates: "직속 부하 직원 수",
        totalNumberOfSubordinates: "총 부하 직원 수",
        directSubordinateReturnsCommission: "직속 부하 직원에 대한 커미션 리베이트",
        theTotalCommission: "총 리베이트",
        checkHistory: "리베이트 기록 보기",
        history: "커미션 기록",
        theLowerAccount: "하위 계정",
        type: "유형：",
        serviceCharge: "수수료：",
        percentageofCommissionReturned: "리베이트 비율：",
        commissionrebateAmount: "리베이트 금액：",
        time: "시간：",
        typeName1: "재충전 및 커미션",
        typeName2: "MT 주문 처리 수수료 3단계 분배",
        typeName3: "MT 수익성 있는 3단계 분배",
        typeName4: "추천 보상",
        typeName30: "재충전 및 커미션",
        typeName31: "정기적인 거래 이익 리베이트",
        typeName32: "유동성 채굴 수익 리베이트",
        typeName45: "거래 수수료 리베이트",
        typeName46: "자체 처리 수수료에 대한 수수료 리베이트",
        typeName50: "배송 계약 처리 수수료 리베이트",
        typeName51: "무기한 계약 처리 수수료 환불",
        typeName52: "배송 계약 처리 수수료에 대한 추가 수수료 리베이트",
        typeName53: "무기한 계약 처리 수수료에 대한 추가 수수료 리베이트",
        typeName54: "무기한 계약 처리 수수료에 대한 추가 리베이트",
        shareRules1: "초대 지침",
        shareRules2: "초대센터에 들어가 초대링크나 코드를 복사해 친구들과 공유해보세요. 귀하의 초대코드로 친구를 등록하면 친구가 귀하의 부하 직원이 될 수 있습니다.。",
        shareRules3: "리베이트 받기",
        shareRules4: "부하 직원이 거래를 수행하면 해당 수수료를 받을 수 있으며, 부하 직원은 최대 3단계까지 지원됩니다. 예를 들어 친구 A를 초대하고 A가 B를 초대하고 B가 C를 초대하면 A, B, C가 플랫폼에서 계약 및 기타 거래를 수행하고 모두 해당 수수료를 받을 수 있습니다.。",
        shareRules5: "팀 수준",
        shareRules6: "1급 부하직원을 많이 승급할수록 팀 레벨이 높아지고, 더 높은 리베이트를 누릴 수 있습니다. 팀 레벨은 LV1~LV6으로 구분되며, 업그레이드 규칙은 아래 표와 같으며, 'N'은 재충전 및 실명인증을 완료한 1급 부하 직원 수입니다.。",
        shareRules7: "부하직원이 배달계약에 베팅을 하면, 귀하는 베팅한 금액에 해당하는 커미션을 받을 수 있습니다.。",
        upgradeConditions: "필요하다",
        recommendedNumberOfPeople: "권장인원"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "제안 및 피드백",
        text: "사용 중 버그가 발생하거나 제품에 대한 제안 사항이 있으면 피드백을 보내주세요.",
        email: "이메일 주소",
        enterEmail: "이메일 주소를 입력해주세요",
        enterCorrectEmail: "올바른 이메일 주소를 입력해주세요",
        title: "제목",
        enterTitle: "질문 제목을 입력하세요",
        describeTheProblem: "문제 설명",
        enterDescribeTheProblem: "설명하는 문제를 입력하세요.",
        submit: "제출하다",
    },
    help: {
        enterSearch: "찾다",
        title: "지원 센터",
        search: "질문을 입력해주세요",
        text1: "귀하의 요구사항을 이해하고 더욱 편리한 서비스를 지속적으로 제공하겠습니다."
    },
    announcement: {
        enterSearch: "찾다",
        title: "공지사항 센터",
        search: "질문을 입력해주세요"
    },
    privacy: {
        title: "개인 정보 보호 정책",
        content1: "1. 이용자의 어떤 개인정보가 수집되나요?？",
        content2: "제품 및 서비스 제공과 지속적인 개선을 위해 개인정보를 수집합니다.。",
        content3: "당사가 수집하는 개인정보의 종류는 다음과 같습니다：",
        content4: '귀하가 당사에 제공하는 정보: 당사는 플랫폼과 관련하여 귀하가 제공하는 모든 정보를 수신하고 저장합니다. 귀하는 특정 정보를 제공하지 않도록 선택할 수 있지만, 그 결과 특정 "본 플랫폼 서비스"를 이용하지 못할 수도 있습니다. 귀하가 당사에 제공하는 정보의 예는 다음과 같습니다.：',
        content5: "이름",
        content6: "주소",
        content7: "시민권 국가",
        content8: "증명사진",
        content9: "휴대폰번호/이메일번호",
        content10: "귀하를 식별하는 데 도움이 되는 기타 정보",
        content11: "자동으로 수집되는 정보: 당사는 귀하의 플랫폼 서비스 사용에 관한 특정 유형의 정보를 자동으로 수집하고 저장하며, 많은 웹사이트와 마찬가지로 쿠키 및 기타 고유 식별자를 사용합니다. 당사는 귀하의 웹 브라우저나 장치가 플랫폼에 액세스할 때 특정 유형의 정보를 얻습니다. 당사가 수집하고 분석하는 정보의 예는 다음과 같습니다.：",
        content12: "개인용 컴퓨터를 인터넷에 연결하는 인터넷 프로토콜(IP) 주소；",
        content13: "개인 장치 또는 컴퓨터 로그인 정보, 이메일 주소, 비밀번호 및 위치；",
        content14: "버전 및 시간대 설정；",
        content15: "거래 내역。",
        content16: "기타 출처로부터의 정보: 당사는 신용 조사 기관의 신용 기록과 같은 다른 출처로부터 귀하에 관한 정보를 수집할 수 있습니다. 우리는 사기를 예방하고 탐지하기 위해 이 정보를 사용할 것입니다.。",
        content17: "2. 아이들이 사용해도 되나요?？",
        content18: '본 플랫폼은 18세 미만의 개인이 "서비스"를 이용하는 것을 허용하지 않습니다.”。',
        content19: "3. 쿠키와 기타 식별자는 무엇을 위해 사용됩니까?？",
        content20: "당사는 쿠키 및 유사한 도구를 사용하여 귀하의 경험을 최적화하고, 서비스를 제공하고, 고객이 당사 서비스를 사용하는 방식을 이해하여 목표를 개선할 수 있습니다. 당사는 시스템이 귀하의 브라우저나 장치를 인식하고 귀하에게 서비스를 제공할 수 있도록 쿠키를 사용합니다.。",
        content21: '당사는 다음과 같은 서비스를 제공하기 위해 운영 쿠키 및 유사한 도구(총칭하여 "쿠키")를 사용합니다.：',
        content22: "귀하가 로그인하고 서비스를 이용할 때 귀하를 식별합니다。",
        content23: "맞춤형 기능과 서비스 제공。",
        content24: "사기 행위로부터 보호。",
        content25: "보안 향상。",
        content26: "귀하의 기본 설정(통화, 언어 등)을 추적하세요.）。",
        content27: "또한 당사는 쿠키를 사용하여 사용자가 당사 서비스를 사용하는 방식을 이해함으로써 목표한 개선을 이룰 수 있습니다.。",
        content28: "4. 내 개인정보가 공유되나요?？",
        content29: "사용자 정보는 당사 사업의 중요한 부분이며, 당사는 사용자의 개인정보를 타인에게 판매하지 않습니다. 본 플랫폼은 다음과 같은 상황에서만 사용자의 개인 정보를 자회사 또는 계열사와 공유합니다. 이러한 자회사 또는 계열사는 본 개인 정보 보호 정책을 준수하거나 최소한 본 개인 정보 보호 정책에 설명된 보호 조치만큼 보호적인 관행을 따라야 합니다.。",
        content30: "제3자 서비스 제공업체: 당사는 당사를 대신하여 기능을 수행하도록 다른 회사 및 개인을 고용합니다. 이러한 기능의 예로는 데이터 분석, 마케팅 지원 제공, 결제 처리, 콘텐츠 전송, 신용 위험 평가 및 관리 등이 있습니다. 이러한 제3자 서비스 제공업체는 해당 기능을 수행하는 데 필요한 개인정보에 접근할 수 있지만 다른 목적으로는 이를 사용할 수 없습니다. 또한 본 개인 정보 보호 정책 및 해당 데이터 보호법을 준수하여 개인 정보를 처리해야 합니다.。",
        content31: "사업 양도: 당사의 사업이 지속적으로 성장함에 따라 당사는 다른 사업체나 서비스를 판매하거나 구매할 수 있습니다. 이러한 거래에서 사용자 정보는 일반적으로 양도된 비즈니스 자산에 속하지만 기존 개인 정보 보호 정책의 약속이 계속 적용됩니다(물론 사용자가 달리 동의하지 않는 한). 또한, 당사 또는 당사 자산의 실질적 전부를 인수하는 경우에는 이용자 정보도 이전됩니다. 당사 및 타인의 보호: 법률이나 규제 의무를 준수하기 위해 계정 및 기타 개인 정보의 공개가 적절하다고 판단하는 경우, 당사의 이용 약관 및 기타 계약 또는 당사의 권리, 재산 또는 안전을 시행하거나 적용하십시오. 사용자 또는 기타 당사는 언제 계정 및 기타 개인정보를 공개할 것입니다. 여기에는 사기로부터 보호하고 신용 위험을 줄이기 위해 다른 회사 및 조직과 정보를 교환하는 것이 포함됩니다.。",
        content32: "5. 개인 데이터의 국제 전송",
        content33: '당사는 귀하의 데이터를 유럽 경제 지역("EEA") 외부로 전송할 수 있습니다. 유럽연합 집행위원회가 데이터가 전송되는 국가가 적절한 수준의 보호를 제공한다고 확인한 경우를 제외하고, 당사는 그러한 전송이 해당 데이터 보호 규칙을 준수하도록 보장하기 위해 적절한 보호 장치를 마련할 것입니다.。',
        content34: "6. 내 개인정보는 안전한가요?？",
        content35: "우리는 귀하의 보안과 개인 정보 보호를 염두에 두고 시스템을 설계했습니다. 당사는 암호화 프로토콜과 소프트웨어를 사용하여 전송 중 개인정보의 보안을 보호하기 위해 노력합니다.。",
        content36: "우리는 개인정보를 수집, 저장, 공개할 때 항상 물리적, 전자적, 절차적 보호 장치를 채택합니다. 당사의 보안 절차에 따라 귀하에게 개인 정보를 공개하기 전에 귀하의 신원을 확인해야 할 수도 있습니다.。",
        content37: "귀하가 할 수 있는 가장 중요한 일은 귀하의 개인 계정 비밀번호를 무단 액세스로부터 보호하는 것입니다. 귀하의 계정에는 다른 온라인 계정과 구별되는 고유한 비밀번호를 설정하는 것이 좋습니다. 그리고 공유 컴퓨터 사용 후에는 반드시 로그아웃을 해주세요。",
        content38: "7. 개인정보를 어떻게 보호해야 하나요?？",
        content39: "당사가 개인정보를 수집하고 처리하는 방식에 대해 질문이나 이의가 있는 경우 고객 서비스 담당자에게 문의하시기 바랍니다.",
        content40: "귀하는 특정 목적을 위해 귀하의 개인정보를 처리하는 데 동의한 후 언제든지 귀하의 의사를 철회할 수 있으며 당사는 해당 목적을 위한 귀하의 데이터 처리를 중단합니다.。",
        content41: "또한 귀하는 관련 법률에 따라 개인 데이터에 대한 접근, 수정, 삭제를 요청하고 데이터 이동성을 요청할 권리가 있습니다. 귀하는 또한 귀하의 개인 데이터 처리에 반대하거나 특정 상황에서 귀하의 개인 데이터 처리를 제한하도록 요청할 수 있습니다.。",
        content42: "8. EU GDPR 및 영국 데이터 보호법 – 법적 근거",
        content43: "EU GDPR 및 영국 데이터 보호법은 개인 정보를 사용할 때 법적 근거를 준수하도록 요구합니다. 당사의 기준은 개인 정보가 사용되는 특정 목적에 따라 다릅니다. 이러한 기반에는 다음이 포함됩니다.：",
        content44: "계약 이행 – 주문을 수락 및 처리하고 결제를 처리하기 위해 귀하의 개인 정보를 사용하는 것을 포함하여 제품 또는 서비스에 대해 귀하에게 제공하거나 전달합니다.。",
        content45: "我们的合法商业利益和用户利益 - 我们检测并防范欺诈和滥用行为，为我们的用户、我们自己或他人的安全提供保护。",
        content46: "귀하의 동의 – 귀하에게 전달된 특정 목적을 위해 귀하의 개인정보를 처리하려면 귀하의 동의가 필요합니다. 귀하가 특정 목적을 위해 귀하의 개인 정보를 처리하는 데 동의한 후에는 언제든지 동의를 철회할 수 있으며 당사는 해당 목적을 위한 귀하의 데이터 처리를 중단합니다.。",
        content47: "법적 의무 준수 - 당사는 법률에서 요구하는 대로 귀하의 개인정보를 사용합니다. 예를 들어 신원 확인을 위해 은행 계좌 정보를 수집합니다.。",
        content48: "위의 내용과 기타 법적 근거는 당사가 개인정보를 사용하는 구체적인 목적에 따라 다릅니다.。",
        content49: "9. 사용 조건, 고지 사항 및 수정 사항",
        content50: "귀하의 개인정보 사용 및 개인정보 보호에 대한 모든 분쟁은 본 정책 및 당사 이용약관의 적용을 받습니다. 이 플랫폼의 개인 정보 보호에 대해 우려 사항이 있는 경우 자세한 설명과 함께 당사에 문의해 주시면 기꺼이 문제를 해결해 드리겠습니다. 귀하는 또한 현지 데이터 보호 기관에 연락할 권리가 있습니다.。",
        content51: "당사의 비즈니스는 끊임없이 변화하고 있으며 개인정보 보호정책도 마찬가지입니다. 귀하는 당사 웹사이트를 자주 확인하여 최신 변경 사항을 확인해야 합니다. 개정된 내용에 동의하지 않으시면 즉시 접속을 중단하시기 바랍니다. 개인정보 보호정책의 업데이트된 버전이 공개된 후에도 계속해서 액세스한다는 것은 업데이트된 내용에 동의하고 업데이트된 개인정보 보호정책을 준수하는 데 동의한다는 것을 의미합니다. 달리 명시하지 않는 한, 현재 개인정보 보호정책은 당사가 귀하 및 귀하의 계정에 대해 보유하고 있는 모든 정보에 적용됩니다.。",
    },
    serviceAgreement: {
        title: "약관 동의",
        content1: '본 웹사이트는 사용자가 디지털 자산 거래를 수행하고 관련 서비스(이하 "서비스" 또는 "서비스"라 함)를 제공하기 위한 플랫폼입니다. 본 계약을 설명하는 편의를 위해 본 웹사이트는 본 계약에서 "우리" 또는 기타 1인칭 대명사를 집합적으로 사용합니다. 웹사이트에 로그인한 자연인 또는 기타 주체가 본 웹사이트의 사용자인 경우, 본 계약 표현의 편의를 위해 아래에서는 "귀하" 또는 기타 제2의 자를 사용합니다. 본 계약 제시의 편의를 위해 본 계약에서는 당사와 귀하를 집합적으로 "당사자"라고 하며, 당사 또는 귀하를 개별적으로 "당사자"라고 합니다.”。',
        content2: "중요한 힌트：",
        content3: "우리는 여기서 당신에게 상기시키고 싶습니다.：",
        content4: "1 디지털 자산 자체는 금융 기관이나 회사 또는 본 웹사이트에서 발행한 것이 아닙니다.",
        content5: "2 디지털 자산 시장은 새롭고 확인되지 않았으며 성장하지 못할 수 있습니다；",
        content6: "3 디지털 자산은 주로 투기꾼에 의해 많이 사용되며 소매 및 상업 시장에서는 상대적으로 거의 사용되지 않습니다. 디지털 자산 거래는 매우 위험하며 하루 종일 지속적으로 거래되고 상승 및 하락에 제한이 없으며 가격이 시장에 쉽게 영향을 받습니다. 제작자와 글로벌 정부 정책.；",
        content7: "4  회사는 단독 재량에 따라 귀하가 본 계약을 위반했거나 본 웹사이트에서 제공하는 서비스 또는 본 웹사이트에서 제공하는 서비스의 사용이 다음과 같다고 판단하는 경우 언제든지 귀하의 계정을 일시 중지하거나 종료할 수 있는 권리를 보유합니다. 귀하의 관할권 법률에 따라 불법입니다. , 또는 본 웹사이트에서 제공하는 서비스 사용 또는 디지털 자산 거래를 일시 중지하거나 종료합니다. [중국 본토, 대만, 이스라엘, 이라크, 방글라데시, 볼리비아, 에콰도르, 키르기스스탄, 세바스토폴 및 영국(소매 사용자)] 출신의 누구든지 본 웹사이트에서 제공하는 계약 거래 서비스를 이용하는 것이 금지되어 있습니다. 앞서 언급한 국가 또는 지역 목록은 국가 또는 지역별 정책 및 제품 유형에 따라 변경될 수 있으며, 해당 시점에 구체적으로 공지할 수 없으므로 적시에 본 계약의 업데이트에 주의하시기 바랍니다. ",
        content8: "디지털 자산 거래는 매우 높은 위험을 수반하며 대부분의 사람들에게 적합하지 않습니다. 귀하는 이 거래로 인해 부분적 또는 전체적 손실이 발생할 수 있음을 이해하고 이해하므로 감당할 수 있는 손실 정도에 따라 거래 금액을 결정해야 합니다. 귀하는 디지털 자산이 파생위험을 발생시킨다는 사실을 알고 이해하고 있으므로 궁금한 사항이 있는 경우 먼저 전문 자문가의 도움을 받는 것이 좋습니다. 또한 위에서 언급한 위험 외에도 예측할 수 없는 위험도 있습니다. 귀하는 디지털 자산 구매 또는 판매 결정을 내리기 전에 귀하의 재정 상황과 위에 언급된 위험을 신중하게 고려하고 명확한 판단을 통해 평가해야 하며 이로 인해 발생하는 모든 손실을 책임져야 합니다.우리는 이에 대해 어떠한 책임도 지지 않습니다.。",
        content9: "당신에게 경고：",
        content10: "1 귀하는 본 웹사이트가 귀하가 디지털 자산 정보를 획득하고, 거래 당사자를 찾고, 디지털 자산에 대한 거래를 협상하고 수행하는 장소로만 사용된다는 점을 이해합니다. 본 웹사이트는 귀하의 거래에 전혀 참여하지 않으므로 신중하게 판단해야 합니다. 관련 디지털 자산 및/또는 거래 또는 정보의 진위성, 합법성 및 유효성을 확인하고 그에 따른 책임과 손실을 스스로 부담합니다.。",
        content11: "2 이 웹사이트의 모든 의견, 뉴스, 토론, 분석, 가격, 추천 및 기타 정보는 일반적인 시장 의견이며 투자 조언을 구성하지 않습니다. 당사는 이익 손실을 포함하되 이에 국한되지 않고 이 정보에 의존하여 직간접적으로 발생하는 모든 손실에 대해 책임을 지지 않습니다.。",
        content12: "3 본 웹사이트는 언제든지 자체 결정을 내리고 내용을 수정 또는 변경할 수 있는 권리를 보유합니다. 당사는 웹사이트 정보의 정확성을 보장하기 위해 합리적인 조치를 취했지만 그 정확성을 보장할 수는 없으며 이에 대해 책임을 지지 않습니다. 본 웹사이트의 정보 또는 정보 부족으로 인해 발생하는 모든 손실 또는 손해 인터넷 연결, 통지 및 정보 전송 또는 수신의 지연 또는 실패로 인해 발생하는 직간접적 손실。",
        content13: "4 인터넷 기반 거래 시스템을 사용하는 데에는 소프트웨어, 하드웨어 및 인터넷 연결 오류를 포함하되 이에 국한되지 않는 위험도 있습니다. 당사는 인터넷의 신뢰성과 가용성을 통제할 수 없으므로 왜곡, 지연 및 연결 실패에 대해 책임을 지지 않습니다.。",
        content14: "5 본 웹사이트를 이용하여 자금세탁, 밀수, 상업적 뇌물수수 등 모든 불법 거래 활동이나 불법 활동에 참여하는 것은 금지되어 있습니다. 의심되는 불법 거래나 불법 활동이 발견될 경우 본 웹사이트는 다음을 포함한 다양한 수단을 취할 것입니다. 계정 동결 및 관련자에게 통보하는 것에 국한되지 않으며, 당국 등은 이로 인해 발생하는 모든 책임을 지지 않으며 관련자로부터 책임을 추궁할 권리를 갖습니다.。",
        content15: "6 본 사이트를 이용하여 악의적으로 시장을 조작하는 행위, 부적절한 거래, 기타 비윤리적인 거래 행위를 금지하며, 이러한 사실이 발견될 경우 본 사이트에서는 경고 및 거래 제한 조치를 취하고, 악의적인 가격 조작, 회사에 대한 악의적 영향력 등 모든 비윤리적 행위를 종료합니다. 당사는 계정정지 등 예방적 보호조치로 인해 발생하는 어떠한 책임도 지지 않으며, 관련자에게 책임을 추궁할 권리를 가집니다.。",
        content16: "一、일반 원칙",
        content17: '1.1 "이용약관"(이하 "본 계약" 또는 "본 약관"이라 함)은 본문, "개인정보 보호약관", "고객 파악 및 자금세탁 방지 정책" 및 기타 다양한 정책으로 구성됩니다. 본 웹사이트에 이미 게시되었거나 향후 게시될 수 있습니다. 수업 규칙, 진술, 설명 등。',
        content18: "1.2 귀하께서는 본 사이트에서 제공하는 서비스를 이용하시기 전에 본 약관을 주의 깊게 읽어 보시기 바라며, 이해가 되지 않거나 기타 필요한 사항이 있는 경우에는 전문변호사와 상담하시기 바랍니다. 귀하가 언제든지 본 계약 및/또는 그 수정 사항에 동의하지 않는 경우, 즉시 본 웹사이트에서 제공하는 서비스 사용을 중단하거나 더 이상 본 웹사이트에 로그인하지 마십시오. 귀하가 본 웹사이트에 로그인하고 본 웹사이트의 서비스를 사용하거나 기타 유사한 행위를 하면 이는 본 웹사이트에서 언제든지 본 계약을 수정하는 것을 포함하여 본 계약의 내용을 이해하고 완전히 동의했음을 의미합니다.。",
        content19: '1.3 귀하는 본 웹사이트의 요구사항에 따라 관련 사항을 기입하신 후, 기타 관련 절차를 거쳐 회원가입을 완료하시면 본 웹사이트의 회원(이하 "회원")이 되실 수 있습니다. 가입 과정에서 "동의함" 버튼을 클릭하시면 됩니다. 회사와의 계약서 양식, 또는 본 웹사이트 이용 중 "동의" 또는 이와 유사한 의미로 표시된 버튼을 클릭하거나, 본 웹사이트가 허용하는 다른 방식으로 본 웹사이트에서 제공하는 서비스를 실제로 이용하는 경우 , 이는 귀하가 본 계약의 모든 조건을 완전히 이해하고 동의하며 수락한다는 것을 의미합니다. 귀하의 자필 서명이 없어도 귀하에 대한 본 계약의 법적 구속력에는 영향을 미치지 않습니다.。',
        content20: "1.4 본 웹사이트의 회원이 된 후, 귀하는 회원 계정과 해당 비밀번호를 받게 되며, 이를 관리할 책임은 귀하에게 있으며, 귀하의 계정으로 수행되는 모든 활동과 이벤트에 대한 법적 책임은 귀하에게 있습니다.。",
        content21: "1.5 본 웹사이트의 회원이 된 회원만이 본 웹사이트에서 제공하는 디지털 자산 거래 플랫폼을 사용하여 거래할 수 있으며, 기타 회원에게만 제공되는 본 웹사이트에서 규정한 서비스를 누릴 수 있습니다. 비회원은 웹사이트 로그인, 웹사이트 탐색만 할 수 있습니다. 및 본 웹사이트에서 규정하는 기타 서비스.。",
        content22: "1.6 본 웹사이트에서 제공하는 모든 서비스와 기능을 등록하고 이용함으로써 귀하는 이 내용을 읽고, 이해하고, 동의한 것으로 간주됩니다.：",
        content23: "1.6.1 본 계약의 모든 이용 약관에 동의하십시오.。",
        content24: "1.6.2 귀하는 18세 이상이거나 다른 관련 법률에 따라 계약을 체결할 수 있는 법적 연령임을 확인합니다. 본 웹사이트에 대한 귀하의 등록, 판매 또는 구매, 정보 게시 등 및 본 웹사이트의 서비스 수락은 규정을 준수해야 합니다. 귀하의 요구 사항에 따라 주권 국가 또는 지역의 관련 법률 및 규정에 대한 관할권을 가지며 이러한 조건을 수락하고 거래를 체결하며 디지털 자산 거래를 위해 이 웹사이트를 사용할 수 있는 모든 권한을 갖습니다.。",
        content25: "1.6.3 귀하는 귀하에게 속한 거래와 관련된 모든 디지털 자산이 합법적으로 취득되었으며 소유권을 가지고 있음을 보장합니다.。",
        content26: "1.6.4 귀하는 자신의 거래 또는 비거래 활동과 이익 또는 손실에 대해 전적으로 책임을 진다는 데 동의합니다.。",
        content27: "1.6.5 귀하는 등록 시 제공한 정보가 사실이고 정확함을 확인합니다.。",
        content28: "1.6.6 귀하는 거래 이익 보고를 포함하여 세금 목적으로 관련 법률의 요구 사항을 준수하는 데 동의합니다.。",
        content29: "1.6.7 귀하는 본 웹사이트에서 제공하는 서비스와 관련 여부를 불문하고, 언제든지 본 웹사이트 또는 회사의 이익을 해치는 행위나 활동에 가담하지 않을 것에 동의합니다.。",
        content30: "1.6.8 본 계약은 귀하와 당사 사이의 권리와 의무만을 규율하며, 본 웹사이트 사용자와 다른 웹사이트 사용자 및 귀하 사이의 디지털 자산 거래로 인해 발생하는 법적 관계 및 법적 분쟁을 포함하지 않습니다.。",
        content31: "2. 계약 개정",
        content32: "당사는 수시로 본 약관을 개정할 수 있으며, 이를 별도로 공지하지 않고 홈페이지에 공지할 수 있으며, 개정 후 본 약관에 표시된 [최종 업데이트 시간]에 개정 시점이 표시되며, 자동으로 개정된 시간이 적용됩니다. 웹사이트에 게시되면 효력이 발생합니다. 귀하는 수시로 본 계약의 업데이트 시간과 업데이트된 내용을 검색하고 주의를 기울여야 하며, 관련 변경 사항에 동의하지 않는 경우 즉시 본 사이트 서비스 이용을 중단해야 하며 본 서비스를 계속해서 이용해야 합니다. 웹사이트는 귀하가 개정된 계약을 수락하고 이에 구속된다는 것을 의미합니다.。",
        content33: "3. 등록",
        content34: "3.1 등록자격",
        content35: "귀하는 등록 절차를 완료하거나 본 웹사이트에서 허용하는 다른 방식으로 본 웹사이트에서 제공하는 서비스를 실제로 사용할 때 해당 법률에 규정된 대로 본 계약에 서명하고 본 웹사이트의 서비스를 사용할 수 있어야 함을 확인하고 약속합니다. 자연인, 법인 또는 기타 조직. 귀하가 등록 동의 버튼을 클릭하면 귀하 또는 귀하의 위임된 대리인이 본 약관의 내용에 동의하고 그의 대리인이 본 사이트에 등록하여 서비스를 이용하는 것을 의미합니다. 귀하가 상기 대상 자격을 갖추지 못한 경우, 귀하와 귀하의 위임 대리인은 이로 인해 발생하는 모든 결과를 책임져야 하며, 회사는 귀하의 계정을 취소하거나 영구적으로 동결하고 귀하와 귀하의 위임 대리인에게 책임을 물을 권리가 있습니다.。",
        content36: "3.2 등록 목적",
        content37: "귀하는 본 웹사이트에 등록하는 것이 법률 및 규정을 위반하거나 본 웹사이트의 디지털 자산 거래 질서를 방해할 목적이 아님을 확인하고 약속합니다.",
        content38: "3.3등록 절차",
        content39: "3.3.1 귀하는 본 웹사이트의 사용자 등록 페이지에서 요구되는 유효한 이메일 주소, 휴대폰 번호 및 기타 정보를 제공하는 데 동의합니다. 귀하가 제공했거나 확인한 이메일 주소, 휴대폰 번호 또는 본 웹사이트에서 허용하는 기타 방법을 로그인으로 사용할 수 있습니다. 이 웹사이트에 들어가는 방법입니다. 필요한 경우, 다양한 관할권의 관련 법률 및 규정에 따라 귀하는 법률 및 규정, 개인 정보 보호 조항 및 자금 세탁 방지 조항에 규정된 실명, 신분증 및 기타 관련 정보를 제공해야 하며 등록 정보를 지속적으로 업데이트해야 합니다. 시기 적절하고 상세하며 정확한 방식으로 요구됩니다. 원래 입력한 모든 정보는 등록 정보로 참조됩니다. 귀하는 해당 정보의 신뢰성, 완전성 및 정확성에 대한 책임이 있으며, 이로 인해 발생하는 직간접적인 손실과 불리한 결과를 부담해야 합니다.。",
        content40: "3.3.2 귀하가 위치한 주권 국가 또는 지역의 법률, 규정, 규칙, 명령 및 기타 규정에 따라 휴대전화번호에 대한 실명 요구가 있는 경우, 귀하는 등록된 휴대전화번호가 실명으로 등록되어 있음에 동의합니다. 귀하가 규정에 따라 이를 제공하지 않는 경우, 귀하에게 발생하는 모든 결과는 직접 또는 간접적인 손실을 초래할 것이며 불리한 결과는 귀하가 부담해야 합니다.。",
        content41: "3.3.3귀하가 등록에 필요한 정보를 적법하고 완전하고 효과적으로 제공하고 이를 확인한 경우, 귀하는 본 사이트의 계정번호와 비밀번호를 얻을 수 있는 자격을 갖게 됩니다. 성공적으로 완료되었으며 이 웹사이트에 회원으로 로그인하실 수 있습니다.。",
        content42: "3.3.4 귀하는 이 웹사이트의 관리 및 운영과 관련하여 이 웹사이트에서 보내는 이메일 및/또는 단문 메시지를 수신하는 데 동의합니다.。",
        content43: "4. 서비스",
        content44: "본 웹사이트는 본 웹사이트를 통한 귀하의 디지털 자산 거래 활동(디지털 자산 거래 및 기타 서비스를 포함하되 이에 국한되지 않음)을 위한 온라인 거래 플랫폼 서비스만 제공합니다.。",
        content45: "4.1 서비스 내용",
        content46: "4.1.1 귀하는 본 웹사이트에서 다양한 디지털 자산 상품의 실시간 시장 상황과 거래 정보를 검색할 권리가 있으며, 본 웹사이트를 통해 디지털 자산 거래 지시서를 제출하고 디지털 자산 거래를 완료할 권리가 있습니다.。",
        content47: "4.1.2 귀하는 본 웹사이트의 회원 계정에 있는 정보를 볼 수 있는 권리가 있으며, 본 웹사이트가 제공하는 기능을 적용하여 운영할 수 있습니다.。",
        content48: "4.1.3 귀하는 본 웹사이트에 게시된 활동 규칙에 따라 본 웹사이트에서 주최하는 웹사이트 활동에 참여할 권리가 있습니다.。",
        content49: "4.1.4 本웹사이트가 귀하에게 제공하기로 약속하는 기타 서비스。",
        content50: "4.2 서비스 규칙 귀하는 본 사이트의 다음 서비스 규칙을 준수할 것을 약속합니다.：",
        content51: "4.2.1귀하는 법률, 규정 및 정책 요구 사항을 준수해야 하며 귀하 계정에 있는 모든 디지털 자산 출처의 합법성을 보장해야 하며 본 사이트 또는 본 사이트에서 제3자의 권리와 이익에 해를 끼치는 불법 활동이나 기타 활동에 참여해서는 안 됩니다. 또는 다음을 포함하되 이에 국한되지 않는 서비스 사용: 불법적이거나 불법적이거나 타인의 권리를 침해하는 정보의 전송 또는 수신, 다단계나 기타 유해한 정보 또는 발언의 전송 또는 수신, 이메일 헤더 정보의 사용 또는 위조 본 웹사이트의 승인 없이 본 웹사이트에。",
        content52: "4.2.2 귀하는 법령과 규정을 준수하고, 계좌번호, 로그인 비밀번호, 펀드 비밀번호, 회원가입 시 연동된 휴대전화번호, 휴대전화로 수신된 휴대전화 인증번호를 적절하게 사용하고 보관해야 합니다. 귀하의 계정과 로그인 비밀번호, 펀드 비밀번호, 휴대전화 인증번호를 사용하여 발생하는 모든 운영 및 결과에 대한 책임은 전적으로 귀하에게 있습니다. 본 웹사이트의 계좌번호, 로그인 비밀번호, 펀드 비밀번호 또는 인증코드가 귀하의 승인 없이 제3자에 의해 사용되거나 기타 계정 보안 문제가 있음을 발견한 경우, 귀하는 즉시 본 웹사이트에 통보하고 본 웹사이트의 정지를 요청해야 합니다. 본 웹사이트 계정의 서비스. . 본 웹사이트는 합리적인 시간 내에 귀하의 요청에 대해 조치를 취할 권리가 있지만, 조치를 취하기 전에 발생한 결과(귀하가 겪는 손실을 포함하되 이에 국한되지 않음)에 대해 어떠한 책임도 지지 않습니다. 귀하는 본 웹사이트의 동의 없이 귀하의 웹사이트 계정을 타인에게 기부, 대여, 임대, 양도 또는 기타 방식으로 처분할 수 없습니다.。",
        content53: "4.2.3 귀하는 이 웹사이트에서 귀하의 계정 및 비밀번호로 발생하는 모든 활동(정보 공개, 정보 게시, 온라인 동의 클릭, 다양한 규칙 및 계약 제출, 온라인 계약 갱신 또는 구매를 포함하되 이에 국한되지 않음)에 대해 책임을 지는 데 동의합니다. 서비스 등)。",
        content54: "4.2.4 본 웹사이트에서 디지털 자산 거래를 수행할 때 디지털 자산 거래의 정상적인 진행을 악의적으로 방해하거나 거래 순서를 방해해서는 안 되며, 본 웹사이트의 정상적인 운영을 방해하거나 다른 사용자의 본 웹사이트 사용을 방해해서는 안 됩니다. 기술적 수단이나 그 밖의 방법으로 서비스를 제공하는 경우, 허위의 내용을 이용하거나 사실 기타 수단을 통해 본 사이트의 영업권을 악의적으로 훼손하는 행위를 해서는 안 됩니다.。",
        content55: "4.2.5 귀하는 온라인 거래로 인해 다른 이용자와 분쟁이 발생한 경우 비사법적, 행정적 경로를 통해 본 웹사이트에 관련 정보 제공을 요청할 수 없습니다.。",
        content56: "4.2.6 귀하는 본 웹사이트에서 제공하는 서비스를 사용하는 동안 발생하는 모든 하드웨어, 소프트웨어, 서비스 및 기타 비용뿐만 아니라 납부해야 할 세금을 단독으로 판단하고 부담해야 합니다.。",
        content57: "4.2.7 귀하는 본 사이트에서 수시로 게시하고 업데이트하는 본 계약 및 기타 서비스 조건과 운영 규칙을 준수해야 하며 언제든지 본 사이트에서 제공하는 서비스의 사용을 종료할 권리가 있습니다.。",
        content58: "4.3 제품 규칙",
        content59: "4.3.1 거래 정보 찾아보기",
        content60: "본 웹사이트에서 거래 정보를 검색할 때 가격, 수수료 수량, 처리 수수료, 매수 또는 매도 방향을 포함하되 이에 국한되지 않는 거래 정보에 포함된 모든 내용을 주의 깊게 읽어야 하며, 해당 내용에 포함된 모든 내용을 완전히 수락한 후에만 가능합니다. 거래정보 버튼 클릭만으로 거래 가능。",
        content61: "4.3.2 거래 세부정보 보기",
        content62: "귀하의 계정을 통해 해당 거래 기록을 볼 수 있습니다.。",
        content63: "5. 본 사이트의 권리와 의무",
        content64: "5.1 귀하가 본 약관에서 규정한 등록 자격을 갖추지 못한 경우, 본 사이트는 귀하의 등록을 거부할 권리가 있으며, 이미 등록한 경우, 본 사이트는 귀하의 회원 계정을 취소할 권리를 갖습니다. 귀하의 공인 대리인이 책임을 지는 것이 맞습니다. 동시에 본 웹사이트는 기타 상황에서 귀하의 등록을 수락할지 여부를 결정할 권리를 보유합니다.。",
        content65: "5.2 본 웹사이트의 자체 판단에 따라 귀하 또는 귀하의 관련 계정 사용자가 고위험 투자에 적합하지 않다고 본 웹사이트가 판단하는 경우 당사는 귀하의 계정 및 모든 관련 계정의 사용을 일시 중지하거나 종료할 권리가 있습니다.。",
        content66: "5.3 본 웹사이트는 계정 사용자가 계정의 최초 등록자가 아님을 발견한 경우 해당 계정의 사용을 일시 중지하거나 종료할 권리가 있습니다.。",
        content67: "5.4 본 웹사이트는 기술적 테스트, 수동 샘플링 및 기타 테스트 방법을 통해 귀하가 제공한 정보가 잘못되었거나 사실이 아니거나 유효하지 않거나 불완전하다고 합리적으로 의심하는 경우 귀하에게 정보를 수정 또는 업데이트하도록 통지하거나 서비스 제공을 일시 중지 또는 종료할 권리가 있습니다. 이 웹사이트는 귀하에게 서비스를 제공합니다.。",
        content68: "5.5 본 웹사이트는 명백한 오류가 발견된 경우 본 웹사이트에 표시된 모든 정보를 수정할 권리를 보유합니다.。",
        content69: "5.6 본 웹사이트는 언제든지 본 웹사이트의 서비스를 수정, 중단 또는 종료할 수 있는 권리를 가집니다. 본 웹사이트는 서비스 수정 또는 종료 권리를 행사하기 위해 귀하에게 사전 통지할 필요가 없습니다. 본 웹사이트가 본 사이트의 하나 이상의 서비스를 종료하는 경우 홈페이지의 종료는 본 홈페이지의 홈페이지를 통해 이루어지며, 종료 공지 게시일로부터 효력이 발생합니다.。",
        content70: "5.7 본 웹사이트는 본 웹사이트의 정상적인 운영을 보장하고 필요하고 안정적인 거래 환경과 거래 서비스를 제공하며 디지털 자산 거래 질서를 유지하기 위해 필요한 기술적 수단과 관리 조치를 취합니다.。",
        content71: "5.8 귀하가 연속 1년 동안 귀하의 회원 계정과 비밀번호를 사용하여 본 웹사이트에 로그인하지 않는 경우, 본 웹사이트는 귀하의 계정을 취소할 권리를 갖습니다. 계정이 취소된 후, 본 웹사이트는 등록 및 사용을 위해 해당 회원 이름을 다른 사용자에게 공개할 권리가 있습니다.。",
        content72: "5.9 본 웹사이트는 기술 투자 강화, 보안 예방 조치 및 기타 조치 개선을 통해 귀하의 디지털 자산의 보안을 보장하며, 귀하의 계정에 예측 가능한 보안 위험이 발생할 경우 사전에 귀하에게 통지할 것입니다.。",
        content73: "5.10 本본 사이트는 법률, 규정 또는 본 사이트의 규정을 준수하지 않는 본 사이트의 모든 종류의 콘텐츠 정보를 언제든지 삭제할 권리가 있으며, 본 사이트는 그러한 권리 행사를 위해 귀하에게 사전 통지할 필요가 없습니다.。",
        content74: "5.11 본 웹사이트는 귀하가 거주하는 주권 국가 또는 지역의 법률, 규정, 규칙, 명령 및 기타 규제 요구 사항에 따라 더 많은 정보나 자료를 제공하고 현지 규제 요구 사항을 준수하기 위한 합리적인 조치를 취할 것을 요청할 권리가 있습니다. 본 웹사이트는 귀하의 주권 국가 또는 지역의 법률, 규정, 규칙, 명령 및 기타 요구 사항에 따라 귀하에게 제공되는 본 웹사이트 서비스의 일부 또는 전부를 일시 중지하거나 영구적으로 중단할 권리가 있습니다.。",
        content75: "5.12 이 웹사이트는 단독 재량에 따라 영업일 기준 7일 전에 통지한 후 귀하의 계정을 폐쇄할 권리를 보유합니다. 따라서 주문을 취소하고 포지션을 청산하는 데 영업일 기준 7일이 소요됩니다. 기간 만료 시 주문을 취소하지 않거나 포지션을 청산하지 않은 경우 당사는 강제로 주문을 취소하고 포지션을 청산하며, 귀하의 계정에 남아 있는 디지털 자산을 귀하에게 반환합니다.。",
        content76: "5.13 거래자의 권리와 이익을 보호하기 위해 본 웹사이트는 조기 배송 계약, 정산, 계약종류, 조기배송 및 정산 계약 등의 주기와 정산, 배송가격 등은 본 홈페이지에 공지된 바에 따릅니다.。",
        content77: "6. 보상",
        content78: "6.1 어떠한 경우에도 직접적인 손해에 대한 당사의 책임은 3개월 동안 귀하가 이 웹사이트를 사용하는 데 대해 청구한 총 서비스 요금을 초과하지 않습니다.。",
        content79: "6.2 귀하가 본 약관 또는 기타 법령을 위반한 경우 귀하는 당사에 최소 200만 달러를 배상하고 이에 따른 모든 비용(변호사 비용 등 포함)을 부담해야 하며, 실제 손실을 보상하기에 충분하지 않은 경우 귀하는 이를 보상해야 합니다. 그것을 위해.。",
        content80: "7. 금지명령 구제를 구할 권리",
        content81: "당사와 귀하는 모두 귀하의 계약 위반 또는 계약 위반 가능성에 대한 관습법 구제책이 우리가 겪는 모든 손실을 보상하기에 충분하지 않을 수 있음을 인정합니다. 귀하의 계약 위반 또는 계약 위반 가능성이 있는 경우 기타 모든 구제책。",
        content82: "8. 책임의 제한 및 면책조항",
        content83: "8.1 귀하는 어떠한 경우에도 당사가 다음 사항에 대해 책임을 지지 않음을 이해하고 이에 동의합니다.：",
        content84: "8.1.1 소득 손실；",
        content85: "8.1.2 거래 이익 또는 계약 손실；",
        content86: "8.1.3 영업 중단으로 인한 손실；",
        content87: "8.1.4 예상되는 금전적 절감액 손실；",
        content88: "8.1.5 정보 문제로 인한 손실；",
        content89: "8.1.6 기회, 영업권 또는 평판의 상실；",
        content90: "8.1.7 데이터 손상 또는 손실；",
        content91: "8.1.8 대체 제품이나 서비스 구매 비용；",
        content92: "8.1.9 불법 행위(과실 포함), 계약 위반 또는 기타 사유로 인해 발생하는 간접적, 특별 또는 부수적 손실이나 손해(해당 손실이나 손해가 당사에서 합리적으로 예측 가능한지 여부, 당사가 해당 사실을 인지했는지 여부에 관계 없음) 분실 또는 파손 가능성。",
        content93: "8.1.1 8.1.9 조항은 서로 독립적입니다.。",
        content94: "8.2 귀하는 다음 상황으로 인해 귀하에게 발생한 손해에 대해 당사가 책임을 지지 않는다는 점을 이해하고 이에 동의합니다.：",
        content95: "8.2.1 귀하의 특정 거래에는 심각한 법률 위반이나 계약 위반이 포함될 수 있습니다.。",
        content96: "8.2.2 이 웹사이트에서의 귀하의 행동은 불법적이거나 부도덕한 것으로 의심됩니다。",
        content97: "8.2.3 본 웹사이트의 서비스 또는 기타 대체 행위를 통해 데이터, 정보를 구매 또는 획득하거나 거래를 수행함으로써 발생하는 비용 및 손실。",
        content98: "8.2.4 본 웹사이트의 서비스에 대한 귀하의 오해。",
        content99: "8.2.5 기타 본 웹사이트에서 제공하는 서비스와 관련하여 당사의 귀책사유가 아닌 손실。",
        content100: "8.3 우리는 정보 네트워크 장비 유지 관리, 정보 네트워크 연결 장애, 컴퓨터, 통신 또는 기타 시스템 장애, 정전, 기상 조건, 사고, 파업, 노동 쟁의, 폭동, 폭동, 생산성 또는 생산 자재 부족, 화재, 홍수에 대한 책임이 있습니다. , 폭풍, 폭발, 전쟁, 은행 또는 기타 파트너 사유, 디지털 자산 시장 붕괴, 정부 조치, 사법 또는 행정 기관의 명령, 당사가 통제할 수 없거나 통제할 수 없는 기타 행위, 또는 제3자의 사유 서비스 불능이나 지연 또는 귀하의 손실에 대해 어떠한 책임도 지지 않습니다.。",
        content101: "8.4 당사는 본 웹사이트에 포함된 모든 정보, 프로그램, 텍스트 등이 완전히 안전하며 어떠한 바이러스, 트로이 목마 및 기타 악성 프로그램에 의해 방해를 받거나 파괴되지 않는다는 점을 보장할 수 없습니다. 이 웹 사이트나 다운로드한 프로그램, 정보, 데이터 등을 다운로드하고 사용하는 것은 모두 귀하의 개인적인 결정이며 위험과 가능한 손실은 귀하가 부담해야 합니다.。",
        content102: "8.5 당사는 본 웹사이트에 링크된 제3자 웹사이트의 정보, 제품, 서비스 또는 당사 소유가 아닌 기타 형태의 콘텐츠에 대해 어떠한 보장이나 약속도 하지 않습니다. -당사 웹사이트, 제품 등은 모두 귀하의 결정이며 이로 인해 발생하는 모든 책임은 귀하에게 있습니다.",
        content103: "8.6 당사는 본 웹사이트에서 제공하는 서비스의 적합성, 오류 또는 누락 없음, 연속성, 정확성, 신뢰성 및 특정 서비스에 대한 적합성을 포함하되 이에 국한되지 않는 본 웹사이트의 서비스 사용에 대해 어떠한 명시적 또는 묵시적 보증도 하지 않습니다. 목적. 동시에, 우리는 본 웹사이트에서 제공하는 서비스와 관련된 기술 및 정보의 유효성, 정확성, 정확성, 신뢰성, 품질, 안정성, 완전성 및 적시성에 대해 어떠한 약속이나 보증도 하지 않습니다. 본 웹사이트에 로그인하거나 본 사이트에서 제공하는 서비스를 이용할지 여부는 귀하의 개인적인 결정이며, 귀하는 위험과 손실 가능성을 스스로 부담해야 합니다. 당사는 디지털 자산의 시장, 가치 및 가격에 관해 어떠한 명시적 또는 묵시적 보증도 하지 않습니다. 귀하는 디지털 자산 시장이 불안정하다는 점을 이해하고 이해합니다. 가격과 가치는 언제든지 크게 변동하거나 붕괴될 수 있습니다. 디지털 자산 거래는 귀하의 개인적인 자유입니다. 귀하의 책임과 가능한 손실을 선택하고 결정하십시오.。",
        content104: "8.7 본 계약에 명시된 당사의 보증 및 약속은 본 계약 및 본 웹 사이트에서 제공되는 서비스와 관련하여 당사가 제공하는 유일한 보증 및 진술이며 서면, 구두, 명시적이든 다른 방식 및 방식으로 발생하는 모든 보증 및 약속을 대체합니다. 또는 암시. 이러한 모든 보증 및 진술은 당사 자체의 약속 및 보증만을 나타낼 뿐 제3자가 본 계약의 보증 및 약속을 준수할 것이라고 보장하지 않습니다.。",
        content105: "8.8 당사는 본 계약에 언급되지 않은 손해배상 책임을 해당 법률의 최대 범위까지 제한, 배제 또는 상쇄할 수 있는 권리를 포기하지 않습니다.。",
        content106: "8.9 귀하가 등록한 후, 귀하는 본 계약에 규정된 규칙에 따라 당사가 수행하는 모든 작업에 동의하게 되며, 이로 인해 발생하는 모든 위험은 귀하가 부담하게 됩니다.。",
        content107: "9, 계약 종료",
        content108: "9.1 본 사이트는 본 약관의 규정에 따라 본 사이트의 모든 서비스를 종료할 권리를 가지며, 본 약관은 본 사이트의 모든 서비스가 종료되는 날에 종료됩니다.。",
        content109: "9.2 본 계약이 종료된 후, 귀하는 본 웹사이트에 서비스를 계속 제공하도록 요구하거나 기타 의무를 수행하도록 요구할 권리가 없습니다. 여기에는 본 웹사이트가 원래 웹사이트 계정의 정보를 귀하에게 유지하거나 공개하도록 요구하는 것을 포함하되 이에 국한되지 않습니다. 귀하 또는 제3자에게 제공되며, 해당 제3자는 자신이 읽거나 보내지 않은 정보 등을 전달합니다.。",
        content110: "9.3 본 계약의 종료는 위반 당사자에 대해 다른 책임을 져야 하는 준수 당사자의 요구 사항에 영향을 미치지 않습니다.。",
        content111: "10. 지적재산권",
        content112: "10.1 본 웹사이트에 포함된 모든 지적 성과에는 웹사이트 로고, 데이터베이스, 웹사이트 디자인, 텍스트 및 그래픽, 소프트웨어, 사진, 비디오, 음악, 사운드 및 이들의 조합, 소프트웨어 편집, 관련 소스 코드 및 소프트웨어(소형 응용 프로그램 포함)가 포함되지만 이에 국한되지는 않습니다. ) 프로그램 및 스크립트의 지적재산권)은 본 웹사이트에 귀속됩니다. 귀하는 전술한 자료나 콘텐츠를 상업적 목적으로 복제, 변경, 복사, 전송 또는 사용할 수 없습니다.。",
        content113: "10.2 본 웹사이트 이름에 포함된 모든 권리(영업권, 상표, 로고를 포함하되 이에 국한되지 않음)는 회사에 귀속됩니다.。",
        content114: "10.3 본 계약에 대한 귀하의 수락은 복제권, 배포권, 임대권, 전시권, 공연권, 상영권, 방송권, 정보 인터넷 전송권, 촬영권, 개작권, 번역권, 편집권 및 기타 양도 가능한 권리는 저작권자가 누려야 하며 본 웹사이트에 무료로 독점적으로 양도됩니다. 침해 대상에 대해 소송을 제기하고 전액 보상을 받으십시오. 본 계약은 귀하가 본 웹사이트에 게시한 저작권법에 의해 보호되는 모든 콘텐츠에 대해 유효하며, 콘텐츠가 본 계약에 서명하기 전이든 후에든 관계없이 유효합니다.。",
        content115: "10.4 귀하는 본 사이트의 서비스를 이용함에 있어 본 사이트 또는 타인의 지적재산권을 불법적으로 사용하거나 처분할 수 없습니다. 귀하는 이 웹사이트에 게시된 정보를 어떤 형태로든 게시할 수 없으며 다른 웹사이트(및 미디어)가 이를 사용하도록 승인할 수 없습니다.。",
        content116: "11. 정보보호",
        content117: '본 웹사이트에 별도로 게시된 "개인정보 보호약관"의 조항이 우선 ​​적용됩니다.。',
        content118: "12. 계산",
        content119: "모든 거래 계산 결과는 당사에서 검증되었으며 모든 계산 방법은 웹사이트에 게시되어 있지만 웹사이트 사용이 중단되거나 오류가 발생하지 않을 것이라고 보장할 수는 없습니다.。",
        content120: "13. 수출 통제",
        content121: "您관련 법률에 따라 귀하는 본 웹사이트의 자료(소프트웨어 포함)를 수출, 재수출, 수입 또는 양도할 수 없음을 이해하고 인정합니다. 위에서 언급한 수출 또는 관련 양도가 법률 및 규정을 위반하거나 기타 해당 법률 및 규정을 위반하는 경우, 그러한 상황을 발견한 경우 적시에 적극적으로 보고하고 처리하도록 지원해야 합니다.。",
        content122: "14. 전송",
        content123: "본 계약에 규정된 권리와 의무는 해당 권리와 의무의 혜택을 받는 당사자의 양수인, 상속인, 집행자 및 관리자에게도 구속력을 갖습니다. 귀하는 이를 당사의 동의 없이 제3자에게 양도할 수 없지만, 당사는 언제든지 본 계약에 따른 당사의 권리와 의무를 제3자에게 양도하고 귀하에게 통지할 수 있습니다.。",
        content124: "15. 가분성",
        content125: "본 계약의 특정 조항이 관할 법원에서 시행 불가능하거나 유효하지 않거나 불법적인 것으로 판단되는 경우에도 이는 본 계약의 나머지 조항의 유효성에 영향을 미치지 않습니다.。",
        content126: "16. 비대리관계",
        content127: "본 계약에 달리 규정된 경우를 제외하고, 본 계약의 어떤 조항도 당사를 귀하의 대리인, 수탁자 또는 기타 대리인으로 생성, 암시 또는 구성하는 것으로 간주되지 않습니다.。",
        content128: "17. 기권",
        content129: "당사 또는 귀하가 계약 위반에 대한 책임 또는 본 계약에 규정된 기타 책임을 포기하는 것은 계약 위반에 대한 다른 책임을 포기하는 것으로 간주되거나 해석되지 않습니다. 그러한 권리나 구제책을 포기하는 것으로 해석될 수 있습니다.。",
        content130: "18. 제목",
        content131: "모든 제목은 계약 표현의 편의를 위한 것이며 계약 조건의 내용이나 범위를 확장하거나 제한하는 데 사용되지 않습니다.。",
        content132: "19. 준거법",
        content133: "19.1 분쟁이나 청구를 제기하기 전에 귀하는 분쟁을 비공식적으로 해결하기 위해 먼저 이 웹 사이트에 이메일을 통해 연락한다는 것을 인정하고 동의합니다. 우리는 가능한 한 빨리 내부적으로 분쟁을 해결하도록 노력할 것입니다. 양 당사자는 선의의 협상을 통해 분쟁을 해결하는 데 동의합니다(토론). 기밀이어야 하며 해당 규칙에 따라 보호되어야 하며 법적 절차에서 증거로 사용되지 않습니다.)。",
        content134: '19.2 귀하는 본 약관의 존재, 타당성, 해석, 이행, 위반 또는 종료를 포함하여 분쟁, 논쟁, 불일치 또는 청구가 있는 경우 또는 본 약관으로 인해 또는 이와 관련하여 발생하는 모든 분쟁("분쟁")이 발생하는 경우 이를 인정하고 이에 동의합니다. ), 위에 언급된 당사자들은 당시 유효한 "ICC 중재 규칙"에 따라 분쟁을 국제 상공회의소의 국제 중재 재판소("ICC 국제 중재 재판소")에 제출해야 합니다. 중재 장소는 벨리즈이며 중재에 적용되는 법률은 국제 상공회의소 중재 규칙에 따라 결정됩니다. 당사자들이 달리 합의하지 않는 한, ICC 중재 규칙에 따라 단 한 명의 중재인만 임명됩니다. 중재인은 중재 합의의 존재, 범위 또는 유효성에 대한 이의제기나 청구 또는 반소의 중재 가능성을 포함하되 이에 국한되지 않는 자신의 관할권에 대해 판결할 독점적인 권한을 갖습니다. 모든 중재는 영어로 진행됩니다. 중재가 어떻게 진행되는지에 관계없이 중재인은 판정과 판정의 기초가 되는 필수 결론을 설명하기에 합리적이고 충분한 서면 판정을 발행해야 합니다. 중재 판정은 최종적이며 당사자들에 대해 구속력을 가지며 관할권이 있는 모든 법원에서 집행될 수 있습니다.。',
        content135: "20. 계약의 유효성과 해석",
        content136: "20.1 본 계약은 귀하가 본 웹사이트의 등록 페이지를 클릭하여 등록, 등록 절차 완료, 본 웹사이트의 계정 및 비밀번호 획득에 동의할 때 발효되며 본 웹사이트와 귀하 모두에게 구속력을 갖습니다.。",
        content137: "20.2 본 계약의 최종 해석권은 본 웹사이트에 귀속됩니다.。",
        content138: "고객 및 자금세탁 방지 정책을 숙지하세요.",
        content139: "1. 소개",
        content140: '1.1 우리는 "Know Your Customer" 및 자금세탁 방지와 관련된 법률 및 규정을 주의 깊게 준수할 것을 약속하며 "Know Your Customer 및 Anti-Money Laundering Policy"를 고의적으로 위반하지 않습니다. 당사는 합리적인 통제 범위 내에서 귀하에게 안전한 서비스를 제공하고, 범죄 용의자의 자금세탁 행위로 인한 손실로부터 귀하를 최대한 보호하기 위해 필요한 조치와 기술을 강구할 것입니다.。',
        content141: "1.2 당사의 고객 파악 및 자금 세탁 방지 정책은 귀하가 소속된 다양한 법적 관할권에 대한 고객 파악 및 자금 세탁 방지 정책을 포함하는 포괄적인 국제 정책 시스템입니다.。",
        content142: "2. 귀하의 고객 및 자금세탁 방지 정책은 다음과 같습니다.：",
        content143: "2.1 고객 파악 및 자금 세탁 방지 정책을 공표하고 관련 법률 및 규정에서 정한 기준을 충족하도록 수시로 업데이트합니다.；",
        content144: "2.2 본 웹사이트 운영에 관한 몇 가지 지도 원칙과 규칙을 공포하고 업데이트하며, 당사 직원은 이러한 원칙과 규칙의 지침에 따라 서비스를 제공합니다.；",
        content145: "2.3 엄격한 신원 확인, 자금세탁 방지 업무를 담당하는 전문팀 구성 등 거래 내부 모니터링 및 통제를 위한 절차를 설계하고 완성합니다.；",
        content146: "2.4 고객에 대한 실사 및 지속적인 감독을 수행하기 위해 위험 예방 접근 방식을 채택합니다.；",
        content147: "2.5 발생한 거래를 검토하고 정기적으로 확인하세요.；",
        content148: "2.6 의심스러운 거래를 관할 당국에 신고하세요.；",
        content149: "2.7 신원 증명, 주소 및 거래 기록은 최소 6년 동안 보관되며 귀하에게 추가 통지 없이 규제 당국에 제출될 수 있습니다.。",
        content150: "3. 신원정보 및 확인",
        content151: "3.1 신원정보",
        content152: "3.1.1 다양한 관할권 및 다양한 법인 유형의 다양한 규정에 따라 당사가 수집하는 귀하의 정보 내용은 일관되지 않을 수 있습니다. 원칙적으로 다음 정보는 등록된 개인으로부터 수집됩니다.：",
        content153: "기본 개인 정보: 귀하의 이름, 주소(다른 경우 영구 주소도 포함), 생년월일, 국적 및 기타 이용 가능한 정보. 신원 확인은 여권, 신분증, 기타 관할권에서 요구하고 실행하는 기타 신원 확인 서류 등 공식 기관 또는 기타 유사한 기관에서 발행한 문서를 기반으로 해야 합니다. 귀하가 제공한 주소는 여객운송권이나 이자율표 확인, 선거인명부 확인 등 적절한 방법을 통해 확인됩니다.。",
        content154: "유효한 사진: 등록하기 전에 신분증을 가슴에 대고 있는 본인의 사진을 제공해야 합니다.；",
        content155: "연락처 정보: 전화/휴대폰 번호 및/또는 유효한 이메일 주소。",
        content156: "3.1.2 귀하가 법인 또는 기타 법인인 경우 당사는 귀하 또는 귀하의 신탁 계좌의 최종 수혜자를 식별하기 위해 다음 정보를 수집합니다.。",
        content157: "회사 등록 및 등록 증명서, 회사 정관 및 각서 사본, 회사 지분 구조 및 소유권 설명에 대한 자세한 인증 자료(본 웹사이트에서 계정 개설 및 실행을 결정하는 권한을 부여받은 주체의 이사회 결의를 증명함), 회사 필요에 따라 제공해야 하는 이사, 대주주의 신원 문서 및 본 웹사이트에서 계정에 서명하도록 권한을 부여받은 사람, 회사의 주요 사업장 주소가 회사의 우편 주소와 다른 경우 우편 주소를 제공하십시오. 회사의 현지 주소가 주 사업장 주소와 일치하지 않는 경우 고위험 고객으로 간주되어 추가 서류를 제출해야 합니다.。",
        content158: "다양한 관할 구역 및 다양한 유형의 법인체의 다양한 규정에 따라 당사가 요구하는 당국이 발행한 기타 인증서 및 문서와 당사가 필요하다고 간주하는 문서。",
        content159: "3.1.3 신원 정보는 영어 또는 중국어 버전만 허용되며, 그렇지 않은 경우 신원 정보를 영어로 번역한 후 공증을 받으시기 바랍니다.。",
        content160: "3.2 확인 확인",
        content161: "3.2.1 신원 확인 서류 제공을 요청하는 전체 페이지 콘텐츠。",
        content162: "3.2.2 신분증을 가슴에 대고 있는 사진을 요청합니다.。",
        content163: "3.2.3 증빙 서류의 사본은 일반적으로 원본 바우처와 대조하여 확인해야 합니다. 그러나 적절하고 신뢰할 수 있는 인증자가 사본이 원본 문서의 정확하고 완전한 복제임을 인증할 수 있는 경우 사본은 허용됩니다. 이러한 인증자에는 대사, 사법 위원, 지방 치안 판사 등이 포함됩니다.。",
        content164: "3.2.4 최종 수익자를 식별하고 계정을 통제하기 위한 요구 사항은 어떤 개인이 최종적으로 직접 고객을 소유하거나 통제하는지 결정하고/또는 진행 중인 거래가 다른 사람을 대신하여 실행되고 있음을 확인하는 것입니다. 기업인 경우에는 대주주(의결권 10% 이상 보유자 등)의 신원을 확인해야 합니다. 일반적으로 주식의 25%를 보유하는 것은 정상적인 위험에 속하는 것으로 간주되어 주주의 신원을 확인해야 하며, 주식의 10%를 보유하거나 더 많은 의결권이나 주식을 보유하는 것은 고위험 상황으로 간주됩니다. 그리고 주주의 신원을 확인해야합니다.。",
        content165: "4. 거래 모니터링",
        content166: "4.1 당사는 보안 및 실제 거래 조건에 따라 일일 거래 및 현금 인출 최대 한도를 수시로 설정하고 조정합니다.;",
        content167: "4.2 등록된 사용자에게 거래가 빈번하게 발생하거나 불합리한 사유가 있는 경우 당사 전문팀이 의심스러운 거래인지 평가하여 판단합니다.;",
        content168: "4.3 당사는 자체 판단에 따라 해당 거래가 의심스럽다고 판단하는 경우, 가능한 한 빠른 시일 내에 거래 정지, 거래 거부, 심지어는 거래 취소 등의 제한 조치를 취할 수 있으며, 이를 관할 당국에 신고할 수 있습니다. 당신에게 알리지 않습니다.;",
        content169: "4.4 우리는 국제 자금세탁 방지 기준을 준수하지 않는 관할 구역의 사람이나 정치적으로 노출된 사람으로 간주될 수 있는 사람의 등록 신청을 거부할 권리가 있습니다. 귀하에게 부여된 의무나 책임을 위반한 경우。",
    },
    register: {
        formTitle1: "만들다",
        formTitle2: "이메일이나 휴대폰 번호를 이용해 등록하세요",
        formTitle3: "계정",
        field1: "확인 코드",
        field2: "얻다",
        field3: "비밀번호 확인",
        field4: "邀请码초대 코드",
        field41: "（선택 과목）",
        field5: "읽었으며 이에 동의합니다.",
        field6: "서비스 약관",
        placeholder1: "인증번호를 입력해주세요",
        content7: "인증번호를 입력해주세요",
        content12: "전화번호를 입력해주세요",
        content13: "비밀번호를 입력 해주세요",
        content14: "이메일을 입력해주세요",
        content18: "비밀번호를 다시 입력해주세요",
        content19: "서비스 약관에 동의해주세요.",
        content20: "올바른 인증 코드를 입력하세요.",
        content23: "아직도 인증코드를 받지 못했습니다？",
        content24: "아래 인증번호를 입력해주세요",
        registerSuccess: "등록 성공",
        content25: "비밀번호가 일치하지 않습니다.",
        content26: "올바른 이메일 주소를 입력해주세요",
        content27: "비밀번호 재설정 성공"
    },
    download: {
        content1: "언제 어디서나 거래하세요",
        content2: "여러 단말기와 완벽하게 호환되어 언제든지 다양한 시나리오의 거래 요구 사항을 충족합니다.",
        content3: "언제든지 실시간 시장 상황을 확인하세요",
        content4: "BTC, ETH, XRP 및 기타 디지털 자산을 쉽게 사고 팔 수 있습니다.",
        content5: "관심 있는 암호화폐의 가격 변동에 대한 조기 경고를 받으세요",
        content6: "비트코인 현물 대출, 선물 대출 및 옵션 가격 보기",
        content7: "다양한 거래소의 암호화폐 가격 비교",
        title: "다운로드",
        install: "설치 참고사항",
        one: "1、설명 파일 다운로드 허용",
        two: "2、설명 파일이 다운로드되었습니다.",
        three: "3、휴대폰에서 [설정]으로 이동하여 [일반]을 찾으세요.",
        four: "4、프로필 및 장치 관리자",
        five: "5、프로필을 클릭하세요",
    },
    identity: {
        noAuthentication: {
            title: "주니어 인증",
            pleaseSelectYourCountry: "국가를 선택하세요.",
            name: "이름",
            enterName: "당신의 이름을 입력 해주세요",
            city: "도시",
            enterCity: "도시를 입력하세요.",
            IDNumber: "ID 번호",
            enterIDNumber: "귀하의 ID 번호를 입력해주세요",
        },
        noAdvancedAuthentication: {
            title: "고급인증",
            infoCountry: "국가/지역",
            infoName: "이름",
            infoNumber: "ID 번호",
            infoAddress: "주소",
        },
        text: "인증되지 않음",
        recertification: "재인증",
        certification: "인증",
        authenticationInProgress: "검증됨",
        inAuthentication: "인증 진행 중",
        uploadTheFrontOfTheDocument: "신분증 앞면 업로드",
        uploadTheReverseSideOfTheDocument: "증명서 뒷면을 업로드하세요.",
        uploadAPhotoOfAHandHeldID: "손에 들고 있는 신분증 사진을 업로드하세요.",
        pleaseUpload: "제발 ",
        pleaseCompleteOrdinaryCertificationFirst: "1차 인증 심사의 성공을 기다려야 함",
        refused: "거부됨",
        reason: "이유",
        succeeded: "성공했다",
        uploading: "업로드 중",
        newTitle: "신분증 발급 국가/지역을 선택하세요.",
        pleaseOrdinaryCertificationFirst: "기본 인증을 먼저 수행해 주세요.",
        text1: "아바타 수정",
        text2: "닉네임",
        text3: "계정",
        text4: "닉네임 수정",
        text5: "새로운 별명",
        text6: "새 닉네임을 입력하세요",
        text7: "기존 비밀번호",
        text8: "이전 비밀번호를 입력하세요",
        text9: "새 비밀번호",
        text10: "새 비밀번호를 입력하세요",
        text11: "비밀번호 확인",

        text12: "새 비밀번호를 다시 입력하세요.",
        text13: "새 우편함",
        text14: "확인 코드",
        text15: "인증번호를 입력해주세요",
        text16: "당신의 휴대폰 번호",
        text17: "새로운 휴대폰 번호",
        text18: "전화번호를 입력해주세요",
        text19: "휴대폰 인증코드",
        text20: "주니어 인증",
        text21: "신원정보를 확인해주세요",
        text22: "국가",
        text23: "도시",
        text24: "도시를 입력하세요",
        text25: "당신의 이름을 입력",
        text26: "귀하의 ID 번호",
        text27: "귀하의 ID 번호를 입력하세요",
        text28: "신분증 앞면 사진",
        text29: "문서를 업로드하려면 클릭하세요.",
        text30: "다시 업로드",
        text31: "업로드",
        text32: "증명사진을 들고 있는 모습",
        text33: "보유 신분증 사진을 업로드하려면 클릭하세요.",
        text34: "Google OTP를 연결했습니다.",
        text35: "열쇠",
        text36: "구글 인증코드를 입력해주세요",
        text37: "보내다",
        text38: "비워둘 수 없습니다.",
        text39: "이메일 형식이 잘못되었습니다.",
        text40: "두 개의 비밀번호가 일치하지 않습니다",
        text41: "성공적으로 복사되었습니다",
        text42: "복사 실패",
        text43: "아바타 수정",
        text44: "비밀번호 변경",
        text45: "이메일 바인딩",
        text46: "이메일 수정",
        text47: "휴대폰 바인딩",
        text48: "휴대폰 수정",
        text49: "구글 인증",
        text50: "Google OTP 바인딩",
        text51: "Google 인증 코드를 입력하세요.",
        text52: "구하다",
        text53: "제출 확인",
        text54: "개정하다",
        text55: "제본",
        text56: "Google 인증 끄기",
        text57: "다음 단계",
        text58: "마치다",
        text59: "Google 인증이 바인딩되었습니다.",
        text60: "Google 인증이 번들 해제되었습니다.",
        text61: "기본 인증이 제출되었습니다.",
        text62: "고급 인증이 제출됨",
        text63: "업로드된 이미지가 없습니다.",
        text64: "재전송",
        text65: "닉네임을 입력해주세요",
        text66: "닉네임이 저장되었습니다",
        text67: "아직 업로드된 이미지가 없습니다.",
        text68: "비밀번호 재설정 완료",
        text69: "이메일이 수정되었습니다.",
        text70: "아바타가 성공적으로 저장되었습니다",
        text71: "이미지 업로드",
        text72: "당신의 사서함",
        text73: "새 이메일을 입력하세요",
        text74: "당신의 이름",
        text75: "인증으로 이동",
        text76: "보유하고 있는 신분증 사진을 확인해 주세요."
    },
    retrievePassword: {
        formTitle1: "암호를 재설정",
        formTitle2: "고객님의 자산 안전을 위해 비밀번호 변경 후 24시간 이내에는 거래가 금지됩니다.。",
        formTitle3: "비밀번호 검색",
        formTitle4: "변경 사항 확인",
        formTitle5: "이전 비밀번호를 입력해주세요",
        formTitle6: "새로운 비밀번호를 입력해주세요"
    },
    home: {
        title1: "가장 인기 있는 암호화폐 거래 플랫폼",
        title3: "왜? ",
        title4: " 안전하고 규정을 준수하며 예측 가능한 디지털 인프라를 지원하고 운영하도록 설계된 고성능 오픈 소스 블록체인 플랫폼입니다.。",
        field1: "거래소 설립",
        field2: "디지털 자산 금융 서비스",
        field3: "수천만 명의 사용자",
        field4: "다수의 투자자 보호 기금 조성",
        field5: "빠른 거래",
        field6: "CME Gruop은 최고의 거래 경험을 위해 매우 짧은 지연 시간으로 주문을 일치시킬 수 있습니다.。",
        title5: "시장 동향",
        title6: "더보기",
        table1: "통화",
        table2: "최신 가격",
        table3: "24시간 기복",
        table4: "24시간 거래량",
        table5: "24시간 거래량",
        table6: "인용 부호",
        title7: "~에 대한 ",
        title8: " 사용자에게 가장 발전된 거래 도구, 혁신적인 기술 및 비교할 수 없는 품질의 고객 서비스를 제공하기 위해 최선을 다하고 있습니다.。",
        title9: "디지털 자산 분야는 날이 갈수록 변화하고 있으며, 안목과 빠른 발전만이 기술 혁신의 속도를 따라갈 수 있습니다.。",
        title901: " 사용자 및 글로벌 유동성 공급자에게 최첨단 디지털 자산 거래 서비스 제공。",
        title10: "우리는 모든 사용자가 최고의 거래 경험을 누릴 자격이 있다고 굳게 믿습니다.。",
        title11: "신뢰할 수 있는 보안",
        title12: "우리의 정교한 보안 조치와 SAFU 자금은 모든 위험으로부터 디지털 자산을 보호합니다.。 ",
        title13: "최고의 거래율",
        title14: "우대 거래율, 경쟁력 있는 VIP 권리, 최고 품질의 서비스를 누려보세요.。",
        title15: "24*7시간 고객 서비스 지원",
        title16: "연중무휴 24시간 전천후 풀타임 작동 모드로 관련 문의사항에 최대한 빨리 답변해 드립니다.。",
        title17: "거래 수수료 0",
        title18: "다양한 결제 방법을 사용하여 수수료 없이 안전하고 빠르게 디지털 통화를 거래하세요。",
        title19: "언제 어디서나 거래하세요",
        title20: "多단말기는 완벽하게 호환되며 언제든지 다양한 시나리오의 거래 요구를 충족할 수 있습니다.",
        title21: "언제든지 실시간 시장 상황을 확인하세요",
        title22: "BTC, ETH, XRP 및 기타 디지털 자산을 쉽게 사고 팔 수 있습니다.",
        title23: "관심 있는 암호화폐의 가격 변동에 대한 조기 경고를 받으세요",
        title24: "비트코인 현물 대출, 선물 대출 및 옵션 가격 보기",
        title25: "다양한 거래소의 암호화폐 가격 비교",
        title26: "지금 등록하세요",
        title27: "달러",
        title28: "24시간 거래량",
        title29: "통합 유동성 공급자",
        title30: "사용자",
        title31: "더",
        title32: "바이낸스 론, 새로운 모기지 자산 CTSI&KSM 추가 및 BUSD&USDT 이자율 인하 할인 출시！",
        title33: "우리의 장점",
        title34: "안전하고 안정적",
        title35: "최고의 기술팀, 전방위적인 보안 보호, 고속 트랜잭션 합성 엔진의 독립적인 연구 및 개발은 대규모 동시 트랜잭션에서도 여전히 안정적이고 안정적으로 작동할 수 있습니다.。",
        title36: "전문적인 규정 준수",
        title37: "블록체인 및 금융 분야에서 다년간의 경험을 보유하고 규정에 맞는 디지털 자산 거래 라이센스를 보유하고 100% 준비금을 보장하는 전문 운영팀。",
        title38: "사용자 우선",
        title39: "글로벌 시장 대응, 다국어 지원, 연중무휴 24시간 운영, 강력한 커뮤니티 지원 및 전문적인 고객 서비스。",
        title40: "고성능",
        title41: "1밀리초 미만의 주문 응답 시간으로 초당 300,000건의 트랜잭션。",
        title42: "회사 소개",
        title43: "언제 어디서나 거래하세요",
        title44: "여러 단말기와 완벽하게 호환되어 언제든지 다양한 시나리오의 거래 요구 사항을 충족합니다.",
        title45: "（다운로드하려면 코드를 스캔하세요)",
        title46: "디지털 화폐 뉴스",
        title47: "우리의 비즈니스 파트너",
        title48: "패자 목록",
        title49: "24시간 회전율",
        title50: "24시간 증가 및 감소"
    },
    quotation: {
        marketTrading: "인용 부호",
        turnover: "용량",
        optional: "선택 과목",
        contractMarket: "계약 시장",
        currencyPair: "통화쌍",
        latestPrice: "종가",
        upsAndDowns: "견적 변경",
        highest: "제일 높은",
        lowest: "가장 낮은",
        transactionAmount: "거래량",
        operate: "작동하다",
        buy: "거래",
        topTurnovers: "거래 목록",
        topGainers: "게이너 목록",
        tradingPair: "거래 쌍",
        buyOrder: "구입하다",
        sellOrder: "매도 주문"
    },
    accountChange: {
        demo: "시뮬레이션",
        realAccount: "실제계좌",
        demoAccount: "데모 계정",
        accountRole: "계정 역할",
        pleaseSelect: "선택해주세요",
        AccountChangeType: "계정 변경 유형",
        AccountType: "계정 유형",
        currencyType: "통화 유형",
        allType: "모든 유형",
        allAccount: "모든 계정",
        allCoin: "모든 통화",
        coin: "통화",
        amount: "금액 변경",
        balance: "계정 잔액",
        time: "시간",
        title: "계정 변경 기록",
        filtrar: "필터",
        check: "로 전환",
        showOnly: "보유 통화만 표시",
        holdAssets: "통화 잔액",
        accountList: "계정 목록"
    },
    contractTransaction: {
        chart: "차트",
        basicEdition: "기본 버전",
        depthMap: "깊이 지도",
        second: "두번째",
        minute: "가리키다",
        day: "하늘",
        week: "주",
        month: "달",
        open: "열려 있는",
        trade: "거래",
        buyLong: "장기 구매",
        buyShort: "단기 구매",
        cannotBuy: "이 통화는 배송 계약 거래를 지원하지 않습니다.",
        cannotBuy1: "이 통화는 영구 거래를 지원하지 않습니다.",
        tradingAssets: "자산 거래",
        amountAvailable: "가능 수량",
        timeStr: "시간",
        transactionPeriod: "거래기간",
        quantity: "수량",
        enterQuantity: "수량을 입력해주세요",
        buyIn: "오래 가세요",
        latestTransaction: "최신 거래",
        time: "시간",
        direction: "방향",
        price: "가격",
        sell: "짧은",
        positionOrder: "배달 주문",
        historicalOrders: "역사적 명령",
        product: "제품",
        realAccount: "(실계좌)",
        demoAccount: "(데모 계정)",
        openingTime: "주문 시간",
        openingPrice: "개장 가격",
        curretPrice: "현재 가격",
        liquidationPrice: "종가",
        profitOrLoss: "이익과 손실",
        endTime: "폐관시간",
        areYouSureToBuy: "꼭 구매하시겠습니까?？",
        areYouSureToLongBuy: "정말 오랫동안 가고 싶나요?？",
        areYouSureToShortBuy: "정말 공매도를 하시겠습니까?？",
        successfullyBought: "성공적으로 구매",
        minBuy: "최소 바이인",
        assets: "자산",
        withdraw: "철회하다",
        entrustedOrder: "주문하다",
        total: "합계를 더합니다.",
        continueToPlaceOrder: "계속해서 주문하세요",
        orderConfirmation: "주문 확인",
        buyRu: "구입하다",
        sellChu: "팔다",
        areYouSureToSell: "확실히 팔까요?？",
        operation: "작동하다",
        buyCover: "커버 위치",
        commitSuccess: "거래 성공",
        onlyEnterIntegers: "정수만 입력할 수 있습니다.",
        trend: "경향",
        lotteryTime: "주문 마감일",
        countdown: "카운트다운",
        period: "문제",
        cycle: "기간",
        unlimited: "제한 없는",
        put: "하락",
        call: "상승",
        pending: "커미션 중",
        unopened: "아직 그려지지 않음",
        opened: "추첨된 경품",
        settlementPrice: "정산가격",
        rateOfReturn: "수익률",
        currentPositions: "현재 위치",
        positionQuantity: "포지션 수량",
        guarantee: "증거금",
        text1: "상승",
        text2: "하락",
        text3: "구매 대기 중",
        text4: "주문 수량",
        text5: "거래 수",
        text6: "취소",
        text7: "배달 시간",
        text8: "취소 알림",
        text9: "주문을 취소하시겠습니까?",
        text10: "불법 수량",
        text11: "주문 알림",
        text12: "확실히 매수세를 보이고 있나요?",
        text13: "풋 구매를 확신하시나요?",
        text14: "취소가 완료되었습니다."
    },
    mining: {
        title1: "세계 최고의 블록체인 디지털 자산 채굴 플랫폼",
        title2: "개방적이고 투명하며 안전하고 안정적입니다.",
        title3: "통화를 선택하세요",
        title4: "채굴 연간 수익률",
        title5: "하늘",
        title6: "채굴기간",
        title7: "제품 세부 정보",
        btn: "지금 내 것",
        btn2: "폐쇄",
        tabs1: "진행 중",
        tabs2: "구속된",
        table1: "상품명",
        table2: "채굴기간",
        table3: "채굴 시간",
        table4: "채굴량",
        table5: "만료일",
        table6: "성숙해짐",
        table7: "주문 상태",
        egularMining: "정기적으로 채굴",
        inProgress: "진행 중",
        redeemed: "구속된",
        redemption: "구원",
        regularMining: "정기적으로 채굴",
        earlyRedemption: "조기상환",
        need: "필요",
        handlingFee: "수수료",
        redemptionConfirmationMessage: "사전에 교환 여부를 확인하세요？",
        form1: "채굴 연간 수익률",
        form2: "채굴기간",
        form3: "채굴 화폐",
        form4: "구독 금액(최소/최대)",
        form5: "조기상환수수료: ",
        form6: "구매 가격",
        form7: "사용 가능한 자금",
        form8: "구매 가격",
        form9: "사용 가능한 자금",
        form10: "구매금액을 입력해주세요",
        form11: "모두",
        form12: "예상 수익",
        form13: "사용 가능한 자금이 부족합니다.",
        form14: "구매금액을 입력해주세요",
        form15: "내 주문",
        form16: "소득",
        form17: "주문 세부정보",
        form18: "조기상환 안내:",
        form19: "구독 금액(최소)",
        form20: "구독 금액(최대)",
        form21: "보유수량",
        form22: "구매 시간",
        form23: "상환 시간",
        form24: "~ 위에",
        form25: "구독 성공",
        form26: "상환 성공",
        form27: "25% 이상의 수익률로 손쉬운 재무 관리, 시장 대비 우수한 성과, 구매 후 리워드도 받을 수 있음",
        form28: "친구를 초대하면 50 USDT의 보상을 받을 수 있으며, 계약 바우처로도 20% 커미션을 돌려받을 수 있습니다."
    },
    assets: {
        content1: "계정 자산",
        content2: "오늘의 수입",
        content3: "당좌 계정",
        content4: "실계좌는 거래 및 출금에 사용될 수 있는 실제 계좌 자산입니다.",
        content5: "코인 출금",
        content6: "코인 입금",
        content7: "교환",
        content8: "옮기다",
        content9: "선택해주세요",
        content10: "데모 계정",
        content11: "실제계좌",
        content12: "자산 취득",
        content13: "정말로 실제 계정으로 전환하시겠습니까?？",
        content14: "정말로 데모 계정으로 전환하시겠습니까?？",
        content15: "성공하다",
        table1: "통화",
        table2: "수량",
        table3: "꼭 매달리게 하다",
        table4: "사용 가능",
        dialog1: "제품 세부 정보",
        dialog2: "1、이 자산은 데모 계정 모드에서만 사용할 수 있습니다.",
        dialog3: "2、남용을 방지하기 위해 한 달 내에 한 번만 상환이 허용됩니다.",
        dialog4: "3、자산을 수령하면 원래 기준으로 받은 자산의 양이 추가되며 포지션 상태는 변경되지 않습니다.",
        dialog5: "4、기타 문의사항이 있으시면 고객센터로 연락주세요",
        dialog6: "폐쇄",
        dialog7: "지금 데모 자산 받기",
        dialog8: "편입",
        dialog9: "다른 곳으로 이전하다",
        dialog10: "스위치",
        dialog11: "평가",
        dialog12: "데모 자산",
        dialog13: "내 계정",
        dialog14: "총 계정 자산",
        dialog15: "자산 개요",
        dialog16: "총자산평가",
        dialog17: "자산 가치 평가",
        dialog18: "오늘의 수입",
        dialog19: "자금 동결",
        dialog20: "매달 시뮬레이션 자산 할당량을 받아보실 수 있습니다",
        dialog21: "받다",
        dialog22: "당신은 성공적으로 받았습니다",
        dialog23: '30일 누적 수입',
        dialog24: "금융상품",
        dialog25: "나의 재무관리",
        dialog26: "역사적 재무관리",
        dialog27: "역사 기록",
        dialog28: "펀딩 기록"
    },
    validator: {
        dialog14: "검증인",
        dialog15: "바인딩 유효성 검사기",
        dialog16: "유효성 검사기 스위치",
        dialog17: "확인 코드",
        dialog18: "유효성 검사기 QR 코드",
        dialog19: "유효성 검사기 키",
        dialog20: "구하다",
        dialog21: "유효성 검사기를 선택하세요.",
        dialog22: "복사 키",
        dialog23: "성공적으로 저장 되었음",
        dialog24: "인증번호를 입력해주세요",
        dialog25: "알림: 저장을 확인하기 전에 해당 앱을 열고 아래 QR 코드를 스캔하거나 키를 수동으로 추가하세요.！",
        dialog26: "귀하의 계정에 최대의 보안을 제공하려면 인증기를 활성화하세요。",
        dialog27: "로그인하거나 돈을 인출할 때마다 휴대폰을 통해 액세스되는 고유 인증 코드를 입력해야 합니다.。",
        dialog28: "유효성 검사기 설정",
        dialog29: "돕다",
        dialog30: "인증코드를 바인딩하지 않았습니다. 인증기로 더 강력한 보호를 받으세요。",
        dialog31: "유효성 검사기가 아직 설정되지 않았습니다. 먼저 설정하십시오.",
        dialog32: "설정으로 바로 가기"
    },
    recharge: {
        title1: "디지털 통화를 사용하여 구매하는 방법을 모릅니다？",
        title2: "세 단계만 거치면 됩니다!",
        title3: "드롭다운 메뉴에서 구매하려는 디지털 통화를 선택하고 구매 수량 또는 금액을 입력하세요.。",
        title4: "원하는 결제 방법을 선택하세요",
        title5: "결제가 확인되면 구매한 디지털 화폐가 자동으로 귀하의 계정으로 이체됩니다.。",
        title6: "충전할 통화를 선택하세요.",
        title7: "입금기록",
        table1: "통화",
        table2: "수량",
        table3: "체인명",
        table4: "상태",
        table5: "이유",
        table6: "시간",
        table7: "바우처 보기",
        dialog1: "아래 인증번호를 입력해주세요",
        dialog2: "결제 코드",
        dialog3: "복사",
        dialog4: "주소",
        dialog5: "입금 금액",
        dialog6: "거래 스크린샷",
        dialog7: "중요한 공지",
        dialog8: "입금액을 입력해주세요.",
        dialog9: "이미지 업로드",
        dialog10: "내용을 입력해주세요",
        dialog11: "재충전 스크린샷을 업로드해주세요.",
        dialog12: "검토되지 않음",
        dialog13: "거부됨",
        dialog14: "입금됨",
        tip1: "성공적으로 제출되었습니다",
        tip2: "성공적으로 복사되었습니다",
        tip3: "복사 실패",
        tip4: "충전할 통화를 선택하세요.",
        tip5: "1.위 입금주소는 플랫폼의 공식 입금주소입니다.플랫폼의 공식 입금주소를 확인하시기 바랍니다.오입입으로 인한 자금 손실은 본인 부담입니다.；",
        tip6: "2.정보가 변조되거나 유출되는 것을 방지하기 위해 컴퓨터와 브라우저가 안전한지 확인하십시오.；",
        tip7: "3.위 주소를 재충전한 후 계정에 적립되기 전에 전체 네트워크 노드를 확인해야 합니다.；",
        tip8: "4.위의 토큰 시스템 및 통화를 선택하신 후 해당 금액을 입금하여 입금해 주시기 바라며, 기타 관련 없는 자산을 양도하지 마시기 바랍니다. 그렇지 않을 경우 해당 자산은 회수되지 않습니다.。",
        tip9: "QR 코드 저장",
        tip10: "충전하다",
        tip11: "중요한 공지",
        tip12: "오프사이트 링크",
        tip13: "QR코드 충전",
        tip14: "다음 단계",
        tip15: "충전기록",
        tip16: "충전 세부정보",
        tip17: "주소",
        tip18: "빠른결제",
        tip20: "잔액 불충분",
        tip22: "제출되었으며 확인을 기다리는 중입니다.",
        tip23: "결제를 기다리는 중",
        tip24: "총 금액",
        tip25: "결제가 제출되었으며 차단 확인을 기다리고 있습니다.",
        tip26: "결제가 거부되었습니다.",
        tip27: "결제 실패",
        tip28: "결제 성공",
        bankChannel: "은행 카드 채널",
        tip29: "과거 검색",
        tip30: "통화 목록",
        tip31: "네트워크 선택",
        tip32: "본 통화 충전 시 아래 지원되는 네트워크를 통해서만 충전하시기 바랍니다. 다른 네트워크를 통해 충전하실 경우 자금이 손실됩니다.",
        tip33: "본 화폐 출금시 아래 지원되는 네트워크를 통해서만 출금하시기 바라며, 다른 네트워크를 통해 출금하실 경우 자금이 손실됩니다.",
        tip34: "빠른 재충전",
        tip35: "코드를 스캔하여 재충전하세요",
        tip36: "수동충전",
        tip37: "재충전 네트워크",

        tip38: "충전 주소",
        tip39: "충전금액",
        tip40: "거래영수증 업로드",
        tip41: "이미지 업로드",
        tip42: "제출 확인",
        tip43: "결제 확인",
        tip44: "참고사항: 충전할 주소를 꼭 확인해주세요. 잘못 기재하여 발생하는 금액의 손실은 당사에서 책임지지 않습니다.",
        tip45: "상기시키다",
        tip46: "주소가 복사되었습니다.!",
        tip47: "주소 복사 실패!",
        tip48: "사용 가능한 잔액이 부족합니다.",
        tip49: "재충전이 성공적으로 제출되었으며 체인에서 확인을 기다리고 있습니다.",
        tip50: "재충전 실패",
        tip51: "충전금액이 틀렸어요",
        tip52: "연결에 실패",
        tip53: "지갑이 로그인되지 않았습니다",
        tip54: "연결이 거부되었습니다.",
        tip55: "성공적으로 제출되었으며 고객 서비스 검토 및 승인을 기다리고 있습니다.",
        tip56: "거래 영수증 스크린샷을 업로드해 주세요.",
        tip57: "제출 실패",
        tip58: "충전기록",
        tip59: "출금기록",
        tip60: "통화 충전",
        tip61: "출금통화",
        tip62: "충전 주소",
        tip63: "스크린샷 쇼",
        tip64: "이미지를 확대하려면 클릭하세요.",
        tip65: "재충전 시간",
        tip66: "거부 이유",
        tip67: "출금금액",
        tip68: "받은 금액",
        tip69: "현금인출 네트워크",
        tip70: "출금주소",
        tip71: "출금시간",
        tip72: "폐쇄",
        tip73: "거래 스크린샷",
        tip74: "검토를 보류하다",
        tip75: "거부됨",
        tip76: "완전한",
        tip77: "거부됨",
        tip78: "감사됨",
        tip79: "지급됨",
        tip80: "발췌",
        tip81: "환승 네트워크",
        tip82: "주소/송장",
        tip83: "모두 철회",
        tip84: "크라우드펀딩이나 ICO 주소로 직접 돈을 인출하지 마세요. 그렇지 않으면 크라우드펀딩이나 ICO에서 발행된 토큰을 받을 수 없습니다.",
        tip85: "도착 수량",
        tip86: "출금 확인",
        tip87: "잘못된 출금 주소",
        tip88: "잘못된 인출 금액",
        tip89: "인출할 금액은 최소",
        tip90: "최대 인출 금액은",
        tip91: "출금 요청이 제출되었습니다",
        tip92: "주소 목록",
        tip93: "아직 주소를 추가하지 않았습니다.",
        tip94: "지금 추가하세요",
        tip95: "주소 추가",
        tip96: "새 주소 추가",
        tip97: "선택해주세요",
        tip98: "주소",
        tip99: "환승 네트워크를 선택해 주세요",
        tip100: "주소를 입력해주세요",
        tip101: "주소가 추가되었습니다.",
        tip102: "거래 중",
    },
    fundExchange: {
        title1: "자금 교환 방법？",
        title2: "변환할 적절한 통화를 선택하세요.。",
        title3: "변환할 적절한 통화를 선택하세요.。",
        title4: "환전할 금액을 입력하고 제출을 클릭하면 환전이 완료됩니다.。",
        title5: "~에서",
        title6: "도착하다",
        title7: "교환수량",
        title8: "환율",
        title9: "사용 가능",
        title10: "상환수량을 입력해주세요",
        title11: "교환에 해당하는 거래 쌍이 없습니다.",
        title12: "교환할 수량을 입력해주세요",
        title13: "상환 성공",
        title14: "교환기록",
        table1: "통화 사용",
        table2: "수량",
        table3: "환율",
        table4: "통화 교환",
        table5: "영수증",
        table6: "시간",
        title15: "~에서",
        title16: "현재 사용 가능한 자산이 없습니다. 다른 자산을 선택하십시오."
    },
    fundTransfer: {
        title1: "자금 이체 방법？",
        title2: "이전할 현재 계좌를 선택하세요.。",
        title3: "이전하려는 계좌를 선택하세요.。",
        title4: "이체 금액을 입력하고 제출을 클릭하면 이체가 완료됩니다.。",
        title5: "통화",
        title6: "이체 수량",
        title7: "이체할 금액을 입력해주세요",
        title8: "이체 기록",
        title9: "지갑 종류를 선택해주세요",
        title10: "통화를 선택하세요",
        title11: "지갑 종류를 선택해주세요",
        title12: "통화를 선택하세요",
        title13: "이체할 금액을 입력해주세요",
        title14: "성공적으로 제출되었습니다",
        table1: "계좌이체",
        table2: "통화 이체",
        table3: "이체 수량",
        table4: "계좌 이체",
        table5: "시간",
        table6: "옮기다",
        table7: "자금 이체",
        table8: "로 전송",
        table9: "이체 기록",
        table10: "송금 세부정보",
        table11: "전송 성공",
        table12: "전송 실패",
        table13: "현재 계좌는 양도할 수 없습니다"
    },
    withdrawal: {
        title1: "중요한 공지",
        title2: "1.차익거래를 방지하기 위해 거래량이 할당량에 도달하면 통화 출금을 신청할 수 있습니다.。",
        title3: "2.출금 신청 후 24시간 이내에 출금이 이루어지며, 출금 예정 시간 이후에도 출금이 이루어지지 않을 경우 온라인 고객센터로 문의하시기 바랍니다.。",
        title4: "3.출금신청서를 제출한 후 출금이 진행되어 자금이 시스템에 의해 일시적으로 보류되기 때문에 자금이 동결되며, 이는 자산을 잃거나 자산에 이상이 있는 것을 의미하지 않습니다.。",
        title5: "통화",
        title6: "지갑 주소",
        title7: "인출된 코인의 양",
        title8: "코인 출금 ​​기록",
        title9: "통화를 선택하세요",
        title10: "체인 종류를 선택해 주세요",
        title101: "",
        title11: "들어 오세요",
        title12: "지갑 주소",
        title13: "성공적으로 제출되었습니다",
        title14: "검토중",
        title15: "검토가 완료되었습니다.",
        title16: "감사 실패",
        title17: "지급됨",
        title18: "지갑 주소를 입력하거나 붙여넣으세요.",
        title19: "출금금액을 입력해주세요",
        title20: "수수료",
        title21: "실제 도착",
        table1: "통화",
        table2: "인출된 코인의 양",
        table3: "체인명",
        table4: "지갑 주소",
        table5: "상태",
        table6: "이유",
        table7: "신청 시간",
        table8: "코인 출금",
        table81: "코인 출금",
        table9: "출금주소",
        table10: "인출할 통화를 선택하세요.",
        table11: "꼭 입력해주세요",
        table12: '주소(보통 "0x"로 시작함)）',
        table13: "코인 출금 ​​가능(수수료 차감)）：",
        table14: "통화 선택",
        table15: "문제가 발생했나요? 즉각적인 피드백",
        table16: "균형",
        table17: "提币详情",
        password: "비밀번호",
        passwordTips: "비밀번호를 입력 해주세요",
        passwordAg: "비밀번호 확인",
        passwordAgTips: "비밀번호를 다시 입력해주세요",
        oldPassword: "기존 비밀번호",
        oldPasswordTips: "이전 비밀번호를 입력해주세요",
        newPassword: "새 비밀번호",
        newPasswordTips: "새로운 비밀번호를 입력해주세요",
        passwordNotSame: "비밀번호가 일치하지 않습니다.",
        withdrawPasswordSet: "출금 비밀번호 설정",
        withdrawPasswordModify: "출금 비밀번호 변경",
        withdrawPassword: "비밀번호 철회",
        withdrawPasswordTips: "출금 비밀번호를 입력해주세요",
        setWithdrawPasswordFirst: "먼저 출금 비밀번호를 설정해주세요",
        withdrawPasswordSetSuccess: "설정 성공",
        withdrawPasswordModifySuccess: "수정되었습니다.",
        submit: "제출하다",
    },
    customer: {
        content1: "온라인 서비스",
        content2: "고객 서비스",
        content3: "연락처 정보를 입력해주세요",
        content4: "내용을 입력해주세요",
        content5: "보내다",
        content6: "전송 실패, 네트워크 연결 실패",
        content7: "그림",
        content8: "메시지가 비어 있습니다",
        content9: "내 몫",
        content10: "링크 공유",
        content11: "사진을 저장하다",
        content12: "내 수입",
        content13: "레벨 설명",
        content14: "팀 규모",
        content15: "초청소득",
        content16: "규칙 설명",
        content17: "회원등급",
        content18: "충전금액이 다음보다 많습니다.",
        content19: "팀 수준",
        content20: "팀 레벨 및 수입",
        content21: "조건을 충족하려면",
        content22: "분배소득",
        content23: "레벨 팀",
        content24: "레벨, 초대",
        content25: "유효한 회원",
        content26: "1차 소득",
        content27: "2차 소득",
        content28: "레벨 3 소득",
    },
    standardContract: {
        increaseMargin: "마진 증가",
        increaseAmount: "증가액",
        Pleaseenterthemargin: "증가할 마진 금액을 입력하세요.",
        Pleaseenterthecorrect: "정확한 입금액을 입력해주세요",
        Increasemarginsuccessfully: "마진 증가 성공",
        takeProfitPriceWrong: "이익실현 가격이 잘못됨",
        stoppriceerror: "중지 가격 오류",
        thetakeprofit1: "이익 실현 가격은 시장 가격보다 높아야 합니다.",
        thetakeprofit2: "손절매 가격은 시장 가격보다 낮아야 합니다.",
        thetakeprofit3: "이익 실현 가격은 시장 가격보다 낮아야 합니다.",
        thetakeprofit4: "손절매 가격은 시장 가격보다 높아야 합니다.",
        thetakeprofit5: "잘못된 수량",
        estimatedprofitloss: "추정 손익",
        positionAmount: "직위 수",
        closeAmount: "마감 수량",
        whenthelatest: "최신 가격에 도달하면",
        whenthelatest2: ", 현재 포지션을 청산하기 위해 시장 가격 이익 실현 주문이 실행됩니다. 기대손익",
        whenthelatest3: ", 현재 포지션을 청산하기 위해 시장 손절매 주문이 실행됩니다. 기대손익",
        contract: "계약",
        ordercanceledsuccessfully: "주문이 취소되었습니다.",
        successfullyordered: "성공적으로 주문했습니다",
        positionopeningreminder: "포지션 개시 알림",
        wrongquantity: "잘못된 수량",
        quantitycannot: "수량은 다음보다 클 수 없습니다.",
        modificationstoploss: "손절매 및 손절매 수정 성공",
        positionclosedsuccessfully: "포지션이 성공적으로 마감되었습니다",
        orderCancellation: "주문 취소",
        orderCancellation2: "주문을 취소하시겠습니까?",
        tobeexecuted: "처형될 예정",
        expired: "만료됨",
        failed: "실패한",
        contractTransaction: "무기한 계약",
        increase: "증가하다",
        openPosition: "전체 창고",
        isolatedPosition: "고립된 위치",
        limitOrder: "가격 제한",
        marketOrder: "시장 가격",
        pleaseSelectLeverage: "레버리지를 선택해 주세요",
        pleaseEnterQuantity: "수량을 입력해주세요",
        canOpenMore: "열 수 있는 최대 위치",
        contractAmount: "계약금액",
        openingFee: "수수료",
        currentPosition: "위치",
        historicalPositions: "과거 거래",
        currentCommission: "현재 주문",
        historicalCommission: "역사위원회",
        buyPrice: "구매 가격",
        income: "이익",
        quantity: "수량",
        marginRate: "마진율",
        initialMargin: "개시마진",
        estimatedPriceParity: "강제청산 가격",
        operate: "작동하다",
        finishQuantity: "거래량",
        averagePrice: "평균 거래 가격",
        pleaseEnterLeverage: "레버리지를 입력해 주세요",
        takeProfitAndStopLoss: "손실 중지",
        takeProfit: "이익을 얻으세요",
        stopLoss: "손실을 막다",
        openLong: "Long",
        openShort: "Short",
        liquidation: "포지션 마감",
        marketLquidation: "만능 시장 가격",
        cancel: "주문 취소",
        enterBuyPrice: "구매가격을 입력해주세요",
        areYouSureLquidation: "확실히 포지션을 닫으시겠어요??",
        confirm: "확신하는",
        enterStopWinPrice: "이익 실현 가격을 입력하세요.",
        enterStopLossPrice: "손실정지가격을 입력해주세요",
        commissionTime: "커미션 시간",
        commissionPrice: "수수료 가격",
        commissionQuantity: "총 수수료 금액",
        volumeAlreadyTraded: "완료금액",
        areYouSureToWinOrLoss: "정말로 이익을 멈추고 손실을 멈추시겠습니까??",
        maxQuantity: "최대 위치",
        saleQuantity: "최대 위치",
        salePrice: "평균 종가",
        saleTime: "시간",
        succeeded: "성공했다",
        cancelled: "취소 된",
        piece: "열려 있는",
        triggerPrice: "방아쇠 가격",
        setWin: "이익 실현 설정",
        setLoss: "정지 손실 설정",
        enterWinQuantity: "이익 실현 금액을 입력하세요.",
        enterLossQuantity: "Stop Loss 금액을 입력해주세요.",
        planCommission: "계획 수수료",
        enterWinTriggerPrice: "이익실현 트리거 가격을 입력하세요.",
        enterLossTriggerPrice: "손절매 가격을 입력하세요",
        insufficientQuantity: "수량 부족",
        enterTriggerPrice: "트리거 가격을 입력하세요.",
        tips1: "귀하의 주문 가격이 현재 가격보다 높으며, 현재 시장 가격으로 바로 거래가 실행됩니다. 구매하시겠습니까?？",
        tips2: "귀하의 주문 가격이 현재 가격보다 낮으며, 현재 시장 가격으로 바로 거래가 이루어집니다. 구매하시겠습니까?？",
        type: "유형",
        increaseQuantity: "순위 높이기",
        reduceQuantity: "직위 축소",
        limitCommission: "제한 주문",
        tips3: "손절매 가격은 시장 가격보다 낮아야 합니다.",
        tips4: "이익 실현 가격은 시장 가격보다 높아야 합니다.",
        tips5: "손절매 가격은 시장 가격보다 높아야 합니다.",
        tips6: "이익 실현 가격은 시장 가격보다 낮아야 합니다.",
        tips7: "손절매 가격은 시장 가격, 주문 가격보다 낮아야 합니다.",
        tips8: "이익 실현 가격은 주문 가격보다 높아야 합니다(시장 가격보다 낮을 수 있음).）",
        tips9: "손절매 가격은 시장 가격, 주문 가격보다 커야 합니다.",
        tips10: "이익 실현 가격은 주문 가격보다 작아야 합니다(시장 가격보다 클 수 있음).",
        accountEquity: "계정 잔액",
        unrealizedPL: "미실현 손익",
        tips11: "당신 말이 맞나요?",
        tips12: "시장 가격",
        tips13: "모든 포지션 닫기",
        tips14: "다시 알림 안 함",
        profitRate: "마감 수익률",
        openTime: "영업 시간",
        unwindTime: "폐관 시간",
        dealTime: "거래시간",
        amountClose: "청산된 포지션의 양",
        long: "많은",
        short: '없는',
        orderQuantity: "주문 수량",
        entrustedMargin: "위탁마진",
    },
    spotGoods: {
        spotGoods: "플래시 교환",
        sellPrice: "판매 가격",
        orderRecord: "플래시 상환 기록",
        transactionTime: "거래시간",
        maxBuy: "구매 가능",
        maxSell: "판매 가능",
        purchaseQuantity: "구매 수량",
        slippage: "미끄러짐",
        convertTips: "우리는 0-5의 슬리피지(Slippage)를 적용하여 최적의 가격으로 거래를 종료합니다.%。",
        changePrice: "현재 거래소 가격",
        consume: "소비하다",
        get: "얻다",
        changeTips: "정말로 사용하시겠습니까?？",
        confirm: "상환 확인"
    },
    news: {
        title: "뉴스 센터",
        redMore: "더 읽어보세요",
    },
    credit: {
        credit: "신용 점수",
        creditScore: "총 신용 점수",
        latestEntry: "최신 기록",
        liveUpdate: "실시간 업데이트",
        systemEvaluation: "시스템 평가",
        time: "날짜",
        name: "이름",
        reason: "이유",
        scoreStory: "신용 점수 기록"
    },
    borrowing: {
        borrowing: "대출",
        diversifiedMarket: "다양한 시장",
        totalSupply: "총 공급량",
        totalBorrowings: "총 차입금",
        tips1: "이는 대출 한도 내에서 담보를 통해 빌릴 수 있는 총 금액입니다.。",
        tips2: "귀하의 안정적인 이자율은 대출 기간 동안 동일하게 유지됩니다. 장기 공급 및 예측 가능성을 선호하는 사용자에게 권장됩니다.。",
        totalSupplys: "총 공급량",
        supplyAPR: "공급율",
        totalBorrowings: "총 차입금",
        borrowAPR: "대출 금리",
        check: "확인하다",
        market: "시장",
        availableLiquidity: "사용 가능한 유동성",
        currencyPrice: "현재 통화 가격",
        supplyAssets: "공급 자산",
        tips3: "참고: 아래 금리는 고정금리이며, 공급기간 종료 후 원금과 수입이 귀하의 계좌로 반환됩니다.。",
        supplyCycle: "공급주기",
        minimumSupply: "최소 공급량",
        supply: "공급",
        borrowedAssets: "빌린 자산",
        tips4: "알아야 할 사항: 돈을 빌리려면 담보 자산이나 증거를 제공해야 합니다.，",
        tips5: "모기지 자산 설명을 참조하세요.。",
        repaymentCycle: "상환주기",
        BorrowableAmount: "대출가능금액",
        borrow: "대출",
        accountInformation: "계정 정보",
        walletBalance: "지갑 잔액",
        canSupply: "사용 가능",
        AmountCanBeBorrowed: "총 대출 가능 금액",
        borrowedAmount: "차입금액",
        remainingAmount: "남은 잔액",
        getBorrowednAmount: "대출 한도 받기",
        supplyOrder: "공급 주문",
        borrowedOrder: "대출 주문",
        supplyAmount: "공급량",
        expectedProfits: "기대수익률",
        supplyTime: "공급시간",
        expirationTime: "만료일",
        borrowingAmount: "대출 금액",
        dueRepayment: "상환금액",
        borrowingTime: "출시 시간",
        repaymentTime: "상환시기",
        repay: "반환",
        supplyCurrency: "통화 공급",
        buyNow: "지금 구매",
        supplyRecords: "공급 기록",
        borrowRecords: "대출기록",
        completed: "완전한",
        borrowNow: "지금 빌리세요",
        borrowCurrency: "통화 차용",
        borrowMin: "가장 작은",
        borrowMax: "최고",
        borrowRange: "대출금액(최소/최대）",
        tips6: "기한 내에 상환하시기 바랍니다. 연체시에는 일일금액(원금+이자)이 추가로 청구됩니다.",
        tips7: "연체료。",
        pleaseEnterBorrowAmount: "대출금액을 입력해주세요.",
        interest: "관심",
        tips8: "정말로 미리 상환하시겠습니까?？",
        borrowChannel: "채널 차용",
        tips9: "당신을 알아가는 것부터 시작해 보세요",
        name: "이름",
        lastName: "성",
        birthDate: "생일",
        tips10: "좀 더 자세한 정보를 알려드리겠습니다. 주소가 어떻게 되나요?？",
        familyAddress: "집 주소(호수 포함)",
        city: "도시",
        country: "국가",
        postalCode: "우편 번호",
        pleaseEnterYourHomeAddress: "집 주소를 입력해주세요",
        tips11: "귀하의 재정 상황에 대해 알려주십시오.",
        tips12: "나중에 이 정보를 확인해야 할 수도 있으므로 최대한 정확하게 입력해 주시기 바랍니다.",
        yourMonthlyIncome: "당신의 월 소득",
        enterYourMonthlyIncome: "월 소득을 입력해주세요.",
        extraIncomeOptional: "추가 소득 (선택 사항)",
        enterExtraIncome: "추가소득을 입력해주세요",
        monthlyRentOrMortgagePayment: "월세 또는 모기지 지불",
        enterMonthlyRent: "월 임대료 또는 모기지 지불액을 입력하세요.",
        tip13: "모기지 있으면 알려주세요",
        tip14: "모기지 보증을 선택하지 않으면 심사 통과 확률이 낮습니다.",
        isThereMortgage: "모기지가 있나요?",
        selectIsThereMortgage: "담보대출이 있는지 선택해주세요.",
        selectMortgageType: "모기지 종류를 선택해 주세요",
        yes: "예",
        no: "아니요",
        uploadScreenshotOfTransaction: "거래 스크린샷을 업로드해주세요.",
        tip15: "계속 연락하자",
        tip16: "귀하의 대출 신청에 대한 추가 정보가 필요한 경우 이 전화번호로 연락드릴 수 있습니다.",
        tip17: "나는 동의서를 읽고 전자적으로 서명했습니다.",
        tip18: "이용약관 및 전자 동의",
        tip19: "，여기에는 당사가 귀하에게 당사에 관한 정보를 보내는 데 대한 귀하의 동의가 포함됩니다.",
        tip20: "제품 및 서비스 이용약관",
        tip21: "정보를 입력하신 후 시스템 평가 및 검토를 기다려주세요.。",
        phoneNumber: "전화 번호",
        enterPhoneNumber: "전화번호를 입력해주세요",
        tip22: "이용약관 및 전자동의서 양식에 동의하려면 확인란을 선택하세요.",
        inReview: "검토중",
        applicationAmount: "신청금액",
        reasonForRejection: "거부 이유",
        reapply: "재신청",
        applyStatus: "신청 상태",
        currentOrder: "현재 주문",
        interestRate: "이자율"
    },
    yuebao: {
        filed1: "투자하다",
        filed2: "테이크아웃",
        filed3: "일일 수입",
        filed4: "팀 가속",
        filed5: "입금됨",
        filed6: "어제 수익",
        filed7: "총 수익",
        filed8: "사용 가능한 자금",
        filed9: "금액을 입력해주세요.",
        filed10: "유동성 채굴",
        filed11: "소개",
        field12: "최소 구매 가격",
        field13: "가장 많이 구매하실 수 있는",
        filed14: "신청",
        filed15: '정기적인',
        filed16: '연간 이자율',
    },
    tipsPopover: {
        title: "이 애플리케이션을 설치하세요",
        press: "~에 따르면",
        and: "그리고",
        scrren: "홈 화면에 추가",
        hasInstall: "(설치되어 있으면 창을 닫아주세요)",
    },
    follow: {
        title1: "후속 계획",
        follow_text1: "후속 명령 시작",
        follow_text2: "출시 이력",
        follow_text3: "제목",
        follow_text4: '거래쌍',
        follow_text5: '구매 기간',
        follow_text6: '주문 시간',
        follow_text7: '출시 시간',
        follow_text8: '팔로어 수',
        follow_text9: '주문량',
        follow_text10: '작동하다',
        follow_text11: '초대하다',
        follow_text12: '주문 방향을 따르세요',
        follow_text13: '주문 조건 설정',
        follow_text14: '주문을 따르기 위한 조건',
        follow_text15: '정액',
        follow_text16: '백분율',
        follow_text17: '초대받은 사람',
        follow_text18: '상대방의 아이디',
        follow_text19: "주문을 따르려면 확인하세요.",
        follow_text20: "주문 금액(선택 사항)）",
        follow_text21: "제목을 입력하세요",
        follow_text22: "거래쌍을 선택해주세요",
        follow_text23: "주문방향을 선택해주세요",
        follow_text24: "구매기간을 선택해주세요",
        follow_text25: "주문시간을 선택해주세요",
        follow_text26: "구매 수량을 선택해 주세요",
        follow_text27: "최대 구매 수량은 최소 구매 수량보다 작을 수 없습니다.",
        follow_text28: "최소 바이인",
        follow_text29: "가장 많이 구매",
        follow_text30: "복사 주문을 성공적으로 생성했습니다.",
        follow_text31: "거래 쌍 목록",
        follow_text32: "이미 명령을 따랐습니다.",
        follow_text33: "초대 대상자 정보를 입력해주세요",
        follow_text34: "초대됨",
        follow_text35: "나를 초대했다",
        follow_text36: "복사 기록",
        follow_text37: "주문 금액 범위",
        follow_text38: "주문코드를 입력해주세요"
    },
    followHistory: {
        title1: '출시 이력',
        title2: "복사 기록",
        follow_h_text1: "주문 총액",
        follow_h_text2: "주문을 따르는 총 인원 수",
        follow_h_text3: "릴리스 수",
        follow_h_text4: "승률",
        follow_h_text5: "명령을 따르는 횟수",
        follow_h_text6: "다음 주문으로 인한 이익",
        follow_h_text7: "방향",
        follow_h_text8: "이기다",
        follow_h_text9: "적자",
    },
    "asset.index.1579E8B41F8D5134": "내 자산",
    "asset.index.B3F6F05Dfbe431Cd": "시뮬레이션",
    "asset.index.B9Aeab4Ee8F33746": "자산 가치 평가",
    "asset.index.583Df8Bd541Eb7E8": "수익 동향",
    "asset.index.B1254815D133Cc26": "7 일",
    "asset.index.2F615F6C029F01B7": "30 일",
    "asset.index.29439Fb58E4Bbe22": "철회하다",
    "asset.index.831Ab31568A78285": "충전하다",
    "asset.index.39F7A0D48D12E8C9": "옮기다",
    "asset.index.B99063C5689Beaec": "플래시 교환",
    "asset.index.E1Dc620F2F038B2A": "자산 수신",
    "asset.index.972A0B4C2B4393D0": "내 계정",
    "asset.index.9Dfb0Fb334620733": "사용 가능한 잔액",
    "asset.index.Cdc5Fbd26790Cb47": "동결 금액",
    "asset.index.3D8132D72Ae965Cd": "평가",
    "asset.receive.825503Cc430559Cb": "자산 취득",
    "asset.receive.54154A0553A535B7": "데모 자산",
    "asset.receive.F2E57B00A9C5D129": "1.이 자산은 데모 계정 모드에서만 사용할 수 있습니다.",
    "asset.receive.Ea0D1D52989299Bd": "2.남용을 방지하기 위해 한 달 내에 한 번만 상환이 허용됩니다.",
    "asset.receive.090E0037Aa235D3D": "3.자산을 수령하면 원래 기준으로 받은 자산의 양이 추가되며 포지션 상태는 변경되지 않습니다.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.기타 문의사항이 있으시면 고객센터로 연락주세요",
    "asset.receive.Df5B3A511D986575": "지금 데모 자산 받기",
    "asset.records.1Fa02E8187A39592": "지난 3개월",
    "asset.records.7B291C37333Fe34D": "가까운 곳에서 먼 곳까지",
    "asset.records.A476Cd1Ec868Fc5F": "지난 3개월,",
    "asset.records.9D5755A842E55Bb7": "가까운 곳에서 먼 곳까지,",
    "asset.records.E3Fa05E607203Cdc": "충전하다",
    "asset.records.B4F206C6Bec08Dea": "철회하다",
    "asset.index.Df5B3A511D986576": "데모 자산을 성공적으로 받았습니다.",
    "deposit.depositSpeedy.Efe81D0E0174239B": "결제수단",
    "deposit.depositSpeedy.B9293Ee96E435F85": "명목화폐",
    "deposit.depositSpeedy.16658A855F07D171": "양",
    "deposit.depositSpeedy.204Ffab8A6E01870": "확신하는",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "비워둘 수 없습니다.",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "최소 재충전",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "최대 재충전",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "USDT 입금",
    "deposit.depositWallet.551640C76F4Ca4A1": "통화 충전",
    "deposit.depositWallet.B404C543712B71D5": "재충전 네트워크",
    "deposit.depositWallet.A484B0F5E94B3F4F": "입금주소",
    "deposit.depositWallet.D3980D54Acf242Db": "QR 코드 저장",
    "deposit.depositWallet.A06E84801D6582Be": "충전금액",
    "deposit.depositWallet.Daaaeb1B7B22B126": "힌트",
    "deposit.depositWallet.D599E40E1Eeec1B3": "재충전 성공",
    "deposit.depositWallet.4F48Abbafce532Bb": "성공적으로 제출되었으며 고객 서비스 검토를 기다리는 중입니다.",
    "deposit.depositWallet.A88C481228811696": "네트워크 전환을 거부했습니다.",
    "deposit.depositWallet.C6Ebf27895C21932": "TRON 지갑 플러그인을 수동으로 열고 로그인하세요.",
    "deposit.index.B5491C8Dd09D8183": "충전할 통화를 선택하세요.",
    "deposit.index.2F784B2298D39987": "빠른 재충전",
    "deposit.index.0Daa03Dcdcabd837": "오프사이트 링크",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "출금확인",
    "withdraw.bottomSheet.A086Fa20082C849D": "아래 정보가 올바른지 확인해주세요.",
    "withdraw.bottomSheet.Bf8B184D32971501": "출금 네트워크",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "주소",
    "withdraw.bottomSheet.F9Db93B87E08763B": "수량",
    "withdraw.bottomSheet.4Ad26475002878C0": "수수료",
    "withdraw.index.A96454041169Af6D": "통화 선택",
    "withdraw.index.2A563B7081Dd5B83": "출금주소",
    "withdraw.index.B189C2Ef713Cfff4": "인출된 코인의 양",
    "withdraw.index.558F35140E84B562": "사용 가능：",
    "withdraw.index.F4E759087E12Cb77": "모두 철회",
    "withdraw.index.5Cbd9A6B284D25Ae": "펀드 비밀번호",
    "withdraw.index.B89Cbe4Bfc33Bb41": "수수료:",
    "withdraw.index.C886De8D8A27026E": "중요한 공지",
    "withdraw.index.Eac7C5A4095F5606": "1.차익거래를 방지하기 위해 거래량이 할당량에 도달하면 통화 출금을 신청할 수 있습니다.。",
    "withdraw.index.C3Cc7Fff24504Be9": "2.출금신청서를 제출한 후 24시간 이내에 금액이 도착하며, 출금예정시간 이후에도 금액이 도착하지 않을 경우 온라인 고객센터로 문의하시기 바랍니다.。",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.출금신청서를 제출한 후 출금이 진행되어 자금이 시스템에 의해 일시적으로 보류되기 때문에 자금이 동결되며, 이는 자산을 잃거나 자산에 이상이 있는 것을 의미하지 않습니다.。",
    "withdraw.index.4639F3A6E07C00B3": "다음 단계",
    "withdraw.index.81Fba0D65Cc5C3Aa": "지갑 주소를 입력하거나 붙여넣으세요.",
    "withdraw.index.25768141C8Bdea91": "출금금액을 입력해주세요",
    "withdraw.index.97A85734Cd1B7Bf0": "펀드 비밀번호를 입력해주세요",
    "withdraw.index.39C7D74B33241C78": "출금금액이 틀렸습니다",
    "withdraw.index.86A5D17908197525": "출금 확인, 코인 출시 검토 대기 중)",
    "withdraw.index.48Fb603C6638C7F6": "펀드 비밀번호를 설정해주세요",
    "withdraw.index.873Ade9A3Bacc2F7": "힌트",
    "bill.index.5Bbb2A6Ac68D6B2E": "실제계좌",
    "bill.index.75751E0Eb8261C40": "데모 계정",
    "bill.index.Cbc853B37Ecc2E97": "계정 변경 유형",
    "bill.index.4A0Cacddeb2E38B1": "계정 유형",
    "bill.index.F16217028E098B2F": "통화",
    "bill.index.A620Dbe6F06B78Ba": "모든 유형",
    "bill.index.2C3C148Bb94D9B3F": "모든 계정",
    "c2C.index.Ab45695E4Bf1E382": "C2C 거래",
    "c2C.index.2702Dc911Dfea762": "내가 사고 싶은",
    "c2C.index.D737D5B8367A53C4": "나는 팔고 싶다",
    "c2C.index.C0C540A3Ba9Abe01": "용량",
    "c2C.index.D8644E8D60C8Bd8A": "단가",
    "c2C.index.Ebb8670B203D3604": "한계",
    "c2C.index.F8C6245A2Ebf1D27": "모든 은행 카드",
    "c2C.index.F8C6245A2Ebf1D28": "통화 수집",
    "c2C.index.F8C6245A2Ebf1D29": "은행 카드",
    "c2C.index.Cc3Aa8141Da9Ec9E": "구입하다",
    "c2C.index.Cc3Aa8141Da9Ec9F": "팔다",
    "c2C.index.67A39834582Ad99A": "데이터 없음",
    "c2C.order.04C23810B47F77F7": "금액별",
    "c2C.order.6905365902E0A73D": "수량별",
    "c2C.order.3F0Ecebbd9D2F23B": "사용 가능",
    "c2C.order.16853Bda54120Bf1": "모두",
    "c2C.order.Ad3335796F2C95F7": "일시금",
    "c2C.order.61092E27A6879318": "자산보안을 위해 예방의식을 높여주세요！",
    "c2C.order.0F51881F0Fb920B2": "거래정보",
    "c2C.order.Dd74Ceca85E5Bc65": "지불 기한",
    "c2C.order.De3341388A59073F": "30 분",
    "c2C.order.D334B597A352167F": "판매자 닉네임",
    "c2C.order.D334B597A352167G": "구매자 닉네임",
    "c2C.order.9D5F89F99Ced890D": "거래수단",
    "c2C.order.8Adf755B14Bc6B7C": "다음 내용을 먼저 읽어주세요",
    "c2C.order.686B16F454A7338B": "은행 카드로 이체할 때 어떠한 언급도 하지 마십시오. 그렇지 않으면 돈이 풀려나지 않고 계좌가 직접 차단됩니다. 결제 후 새로운 거래 내역 다이어그램을 제공해야 합니다. (P 다이어그램이나 숨겨진 거래 내역이 플랫폼에 보고되어 계정이 동결되는 경우)）",
    "c2C.order.9Bec8Fba867B739B": "결제 수단 선택",
    "c2C.order.Cf2D892C419Fea36": "최소 구매",
    "c2C.order.7290A6B7456A03E0": "최대 구매",
    "c2C.orderConfirm.F7E99B8C203E2B07": "주문 세부정보",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "주문이 생성되었습니다",
    "c2C.orderConfirm.0F5433503F3845Ce": "카운트다운 시간 내에 판매자에게 비용을 지불하세요.。",
    "c2C.orderConfirm.24C3Bcacdb85A451": " 구매자 지불을 기다리는 중 ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "판매자에게 연락",
    "c2C.orderConfirm.8E8140D7Ab607B25": "구매자에게 연락",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "이제 판매자의 디지털 화폐가 에스크로 계좌에 입금되었습니다. 부담 없이 결제해주세요.  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "구매자의 실명：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "7x24시간 고객 서비스 지원 ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "주문 번호",
    "c2C.orderConfirm.726C51702F70C486": "생성 시간",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "고객 서비스에 문의하세요",
    "c2C.orderConfirm.103408Fbbe30287F": "주문 취소",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "결제 확인했어요",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "이미 지불",
    "c2C.orderConfirm.387Ca3625B260E4E": "귀하의 유료 주문",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "거래 취소",
    "c2C.orderConfirm.76C86566Cc3751C0": "주문이 취소됨",
    "c2C.orderConfirm.205B349751D176B4": "결제 성공",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "결제수단",
    "c2C.paymentAdd.C87E865Df6800304": "결제수단 구성",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "결제 수단 유형",
    "c2C.paymentAdd.06910Bddf002695F": "실제 이름",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "페이팔 계정",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "은행계좌번호/카드번호",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "통화명",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "QR코드 결제",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "업로드하려면 클릭하세요.",
    "c2C.paymentAdd.6Daae340F2E9718A": "은행 이름",
    "c2C.paymentAdd.347751415C3Da489": "체인명",
    "c2C.paymentAdd.15B3627Faddccb1D": "주목",
    "c2C.paymentAdd.86Da96Cbd274058F": "주의 사항: 디지털 화폐를 판매할 때 선택한 결제 방법이 구매자에게 표시되므로 해당 정보가 정확하게 입력되었는지 확인하십시오.。",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "구하다",
    "c2C.paymentAdd.90D2A3A5C07179C0": "실명을 입력해주세요",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "귀하의",
    "c2C.paymentAdd.Ec389E6190Eedb62": "은행명을 입력해주세요",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "체인 이름을 입력하세요.",
    "c2C.paymentAdd.94F034F0E01439E0": "주소를 입력해주세요",
    "c2C.paymentAdd.B12E035663A42B32": "모든 은행 카드",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "통화 수집",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "결제 코드를 업로드해주세요.",
    "c2C.paymentAdd.07C443212Ff04Bfb": "성공적으로 추가되었습니다",
    "c2C.paymentDetail.86Da96Cbd274058F": "주의 사항: 디지털 화폐를 판매할 때 선택한 결제 방법이 구매자에게 표시되므로 해당 정보가 정확하게 입력되었는지 확인하십시오.。",
    "c2C.paymentDetail.C4C613Da60226De4": "수정되었습니다.",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C 결제수단",
    "c2C.records.9E36893D5736Abb4": "주문 목록",
    "c2C.records.4B53F9A5B13B3235": "결제 대기 중",
    "c2C.records.4B53F9A5B13B3236": "거래 취소",
    "c2C.records.4B53F9A5B13B3237": "이미 지불",
    "contract.index.9706Df877458F0Be": "전체 창고",
    "contract.index.623Fc7D3D40Aa479": "개시마진",
    "contract.index.F92Dce1169330387": "시장가격은 균일하다",
    "contract.index.3598B97Dd28Dbf20": "수량(개)을 입력해주세요.",
    "contract.index.6E1Fae821Dc0B797": "지속 가능한",
    "contract.index.Eb07Eb0C37C14575": "배달",
    "contract.index.Eb6492F19B1C1Fb6": "과거 거래 내역",
    "contract.index.873Ade9A3Bacc2F7": "힌트",
    "contract.index.Ff63B0F93D39F50B": "당신 말이 맞나요?",
    "contract.index.Ff63B0F93D39F50D": "오래 가세요",
    "contract.index.Ff63B0F93D39F50E": "짧은",
    "contract.index.Ff63B0F93D39F50F": "지휘하다",
    "contract.index.Ff63B0F93D39F50G": "모든 포지션 닫기？",
    "customer.dialog.3D410C88E8A24Fe8": "닉네임 확인",
    "customer.dialog.4B3340F7872B411F": "취소",
    "customer.dialog.549B1456Bb5F98B3": "닉네임을 입력해주세요",
    "customer.dialog.B773B3E45Ccfad00": "닉네임이 비어 있습니다.",
    "customer.index.7308C6B849D159E9": "사진 세부정보",
    "delivery.callDialog.C74F562C3Fe91Fe3": "성공적으로 주문했습니다",
    "delivery.callDialog.B8C98F42Caa15Ca5": "방향",
    "delivery.callDialog.B79A581Dae543974": "장기 구매",
    "delivery.callDialog.B79A581Dae543975": "단기 구매",
    "delivery.callDialog.3B26F0F7E90773F3": "개장 가격",
    "delivery.callDialog.65Fb252Bd94E1039": "예상 수익",
    "delivery.index.B483Ba4Fce633539": "배달",
    "delivery.index.224Dd6Cb58D58249": "거래기간",
    "delivery.index.28158F517E902788": "최소 바이인",
    "delivery.index.A1232162B14Bec76": "배달 주문",
    "delivery.index.445B1D3176D9C69D": "역사적 명령",
    "delivery.index.Ab6Cb86E28653C5C": "배달",
    "delivery.index.20A7A6F14D3698C0": "영업 시간",
    "delivery.index.Ec6821A49395Ddc2": "폐관 시간",
    "delivery.index.254Fdc665C391743": "종가",
    "delivery.index.2A0C5E68B44A472A": "이익과 손실",
    "delivery.index.E05B33309F6D236A": "수량을 입력해주세요",
    "delivery.index.B2899E073Cb355D9": "최소 구매",
    "download.description.869467A93C7Dd4A1": "설치 참고사항",
    "download.description.C80C1Edd6Bee12Bc": "1.설명 파일 다운로드 허용",
    "download.description.2F5Bc180F92642B4": "2.설명 파일이 다운로드되었습니다.",
    "download.description.3C3B849F6Ccf712F": "3.휴대폰에서 [설정]을 입력하면 다운로드한 설명 파일이 계정 정보 아래에 표시됩니다.",
    "download.description.86Affd862985E045": "4.설명 파일을 다운로드하려면 클릭하세요.",
    "download.description.32832492Bfc1D95E": "5.설치하려면 클릭하세요.",
    "download.index.245883C80F181C4A": "다운로드",
    "download.index.Ef3De8Cb2722937A": "언제 어디서나 거래를 시작하세요",
    "download.index.F4A87Eaeed691638": "APP을 통해 최신 뉴스를 받아보세요.",
    "download.index.Ad3Aba52D0D39D01": "설치 패키지 다운로드",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "친절한 팁",
    "download.safariGuide.2Cc68Aaadf623477": "사파리에서 방문해주세요",
    "download.safariGuide.C80Fff2A6Ff4E768": "분산형 웹사이트를 방문 중입니다. 사파리 애플리케이션을 실행하세요.",
    "download.safariGuide.06Da016F3E2F79Bd": "링크 복사",
    "finance.index.B034B367545D5236": "글로벌 고품질 프로젝트",
    "finance.index.6Ebfac2C0A9228B0": "160만 명 이상의 활성 사용자, 1,224억 달러",
    "finance.index.52Bdcaf19659363D": "유동성 채굴",
    "finance.index.D66924272B031Bf5": "매우 높은 연간 소득",
    "finance.index.34789D88Afcefa64": "항목보기",
    "finance.index.E12676B95654D21E": "신용대출",
    "finance.index.4C924Cfd89A11Ac7": "더 많은 거래 기회를 찾아보세요",
    "finance.index.C251D4B594Da2848": "AI 정량화",
    "finance.index.4E53Cf2E5F173Dd3": "원클릭 임대, AI 스마트 거래",
    "finance.records.Cce168301447D1Ba": "진행 중",
    "finance.records.4587B6Bd7Cfa4454": "구속된",
    "finance.records.18685418E3A50147": "채광",
    "finance.records.Df9Fd438F97Cc908": "하늘",
    "finance.records.E23Cb03C5Ad359E5": "용어",
    "finance.records.Bd87Dac6Cba7Eba8": "구매 수량",
    "finance.records.2D0Fff99265E8B1A": "유리한",
    "finance.records.2D0Fff99265E8B1B": "만기 이익",
    "finance.records.024F5F0B851782De": "채굴 시간：",
    "finance.records.3143A26D3F983896": "상환 시간：",
    "finance.records.96574Aaf8Db85Ead": "조기상환",
    "finance.records.531E1829203181Ac": "정말 일찍 사용하시겠습니까?;",
    "finance.records.9205843B8923Dac3": "상환 성공;",
    "loan.applyCallback.B9258Ec8746A74D8": "채널 차용",
    "loan.applyForm.7B8Ed340400A6E2B": "이름",
    "loan.applyForm.549A44Bae725F4A2": "성",
    "loan.applyForm.2A27E61375E7Fea8": "생일",
    "loan.applyForm.89925B019D537738": "이메일 주소",
    "loan.applyForm.Afaeb32Cb4D62E28": "집 주소(호수 포함)）",
    "loan.applyForm.E05B5D049B64B040": "도시",
    "loan.applyForm.B166E4E8Fe9513Fa": "국가",
    "loan.applyForm.83Aaede109E395F8": "우편 번호",
    "loan.applyForm.647Ed21332A4968B": "당신의 월 소득",
    "loan.applyForm.Cf0A9B845D34D4B4": "추가 소득 (선택 사항)",
    "loan.applyForm.7048F618Acc6A54C": "월세 또는 모기지 지불",
    "loan.applyForm.15C1Bba18Ae18545": "모기지 있으면 알려주세요",
    "loan.applyForm.3921E90625A84E36": "담보대출 보증을 선택하지 않으면 심사 통과 확률이 낮다",
    "loan.applyForm.262Eec4F8906B0F6": "모기지가 있나요?",
    "loan.applyForm.8634Eb21B6910F21": "모기지 종류를 선택해 주세요",
    "loan.applyForm.A4897B3A331B5E61": "거래 바우처",
    "loan.applyForm.6623384C683De457": "계속 연락하자",
    "loan.applyForm.A8360Bdba86834Cb": "귀하의 대출 신청에 대한 추가 정보가 필요한 경우 이 전화번호로 연락드릴 수 있습니다.",
    "loan.applyForm.C1C373F385A4C7B3": "전화 번호",
    "loan.applyForm.1B9785Badb91191F": "정보를 입력하신 후 시스템 평가 및 검토를 기다려주세요.。",
    "loan.applyForm.8755B9C761D4080C": "나는 동의서를 읽고 전자적으로 서명했습니다.",
    "loan.applyForm.3E987B787468148C": "이용약관 및 전자 동의",
    "loan.applyForm.Bc54E76B61C50Fb9": "약관을 읽고 동의해주세요.",
    "loan.applyForm.89E158384F634Eb8": "이전 단계로 돌아가기",
    "loan.applyForm.Efa5A4548E2F3510": "집 주소",
    "loan.applyForm.4Cadf1787541B35D": "예,",
    "loan.applyForm.Fab408D2F4100447": "아니요,",
    "loan.applyForm.3Bad7A307480Cc56": "이메일 형식이 잘못되었습니다.;",
    "loan.applyForm.306322F49B36855D": "신청서가 제출되었습니다. 고객 서비스 검토를 기다려 주세요.",
    "loan.applyForm.3C6E0E858C995Ff9": "거래 바우처를 업로드해주세요.",
    "loan.applyForm.2Bf443476B271376": "최대 4장의 사진을 업로드할 수 있습니다.",
    "loan.applyQuota.Ef1D8E23003B9A6F": "채널 차용",
    "loan.applyQuota.Ef1D8E23003B9A6G": "대출 한도 받기",
    "loan.applyQuota.9E0780F9352C7273": "지갑 잔액",
    "loan.applyQuota.Cbc1Eb9D639A6105": "총 대출 가능 금액",
    "loan.applyQuota.033A05462195B080": "차입금액",
    "loan.applyQuota.9Aa89B036Ef4821D": "남은 잔액",
    "loan.applyQuota.5656Fb52Ef17A557": "대출 한도 받기",
    "loan.applyQuota.6Ff6702B4C917D40": "검토중",
    "loan.applyQuota.6Ff6702B4C917D41": "거부됨",
    "loan.applyQuota.6Ff6702B4C917D42": "성공했다",
    "loan.applyQuota.0Bad0E857Ea4A952": "신청 시간",
    "loan.applyQuota.3E7E56E2De2Cc546": "신청금액",
    "loan.applyQuota.8F1D170D9E54Af5B": "신청 진행",
    "loan.applyQuota.6167Ca035B93Caf3": "이유",
    "loan.applyQuota.410C7954F9A3981D": "재신청",
    "loan.bottomSheet.61Cd62F861D8F5B2": "통화를 선택하세요",
    "loan.index.4C10307B22E885C3": "더 많은 거래 기회를 발견하고 부를 창출하세요",
    "loan.index.Ae8D37Dea2B3717B": "이자율",
    "loan.index.1Bb54625682E85Db": "규칙 소개",
    "loan.index.5Bd31Fab681C6E9D": "더보기",
    "loan.index.111F95F404814327": "대출 통화",
    "loan.index.6Bc2723B3Bec106D": "대출",
    "loan.index.3200106280E6E370": "이자율",
    "loan.index.049A6Ccb6C81A635": "대출금액(최소/최대）",
    "loan.productDetails.5037Ad86Ab4B7E02": "대출",
    "loan.productDetails.37181C74559Db182": "주문하다 ",
    "loan.productDetails.5802Aaf06A1E4755": "유동성",
    "loan.productDetails.89Aea9Cf9337C40F": "대출가능수량",
    "loan.productDetails.9E419Ae2D06F3Ad9": "상환할 수량",
    "loan.productDetails.C65F329Ef14C4A3D": "빌린 코인 금액",
    "loan.productDetails.3A74667815Adc8Bf": "최고",
    "loan.productDetails.Ab851D8351573227": "하늘",
    "loan.productDetails.Ddf82D8A2C8F46A0": "상환주기",
    "loan.productDetails.C105E5562005Aeb1": "하늘",
    "loan.productDetails.3F346Be2137F0756": "대출금액(최소/최대）",
    "loan.productDetails.2707F78Df864E194": "관심",
    "loan.productDetails.B9Dc9B81E726Bdf1": "기한 내에 상환하시기 바랍니다. 연체시에는 일일금액(원금+이자)이 추가로 청구됩니다.",
    "loan.productDetails.B9Dc9B81E726Bdf2": "%연체료",
    "loan.productDetails.4D94Faa033Be7356": "나는 동의했다",
    "loan.productDetails.62429A957A22780A": "코인 대출 서비스 계약",
    "loan.productDetails.Bc54E76B61C50Fb9": "약관을 읽고 동의해주세요.",
    "loan.productDetails.Ec8Ba071C53A67Cb": "지금 빌리세요",
    "loan.productDetails.59246A0Bf081D398": "최소",
    "loan.productDetails.Ec3F1172B71Cd36C": "최소 금액",
    "loan.productDetails.7Ccc4Cf1D81930B3": "가장 큰 금액",
    "loan.productDetails.071488598990Aa68": "약관을 읽고 동의해주세요.;",
    "loan.productDetails.579Dd216D3339119": "대출 성공;",
    "loan.records.4951B36Fd0D2A831": "대출 및 신용 이력",
    "loan.records.Bb0Aaa30514B7Cba": "현재 기록",
    "loan.records.0F8B0A7D80769051": "상환내역",
    "loan.records.D4B42E43D88De6F1": "역사 기록",
    "loan.records.Cb1847D6281897E6": "대출 금액",
    "loan.records.C7Bfcacfc2Adea58": "상환금액",
    "loan.records.8B369164D5Fea340": "대출시간：",
    "loan.records.Ab2402Ad3Ffd35E7": "대출금을 조기에 갚으세요",
    "loan.records.B7A0Ca0C195D0Cd1": "출시 시간：",
    "loan.records.42014Bdcfd9C411B": "상환시기：",
    "loan.records.A0Acfaee54F06458": "완전한",
    "loan.records.67A39834582Ad99A": "데이터 없음",
    "loan.records.6A687Ff5B8F1598F": "정말로 미리 상환하시겠습니까?",
    "loan.records.7C20D7008D0Eae86": "상환 성공",
    "mining.bottomSheet.Ba91637D32Ccba04": "유동성 채굴",
    "mining.bottomSheet.7De3D636A165E008": "수익률",
    "mining.bottomSheet.F3003345B189D683": "채굴기간",
    "mining.bottomSheet.3E4C0A8C533F5848": "동전 종류",
    "mining.bottomSheet.Adcba368993Ae65E": "구독 금액(최소/최대)",
    "mining.bottomSheet.94743B24E4Ed4166": "제한 없는",
    "mining.bottomSheet.286944Fedd211257": "사용 가능한 자금",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "예상 수익:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "지금 내 것",
    "mining.bottomSheet.Bb1493C567C114Dd": "친절한 팁：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "조기상환수수료",
    "mining.index.8B2Bba1422Eed6F0": "수백억의 부를 창출하고 순이익 100%",
    "mining.index.42997E300086Ee89": "최고 수익",
    "mining.index.510F74F42052Ecce": "광산 제품",
    "mining.index.B853F96626106912": "채굴하러 가세요",
    "mining.productList.37181C74559Db182": "주문하다",
    "mining.productList.Aac5B810004C46C8": "정기적인",
    "mining.productList.D2Fb4F78C387D888": "채굴에 성공하셨습니다",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "주문 확인",
    "quantify.bottomSheet.6915303B698303B1": "로봇",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "임대료",
    "quantify.bottomSheet.Fbd931286Be387Aa": "주기",
    "quantify.bottomSheet.9Ad84Db481807844": "무기한",
    "quantify.bottomSheet.994D7226Ca083Ea5": "일일 수익률",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "계약 날짜",
    "quantify.bottomSheet.88008603Ba7Bde64": "구매 성공",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "지금부터 수익 지분을 받으세요！",
    "quantify.bottomSheet.C778A674C4A054F9": "주문 확인",
    "quantify.bottomSheet.685Dad322B7029B0": "반품 거래",
    "quantify.buyForm.Acf8560A04334E95": "구매 가격",
    "quantify.buyForm.Cf9Cfbc2C554944F": "개요",
    "quantify.buyForm.8788Df2B625E97B5": "로봇",
    "quantify.buyForm.50Ca63B45E881D50": "배당금 지급 시기",
    "quantify.buyForm.Fcda03Adbc5Fca97": "매일",
    "quantify.buyForm.Afb89504Fc399189": "구매수량을 입력해주세요",
    "quantify.buyForm.1435Afdfb4Ec0596": "최소 구매 수량",
    "quantify.buyForm.1B97F1730Bacd839": "최대 구매 수량",
    "quantify.index.32F56C9B7D0E250A": "기간 : 무기한",
    "quantify.index.E34F028D00904630": "가격",
    "quantify.index.E34F028D00904631": ",일일 수익률",
    "quantify.productDetail.6Bbe178Df539A5Ca": "에스크로 금액",
    "quantify.productDetail.53635B53Bca31Dfa": "예상 일일 수입",
    "quantify.productDetail.F9E46449A6269E09": "누적 수입",
    "quantify.productDetail.98Fc46B1B8C993B2": "호스팅 주문",
    "quantify.productDetail.93624Bb045830966": "지금 대여",
    "quantify.records.0C16A18C32B9Cf4A": "주문기록",
    "quantify.records.B191F5614B0E2312": "구매 내역",
    "quantify.records.6A06033B86A73F7C": "상환 기록",
    "quantify.records.45E0F7E71611618E": "누적 수입",
    "quantify.records.45E0F7E71611618F": "만기 이익",
    "quantify.records.Aae1201E38C4Baaa": "달리기",
    "flash.index.C41Fd4F2Fdfa594B": "소비하다",
    "flash.index.1Ac59Fa2218E3B00": "사용 가능:",
    "flash.index.Bf7233A660821549": "얻다",
    "flash.index.548E775Dd67C7472": "교환 확인",
    "flash.index.066A2449Bd616F03": "상환수량을 입력해주세요",
    "flash.index.Fca26Fcf9C06563F": "플래시 사용 성공",
    "message.index.40031524233F4564": "메시지 센터",
    "message.index.8E5586210Fd44D69": "영구 위치 알림",
    "message.index.Bcd3F470E88C197D": "아직 알림이 없습니다",
    "message.index.23A557Fbce545879": "영구 청산 통지",
    "message.index.B5E19B36B5125550": "출금 알림",
    "message.index.716A981465138334": "충전 알림",
    "message.index.884474Ebff2977Ba": "시스템 정보",
    "message.index.08Cc7D24752Ec42C": "온라인 서비스",
    "message.index.1D820A6A19Ab025D": "7 X 24시간 온라인 수동 서비스",
    "more.index.29881E0B5Df58Bf7": "더 많은 기능",
    "more.index.C77Ee35911D10D9D": "공통 기능",
    "more.index.3558F69B0260Be61": "거래 기능",
    "more.index.7243Fe610Ed98Cec": "실계좌",
    "more.index.7243Fe610Ed98Ced": "데모 계정",
    "more.index.693C7B35Bdf3E3A3": "재원",
    "more.index.F855997Fac114554": "지원 센터",
    "more.index.Ae9A1252278Da742": "돕다",
    "more.index.Ace012504Bc529E0": "정보",
    "more.index.05682Dbef5Eeb0A8": "고객 서비스",
    "more.index.C7Af227A5D5C7Bb9": "라이브 계정으로 전환했습니다",
    "more.index.D818A65592694786": "데모 계정으로 전환했습니다",
    "notice.details.B360807Dbe727E6E": "회사 소개",
    "notice.details.92F2552F695E70E3": "회사 소개",
    "notice.details.7Aaceaf69B67F3D1": "규칙 소개",
    "notice.index.6128438Be34Bcb9F": "영구 통지",
    "notice.index.71627F5Eb7128261": "탈퇴 통지",
    "notice.index.2Dce570Fda841705": "충전 알림",
    "notice.index.228A7C9C6448A9B1": "시스템 알림",
    "setting.dialog.8B4F5B74660Deabe": "색상 구성",
    "setting.dialog.Bfc7D25486Ebc1A9": "녹색 상승 및 빨간색 하락",
    "setting.dialog.796Ea0E1A82C320B": "붉은색 상승과 녹색 하락",
    "setting.index.8Ddc5864E1739466": "환경설정",
    "setting.index.F5205801D57D6727": "언어",
    "setting.index.2E2C12Dd0Cc1B63B": "호가통화",
    "setting.index.322D8496Aec3D918": "버전 번호",
    "setting.index.Aa7E6C204C751Cfa": "로그아웃",
    "share.index.75Bc38Bbf7D2E766": "친구를 초대",
    "share.index.45575Eb5D42213E6": "내 초대 코드",
    "share.index.29F67F0644A78Bf3": "복사",
    "share.index.19Cc65Bb6Bb98Fe7": "내 초대 링크",
    "share.index.5372712F162Fb78B": "직속 부하 직원 수",
    "share.index.C88D6B95E3Ab7D6F": "총 부하 직원 수",
    "share.index.Bb5Fa5471Aeecc62": "직속 부하 직원에 대한 커미션 리베이트",
    "share.index.426B6Bac9A5F3D4F": "총 리베이트",
    "share.index.197E4A428F0401C9": "리베이트 기록 보기",
    "share.records.88A6B2E2B74B13C4": "커미션 기록",
    "share.records.B671A35Ffdc93B89": "할인",
    "transfer.index.B17617A869C03Ca5": "지갑",
    "transfer.index.E4116Dcc6F6C7270": "전송 성공",
    "transfer.index.48Fb603C6638C7F6": "펀드 비밀번호를 설정해주세요",
    "transfer.records.Eb0E39Eb5911D418": "얻다",
    "transfer.records.977554Be0Aa65052": "플래시 교환 시간：",
    "transfer.records.9Aa33884Aa400B7C": "환승 시간：",
    "form1.forget.76636Db8Fae5B124": "당신의 사서함",
    "form1.forget.35354B9Cca1Bb0E6": "이메일 인증코드",
    "form1.forget.604A0B11E916F387": "비밀번호",
    "form1.forget.9Cb1C9C51E7503D6": "비밀번호 확인",
    "form1.forget.3A4F7E04B008453C": "전화 번호",
    "form1.forget.Cf11Dce5B4713B5A": "휴대폰 인증코드",
    "form1.forget.3012Edba57D4F297": "이메일을 입력해주세요",
    "form1.forget.F1Ee1D97E869E447": "인증번호를 입력해주세요",
    "form1.forget.3A5C4C9Ae68Fa0F6": "비밀번호를 입력해주세요",
    "form1.forget.C6Cb174Da411D5F7": "휴대폰을 입력해주세요.",
    "form1.forget.947B3B21Ee45E582": "보내다",
    "form1.forget.3E0580F1E8630Df6": "두 개의 비밀번호가 일치하지 않습니다",
    "form1.forget.C5A61836B8Cc74C6": "비밀번호 변경이 성공적으로 리디렉션되었습니다....",
    "form1.forget.B96D072Bc8962565": "재전송",
    "form1.login.2B72C27Ff828Cfc4": "너의 비밀번호",
    "form1.login.D7C6B872Af9Cb17F": "내 비밀번호 기억해 줘",
    "form1.login.0Fff44C827A4F3B6": "로그인",
    "form1.login.1B7F2C1Ede3990Cc": "아직 계정이 없나요? 지금 등록하세요",
    "form1.login.F657F7Be1Cc33Bd2": "비밀번호를 잊어버려",
    "form1.login.2074A1Eec4B088B0": "지갑 로그인",
    "form1.login.1B8938Fbcc3C9B56": "로그인 성공 및 리디렉션 중...",
    "form1.login.07162A7Bb9310555": "ERC20 지갑을 설치해주세요",
    "form1.login.766969966Ae151Dc": "TRC20 지갑을 설치해주세요",
    "form1.login.B88171E1Ad4C50Bf": "네트워크 전환을 거부했습니다.",
    "form1.register.Aaef0710Cb4Db554": "비밀번호를 다시 입력하세요",
    "form1.register.54Dfea0415C708Ff": "초대 코드",
    "form1.register.6839940Dac08C794": "등록하다",
    "form1.register.9901A3B5550Aa58E": "이미 계정이 있나요? 로그인",
    "form1.register.2548Ca53785252Ea": "초대코드를 입력해주세요",
    "form1.register.Dd71Fd65900Dd03E": "등록 서비스 계약을 읽고 동의하세요.,",
    "form1.register.7975613909490B77": "등록이 완료되었으며 리디렉션 중입니다....",
    "form1.walletDialog.105829Acfc0Bd6Aa": "지갑을 선택하세요",
    "userc.certification.2Af9511640153D2E": "주니어 인증",
    "userc.certification.177B191D8E0Fa84D": "인증되지 않음",
    "userc.certification.601218D4D572E98A": "검증됨",
    "userc.certification.6E37C51Ba9012Fc8": "통과하지 못함",
    "userc.certification.1B9B563E05C96474": "고급인증",
    "userc.certification.1418B83803Ab56B4": "먼저 기본 인증을 완료하세요.",
    "userc.creditScore.0B149112601Acc4D": "신용 점수",
    "userc.creditScore.88Ac7F84E106C5F6": "총 신용 점수",
    "userc.creditScore.3E1D6E0E7F1E6155": "시스템 평가",
    "userc.index.62Eac58D04Bbca0F": "개인 센터",
    "userc.index.F4Cac53F93Cb2Aff": "모의거래",
    "userc.index.42F409Fdf3559773": "보안센터",
    "userc.index.1B8E211009807Db8": "입증",
    "userc.index.Bdfcf21Eb8F2Eb70": "계정 변경 기록",
    "userc.index.6C18D67F6D19586B": "플랫폼 소개",
    "userc.index.807A814Ed58A93Bc": "캐시 지우기",
    "userc.index.E43Daad59759611C": "인증되지 않음",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "로그인 비밀번호 변경",
    "userc.secureCenter.1F37Ba427Debf24A": "계정 바인딩",
    "userc.secureCenter.F9A5Ffa750664004": "Google OTP 바인딩",
    "userb.certificationForm.7685Af7D30043Cd6": "주니어 인증",
    "userb.certificationForm.7685Af7D30043Cd7": "고급인증",
    "userb.certificationForm.70E37C35Abc3F462": "국가",
    "userb.certificationForm.F92C25A68E4D08C5": "도시",
    "userb.certificationForm.0Ccc6387B38E0318": "당신의 이름",
    "userb.certificationForm.94C67Bba370Fdd46": "라이센스 번호",
    "userb.certificationForm.B2D4C7Fffe79258E": "우편",
    "userb.certificationForm.57554268761Fb47A": "신분증 앞면 업로드",
    "userb.certificationForm.D79E90201F5319Bd": "신분증 뒷면 업로드",
    "userb.certificationForm.9C6Bad08Af29E20E": "신분증 사진 업로드",
    "userb.certificationForm.F96B62Dfa31Cde45": "제출 확인",
    "userb.certificationForm.2125D78Ea8C6D287": "업로드 요구 사항",
    "userb.certificationForm.0D64864D3076A824": "예시 보기",
    "userb.certificationForm.35287Deb72281E87": "1.신분증 정보를 명확하게 읽을 수 있어야 합니다.。",
    "userb.certificationForm.3007F2E01Efc7B57": "2.사진의 내용은 사실이고 유효하며 어떠한 수정도 허용되지 않습니다.。",
    "userb.certificationForm.25A90660554319F9": "3.ID 번호와 이름이 명확하게 표시되어야 하며 JPG/JPEG/PNG를 지원해야 합니다.",
    "userb.certificationForm.5A9Aefbc03C778F7": "들어 오세요",
    "userb.certificationForm.3B7A1D8Ce27C937E": "제출이 완료되었습니다. 검토를 기다려 주세요.",
    "userb.certificationForm.744Bd07D8Abd2513": "신분증 앞면을 업로드해주세요.",
    "userb.certificationForm.6De1C319130F7Fef": "신분증 뒷면을 업로드해주세요.",
    "userb.certificationForm.79F8E8B172E30C8A": "신분증 사진을 업로드해주세요.",
    "userb.certificationForm.46412E16D28A4753": "사진 업로드 실패",
    "userb.changeDialog.676B0Adca1F8Eea4": "비밀번호 변경",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "전화 번호",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "유효성 검사기 스위치",
    "userb.modifyInfoDialog.62149E566814154C": "검증인 선택",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "인증 앱을 열고 다음을 클릭하세요.+",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "'QR 코드 스캔' 또는 'Enter 키'를 선택하세요.'",
    "userb.modifyInfoDialog.93F094E2B4369472": "QR 코드를 스캔하거나 키를 입력하세요",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "닫다/열다",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "기존 펀드 비밀번호",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "새로운 펀드 비밀번호",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "새 펀드 비밀번호 확인",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "펀드 비밀번호 확인",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "기존 펀드 비밀번호를 입력해주세요",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "새로운 펀드 비밀번호를 입력해주세요",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "펀드 비밀번호를 입력해주세요",
    "userb.modifyInfoDialog.258A534498Fe984F": "펀드 비밀번호를 확인해 주세요",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "계정 바인딩",
    "userb.modifyInfoDialog.D34746090C25E5Db": "펀드 비밀번호",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "확인하다",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "유효성 검사기가 닫혔습니다.",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Google 인증이 성공적으로 활성화되었습니다.",
    "userb.modifyInfoDialog.24Dba26593F43C74": "펀드 비밀번호가 성공적으로 수정되었습니다",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "펀드 비밀번호 설정 성공",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "바인딩 성공",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "인증코드 오류",
    "userb.veBottomSheet.Eecc4683C725F6F2": "인증서 업로드 예시",
    "userb.veBottomSheet.6F38A0D5858633Ee": "어둡고 깨끗한 배경",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "충분한 조명",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "정렬 보조 프레임",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "사진이 선명해요",
    "userb.veBottomSheet.50B741382E71F103": "실패의 가능한 이유",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "신분증 보호 커버가 제거되지 않았습니다.",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "배경이 어지러움의 영향을 받음",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "신분증은 반사형입니다",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "빛이 너무 어둡습니다/휴대폰 그림자에 가려져 있습니다.",
    "userb.veBottomSheet.1A75D2Fc2614592B": "신분증을 들고",
    "home.home.C3525Eb110Db58Aa": "더",
    "home.home.29761532Fc8Ebecc": "거래",
    "home.home.F15Ea99Bfa6E1848": "안전인증, 안심하고 구매하세요",
    "home.home.46E1Ab39Bae7182A": "빠른",
    "home.home.Fde2Eb7F6F3Dd800": "빠른 거래와 간단한 조작",
    "home.home.Ea4756Bc1642E0F1": "이름",
    "home.home.B6B1127Ede1C97B4": "최신 가격",
    "home.home.3587Ad870Eb0Ab86": "24H 증가 및 감소",
    "home.home.Cb1A4E14Ec2Cd2B5": "발견하다",
    "home.home.0F1B1Ac0E96A40D1": "블록체인",
    "home.home.8Eaefb1107A0E210": "외환",
    "home.home.Ad65263D034Ea051": "귀금속",
    "home.home.5Fc1474Dbf74Ac1A": "농산물",
    "home.home.135318F4664F7773": "에너지",
    "kline.kLine.C96Ff250Cf6B8B84": "24시간 최고가",
    "kline.kLine.6E8Be64F19D74565": "24시간 거래량",
    "kline.kLine.08A4Bfbe26A0F262": "24시간 최저가",
    "kline.kLine.8701Deb075066331": "24시간 거래량(USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1점",
    "kline.kLine.B49Cca240E013124": "5점",
    "kline.kLine.F27A90Be97Ba3D6C": "15점",
    "kline.kLine.Ff3E62395B694384": "30점",
    "kline.kLine.C1993F9C6Db1A674": "1 시간",
    "kline.kLine.Fd2Cbbae405E284C": "1 일",
    "kline.kLine.C9Cd71904395042C": "1월",
    "kline.kLine.7D8127Ec18C7B8C7": "구입하다",
    "kline.kLine.E3D3Ffdb9B3E5636": "수량(개)",
    "kline.kLine.6834Fec1838D8029": "가격(USDT）",
    "kline.kLine.16C52Aae474Bc9Cd": "매도 주문",
    "kline.kLine.B9Bea1A9A88D1419": "길게 열다",
    "kline.kLine.D36Cfa96Ee2B343B": "오픈 쇼트",
    "other.languageRateSelect.6897784800834C1B": "언어 설정",
    "other.languageRateSelect.6897784800834C1C": "가격 통화를 선택하세요.",
    "other.navigationBar.F6E62F9642018Cbb": "첫 장",
    "other.navigationBar.0Ec227161A47B49D": "인용 부호",
    "other.navigationBar.29761532Fc8Ebecc": "거래",
    "other.navigationBar.693C7B35Bdf3E3A3": "재원",
    "other.navigationBar.9734C54620Eb09Af": "자산",

    "home.index.25Aa4Fc745150030": "암호화폐의 왕",
    "home.index.2D6Ecf8Faaa8B124": "더 빠르게, 더 좋게, 더 강하게",
    "home.index.1Db26267D1041223": "1,700개 이상의 암호화폐를 쉽게 거래하세요",
    "home.index.F8E18C90C3E35618": "지금 등록하세요",
    "home.index.29761532Fc8Ebecc": "거래",
    "home.index.D8A95E4506487452": "인기 있는 계약",
    "home.index.5Bd31Fab681C6E9D": "더보기",
    "home.index.010Ae07A09Caf221": "거래쌍",
    "home.index.F4D875118E6D6Dbb": "최근 거래가격",
    "home.index.3Dfdfb3D6599100E": "24시간 가격 인상 및 인하",
    "home.index.28Fb39Bd41E54242": "경향",
    "home.index.B47A3911Bacd94C6": "게이너 목록",
    "home.index.712910D574F43D95": "안정적인 수입",
    "home.index.5507Fd830D7Bf51F": "복사하기 쉬운 최고의 전략",
    "home.index.7De3D636A165E008": "수익률",
    "home.index.B853F96626106912": "채굴하러 가세요",
    "home.index.213B5C96Be7Cd1F8": "일, 연간 이자율",
    "home.index.213B5C96Be7Cd1F9": "최대 구매 가능",
    "home.index.Dc710Cffe6313Bb5": "세부 사항을 확인하세요",
    "home.index.B92Ffc837Ecaa1Dd": "소식",
    "home.index.6C18D67F6D19586B": "플랫폼 소개",
    "home.index.6342E3C33Cb48Cd5": "디지털 통화는 거래 보안을 보장하기 위해 암호화 원칙을 사용하는 교환 매체입니다. 미국 달러와 같은 법정 화폐와 달리 디지털 화폐는 물리적인 형태를 갖고 있지 않습니다. 비트코인(BTC), 이더리움(ETH), 폴카닷(DOT)과 같은 인기 있는 디지털 통화는 블록체인을 기반 기술로 사용하여 분산형 디지털 원장 역할을 합니다. 모든 디지털 통화 거래는 블록체인에 기록되며 일단 확인 및 검증되면 변경할 수 없습니다. 은행이 중앙 집중식 원장을 유지하는 기존 통화와 달리 디지털 통화 거래는 누구나 액세스할 수 있는 공개 블록체인에서 발생합니다.",
    "home.index.198Efc4254683Eef": "또한 합의 메커니즘에 따라 누구나 디지털 화폐 거래를 검증하고 이를 블록체인에 추가할 수 있어 탈중앙화를 달성할 수 있습니다. 삼성, 블랙록, 모건스탠리, 알파벳 등 유수의 글로벌 기업들이 투자를 하면서 사람들은 블록체인 기술이 글로벌 금융 시스템을 완전히 바꿀 것이라고 믿고 있습니다. 디지털 통화는 귀하에게 분산형 금융의 세계로의 문을 열어주며, 최첨단 기술이 가져온 무한한 가능성을 탐색할 수 있게 해줍니다.",
    "home.index.E710339D9576292E": "우리의 장점",
    "home.index.F9Ce8B2C63885B26": "안전하고 안정적",
    "home.index.C4D0A2C9Ff0305Ce": "최고의 기술 팀, 포괄적인 보안 보호, 고속 트랜잭션 합성 엔진의 독립적인 연구 및 개발은 대규모 동시 트랜잭션에서도 여전히 안정적이고 안정적으로 작동할 수 있습니다.",
    "home.index.2E8708Ee3F11Cc1A": "전문적이고 신뢰할 수 있는",
    "home.index.Bb12Cbcf89C56C5C": "블록체인 및 금융 분야에서 다년간의 경험을 보유한 전문 운영팀은 규정에 맞는 디지털 자산 거래 라이센스를 보유하고 있으며 100% 준비금을 보장합니다.",
    "home.index.Aa4723Bfbf6808F4": "마음으로 봉사하다",
    "home.index.1Bf0204B7D3Ee230": "글로벌 시장에 직면하고, 다국어 지원, 연중무휴 24시간 운영, 강력한 커뮤니티 지원 및 전문적인 고객 서비스를 제공합니다.",
    "home.index.2E7Dfd53D26F7224": "고성능",
    "home.index.1C65453103B5Fcc3": "초당 300,000건의 트랜잭션과 1밀리초 미만의 주문 응답 시간.",
    "home.index.85Ac49Ece9Dcc80B": "언제 어디서나 거래하세요!",
    "home.index.2799E18842E4Ebe9": "OKEX 앱과 웹페이지에서 지원되는 즉시 포지션을 개설하세요!",
    "home.index.2Bf52Ce75E29Fc88": "지금 다운로드하려면 코드를 스캔하세요.",
    "home.index.88D1422B33571066": "기계적 인조 인간",
    "home.index.04Ee893775563F21": "애플 버전",
    "home.index.04Ee893775563F22": "제품 서비스",
    "home.index.04Ee893775563F23": "재무 관리",
    "home.index.04Ee893775563F24": "반품",
    "home.index.04Ee893775563F26": "IOS & ANDROID",

    "homepage.text1": "비트코인, 이더리움 및",
    "homepage.text1_2": "1000+ 종 암호화폐 구매",
    "homepage.text2": "암호화폐 체험 여행",
    "homepage.text4": "고금리 재무 관리",
    "homepage.text5": "안정적인 재무 관리, 높은 연간 수익률",
    "homepage.text6": "보상 공유",
    "homepage.text7": "빠른 거래, 간단한 조작",
    "homepage.text8": "더 보기",
    "homepage.text9": "즉시 교환",
    "homepage.text10": "안전 인증, 안심 구매",
    "homepage.text11": "최고의 기술 팀, 포괄적인 보안",
    "homepage.text12": "보호, 독립",
    "homepage.text13": "전문적이고 신뢰할 수 있는",
    "homepage.text14": "다년간의 블록체인 및 금융 경험을 보유한 전문 운영팀",
    "homepage.text15": "진심으로 서비스",
    "homepage.text16": "글로벌 시장을 대상으로, 다국어 지원, 24/7 운영",
    "homepage.text17": "고성능",
    "homepage.text18": "초당 300,000건의 거래를 처리하며 주문 응답 시간이 1 밀리초 미만.",
    "passwod.limit":"비밀번호 길이는 6자 이상이어야 합니다"
}
