import axios from 'axios'
import config from '@/config'
import store from '@/store'
import {urlMode} from '@/config/baseUrls'
// 创建axios实例
import { getCookie } from '@/utils/cookie'

const service = axios.create({
    // baseURL: process.env.NODE_ENV === 'production' ? '' : "https://api.tldrus.com/", // api的base_url
    baseURL: '',
    timeout: 40000, // 请求超时时间,
    headers: {
        // "Content-Type": "application/x-www-form-urlencoded"
        "Content-Type": "application/json",
        "set-aws":'true'
    },
})
function getBaseUrl() {
    if(process.env.NODE_ENV === 'production') {
        if(urlMode == 'list') {
            return window.baseURL
        }
        return config.BASEURL
    }
    return config.BASEURL
}
//是不是模拟账户
function getIsDemoAccount() {
    let accountType = localStorage.getItem('account-type');
    if(accountType) {
        if(accountType == 'real') {
            return "false"
        }
        return "true"
    }
    return "false"
}
// request拦截器
service.interceptors.request.use(configs => {
    let lang = configs.url == '/api/app/language' ? 'ENGLISH' : getCookie("lang")
    configs.headers = {
        ...configs.headers,
        "SET-LANGUAGE": lang,
        "APP-LOGIN-TOKEN": getCookie('user-cookie'),
        "APP-ANALOG": getIsDemoAccount()
    }
    configs.baseURL = getBaseUrl();
    return configs;
}, error => {
    Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
    response => {
        if (response.status == 200) {
            if (response.data.errCode == 100007) {
                if (response.config.url == "/api/app/user/get/info") {
                    return response.data
                } else {
                    store.commit('setUserInfo',{})
                    store.commit('setIsLogin', false)
                    location.href = '/#/login'
                }
                // location.href = '/login'
                return
            } else {
                return response.data
            }

        }
    },
    async error => {
        return Promise.reject(error)
    }
)




export default service