<template>
  <div class="foot-page">
    <div class="foot-box">
      <div class="foot-content d-flex justify-space-between">
        <div class="foot-left">
          <img :src="configData.pcLogo" height="60" alt="" />
          <p class="font-secondary-14 white--text copyright">
            {{configData.systemPower}}
          </p>
          <a class="white--text" :href="`mailto:${configData.email}`">{{ configData.email }}</a>
          <v-select
            style="width: 200px"
            class="mt-15 lang-select"
            dark
            :items="getLanguageList"
            v-model="selectLang"
            @change="changeLang"
            item-text="text"
            item-value="key"
            :height="50"
            hide-details
            :no-data-text="$vuetify.lang.t('$vuetify.public.noDate')"
            solo
          ></v-select>
        </div>
        <div class="foot-right d-flex justify-space-between">
          <div>
            <p class="font-primary-20 font-weight-bold white--text">
              {{ $vuetify.lang.t("$vuetify.setting.aboutUs") }}
            </p>
            <!-- <p class="font-secondary-16 white--text mt-10 _a">
              {{ $vuetify.lang.t("$vuetify.setting.about") }}
              {{ configData.systemName }}
            </p> -->
            <p class="font-secondary-16 white--text _a" @click="$router.push('/notice')">
              {{ $vuetify.lang.t("$vuetify.setting.announcementCenter") }}
            </p>
            <p class="font-secondary-16 white--text _a" @click="$router.push('/help')">
              {{ $vuetify.lang.t("$vuetify.setting.onlineSupportCustomerService") }}
            </p>
          </div>
          <div>
            <p class="font-primary-20 font-weight-bold white--text">
              {{ $vuetify.lang.t("$vuetify.home.index.04Ee893775563F22") }}
            </p>
            <p
              class="font-secondary-16 white--text mt-10 _a"
              @click="$router.push('/contract')"
            >
              {{
                $vuetify.lang.t("$vuetify.standardContract.contractTransaction")
              }}
            </p>
            <p class="font-secondary-16 white--text _a" @click="$router.push('/delivery')">
              {{ $vuetify.lang.t("$vuetify.setting.dreivatives") }}
            </p>
            <p class="font-secondary-16 white--text _a" @click="$router.push('/flash')">
              {{ $vuetify.lang.t("$vuetify.spotGoods.spotGoods") }}
            </p>
            <p class="font-secondary-16 white--text _a" @click="$router.push('/market')">
              {{ $vuetify.lang.t("$vuetify.setting.markets") }}
            </p>
            <p class="font-secondary-16 white--text _a" @click="$router.push('/invest')">
              {{ $vuetify.lang.t("$vuetify.home.index.04Ee893775563F23") }}
            </p>
          </div>
          <div>
            <p class="font-primary-20 font-weight-bold white--text _a">
              {{ $vuetify.lang.t("$vuetify.setting.support") }}
            </p>
            <p
              class="font-secondary-16 white--text mt-10 _a"
              @click="$router.push('/support')"
            >
              {{ $vuetify.lang.t("$vuetify.setting.helpCentre") }}
            </p>
            <p class="font-secondary-16 white--text _a" @click="$router.push('/agreement')">
              {{ $vuetify.lang.t("$vuetify.setting.serviceAgreement") }}
            </p>
            <p class="font-secondary-16 white--text _a" @click="$router.push('/privacy')">
              {{ $vuetify.lang.t("$vuetify.setting.privacyStatement") }}
            </p>
          </div>
          <!-- <div class="text-center download-box" >
            <p class="font-primary-20 font-weight-bold white--text">
              {{ $vuetify.lang.t("$vuetify.setting.download") }}
            </p>
            <v-btn
              class="mt-4"
              color="#0A66FF"
              :height="50"
              :width="186"
              light
              @click="$router.push('/register')"
              >{{ $vuetify.lang.t("$vuetify.login.register") }}</v-btn
            >
            <div class="mt-12 mb-3">
              <div class="qrbox">
                <qrcode-vue :value="qrCode" ref="qrcode" size="140">
                </qrcode-vue>
              </div>
            </div>
            <p class="font-secondary-16">
              {{ $vuetify.lang.t("$vuetify.home.index.2Bf52Ce75E29Fc88") }}
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import QrcodeVue from "qrcode.vue";
import { setCookie } from "@/utils/cookie";
export default {
  data() {
    return {
      selectLang: "",
    };
  },
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapGetters(["getLanguageList", "getConfigData"]),
    configData() {
      return this.getConfigData;
    },
    qrCode() {
      return location.origin + "/h5/#/download";
    },
  },
  created() {
    this.selectLang = localStorage.getItem("lang")
  },
  methods: {
    ...mapMutations(["setLanguage"]),
    changeLang() {
      setCookie("lang", this.selectLang);
      this.setLanguage(this.selectLang);
      localStorage.setItem("lang", this.selectLang);
      this.reloadLangs();
      location.reload();
    },
  },
  watch:{
    getLanguageList(v) {
      if(!this.selectLang) {
        this.selectLang = v[0].key
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width:768px) {
  .foot-content {
    flex-wrap: wrap;
    .foot-left {
      width: 100%;
      text-align: center;
    }
    .foot-right {
      width: 100%;
      padding: 0 16px;
    }
  }
  .download-box {
    display: none;
  }
  .lang-select {
    display: none
  }
  .font-primary-20 font-weight-bold {
    font-size: 18px;
  }
  .font-secondary-16 {
    font-size: 14px;
  }
  .copyright {
    margin-top:0px;
    margin-bottom: 30px;
  }
  .foot-box {
    padding: 30px 0;
  }
}
@media (min-width:768px) {
  .foot-content {
    padding: 120px 0 80px;
    .foot-left {
      width: 30%;
    }
    .foot-right {
      width: 70%;
    }
  }
  .copyright {
    margin-top:36px;
  }
  .foot-box {
    // padding: 120px 0;
    
  }
}
.foot-page {
  // border-top: 1px solid #1d1f25;
}
.qrbox {
  padding: 10px 10px 2px 10px;
  background-color: #fff;
  display: inline-block;
  border-radius: 6px;
}
.foot-box {
  background-color: #0A66FF;
  background-image: url('../../public/image/home/foot.jpg');
  background-repeat: no-repeat;
  background-size: 84%;
  .foot-content {
    max-width: 1500px;
    margin: 0 auto;
    .foot-left {
    }
    .foot-right {
      ._a {
        cursor: pointer;
      }
    }
  }
  ::v-deep .v-input__slot {
    box-shadow: none !important;
    background: transparent !important;
    border-radius: 50px;
    border: 1px solid #D6D7DD;
    padding: 0 18px !important;
  }
}
</style>