import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Italiano",
        dialogDetermine: "Sicuro",
        dreivatives: "Consegna",
        markets: "Citazioni",
        assets: "risorse",
        liquidity: "Estrazione liquidità",
        helpCentre: "Centro assistenza",
        announcementCenter: "Centro annunci",
        about: "Di",
        bulletinCentre: "Centro annunci",
        serviceAgreement: "Condizioni di servizio",
        support: "supporto",
        onlineSupportCustomerService: "Servizio Online",
        suggestionsAndFeedback: "Suggerimenti e feedback",
        service: "Servire",
        download: "scaricamento",
        coinApplication: "Applicazione per l'elenco delle monete",
        privacyStatement: "Informativa sulla Privacy",
        trade: "negoziazione a contratto",
        standardConteact: "Sostenibile",
        signOut: "disconnessione",
        assetWallets: "Portafoglio di risorse",
        financeRecords: "Record di modifica dell'account",
        identification: "Autenticazione",
        personalCenter: "Centro personale",
        chooseLanguage: "Scegli una lingua",
        tips: "suggerimento",
        dark: "Modalità scura",
        light: "modalità giorno",
        languageTitle: "lingua",
        convertCurrency: "Converti valuta",
        cancel: "Annulla",
        aboutUs: "chi siamo",
        standard: "Continuità della norma",
        share: "condividere",
        introduce: "Introduzione alla piattaforma",
        set: "impostare",
        changePassword: "Cambia password di accesso",
        denomination: "Valuta di quotazione",
        color: "Configurazione del colore",
        code: "numero della versione",
        color1: "Il rosso sale e il verde scende",
        color2: "Il verde sale e il rosso scende",
        setLangue: "impostazione della lingua",
        vip: "Vantaggi VIP",
        vip2: "Livello VIP attuale",
        safeMenu: "Centro di sicurezza",
        conventional: "convenzionale",
        other: "altro",
        quickTips: "Aggiungi fino a 3 pulsanti funzione di scelta rapida",
        quickTransaction: "Transazione rapida",
        safeConvenient: "Sicuro e conveniente",
        timeSharing: "punto",
        currentLevel: "livello attuale",
        levelBenefits: "%{level} reddito a livello",
        teamLevel: "Livello di squadra",
        levelLower: "%{level}subordinato",
        rules: "Descrizione della regola",
        bindWithdrawalAddress: "Vincolare l'indirizzo di ritiro",
        tipsBindWithdrawalAddress: "Non hai ancora vincolato il tuo indirizzo di prelievo, per favore vincolalo prima",
        walletAddressFormatIncorrect: "Il formato dell'indirizzo del portafoglio non è corretto",
        tradeRules1: "Prevedere l'aumento e la diminuzione nel periodo futuro. Il prezzo al momento dell'ordine viene utilizzato come prezzo di partenza e il prezzo all'ultimo secondo del periodo di previsione viene utilizzato come prezzo di liquidazione. Se il prezzo di liquidazione è maggiore del prezzo di partenza, significa un aumento. Se la liquidazione il prezzo è inferiore al prezzo di partenza, significa una diminuzione.",
        tradeRules2: "1.cos'è un contratto",
        tradeRules3: "Questa piattaforma fornisce contratti perpetui basati su USDT. Gli utenti possono giudicare l'aumento o la diminuzione e scegliere di acquistare contratti lunghi o vendere contratti brevi per ottenere profitti dall'aumento/diminuzione dei prezzi degli asset digitali. I contratti perpetui non hanno una data di consegna e gli utenti possono mantenerli per sempre.",
        tradeRules4: "2.Cos'è il margine",
        tradeRules5: "Nel mercato dei contratti virtuali, gli utenti devono solo pagare una piccola somma di denaro in una certa proporzione in base al prezzo del contratto come garanzia finanziaria per l'esecuzione del contratto, e poi possono partecipare all'acquisto e alla vendita del contratto. Questo tipo di fondi è il deposito del contratto virtuale. \nI contratti su questa piattaforma adottano il modello di margine separato di conti separati. Ciascuna valuta corrisponde a un conto contrattuale. Le attività e le posizioni del conto delle diverse valute contrattuali sono indipendenti l'una dall'altra e i trasferimenti e le transazioni tra conti contrattuali diversi non si influenzano a vicenda. Dopo l'apertura del conto contrattuale di un utente, i rischi e i benefici di tutte le posizioni detenute nel conto contrattuale verranno calcolati insieme.",
        tradeRules6: "3.Come calcolare il margine",
        tradeRules7: "Margine di posizione = valore nominale del contratto * numero di contratti aperti/moltiplicatore della leva\nmargine congelato = la somma dei margini di tutti gli ordini inseriti con successo e di tutti gli ordini di apertura nell'ordine corrente\nmargine disponibile = saldo del conto - margine di posizione - margine congelato",
        tradeRules8: "4.Come calcolare il tasso di margine",
        tradeRules9: "Il tasso di margine è una misura del rischio patrimoniale dell'utente. \nTasso di margine = (Profitti e perdite non realizzati + Margine di posizione + Margine disponibile + Margine di congelamento - Commissione di liquidazione) / Margine di posizione \nPiù piccolo è il tasso di margine, maggiore è il rischio del conto. Quando il tasso di margine è inferiore o uguale allo 0%, verrà attivata la liquidazione forzata.",
        tradeRules10: "5.Qual è il prezzo stimato di parità forzata?",
        tradeRules11: "Il prezzo stimato della liquidazione forzata, ovvero il prezzo calcolato dal sistema che teoricamente attiva la liquidazione forzata, è solo di riferimento e potrebbe discostarsi leggermente dal valore effettivo.",
        tradeRules12: "6.Qual è il patrimonio netto totale del conto e i profitti e le perdite non realizzati?",
        tradeRules13: "Patrimonio netto totale del conto = saldo del conto + profitti e perdite non realizzati\nI profitti e le perdite non realizzati sono i profitti e le perdite della posizione attualmente detenuta dall'utente nel contratto valutario. I profitti e le perdite non realizzati cambieranno con le ultime variazioni del prezzo della transazione. \nProfitti e perdite non realizzati per posizioni lunghe = (1/prezzo medio della posizione - 1/prezzo dell'ultima transazione) * numero di contratti per posizioni lunghe * valore nominale del contratto * prezzo dell'ultima transazione \n Profitti e perdite non realizzati per posizioni corte = (1/ prezzo dell'ultima transazione - 1/ Prezzo medio della posizione) * Numero di contratti brevi * Valore nominale del contratto * Prezzo dell'ultima transazione",
        text1: "Sicurezza dell 'Account",
        text2: "modalità giorno e notte",
        text3: "svuota la cache",
        text4: "Sei sicuro di voler uscire?",
        text5: "Sei sicuro di voler svuotare la cache?",
        text6: "multi lingua",
        text7: "Per proteggere la tua sicurezza, ti consigliamo di attivare almeno un'autenticazione a due fattori"
    },
    login: {
        login: "Login",
        register: "Registrati",
        title1: "Fai trading sempre e ovunque e visualizza le condizioni di mercato in tempo reale",
        title2: "Fai trading sempre e ovunque e visualizza le condizioni di mercato in tempo reale",
        title3: "La piattaforma di trading di criptovalute più affidabile",
        formTitle1: "Account login",
        formTitle2: "Bentornato! Accedi con la tua email, numero di telefono",
        btn1: "cellulare",
        btn2: "Posta",
        btn3: "Login",
        btn4: "Registrati",
        btn5: "dimenticare la password?",
        field1: "Numero di telefono",
        field2: "parola d'ordine",
        field3: "Posta",
        placeholder1: "nazione",
        placeholder2: "Inserisci il numero di telefono",
        placeholder3: "Per favore, inserisci la password",
        placeholder4: "per favore inserisci la tua email",
        content12: "Inserisci il numero di telefono",
        content13: "Per favore, inserisci la password",
        content14: "per favore inserisci la tua email",
        content16: "Servizi finanziari per asset digitali",
        loginSuccess: "accesso riuscito ",
        chooseArea: "Seleziona il prefisso",
        placeholder5: "Seleziona il codice del paese",
        placeholder6: "ricorda la mia password",
        placeholder7: "Scarica l'APP",
        placeholder8: "Contattare il Servizio Clienti",
        placeholder9: "Seleziona il paese o la regione",
        placeholder10: "prefisso",
        placeholder11: "Accesso al portafoglio",
        placeholder12: "Operazione non riuscita, passare a",
        placeholder13: "portafoglio"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "Di",
        aboutCoinbeadCenterDetails: "Possiede la piattaforma di trading di asset blockchain leader a livello mondiale e gestisce l'intero ecosistema.",
        footDetails1: "volume degli scambi giornalieri",
        footDetails2: "transazioni/secondo",
        footDetails3: "Centro assistenza",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "È un sito Web internazionale di risorse digitali di fama mondiale, che fornisce principalmente monete e derivati ​​di risorse digitali come Bitcoin (BTC), Litecoin (LTC) ed Ethereum (ETH) a utenti globali.",
        coinbeadCenterDetailsm: "Adottando tecnologie avanzate come GSLB, server di rete, transazioni di rete, reti, motori di scambio di memoria ad alta velocità multi-macchina, portafogli freddi, portafogli caldi offline intelligenti, ecc., serviamo i nostri clienti con terminali multipli come telefoni cellulari Web e PC Fornire servizi di transazione tariffaria digitale sicuri, stabili e affidabili. Allo stesso tempo, miglioriamo e aggiorniamo prodotti e servizi in base ai suggerimenti e alle esigenze degli utenti per fornire servizi migliori a ogni cliente e non c'è fine al miglioramento dell'esperienza utente innovativa.",
        infrastructureCenterTitle: "infrastruttura",
        infrastructureCenterDetails: "La nostra missione è fornire servizi infrastrutturali per l’intero ecosistema blockchain",
        freeCenterTitle: "gratuito",
        freeCenterDetails: "La nostra visione è quella di far circolare liberamente il denaro in tutto il mondo. Crediamo che diffondere questa libertà possa rendere il mondo un posto migliore",
        futureCenterTitle: "Pioniere del futuro della tecnologia",
        futureCenterDetails: "Apri il futuro della tecnologia",
    },
    applicationForListing: {
        title: "Applicazione per l'elenco delle monete",
        projectName: "Nome del progetto",
        tokenName: "Nome del token",
        projectDescription: "descrizione del progetto",
        contractAddress: "Indirizzo del contratto",
        officialWebsite: "Sito ufficiale",
        ContactAndEmail: "Contatto email",
        ContactAndName: "Nome del contatto",
        file: "Carica allegato",
        pleaseEnter: "prego entra",
        enterFile: "Per favore carica i file",
        upload1: "Attualmente limitato a 1 selezione di file,",
        upload2: "Questa volta ho scelto",
        upload3: "File,",
        upload4: "Totale selezionato",
        upload5: "File",
    },
    public: {
        submit: "invia",
        copySuccessfully: "Copiato con successo",
        copyFailed: "Copia non riuscita",
        noDate: "Nessun dato",
        submitSuccessfully: "Inserito con successo",
        goBack: "tornare all'ultima pagina",
        cancel: "Annulla",
        home: "Prima",
        loading: "caricamento...",
        confirm: "Confermare",
        loadingFailed: "anomalia della rete",
        nomore: "non più",
        tryAgain: "Aggiorna la pagina/esci dal programma e carica nuovamente!",
        hot: "Aree popolari",
    },
    inviteFriends: {
        inviteFriends: "Bendradarbiavimas su",
        freedomOfWealth: "draugais abipusiai naudingas",
        myInvitationCode: "il mio codice invito",
        clickCopy: "Fare clic per copiare",
        myInvitationLink: "Il mio link di invito",
        clicktoSaveTheQRCode: "Fare clic per salvare il codice QR",
        saveQRCode: "Invita il codice QR",
        numberOfSubordinates: "Numero di subordinati",
        rebateIncome: "Reddito di sconto",
        people: "persone",
        myPromotionRevenue: "Il mio reddito promozionale",
        numberOfDirectSubordinates: "Numero di subordinati diretti",
        totalNumberOfSubordinates: "Numero totale di subordinati",
        directSubordinateReturnsCommission: "Sconto provvigionale per i subordinati diretti",
        theTotalCommission: "sconto totale",
        checkHistory: "Visualizza i record degli sconti",
        history: "Registro della commissione",
        theLowerAccount: "Conto subordinato",
        type: "tipo:",
        serviceCharge: "Commissione di gestione:",
        percentageofCommissionReturned: "Rapporto di sconto:",
        commissionrebateAmount: "Importo del rimborso:",
        time: "tempo:",
        typeName1: "Ricarica e commissione",
        typeName2: "Commissione di gestione degli ordini MT distribuzione a tre livelli",
        typeName3: "MT Distribuzione redditizia a tre livelli",
        typeName4: "Premi per i referral",
        typeName30: "Ricarica e commissione",
        typeName31: "Sconto periodico sugli utili di negoziazione",
        typeName32: "Sconto sui profitti dell'estrazione di liquidità",
        typeName45: "Sconto sulle commissioni di transazione",
        typeName46: "Sconto della commissione sulle proprie spese di gestione",
        typeName50: "Sconto sulle spese di gestione del contratto di consegna",
        typeName51: "Sconto sulle commissioni di gestione del contratto perpetuo",
        typeName52: "Sconto aggiuntivo sulle commissioni per le spese di gestione del contratto di consegna",
        typeName53: "Sconto aggiuntivo sulle commissioni per le spese di gestione del contratto perpetuo",
        typeName54: "Ulteriori sconti sulle commissioni di gestione del contratto perpetuo",
        shareRules1: "Istruzioni per l'invito",
        shareRules2: "Accedi al centro inviti, copia il link o il codice di invito e condividilo con i tuoi amici. Gli amici possono diventare tuoi subordinati registrandosi con il tuo codice di invito.",
        shareRules3: "Guadagna sconti",
        shareRules4: "Quando i subordinati conducono transazioni, puoi ottenere le commissioni corrispondenti, sono supportati fino a tre livelli di subordinati. Ad esempio, se inviti l'amico A, A invita B e B invita C, allora A, B e C possono condurre contratti e altre transazioni sulla piattaforma e tutti voi potrete ricevere le commissioni corrispondenti.",
        shareRules5: "Livello di squadra",
        shareRules6: "Più subordinati di primo livello promuovi, più alto sarà il livello della squadra e maggiore sarà lo sconto di cui potrai usufruire. I livelli della squadra sono divisi in LV1-LV6.Le regole di upgrade sono mostrate nella tabella seguente, dove 'N' è il numero di subordinati di primo livello che hanno ricaricato e completato l'autenticazione del nome reale.",
        shareRules7: "Quando i subordinati scommettono sui contratti di consegna, puoi ricevere commissioni in proporzione alle loro scommesse.",
        upgradeConditions: "Richiedere",
        recommendedNumberOfPeople: "Numero consigliato di persone"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Suggerimenti e feedback",
        text: "Se riscontri bug o hai suggerimenti sui prodotti durante l'uso, ti preghiamo di fornirci un feedback.",
        email: "indirizzo e-mail",
        enterEmail: "Inserisci l'indirizzo email",
        enterCorrectEmail: "Inserisci l'indirizzo email corretto",
        title: "titolo",
        enterTitle: "Inserisci il titolo della domanda",
        describeTheProblem: "Descrivi il problema",
        enterDescribeTheProblem: "Inserisci il problema che descrivi",
        submit: "invia",
    },
    help: {
        enterSearch: "ricerca",
        title: "Centro assistenza",
        search: "Inserisci la tua domanda",
        text1: "Comprendi le tue esigenze e continua a fornirti servizi più convenienti"
    },
    announcement: {
        enterSearch: "ricerca",
        title: "Centro annunci",
        search: "Inserisci la tua domanda"
    },
    privacy: {
        title: "Informativa sulla Privacy",
        content1: "1. Quali informazioni personali sugli utenti vengono raccolte?",
        content2: "Le informazioni personali vengono raccolte per fornire prodotti e servizi e per migliorarli continuamente.",
        content3: "Di seguito sono riportati i tipi di informazioni personali che raccogliamo:",
        content4: 'Informazioni che ci fornisci: riceviamo e archiviamo tutte le informazioni che fornisci in relazione alla Piattaforma. Puoi scegliere di non fornire determinate informazioni, ma di conseguenza potresti non essere in grado di utilizzare alcuni "servizi di questa piattaforma". Esempi di informazioni che ci fornisci includono:',
        content5: "Nome",
        content6: "indirizzo",
        content7: "Paese di cittadinanza",
        content8: "Foto d'identità",
        content9: "Numero di cellulare/numero di posta elettronica",
        content10: "Altre informazioni che ci aiutano a identificarti",
        content11: "Informazioni raccolte automaticamente: raccogliamo e archiviamo automaticamente alcuni tipi di informazioni sull'utilizzo dei servizi della piattaforma. Come molti siti Web, utilizziamo cookie e altri identificatori univoci. Otteniamo determinati tipi di informazioni quando il tuo browser web o dispositivo accede alla Piattaforma. Esempi di informazioni che raccogliamo e analizziamo includono:",
        content12: "L'indirizzo IP (protocollo Internet) che collega un personal computer a Internet;",
        content13: "Informazioni di accesso, indirizzo e-mail, password e posizione di un dispositivo o computer personale;",
        content14: "Impostazioni della versione e del fuso orario;",
        content15: "Cronologia delle transazioni.",
        content16: "Informazioni da altre fonti: potremmo raccogliere informazioni su di te da altre fonti, come la storia creditizia delle agenzie di credito. Utilizzeremo queste informazioni per prevenire e individuare le frodi.",
        content17: "2. Può essere utilizzato dai bambini?",
        content18: 'Questa piattaforma non consente a nessun individuo di età inferiore ai 18 anni di utilizzare il "Servizio".',
        content19: "3. A cosa servono i cookie e altri identificatori?",
        content20: "Utilizziamo cookie e strumenti simili per ottimizzare l'esperienza dell'utente, fornire i nostri servizi e comprendere come i clienti utilizzano i nostri servizi in modo da poter apportare miglioramenti mirati. Utilizziamo i cookie per garantire che il nostro sistema possa riconoscere il tuo browser o dispositivo e fornirti servizi.",
        content21: 'Utilizziamo cookie operativi e strumenti simili (collettivamente, "Cookie") per fornire servizi, quali:',
        content22: "Per identificarti quando accedi e utilizzi i nostri servizi.",
        content23: "Fornire funzionalità e servizi personalizzati.",
        content24: "Proteggersi da attività fraudolente.",
        content25: "Migliorare la sicurezza.",
        content26: "Tieni traccia delle tue preferenze (come valuta e lingua).",
        content27: "Utilizziamo i cookie anche per capire come gli utenti utilizzano i nostri servizi in modo da poter apportare miglioramenti mirati.",
        content28: "4. Le mie informazioni personali verranno condivise?",
        content29: "Le informazioni sugli utenti sono una parte importante della nostra attività e non venderemo le informazioni personali degli utenti ad altri. Questa piattaforma condividerà le informazioni personali degli utenti con le sue filiali o affiliate solo nelle seguenti circostanze. Tali filiali o affiliate sono tenute a rispettare la presente informativa sulla privacy o almeno seguire pratiche protettive quanto le misure di protezione descritte nella presente informativa sulla privacy.",
        content30: "Fornitori di servizi di terze parti: impieghiamo altre società e individui per svolgere funzioni per nostro conto. Esempi di tali funzioni includono: analisi dei dati, fornitura di assistenza di marketing, elaborazione dei pagamenti, trasmissione di contenuti e valutazione e gestione del rischio di credito. Questi fornitori di servizi di terze parti hanno accesso alle informazioni personali necessarie per svolgere le loro funzioni, ma non possono utilizzarle per altri scopi. Inoltre, devono trattare le informazioni personali in conformità con la presente informativa sulla privacy e le leggi applicabili sulla protezione dei dati.",
        content31: "Trasferimenti aziendali: man mano che la nostra attività continua a crescere, potremmo vendere o acquistare altre attività o servizi. In tali transazioni, le informazioni dell'utente generalmente rientrano tra le risorse aziendali trasferite, ma rimangono soggette alle promesse contenute in qualsiasi informativa sulla privacy preesistente (a meno che, ovviamente, l'utente non acconsenta diversamente). Inoltre, se la nostra azienda o sostanzialmente tutti i suoi beni venissero acquisiti, verrebbero trasferite anche le informazioni dell'utente. Protezione della nostra azienda e di altri: quando riteniamo che la divulgazione dell'account e di altre informazioni personali sia appropriata per rispettare la legge o i nostri obblighi normativi, far rispettare o applicare le nostre Condizioni d'uso e altri accordi, o i diritti, la proprietà o la sicurezza della nostra utenti o altri Quando, rilasceremo l'account e altre informazioni personali. Ciò include lo scambio di informazioni con altre società e organizzazioni per proteggersi dalle frodi e ridurre il rischio di credito.",
        content32: "5. Trasferimenti internazionali di dati personali",
        content33: 'Potremmo trasferire i tuoi dati al di fuori dello Spazio Economico Europeo ("SEE"). Metteremo in atto adeguate garanzie per garantire che tali trasferimenti rispettino le norme applicabili sulla protezione dei dati, a meno che la Commissione Europea non abbia confermato che il paese in cui vengono trasferiti i dati fornisce un livello adeguato di protezione.',
        content34: "6. Le mie informazioni personali sono al sicuro?",
        content35: "Abbiamo progettato i nostri sistemi pensando alla tua sicurezza e privacy. Utilizziamo protocolli e software di crittografia per cercare di proteggere la sicurezza delle informazioni personali durante la trasmissione.",
        content36: "Adottiamo sempre garanzie fisiche, elettroniche e procedurali durante la raccolta, l'archiviazione e la divulgazione di informazioni personali. Le nostre procedure di sicurezza implicano che potremmo dover verificare la tua identità prima di rivelarti informazioni personali.",
        content37: "La cosa più importante che puoi fare è proteggere le password del tuo account personale da accessi non autorizzati. Ti consigliamo di impostare una password univoca per il tuo account che sia distinta dagli altri account online. E assicurati di disconnetterti dopo aver utilizzato un computer condiviso.",
        content38: "7. Come dovrei proteggere le informazioni personali?",
        content39: "In caso di domande o obiezioni sul modo in cui raccogliamo ed elaboriamo le informazioni personali, contattare il personale del servizio clienti",
        content40: "Dopo aver acconsentito al trattamento dei tuoi dati personali per uno scopo specifico, puoi ritirare la tua intenzione in qualsiasi momento e interromperemo il trattamento dei tuoi dati per tale scopo.",
        content41: "Inoltre, hai il diritto di richiedere l'accesso, la rettifica e la cancellazione dei dati personali e di richiedere la portabilità dei dati, nel rispetto delle leggi applicabili. Puoi anche opporti al trattamento dei tuoi dati personali o richiedere che il trattamento dei tuoi dati personali sia limitato in determinate circostanze.",
        content42: "8. GDPR dell’UE e legge sulla protezione dei dati del Regno Unito – Base giuridica",
        content43: "Il GDPR dell'UE e la legge sulla protezione dei dati del Regno Unito ci impongono di rispettare la base legale quando utilizziamo le informazioni personali. La nostra base dipende dagli scopi specifici per i quali vengono utilizzate le informazioni personali. Queste basi includono:",
        content44: "Esecuzione di un contratto – per fornire o comunicare con te su prodotti o servizi, compreso il nostro utilizzo delle tue informazioni personali per accettare ed elaborare ordini ed elaborare pagamenti.",
        content45: "I nostri legittimi interessi commerciali e gli interessi degli utenti: rileviamo e preveniamo frodi e abusi per proteggere la sicurezza dei nostri utenti, di noi stessi o di altri.",
        content46: "Il tuo consenso – Richiediamo il tuo consenso per trattare i tuoi dati personali per le finalità specifiche che ti saranno comunicate. Una volta che ci hai dato il consenso al trattamento dei tuoi dati personali per uno scopo specifico, puoi revocare il tuo consenso in qualsiasi momento e interromperemo il trattamento dei tuoi dati per tale scopo.",
        content47: "Rispettare gli obblighi legali: utilizziamo le tue informazioni personali come richiesto dalla legge. Ad esempio, raccogliamo informazioni sul conto bancario a fini di verifica dell'identità.",
        content48: "Quanto sopra e altre basi giuridiche dipendono dagli scopi specifici per i quali utilizziamo le informazioni personali.",
        content49: "9. Condizioni d'uso, avvisi e modifiche",
        content50: "Il tuo utilizzo e qualsiasi controversia sulla privacy sono soggetti alla presente Dichiarazione e ai nostri Termini di utilizzo. Se hai dubbi sulla privacy di questa piattaforma, contattaci con una spiegazione dettagliata e saremo felici di risolvere il problema per te. Hai anche il diritto di contattare la tua autorità locale per la protezione dei dati.",
        content51: "La nostra attività è in continua evoluzione, così come la nostra informativa sulla privacy. Dovresti controllare frequentemente il nostro sito web per vedere le ultime modifiche. Se non sei d'accordo con il contenuto rivisto, dovresti interrompere immediatamente l'accesso. Dopo il rilascio della versione aggiornata dell'Informativa sulla privacy, il tuo accesso continuato significa che accetti il ​​contenuto aggiornato e accetti di rispettare l'Informativa sulla privacy aggiornata. Salvo diversa indicazione, l'attuale Informativa sulla privacy si applica a tutte le informazioni in nostro possesso su di te e sul tuo account.",
    },
    serviceAgreement: {
        title: "Condizioni di servizio",
        content1: 'Questo sito web è una piattaforma specifica per consentire agli utenti di effettuare transazioni di risorse digitali e fornire servizi correlati (di seguito denominato "il servizio" o "il servizio"). Per comodità di descrizione del presente accordo, questo sito utilizza collettivamente "noi" o altri pronomi in prima persona nel presente accordo. Finché la persona fisica o altro soggetto che accede al sito web è un utente di questo sito web, per comodità di esprimere questo accordo, di seguito verrà utilizzato "tu" o altra seconda persona. Per comodità di esprimere il presente Contratto, noi e voi siamo collettivamente indicati come le "Parti" nel presente Contratto, e noi o voi siamo indicati individualmente come "Parte".',
        content2: "suggerimento importante:",
        content3: "Vi ricordiamo in particolare:",
        content4: "1 Le risorse digitali stesse non sono emesse da alcun istituto finanziario o società o da questo sito web",
        content5: "2 I mercati delle risorse digitali sono nuovi, non confermati e potrebbero non crescere;",
        content6: "3 Le risorse digitali sono ampiamente utilizzate principalmente dagli speculatori, con un utilizzo relativamente scarso nei mercati al dettaglio e commerciali. Le transazioni di risorse digitali sono estremamente rischiose. Vengono scambiate continuamente durante il giorno, senza limiti di aumento e diminuzione, e i prezzi sono facilmente influenzati dalle variazioni del mercato. produttori e politiche governative globali.",
        content7: "4 La Società si riserva il diritto di sospendere o chiudere il tuo account in qualsiasi momento se la Società determina, a sua esclusiva discrezione, che hai violato il presente Accordo o che i servizi forniti da questo sito Web o l'utilizzo dei servizi forniti da questo sito Web sono illegale secondo le leggi della tua giurisdizione. , o sospendere o interrompere l'utilizzo dei servizi o delle transazioni di risorse digitali forniti da questo sito Web. A chiunque provenga da [Cina continentale, Taiwan, Israele, Iraq, Bangladesh, Bolivia, Ecuador, Kirghizistan, Sebastopoli e Regno Unito (utenti al dettaglio)] è vietato utilizzare i servizi di transazione contrattuale forniti da questo sito Web. Il suddetto elenco di paesi o regioni cambierà a seconda delle politiche e dei tipi di prodotto dei diversi paesi o regioni. Potremmo non inviarti notifiche specifiche in quel momento, quindi ti preghiamo di prestare attenzione agli aggiornamenti del presente accordo in modo tempestivo.",
        content8: "Il trading di asset digitali comporta rischi estremamente elevati e non è adatto alla maggior parte delle persone. Comprendi e comprendi che questa transazione può comportare una perdita parziale o totale, quindi dovresti determinare l'importo della transazione in base al grado di perdita che ti puoi permettere. Sai e comprendi che le risorse digitali genereranno rischi derivati, quindi se hai domande, ti consigliamo di chiedere prima l'assistenza di consulenti professionisti. Inoltre, oltre ai rischi sopra menzionati, esistono anche rischi che non possono essere previsti. Dovresti considerare attentamente e usare un chiaro giudizio per valutare la tua situazione finanziaria e i rischi sopra menzionati prima di prendere qualsiasi decisione di acquistare o vendere risorse digitali e sopportare tutte le perdite risultanti. Non ci assumiamo alcuna responsabilità per questo.",
        content9: "Notare che:",
        content10: "1 Comprendi che questo sito Web viene utilizzato solo come luogo in cui ottenere informazioni sulle risorse digitali, trovare parti coinvolte nelle transazioni, negoziare e condurre transazioni su risorse digitali. Questo sito Web non partecipa a nessuna delle tue transazioni, quindi dovresti usare il tuo giudizio prudente per determinare le risorse digitali e/o le transazioni rilevanti o l'autenticità, la legalità e la validità delle informazioni, e si assumerà da solo le responsabilità e le perdite che ne derivano.",
        content11: "2 Eventuali opinioni, notizie, discussioni, analisi, prezzi, raccomandazioni e altre informazioni presenti su questo sito Web sono commenti generali sul mercato e non costituiscono consigli di investimento. Non siamo responsabili per eventuali perdite derivanti direttamente o indirettamente dall'affidamento su queste informazioni, incluse ma non limitate a qualsiasi perdita di profitti.",
        content12: "3 Questo sito Web si riserva il diritto di prendere la propria decisione, modificare o cambiare il contenuto in qualsiasi momento.Abbiamo adottato misure ragionevoli per garantire l'accuratezza delle informazioni sul sito Web, ma non possiamo garantirne l'accuratezza e non saremo responsabili per qualsiasi perdita o danno causato dalle informazioni contenute in questo sito Web o dalla mancanza di informazioni.Perdite dirette o indirette derivanti da ritardi o errori nel collegamento a Internet, nella trasmissione o nella ricezione di avvisi e informazioni.",
        content13: "4 Esistono anche rischi nell'utilizzo di sistemi di trading basati su Internet, inclusi ma non limitati a guasti di software, hardware e connessione Internet. Poiché non abbiamo alcun controllo sull'affidabilità e sulla disponibilità di Internet, non ci assumiamo alcuna responsabilità per distorsioni, ritardi e interruzioni della connessione.",
        content14: "5 È vietato utilizzare questo sito Web per impegnarsi in tutte le attività commerciali illegali o attività illegali come riciclaggio di denaro, contrabbando, corruzione commerciale, ecc. Se vengono rilevate transazioni o attività illegali sospette, questo sito Web adotterà vari mezzi disponibili, inclusi ma non limitato al congelamento dei conti e alla notifica alle parti interessate, alle autorità, ecc., non ci assumiamo tutte le responsabilità che ne derivano e ci riserviamo il diritto di perseguire la responsabilità delle persone interessate.",
        content15: "6 È vietato utilizzare questo sito Web per manipolare in modo dannoso il mercato, transazioni improprie e altre attività commerciali non etiche. Se tali incidenti vengono scoperti, questo sito Web avviserà, limiterà le transazioni e chiuderà tutti i comportamenti non etici come la manipolazione dannosa dei prezzi e l'influenza dannosa sul mercato. sistema di trading.Non ci assumiamo alcuna responsabilità derivante da misure di protezione preventiva come la sospensione del conto e ci riserviamo il diritto di perseguire la responsabilità delle persone interessate.",
        content16: "1. Principi generali",
        content17: `1.1 "Accordo con l'utente" (di seguito denominato "il presente Accordo" o "I presenti Termini e condizioni"), è costituito dal testo principale, "Termini sulla privacy", "Conosci il tuo cliente e politica antiriciclaggio" e varie altre politiche che hanno stato pubblicato o potrebbe essere pubblicato in futuro su questo sito Web. Regole di classe, dichiarazioni, descrizioni, ecc.`,
        content18: "1.2 È necessario leggere attentamente il presente accordo prima di utilizzare i servizi forniti da questo sito Web. Se non si capisce qualcosa o se è altrimenti necessario, consultare un avvocato professionista. Se non accetti il ​​presente Contratto e/o la sua modifica in qualsiasi momento, ti preghiamo di interrompere immediatamente l'utilizzo dei servizi forniti da questo sito web o di non accedere più a questo sito web. Una volta effettuato l'accesso a questo sito Web, utilizzato qualsiasi servizio di questo sito Web o qualsiasi altro comportamento simile, significa che hai compreso e accettato pienamente i contenuti del presente Accordo, comprese eventuali modifiche apportate da questo sito Web al presente Accordo in qualsiasi momento.",
        content19: `1.3 È possibile diventare un membro di questo sito Web (di seguito denominato "membro") compilando le informazioni pertinenti in conformità con i requisiti di questo sito Web e registrandosi con successo dopo altre procedure pertinenti. Facendo clic sul pulsante "Accetto" durante la registrazione processo, firmi elettronicamente il modulo di accordo con la società o quando fai clic su qualsiasi pulsante contrassegnato con "Accetto" o significato simile durante l'utilizzo di questo sito Web, o utilizzi effettivamente i servizi forniti da questo sito Web in altri modi consentiti da questo sito Web , significa che comprendi, accetti e accetti pienamente tutti i termini del presente Accordo. L'assenza della tua firma scritta a mano non influirà sulla forza giuridica vincolante del presente Accordo su di te.`,
        content20: "1.4 Dopo essere diventato membro di questo sito Web, riceverai un account membro e la password corrispondente, che saranno conservati da te; sarai legalmente responsabile per tutte le attività e gli eventi condotti con il tuo account.",
        content21: "1.5 Solo i membri che diventano membri di questo sito Web possono utilizzare la piattaforma di scambio di risorse digitali fornita da questo sito Web per transazioni e usufruire di altri servizi previsti da questo sito Web che sono disponibili solo per i membri; i non membri possono solo accedere al sito Web, navigare nel sito Web e altri servizi stipulati da questo sito Web. servizi.",
        content22: "1.6 Registrandoti e utilizzando i servizi e le funzioni forniti da questo sito web, si riterrà che tu abbia letto, compreso e:",
        content23: "1.6.1 Accettare di essere vincolati da tutti i termini e le condizioni del presente Contratto.",
        content24: "1.6.2 Confermi di avere più di 18 anni o di avere l'età legale per stipulare contratti secondo le diverse leggi applicabili. La tua registrazione su questo sito Web, le vendite o gli acquisti, la pubblicazione di informazioni, ecc. e l'accettazione dei servizi di questo sito Web devono essere conformi con le vostre esigenze. Avere giurisdizione sulle leggi e i regolamenti pertinenti di un paese o regione sovrano e avere la piena capacità di accettare questi termini, effettuare transazioni e utilizzare questo sito Web per condurre transazioni di risorse digitali.",
        content25: "1.6.3 Garantisci che tutte le risorse digitali che ti appartengono e coinvolte nella transazione sono ottenute legalmente e ne hanno la proprietà.",
        content26: "1.6.4 Accetti di essere l'unico responsabile delle tue attività commerciali e non commerciali e di eventuali guadagni o perdite.",
        content27: "1.6.5 Confermi che le informazioni fornite al momento della registrazione sono vere e accurate.",
        content28: "1.6.6 Accetti di rispettare i requisiti di qualsiasi legge pertinente, inclusa la segnalazione di eventuali profitti commerciali a fini fiscali.",
        content29: "1.6.7 L'utente accetta di non intraprendere o partecipare a comportamenti o attività che danneggino gli interessi di questo sito Web o della società in qualsiasi momento, indipendentemente dal fatto che siano correlati ai servizi forniti da questo sito Web.",
        content30: "1.6.8 Il presente accordo regola solo i diritti e gli obblighi tra te e noi e non coinvolge i rapporti giuridici e le controversie legali derivanti dalle transazioni di risorse digitali tra gli utenti di questo sito Web e di altri siti Web e te.",
        content31: "2. Modifiche contrattuali",
        content32: "Ci riserviamo il diritto di rivedere il presente Contratto di tanto in tanto e di annunciarlo sul sito Web senza avvisarti separatamente. Dopo la revisione, l'ora della revisione verrà contrassegnata sull'[Ora dell'ultimo aggiornamento] visualizzata nel presente Contratto e richiederà automaticamente effetto una volta pubblicato sul sito. Dovresti navigare e prestare attenzione al tempo di aggiornamento e al contenuto aggiornato di tanto in tanto del presente Accordo. Se non sei d'accordo con le modifiche rilevanti, dovresti interrompere immediatamente l'utilizzo dei servizi di questo sito Web; il tuo uso continuato dei servizi di questo sito web significa che accetti e accetti di essere vincolato dal contratto rivisto. .",
        content33: "3. Registrazione",
        content34: "3.1 Qualifiche di registrazione",
        content35: "Confermi e prometti che quando completi il ​​processo di registrazione o utilizzi effettivamente i servizi forniti da questo sito Web in altri modi consentiti da questo sito Web, dovresti avere la capacità di firmare questo accordo e utilizzare i servizi di questo sito Web come previsto dalle leggi applicabili. persona fisica, persona giuridica o altro ente. Dopo aver fatto clic sul pulsante Accetto per registrarti, significa che tu o il tuo agente autorizzato avete accettato il contenuto dell'accordo e siete stati registrati e avete utilizzato i servizi di questo sito Web dal suo agente. Se non possiedi le suddette qualifiche in materia, tu e il tuo agente autorizzato sosterrete tutte le conseguenze che ne deriveranno e la società si riserva il diritto di cancellare o congelare permanentemente il vostro account e ritenere voi e il vostro agente autorizzato responsabili.",
        content36: "3.2 Scopo della registrazione",
        content37: "Confermi e prometti che la tua registrazione su questo sito Web non ha lo scopo di violare leggi e regolamenti o interrompere l'ordine delle transazioni di risorse digitali su questo sito Web.",
        content38: "3.3processo di registrazione",
        content39: "3.3.1 Accetti di fornire un indirizzo e-mail valido, un numero di cellulare e altre informazioni come richiesto nella pagina di registrazione dell'utente di questo sito Web. È possibile utilizzare l'indirizzo e-mail, il numero di cellulare fornito o confermato o altri metodi consentiti da questo sito Web come accesso metodo per accedere a questo sito web. Se necessario, in conformità con le leggi e i regolamenti pertinenti delle diverse giurisdizioni, è necessario fornire il proprio nome reale, documento di identità e altre informazioni rilevanti previste da leggi e regolamenti, clausole sulla privacy e clausole antiriciclaggio e aggiornare costantemente le informazioni di registrazione in in modo tempestivo, dettagliato e accurato. Tutte le informazioni originariamente digitate verranno indicate come informazioni di registrazione. L'utente è responsabile dell'autenticità, della completezza e dell'accuratezza di tali informazioni e dovrà farsi carico di eventuali perdite dirette o indirette e delle conseguenze negative che ne derivano.",
        content40: "3.3.2 Se le leggi, i regolamenti, le norme, gli ordini e altri regolamenti del paese sovrano o della regione in cui ti trovi prevedono requisiti relativi al nome reale per i numeri di cellulare, accetti che il numero di cellulare registrato fornito sia stato registrato con il nome reale. non lo fornisci in conformità con le normative, eventuali conseguenze causate a te saranno perdite dirette o indirette e le conseguenze negative saranno a tuo carico.",
        content41: "3.3.3 Se fornisci le informazioni richieste per la registrazione in modo legale, completo ed efficace e le hai verificate, hai il diritto di ottenere il numero di conto e la password di questo sito Web. Quando ottieni il numero di conto e la password di questo sito Web, la tua registrazione è considerata con successo e puoi accedere come membro su questo sito web.",
        content42: "3.3.4 Accetti di ricevere e-mail e/o brevi messaggi inviati da questo sito Web relativi alla gestione e al funzionamento di questo sito Web.",
        content43: "4. Servizio",
        content44: "Questo sito Web fornisce solo servizi di piattaforma di trading online per le tue attività di trading di risorse digitali (incluso ma non limitato al trading di risorse digitali e altri servizi) attraverso questo sito Web.",
        content45: "4.1 Contenuto del servizio",
        content46: "4.1.1 Hai il diritto di consultare le condizioni di mercato in tempo reale e le informazioni sulle transazioni di vari prodotti di risorse digitali su questo sito Web e hai il diritto di inviare istruzioni per le transazioni di risorse digitali e completare transazioni di risorse digitali tramite questo sito Web.",
        content47: "4.1.2 Hai il diritto di visualizzare le informazioni nel tuo account di iscrizione su questo sito Web e di applicare le funzioni fornite da questo sito Web per operare.",
        content48: "4.1.3 Hai il diritto di partecipare alle attività del sito web organizzate da questo sito web in conformità con le regole di attività pubblicate su questo sito web.",
        content49: "4.1.4 Questo sito web promette di fornirti altri servizi.",
        content50: "4.2 Regole di servizio Prometti di rispettare le seguenti regole di servizio di questo sito web:",
        content51: "4.2.1 Dovrai rispettare le leggi, i regolamenti e i requisiti politici, garantire la legalità delle fonti di tutte le risorse digitali nel tuo account e non impegnarti in attività illegali o di altro tipo che danneggiano i diritti e gli interessi di questo sito Web o di terze parti su questo sito Web o utilizzando i suoi servizi, inclusi ma non limitati a: Non sei limitato a inviare o ricevere informazioni illegali, illegali o che violano i diritti degli altri, inviare o ricevere schemi piramidali o altre informazioni o commenti dannosi, utilizzare o falsificare e-mail informazioni di intestazione su questo sito Web senza l'autorizzazione di questo sito Web, ecc.",
        content52: "4.2.2 Dovresti rispettare le leggi e i regolamenti e utilizzare e conservare correttamente il numero del tuo conto, la password di accesso, la password del fondo, il numero di cellulare a te associato al momento della registrazione e il codice di verifica del cellulare ricevuto dal tuo cellulare. Sei pienamente responsabile per qualsiasi operazione e conseguenza derivante dall'utilizzo del tuo account, della password di accesso, della password del fondo e del codice di verifica del telefono cellulare. Quando scopri che il numero di conto, la password di accesso, la password del fondo o il codice di verifica di questo sito Web vengono utilizzati da terzi senza la tua autorizzazione, o ci sono altri problemi di sicurezza dell'account, devi avvisare immediatamente ed efficacemente questo sito Web e richiedere a questo sito Web di sospendere il servizio dell'account di questo sito web. . Questo sito web ha il diritto di agire in base alla tua richiesta entro un tempo ragionevole, ma questo sito web non si assume alcuna responsabilità per le conseguenze (incluse ma non limitate a eventuali perdite subite) che si sono verificate prima di agire. Non puoi donare, prendere in prestito, affittare, trasferire o altrimenti disporre del tuo account su questo sito web ad altri senza il consenso di questo sito web.",
        content53: "4.2.3 Accetti di essere responsabile di tutte le attività che si verificano sotto il tuo account e la tua password su questo sito web (inclusi ma non limitati alla divulgazione di informazioni, alla pubblicazione di informazioni, ai clic online per accettare o all'invio di varie regole e accordi, al rinnovo online di accordi o all'acquisto di servizi, ecc.).",
        content54: "4.2.4 Quando si conducono transazioni di risorse digitali su questo sito Web, non è necessario interferire in modo dannoso con il normale svolgimento delle transazioni di risorse digitali o interrompere l'ordine delle transazioni; non è necessario interferire con il normale funzionamento di questo sito Web o interferire con l'utilizzo da parte di altri utenti di questo sito Web. servizi con qualsiasi mezzo tecnico o altro; non è necessario utilizzare mezzi fittizi per diffamare maliziosamente la buona volontà di questo sito Web attraverso fatti e altri mezzi.",
        content55: "4.2.5 In caso di controversia con altri utenti a causa di transazioni online, non è possibile richiedere a questo sito Web di fornire informazioni pertinenti attraverso canali non giudiziari o amministrativi.",
        content56: "4.2.6 L'utente sarà tenuto a giudicare e sostenere esclusivamente le imposte dovute, nonché tutte le spese relative all'hardware, al software, ai servizi e alle altre spese sostenute durante l'utilizzo dei servizi forniti da questo sito Web.",
        content57: "4.2.7 L'utente è tenuto a rispettare il presente accordo e gli altri termini di servizio e regole operative pubblicati e aggiornati di tanto in tanto da questo sito Web e ha il diritto di interrompere l'uso dei servizi forniti da questo sito Web in qualsiasi momento.",
        content58: "4.3 regole del prodotto",
        content59: "4.3.1 Sfoglia le informazioni sulla transazione",
        content60: "Quando navighi tra le informazioni sulle transazioni su questo sito web, dovresti leggere attentamente tutti i contenuti contenuti nelle informazioni sulle transazioni, inclusi ma non limitati a prezzo, volume delle commissioni, spese di gestione, direzione di acquisto o vendita, e solo dopo aver accettato pienamente tutti i contenuti contenuti in le informazioni sulla transazione Le operazioni possono essere effettuate con il semplice clic di un pulsante.",
        content61: "4.3.2 Visualizza i dettagli della transazione",
        content62: "Puoi visualizzare i record delle transazioni corrispondenti tramite il tuo account.",
        content63: "5. Diritti e obblighi di questo sito web",
        content64: "5.1 Se non possiedi i requisiti di registrazione previsti dal presente accordo, questo sito Web ha il diritto di rifiutare la tua registrazione. Per coloro che si sono già registrati, questo sito Web ha il diritto di cancellare il tuo account di iscrizione. Questo sito Web si riserva il diritto di trattenerti o il tuo agente autorizzato responsabile ha ragione. Allo stesso tempo, questo sito si riserva il diritto di decidere se accettare la tua registrazione in qualsiasi altra circostanza.",
        content65: "5.2 In base al giudizio di questo sito Web, se questo sito Web rileva che tu o gli utenti del tuo account associato non siete idonei per investimenti ad alto rischio, abbiamo il diritto di sospendere o chiudere il tuo account e l'utilizzo di tutti gli account associati.",
        content66: "5.3 Quando questo sito Web scopre che l'utente dell'account non è il registrante iniziale dell'account, ha il diritto di sospendere o interrompere l'utilizzo dell'account.",
        content67: "5.4 Quando questo sito Web sospetta ragionevolmente che le informazioni fornite siano errate, false, non valide o incomplete attraverso test tecnici, campionamento manuale e altri metodi di test, ha il diritto di avvisarti per correggere o aggiornare le informazioni o di sospendere o interrompere la fornitura di questo sito web ti offre servizi.",
        content68: "5.5 Questo sito web si riserva il diritto di correggere qualsiasi informazione visualizzata su questo sito web se si scopre che c'è un errore evidente.",
        content69: "5.6 Questo sito Web si riserva il diritto di modificare, sospendere o interrompere i servizi di questo sito Web in qualsiasi momento. Questo sito Web non ha bisogno di avvisarti in anticipo per esercitare il diritto di modificare o interrompere i servizi. Se questo sito Web interrompe uno o più servizi di questo sito Web, la risoluzione avverrà da questo sito Web sul sito Web. Con effetto dalla data di pubblicazione dell'avviso di risoluzione.",
        content70: "5.7 Questo sito Web adotterà i mezzi tecnici e le misure di gestione necessari per garantire il normale funzionamento di questo sito Web, fornire un ambiente di trading e servizi di trading necessari e affidabili e mantenere l'ordine delle transazioni di risorse digitali.",
        content71: "5.8 Se non accedi a questo sito Web utilizzando il tuo account di iscrizione e la password per un anno consecutivo, questo sito Web ha il diritto di cancellare il tuo account. Dopo che l'account è stato cancellato, questo sito web ha il diritto di aprire il nome membro corrispondente ad altri utenti per la registrazione e l'utilizzo.",
        content72: "5.9 Questo sito Web garantisce la sicurezza delle tue risorse digitali rafforzando gli investimenti tecnologici, migliorando le precauzioni di sicurezza e altre misure e ti avviserà in anticipo quando si verificano prevedibili rischi per la sicurezza nel tuo account.",
        content73: "5.10 Questo sito Web ha il diritto di eliminare in qualsiasi momento tutti i tipi di informazioni sui contenuti di questo sito Web che non sono conformi alle leggi e ai regolamenti o ai regolamenti di questo sito Web. Questo sito Web non ha bisogno di avvisarti in anticipo per esercitare tali diritti.",
        content74: "5.11 Questo sito Web ha il diritto di richiederti di fornire ulteriori informazioni o materiali in conformità con le leggi, i regolamenti, le norme, gli ordini e altri requisiti normativi del tuo paese o regione sovrana e di adottare misure ragionevoli per conformarsi ai requisiti normativi locali. sono obbligati a collaborare; questo sito Web ha il diritto di sospendere o interrompere permanentemente l'apertura di alcuni o tutti i servizi di questo sito Web in conformità con i requisiti di leggi, regolamenti, norme, ordini e altre specifiche del proprio paese o regione sovrana.",
        content75: "5.12 Questo sito si riserva il diritto di chiudere il tuo account con un preavviso di sette giorni lavorativi a sua esclusiva discrezione. Pertanto, hai sette giorni lavorativi per annullare gli ordini e chiudere le posizioni. Se non hai annullato l'ordine e chiuso la posizione alla scadenza del periodo, annulleremo forzatamente l'ordine, chiuderemo la posizione e ti restituiremo le risorse digitali rimanenti nel tuo account.",
        content76: "5.13 Al fine di proteggere i diritti e gli interessi dei commercianti, questo sito web ha il diritto di apportare modifiche ai prodotti online in circostanze speciali (come guasti del sistema, guasti della rete, condizioni di mercato estreme, ecc.), come ad esempio: contratti per consegna anticipata e regolamento, tipi di contratto e contratti con consegna anticipata e regolamento. Il periodo, il prezzo di regolamento e di consegna sono soggetti all'annuncio su questo sito web.",
        content77: "6. Compensazione",
        content78: "6.1 In nessun caso la nostra responsabilità nei tuoi confronti per danni diretti supererà le tariffe totali del servizio che ti abbiamo addebitato per l'utilizzo di questo sito Web per un periodo di tre (3) mesi.",
        content79: "6.2 Se violi il presente Contratto o altre leggi e regolamenti, sei tenuto a risarcirci per almeno 2 milioni di dollari USA e a sostenere tutti i costi sostenuti (comprese le spese legali, ecc.). Se ciò non è sufficiente a compensare le perdite effettive, è necessario effettuare pronto per questo.",
        content80: "7. Diritto di chiedere un provvedimento ingiuntivo",
        content81: "Sia noi che voi riconosciamo che i rimedi di diritto comune per la vostra violazione del contratto o possibile violazione del contratto potrebbero non essere sufficienti a compensare tutte le perdite che subiamo, quindi abbiamo il diritto di chiedere un provvedimento ingiuntivo come consentito dal diritto comune o dall'equità nel caso di violazione del contratto o possibile violazione del contratto tutti gli altri rimedi.",
        content82: "8. Limitazione di responsabilità ed esclusione di responsabilità",
        content83: "8.1 Comprendi e accetti che, in qualsiasi circostanza, non saremo responsabili per le seguenti questioni:",
        content84: "8.1.1 perdita di reddito;",
        content85: "8.1.2 profitti commerciali o perdite contrattuali;",
        content86: "8.1.3 Perdite causate dall'interruzione dell'attività;",
        content87: "8.1.4 perdita del risparmio monetario previsto;",
        content88: "8.1.5 Perdite causate da problemi di informazione;",
        content89: "8.1.6 perdita di opportunità, buona volontà o reputazione;",
        content90: "8.1.7 corruzione o perdita di dati;",
        content91: "8.1.8 il costo per l'acquisto di prodotti o servizi sostitutivi;",
        content92: "8.1.9 Qualsiasi perdita o danno indiretto, speciale o incidentale derivante da atto illecito (inclusa la negligenza), violazione del contratto o qualsiasi altra ragione, indipendentemente dal fatto che tale perdita o danno sia ragionevolmente prevedibile da noi o meno; indipendentemente dal fatto che siamo stati informati o meno dell'esistenza di tale in anticipo Possibilità di perdita o danno.",
        content93: "Gli articoli da 8.1.1 a 8.1.9 sono indipendenti l'uno dall'altro.",
        content94: "8.2 Comprendi e accetti che non siamo responsabili per eventuali danni causati a te a causa di una qualsiasi delle seguenti circostanze:",
        content95: "8.2.1 Le vostre transazioni specifiche potrebbero comportare gravi violazioni delle leggi o inadempienze contrattuali.",
        content96: "8.2.2 Il tuo comportamento su questo sito Web potrebbe essere illegale o non etico.",
        content97: "8.2.3 Costi e perdite subiti per l'acquisto o l'ottenimento di dati, informazioni o per l'esecuzione di transazioni attraverso i servizi di questo sito Web o mediante azioni sostitutive.",
        content98: "8.2.4 Il tuo malinteso sui servizi di questo sito web.",
        content99: "8.2.5 Qualsiasi altra perdita relativa ai servizi forniti da questo sito Web che non è causata da noi.",
        content100: "8.3 Siamo responsabili per la manutenzione delle apparecchiature della rete informatica, guasto della connessione alla rete informatica, guasto del computer, della comunicazione o di altro sistema, interruzione di corrente, condizioni meteorologiche, incidenti, scioperi, controversie di lavoro, sommosse, rivolte, sommosse, produttività insufficiente o materiali di produzione, incendi, inondazioni , tempeste, esplosioni, guerre, ragioni bancarie o di altri partner, collasso del mercato delle risorse digitali, azioni governative, ordini di agenzie giudiziarie o amministrative, altre azioni che sfuggono al nostro controllo o che non abbiamo la capacità di controllare, o ragioni di terze parti Noi non si assume alcuna responsabilità per l'impossibilità o il ritardo nel servizio, o per le vostre perdite.",
        content101: "8.4 Non possiamo garantire che tutte le informazioni, i programmi, i testi, ecc. contenuti in questo sito Web siano completamente sicuri e non verranno interferiti e distrutti da virus, cavalli di Troia e altri programmi dannosi. Pertanto, se accedi e utilizzi qualsiasi servizio su questo sito Web o scaricare e utilizzare programmi, informazioni, dati, ecc. scaricati sono tutte decisioni personali e l'utente si assume i rischi e le possibili perdite a proprio rischio e pericolo.",
        content102: "8.5 Non forniamo alcuna garanzia o impegno riguardo a informazioni, prodotti e servizi di siti Web di terzi collegati a questo sito Web o qualsiasi altra forma di contenuto che non ci appartiene.Se si utilizzano servizi, informazioni e servizi forniti da terzi I siti web, i prodotti, ecc. di terze parti sono tutte decisioni tue e tu ti assumi tutte le responsabilità che ne derivano.",
        content103: "8.6 Non forniamo alcuna garanzia espressa o implicita per l'utilizzo dei servizi su questo sito Web, incluso ma non limitato all'idoneità dei servizi forniti da questo sito Web, assenza di errori o omissioni, continuità, accuratezza, affidabilità e idoneità per un particolare scopo. Allo stesso tempo, non assumiamo alcun impegno o garanzia circa la validità, accuratezza, correttezza, affidabilità, qualità, stabilità, completezza e tempestività della tecnologia e delle informazioni coinvolte nei servizi forniti da questo sito web. Se accedere o utilizzare i servizi forniti da questo sito Web è una tua decisione personale e ti assumi i tuoi rischi e le possibili perdite. Non forniamo alcuna garanzia espressa o implicita in merito al mercato, al valore e al prezzo delle risorse digitali. Comprendi e comprendi che il mercato delle risorse digitali è instabile. I prezzi e i valori possono fluttuare o crollare in modo significativo in qualsiasi momento. Il trading di risorse digitali è la tua libertà personale: scegli e decidi a tuo rischio e pericolo e con possibili perdite.",
        content104: "8.7 Le nostre garanzie e impegni stabiliti nel presente Accordo sono le uniche garanzie e dichiarazioni da noi rese in relazione al presente Accordo e ai servizi forniti su questo sito Web e sostituiscono qualsiasi garanzia e impegno derivante in qualsiasi altro modo e modo, sia scritto che orale, espresso o implicito. Tutte queste garanzie e rappresentazioni rappresentano solo i nostri impegni e garanzie e non garantiscono che terze parti rispettino le garanzie e gli impegni del presente Contratto.",
        content105: "8.8 Non rinunciamo ad alcun diritto non menzionato nel presente Contratto per limitare, escludere o compensare la nostra responsabilità per danni nella misura massima consentita dalla legge applicabile.",
        content106: "8.9 Dopo la registrazione, accetti tutte le operazioni che eseguiamo in conformità con le regole stabilite nel presente accordo e tutti i rischi sostenuti sono a tuo carico.",
        content107: "9. Risoluzione del Contratto",
        content108: "9.1 Questo sito Web ha il diritto di terminare tutti i servizi di questo sito Web in conformità con il presente accordo. Il presente accordo verrà risolto nella data in cui tutti i servizi di questo sito Web verranno terminati.",
        content109: "9.2 Dopo la risoluzione del presente accordo, non hai il diritto di richiedere a questo sito Web di continuare a fornire servizi o adempiere ad altri obblighi, incluso ma non limitato a richiedere a questo sito Web di conservare o divulgare qualsiasi informazione nel suo account del sito Web originale. a te o a terzi. I terzi inoltrano tutte le informazioni che non hanno letto o inviato.",
        content110: "9.3 La risoluzione del presente accordo non influirà sulle altre responsabilità della parte non inadempiente nei confronti della parte inadempiente.",
        content111: "10. Diritti di proprietà intellettuale",
        content112: "10.1 Tutti i risultati intellettuali contenuti in questo sito Web includono, ma non sono limitati a, loghi di siti Web, database, design di siti Web, testi e grafica, software, foto, video, musica, suoni e combinazioni degli stessi, compilazioni di software, relativi codici sorgente e software (comprese piccole applicazioni ) I diritti di proprietà intellettuale di programmi e script) appartengono a questo sito web. Non è possibile riprodurre, alterare, copiare, inviare o utilizzare nessuno dei materiali o contenuti di cui sopra per scopi commerciali.",
        content113: "10.2Tutti i diritti contenuti nel nome di questo sito Web (inclusi ma non limitati a avviamento, marchi e loghi) appartengono alla società.",
        content114: "10.3 L'accettazione del presente accordo sarà considerata come l'iniziativa di trasferire il diritto d'autore di qualsiasi forma di informazione pubblicata su questo sito Web, inclusi ma non limitati a: diritti di riproduzione, diritti di distribuzione, diritti di noleggio, diritti di esposizione, diritti di esecuzione, diritti di proiezione, i diritti di trasmissione, i diritti di trasmissione di informazioni su Internet, i diritti di ripresa, i diritti di adattamento, i diritti di traduzione, i diritti di compilazione e altri diritti trasferibili di cui dovrebbe godere il detentore del copyright vengono trasferiti gratuitamente esclusivamente a questo sito Web. Questo sito Web ha il diritto di presentare una richiesta indipendente agire in giudizio contro qualsiasi soggetto per violazione ed ottenere il pieno risarcimento. Il presente accordo è valido per qualsiasi contenuto di lavoro protetto dalla legge sul diritto d'autore pubblicato su questo sito Web, indipendentemente dal fatto che il contenuto sia formato prima della firma del presente accordo o dopo la firma del presente accordo.",
        content115: "10.4 Non è possibile utilizzare o disporre illegalmente dei diritti di proprietà intellettuale di questo sito Web o di altri quando si utilizzano i servizi di questo sito Web. Non è possibile pubblicare o autorizzare altri siti Web (e media) a utilizzare le informazioni pubblicate su questo sito Web in qualsiasi forma.",
        content116: "11. Protezione delle informazioni",
        content117: "Prevarranno le disposizioni delle “Condizioni Privacy” pubblicate separatamente su questo sito.",
        content118: "12. Calcolo",
        content119: "Tutti i risultati del calcolo delle transazioni sono stati da noi verificati e tutti i metodi di calcolo sono stati pubblicati sul sito web, ma non possiamo garantire che l'utilizzo del sito web non sarà interrotto o privo di errori.",
        content120: "13. Controllo delle esportazioni",
        content121: "Comprendi e riconosci che, secondo le leggi pertinenti, non ti è consentito esportare, riesportare, importare o trasferire alcun materiale (incluso il software) su questo sito Web, quindi garantisci che non implementerai, assisterai o parteciperai attivamente a nessuno di le suddette esportazioni o attività correlate che violano leggi e regolamenti. Trasferimento o altre violazioni delle leggi e dei regolamenti applicabili; se tale situazione viene scoperta, è necessario segnalarcelo attivamente in modo tempestivo e aiutarci nella gestione della situazione.",
        content122: "14. Trasferimento",
        content123: "I diritti e gli obblighi previsti dal presente Contratto vincolano anche i cessionari, gli eredi, gli esecutori e gli amministratori dei soggetti che beneficiano di tali diritti e obblighi. Non puoi trasferirlo a terzi senza il nostro consenso, ma possiamo trasferire i nostri diritti e obblighi previsti dal presente Contratto a terzi in qualsiasi momento dandone preavviso.",
        content124: "15. Divisibilità",
        content125: "Se una qualsiasi disposizione del presente Contratto viene ritenuta inapplicabile, non valida o illegale da qualsiasi tribunale della giurisdizione competente, ciò non pregiudicherà la validità delle restanti disposizioni del presente Contratto.",
        content126: "16. Rapporto non di agenzia",
        content127: "Niente nel presente Contratto potrà essere considerato creare, implicare o altrimenti costituirci come vostro agente, fiduciario o altro rappresentante, salvo quanto diversamente previsto nel presente Contratto.",
        content128: "17. Astensione",
        content129: "La rinuncia da parte nostra o dell'utente alla responsabilità di qualsiasi parte per violazione del contratto o altra responsabilità stipulata nel presente Contratto non sarà considerata o interpretata come una rinuncia ad altra responsabilità per violazione del contratto; il mancato esercizio di qualsiasi diritto o rimedio non sarà in alcun modo modo potrà essere interpretato come una rinuncia a tali diritti o rimedi.",
        content130: "18. Titolo",
        content131: "Tutti i titoli servono esclusivamente per comodità di espressione dell'accordo e non vengono utilizzati per espandere o limitare il contenuto o la portata dei termini dell'accordo.",
        content132: "19. Legge applicabile",
        content133: "19.1Prima di sollevare una controversia o un reclamo, riconosci e accetti di contattare prima questo sito Web via e-mail per risolvere la controversia in modo informale. Cercheremo di risolvere la controversia internamente il prima possibile; entrambe le parti accettano di risolvere la controversia attraverso negoziazioni in buona fede (discussioni dovranno essere confidenziali e protetti dalle norme applicabili e non utilizzati come prova in alcun procedimento legale).",
        content134: `19.2L'utente riconosce e accetta che in caso di controversia, controversia, disaccordo o reclamo, inclusa l'esistenza, validità, interpretazione, esecuzione, violazione o risoluzione dei presenti Termini o qualsiasi controversia derivante da o in connessione con i presenti Termini ("Controversia" ), le parti sopra menzionate sottoporranno la controversia alla Corte internazionale di arbitrato della Camera di commercio internazionale ("Corte internazionale di arbitrato ICC") in conformità con le "Regole di arbitrato ICC" allora in vigore. La sede dell'arbitrato sarà il Belize e la legge applicabile all'arbitrato sarà determinata in conformità con il Regolamento arbitrale della Camera di commercio internazionale. Salvo diverso accordo tra le parti, sarà nominato un solo arbitro ai sensi del Regolamento arbitrale della CCI. L'arbitro avrà l'autorità esclusiva di pronunciarsi sulla propria giurisdizione, inclusa, a titolo esemplificativo, qualsiasi obiezione all'esistenza, alla portata o alla validità dell'accordo arbitrale o all'arbitrabilità di qualsiasi pretesa o domanda riconvenzionale. Qualsiasi arbitrato sarà condotto in inglese. Indipendentemente da come viene condotto l'arbitrato, l'arbitro emetterà un lodo scritto che sia ragionevole e sufficiente a spiegare il lodo e le eventuali conclusioni essenziali su cui si basa il lodo. Il lodo arbitrale sarà definitivo e vincolante per le parti e potrà essere eseguito in qualsiasi tribunale della giurisdizione competente.`,
        content135: "20. Efficacia ed interpretazione dell'accordo",
        content136: "20.1 Il presente accordo entra in vigore quando si fa clic sulla pagina di registrazione di questo sito Web per accettare di registrarsi, completare il processo di registrazione e ottenere un account e una password per questo sito Web. È vincolante sia per questo sito Web che per te.",
        content137: "20.2 Il diritto di interpretazione finale di questo accordo appartiene a questo sito web.",
        content138: "Conosci la tua politica sui clienti e antiriciclaggio",
        content139: "1. Introduzione",
        content140: `1.1 Promettiamo di rispettare attentamente le leggi e i regolamenti relativi al "Conosci il tuo cliente" e all'antiriciclaggio e non violeremo intenzionalmente la "Politica Conosci il tuo cliente e antiriciclaggio". Nell'ambito del nostro ragionevole controllo, adotteremo le misure e le tecnologie necessarie per fornirti servizi sicuri e proteggerti il ​​più possibile dalle perdite causate dal comportamento di riciclaggio di denaro di sospetti criminali.`,
        content141: "1.2 La nostra politica Conosci il tuo cliente e antiriciclaggio è un sistema di politiche internazionale completo che include politiche Conosci il tuo cliente e antiriciclaggio per le diverse giurisdizioni legali a cui sei affiliato.",
        content142: "2. Conosci i tuoi clienti e le politiche antiriciclaggio sono le seguenti:",
        content143: "2.1 Promulgare politiche di conoscenza del cliente e antiriciclaggio e aggiornarle di volta in volta per soddisfare gli standard stabiliti dalle leggi e dai regolamenti corrispondenti;",
        content144: "2.2 Promulgare e aggiornare alcuni principi guida e regole per il funzionamento di questo sito Web e i nostri dipendenti forniranno servizi in conformità con le linee guida di tali principi e regole;",
        content145: "2.3 Progettare e completare procedure per il monitoraggio interno e il controllo delle transazioni, come la verifica delle identità attraverso mezzi rigorosi e l'organizzazione della formazione di un team di professionisti responsabile del lavoro antiriciclaggio;",
        content146: "2.4 Utilizzare metodi di prevenzione dei rischi per condurre la due diligence e la supervisione continua dei clienti;",
        content147: "2.5 Rivedere e controllare regolarmente le transazioni avvenute;",
        content148: "2.6 Segnalare le operazioni sospette alle autorità competenti;",
        content149: "2.7 La prova dell'identità, dell'indirizzo e dei registri delle transazioni verrà conservata per almeno sei anni e potrà essere presentata alle autorità di regolamentazione senza ulteriore preavviso.",
        content150: "3. Informazioni e verifica dell'identità",
        content151: "3.1 Informazioni sull'identità",
        content152: "3.1.1 Secondo le diverse normative vigenti nelle diverse giurisdizioni e nei diversi tipi di entità, il contenuto delle informazioni che raccogliamo potrebbe essere incoerente. In linea di principio, le seguenti informazioni verranno raccolte da individui registrati:",
        content153: "Informazioni personali di base: nome, indirizzo (e indirizzo permanente, se diverso), data di nascita e nazionalità e altre informazioni disponibili. La verifica dell’identità dovrebbe basarsi su documenti rilasciati da autorità ufficiali o altre autorità simili, come passaporti, carte d’identità o altri documenti di identificazione richiesti e attivati ​​da diverse giurisdizioni. L'indirizzo da te fornito verrà verificato utilizzando metodi adeguati, come il controllo del biglietto di trasporto passeggeri o della nota del tasso di interesse o il controllo delle liste elettorali.",
        content154: "Foto valida: prima di registrarti, devi fornire una foto di te stesso con il tuo documento d'identità appoggiato al petto;",
        content155: "Dettagli di contatto: numero di telefono/cellulare e/o indirizzo email valido.",
        content156: "3.1.2 Se sei una società o un'altra persona giuridica, raccoglieremo le seguenti informazioni per identificare te o il beneficiario finale del tuo conto fiduciario.",
        content157: "Registrazione della società e certificato di registrazione; copie dello statuto e dell'atto costitutivo della società; materiali di certificazione dettagliati della struttura patrimoniale della società e descrizione della proprietà, comprovanti la delibera del consiglio del mandatario che determina l'apertura e l'esecuzione del conto su questo sito web; società amministratori che devono essere forniti come richiesto, documenti di identità dell'azionista di maggioranza e della persona autorizzata a firmare il conto su questo sito Web; l'indirizzo commerciale principale della società, se diverso dall'indirizzo postale della società, fornire l'indirizzo postale. Se l'indirizzo locale di un'azienda non è coerente con il suo indirizzo commerciale principale, è considerato un cliente a rischio più elevato e dovrà presentare ulteriore documentazione aggiuntiva.",
        content158: "A seconda delle diverse normative nelle diverse giurisdizioni e nei diversi tipi di entità, richiediamo altre certificazioni e documenti rilasciati dalle autorità, nonché documenti che riteniamo necessari.",
        content159: "3.1.3 Accettiamo solo versioni inglesi o cinesi delle informazioni sull'identità. In caso contrario, traduci le informazioni sulla tua identità in inglese e falle autenticare.",
        content160: "3.2 Conferma la verifica",
        content161: "3.2.1 Ti chiediamo di fornire l'intero contenuto della pagina del tuo documento di identità.",
        content162: "3.2.2 Ti chiediamo una foto mentre tieni il tuo documento d'identità appoggiato al petto.",
        content163: "3.2.3 Le copie dei documenti giustificativi dovrebbero generalmente essere confrontate con i voucher originali. Tuttavia, una copia è accettabile se un certificatore idoneo e affidabile può certificare che la copia è una riproduzione accurata e completa del documento originale. Tali certificatori includono ambasciatori, commissari giudiziari, magistrati locali, ecc.",
        content164: "3.2.4 Il requisito per identificare il beneficiario finale e il controllo del conto è determinare quali individui possiedono o controllano il cliente diretto e/o stabilire che le transazioni in corso vengono eseguite per conto di altri. Se si tratta di un'impresa, l'identità dei principali azionisti (come quelli che detengono il 10% o più degli interessi con diritto di voto) dovrebbe essere verificata. In generale, detenere il 25% delle azioni sarà considerato un rischio normale e l'identità dell'azionista dovrà essere verificata; detenere il 10% delle azioni o detenere più diritti di voto o azioni è considerata una situazione ad alto rischio e l'identità dell’azionista deve essere verificato.",
        content165: "4. Monitorare le transazioni",
        content166: "4.1 Impostiamo e adattiamo di tanto in tanto i limiti massimi di transazioni giornaliere e di prelievo di valuta in base alla sicurezza e alle condizioni effettive della transazione.;",
        content167: "4.2 Se le transazioni si verificano frequentemente in un utente registrato o si verificano circostanze irragionevoli, il nostro team di professionisti valuterà e deciderà se sono sospette.;",
        content168: "4.3 Se determiniamo che una transazione è sospetta in base al nostro giudizio, potremmo adottare misure restrittive come la sospensione della transazione, il rifiuto della transazione o addirittura l'annullamento della transazione il prima possibile, se possibile, e segnalarla all'autorità competente, ma non ti avviserà.;",
        content169: "4.4 Ci riserviamo il diritto di respingere le richieste di registrazione provenienti da persone provenienti da giurisdizioni che non rispettano gli standard internazionali antiriciclaggio o da persone che possono essere considerate persone politicamente esposte. Ci riserviamo il diritto di sospendere o terminare transazioni sospette a nostro insindacabile giudizio all'indirizzo in qualsiasi momento, ma non lo facciamo. Violare qualsiasi dovere o responsabilità nei tuoi confronti.",
    },
    register: {
        formTitle1: "creare",
        formTitle2: "Registrati utilizzando la tua email o il numero di cellulare",
        formTitle3: "Account",
        field1: "Codice di verifica",
        field2: "Ottenere",
        field3: "Conferma password",
        field4: "Codice invito",
        field41: "(opzionale)",
        field5: "Ho letto e sono d'accordo",
        field6: "Termini di servizio",
        placeholder1: "inserisci il codice di verifica",
        content7: "inserisci il codice di verifica",
        content12: "Inserisci il numero di telefono",
        content13: "Per favore, inserisci la password",
        content14: "per favore inserisci la tua email",
        content18: "Inserisci nuovamente la password",
        content19: "Si prega di accettare i termini di servizio",
        content20: "Inserisci il codice di verifica corretto",
        content23: "Non hai ancora ricevuto il codice di verifica?",
        content24: "Inserisci il codice di verifica qui sotto",
        registerSuccess: "Registrazione avvenuta",
        content25: "Le password non sono coerenti",
        content26: "Inserisci l'indirizzo email corretto",
        content27: "Reimpostazione della password riuscita"
    },
    download: {
        content1: "Fai trading sempre e ovunque",
        content2: "Perfettamente compatibile con più terminali, soddisfacendo le esigenze di transazione di vari scenari in qualsiasi momento",
        content3: "Controlla le condizioni del mercato in tempo reale in qualsiasi momento",
        content4: "Acquista e vendi facilmente BTC, ETH, XRP e altri asset digitali",
        content5: "Ricevi avvisi tempestivi sulle variazioni di prezzo per le criptovalute che ti interessano",
        content6: "Visualizza il prestito spot Bitcoin, il prestito futures e i prezzi delle opzioni",
        content7: "Confronta i prezzi delle criptovalute su vari scambi",
        title: "scaricamento",
        install: "Note di installazione",
        one: "1. Consenti il ​​download dei file di descrizione",
        two: "2. Il file di descrizione è stato scaricato",
        three: "3. Inserisci [Impostazioni] sul tuo telefono e trova [Generale]",
        four: "4. File di descrizione e gestione dispositivi",
        five: "5. Fare clic sul file di descrizione",
    },
    identity: {
        noAuthentication: {
            title: "Certificazione Juniores",
            pleaseSelectYourCountry: "Selezionare il proprio paese",
            name: "Nome",
            enterName: "per favore inserisci il tuo nome",
            city: "città",
            enterCity: "Inserisci la tua città",
            IDNumber: "Numero di identificazione",
            enterIDNumber: "Inserisci il tuo numero identificativo",
        },
        noAdvancedAuthentication: {
            title: "Certificazione avanzata",
            infoCountry: "paese/regione",
            infoName: "Nome",
            infoNumber: "Numero di identificazione",
            infoAddress: "indirizzo",
        },
        text: "non certificato",
        recertification: "ricertificazione",
        certification: "Certificazione",
        authenticationInProgress: "verificato",
        inAuthentication: "Certificazione in corso",
        uploadTheFrontOfTheDocument: "Carica la parte anteriore del documento d'identità",
        uploadTheReverseSideOfTheDocument: "Carica il retro del certificato",
        uploadAPhotoOfAHandHeldID: "Carica una foto della tua carta d'identità in mano",
        pleaseUpload: "Per favore ",
        pleaseCompleteOrdinaryCertificationFirst: "È necessario attendere il successo della revisione della certificazione primaria",
        refused: "respinto",
        reason: "motivo",
        succeeded: "successo",
        uploading: "caricamento",
        newTitle: "Seleziona il Paese in cui è stato rilasciato il tuo documento d'identità",
        pleaseOrdinaryCertificationFirst: "Si prega di eseguire prima la certificazione primaria",
        text1: "Modifica avatar",
        text2: "Soprannome",
        text3: "account",
        text4: "Modifica del soprannome",
        text5: "nuovo soprannome",
        text6: "Inserisci il tuo nuovo nickname",
        text7: "vecchia password",
        text8: "Inserisci la tua vecchia password",
        text9: "nuova password",
        text10: "Inserisci la tua nuova password",
        text11: "Conferma password",

        text12: "Inserisci nuovamente la tua nuova password",
        text13: "nuova casella di posta",
        text14: "Codice di verifica",
        text15: "inserisci il codice di verifica",
        text16: "il tuo numero di cellulare",
        text17: "Nuovo numero di cellulare",
        text18: "Per favore, inserisca il suo numero di telefono",
        text19: "Codice di verifica del telefono cellulare",
        text20: "Certificazione Juniores",
        text21: "Verifica le informazioni sulla tua identità",
        text22: "Paese",
        text23: "Città",
        text24: "Inserisci la tua città",
        text25: "Inserisci il tuo nome",
        text26: "Il tuo numero identificativo",
        text27: "Inserisci il tuo numero identificativo",
        text28: "Foto frontale della carta d'identità",
        text29: "Clicca per caricare i tuoi documenti",
        text30: "ricaricare",
        text31: "caricamento",
        text32: "Tenendo la foto d'identità",
        text33: "Fai clic per caricare la foto del tuo documento d'identità",
        text34: "Hai associato Google Authenticator",
        text35: "chiave",
        text36: "Inserisci il codice di verifica di Google",
        text37: "Inviare",
        text38: "Non può essere vuoto",
        text39: "Il formato dell'e-mail è sbagliato",
        text40: "Due password non sono coerenti",
        text41: "Copiato con successo",
        text42: "Copia non riuscita",
        text43: "Modifica dell'avatar",
        text44: "cambiare la password",
        text45: "Associazione e-mail",
        text46: "Modifica e-mail",
        text47: "Legatura del cellulare",
        text48: "Modifica cellulare",
        text49: "Verifica di Google",
        text50: "Associa Google Authenticator",
        text51: "Inserisci il codice di verifica di Google",
        text52: "salva",
        text53: "Conferma invio",
        text54: "Rivedere",
        text55: "legame",
        text56: "Disattiva la verifica di Google",
        text57: "Passo successivo",
        text58: "Fine",
        text59: "La verifica di Google è vincolata",
        text60: "La verifica di Google è stata separata",
        text61: "La certificazione primaria è stata presentata",
        text62: "Certificazione avanzata inviata",
        text63: "Nessuna immagine caricata",
        text64: "Invia nuovamente",
        text65: "Per favore inserisci un nickname",
        text66: "Nickname salvato con successo",
        text67: "Nessuna immagine ancora caricata",
        text68: "Reimpostazione della password completata",
        text69: "Email modificata con successo",
        text70: "Avatar salvato con successo",
        text71: "Carica immagine",
        text72: "la tua casella di posta",
        text73: "Inserisci la tua nuova email",
        text74: "Il tuo nome",
        text75: "Vai all'autenticazione",
        text76: "Verifica la foto del tuo documento d'identità"
    },
    retrievePassword: {
        formTitle1: "Resetta la password",
        formTitle2: "Per la sicurezza dei tuoi beni, le transazioni sono vietate entro 24 ore dalla modifica della password.",
        formTitle3: "Recupera password",
        formTitle4: "Conferma le modifiche",
        formTitle5: "Inserisci la vecchia password",
        formTitle6: "Inserisci una nuova password"
    },
    home: {
        title1: "La piattaforma di trading di criptovalute più popolare",
        title3: "perché è ",
        title4: " È una piattaforma blockchain open source ad alte prestazioni progettata per supportare e gestire un'infrastruttura digitale sicura, conforme e prevedibile.",
        field1: "Scambio stabilito",
        field2: "Servizi finanziari per asset digitali",
        field3: "Decine di milioni di utenti",
        field4: "Istituire più fondi di protezione degli investitori",
        field5: "Commercio veloce",
        field6: "CME Gruop è in grado di abbinare gli ordini con una latenza estremamente bassa per la migliore esperienza di trading.",
        title5: "tendenza di mercato",
        title6: "Vedi altro",
        table1: "Valuta",
        table2: "Ultimo prezzo",
        table3: "24 ore su 24, alti e bassi",
        table4: "24H Volume",
        table5: "Volume delle transazioni 24 ore su 24",
        table6: "Citazioni",
        title7: "Di ",
        title8: " Ci impegniamo a fornire agli utenti gli strumenti di trading più avanzati, la tecnologia innovativa e un servizio clienti di qualità senza pari.",
        title9: "Il campo delle risorse digitali cambia ogni giorno che passa: solo essendo lungimiranti e facendo rapidi progressi possiamo stare al passo con l’innovazione tecnologica.",
        title901: " Fornire i servizi di trading di asset digitali più avanzati ai nostri utenti e ai fornitori di liquidità globali.",
        title10: "Crediamo fermamente che ogni utente meriti la migliore esperienza di trading.",
        title11: "Sicurezza di cui ti puoi fidare",
        title12: "Le nostre sofisticate misure di sicurezza e i fondi SAFU proteggono le tue risorse digitali da tutti i rischi. ",
        title13: "Migliori tassi di transazione",
        title14: "Tassi di transazione preferenziali, diritti VIP competitivi e goditi i migliori servizi di qualità.",
        title15: "Assistenza clienti 24 ore su 24, 7 giorni su 7",
        title16: "Modalità operativa 24 ore su 24, 7 giorni su 7, per tutte le stagioni e a tempo pieno, risponderemo alle vostre richieste pertinenti il ​​prima possibile.",
        title17: "0 commissioni di negoziazione",
        title18: "Utilizza più metodi di pagamento per scambiare valute digitali senza commissioni di gestione, in modo sicuro e veloce.",
        title19: "Fai trading sempre e ovunque",
        title20: "Perfettamente compatibile con più terminali, soddisfacendo le esigenze di transazione di vari scenari in qualsiasi momento",
        title21: "Controlla le condizioni del mercato in tempo reale in qualsiasi momento",
        title22: "Acquista e vendi facilmente BTC, ETH, XRP e altri asset digitali",
        title23: "Ricevi avvisi tempestivi sulle variazioni di prezzo per le criptovalute che ti interessano",
        title24: "Visualizza il prestito spot Bitcoin, il prestito futures e i prezzi delle opzioni",
        title25: "Confronta i prezzi delle criptovalute su vari scambi",
        title26: "Iscriviti ora",
        title27: "Dollaro",
        title28: "24H Volume",
        title29: "Fornitore di liquidità integrato",
        title30: "utente",
        title31: "Di più",
        title32: "Binance Loan aggiunge CTSI e KSM come asset collaterali e lancia sconti sulla riduzione del tasso di interesse BUSD e USDT!",
        title33: "Il nostro vantaggio",
        title34: "sicuro e stabile",
        title35: "Un team tecnico di altissimo livello, una protezione di sicurezza completa, ricerca e sviluppo indipendenti di un motore di sintesi delle transazioni ad alta velocità, possono ancora funzionare in modo stabile e affidabile con massicce transazioni simultanee.",
        title36: "Conformità professionale",
        title37: "Un team operativo professionale, con molti anni di esperienza nel campo della blockchain e della finanza, è titolare di una licenza di trading di asset digitali conforme e ha una garanzia di riserva del 100%.",
        title38: "Prima l'utente",
        title39: "Affrontare il mercato globale, supportare più lingue, funzionare 24 ore su 24, 7 giorni su 7, forte supporto della comunità e servizio clienti professionale.",
        title40: "alte prestazioni",
        title41: "300.000 transazioni al secondo e tempo di risposta agli ordini inferiore a 1 millisecondo.",
        title42: "chi siamo",
        title43: "Fai trading sempre e ovunque",
        title44: "Perfettamente compatibile con più terminali, soddisfacendo le esigenze di transazione di vari scenari in qualsiasi momento",
        title45: "(Scansiona il codice QR per scaricare)",
        title46: "Notizie sulla valuta digitale",
        title47: "il nostro partner commerciale",
        title48: "Elenco dei perdenti",
        title49: "Fatturato 24 ore su 24",
        title50: "Incremento e decremento nelle 24h"
    },
    quotation: {
        marketTrading: "Citazioni",
        turnover: "Volume",
        optional: "Opzionale",
        contractMarket: "mercato dei contratti",
        currencyPair: "coppia di valute",
        latestPrice: "Prezzo di chiusura",
        upsAndDowns: "Modifica preventivo",
        highest: "Più alto",
        lowest: "più basso",
        transactionAmount: "Volume delle transazioni",
        operate: "operare",
        buy: "Commercio",
        topTurnovers: "Elenco delle transazioni",
        topGainers: "Elenco dei vincitori",
        tradingPair: "coppia commerciale",
        buyOrder: "Acquisto",
        sellOrder: "vendere"
    },
    accountChange: {
        demo: "simulazione",
        realAccount: "Conto reale",
        demoAccount: "Conto demo",
        accountRole: "Ruolo dell'account",
        pleaseSelect: "si prega di scegliere",
        AccountChangeType: "Tipo di modifica dell'account",
        AccountType: "Tipo di account",
        currencyType: "Tipo di valuta",
        allType: "Tutti i tipi",
        allAccount: "Tutti i conti",
        allCoin: "Tutte le valute",
        coin: "Valuta",
        amount: "Modifica importo",
        balance: "Saldo del conto",
        time: "tempo",
        title: "Record di modifica dell'account",
        filtrar: "filtro",
        check: "passa a",
        showOnly: "Visualizza solo le disponibilità valutarie",
        holdAssets: "Saldo valutario",
        accountList: "Elenco dei conti"
    },
    contractTransaction: {
        chart: "grafico",
        basicEdition: "Versione base",
        depthMap: "Mappa della profondità",
        second: "Secondo",
        minute: "punto",
        day: "cielo",
        week: "settimana",
        month: "luna",
        open: "aprire",
        trade: "Commercio",
        buyLong: "acquisto lungo",
        buyShort: "acquisto a breve",
        cannotBuy: "Questa valuta non supporta le transazioni di contratti di consegna",
        cannotBuy1: "Questa valuta non supporta il commercio perpetuo",
        tradingAssets: "attività commerciali",
        amountAvailable: "quantità disponibile",
        timeStr: "tempo",
        transactionPeriod: "periodo di scambio",
        quantity: "quantità",
        enterQuantity: "Inserisci la quantità",
        buyIn: "Vai a lungo",
        latestTransaction: "ultima transazione",
        time: "tempo",
        direction: "direzione",
        price: "prezzo",
        sell: "corto",
        positionOrder: "Ordine di consegna",
        historicalOrders: "Ordini storici",
        product: "Prodotto",
        realAccount: "(conto reale)",
        demoAccount: "(Conto demo)",
        openingTime: "ora dell'ordine",
        openingPrice: "Prezzo di apertura",
        curretPrice: "Prezzo attuale",
        liquidationPrice: "Prezzo di chiusura",
        profitOrLoss: "Profitti e perdite",
        endTime: "Orario di chiusura",
        areYouSureToBuy: "Sei sicuro di voler acquistare?",
        areYouSureToLongBuy: "Sei sicuro di voler andare lungo?",
        areYouSureToShortBuy: "Sei sicuro di voler acquistare allo scoperto?",
        successfullyBought: "Acquista con successo",
        minBuy: "Buy-in minimo",
        assets: "risorse",
        withdraw: "ritirare",
        entrustedOrder: "Ordine",
        total: "totale",
        continueToPlaceOrder: "Continua a effettuare un ordine",
        orderConfirmation: "Conferma dell'ordine",
        buyRu: "acquistare",
        sellChu: "vendere",
        areYouSureToSell: "Sei sicuro di voler vendere?",
        operation: "operare",
        buyCover: "posizione della copertura",
        commitSuccess: "Transazione riuscita",
        onlyEnterIntegers: "È possibile inserire solo numeri interi",
        trend: "Tendenza",
        lotteryTime: "Termine ultimo per l'ordinazione",
        countdown: "Conto alla rovescia",
        period: "Problema",
        cycle: "Periodo di tempo",
        unlimited: "Illimitato",
        put: "Put",
        call: "Call",
        pending: "Su commissione",
        unopened: "Non ancora disegnato",
        opened: "Premio estratto",
        settlementPrice: "Prezzo di liquidazione",
        rateOfReturn: "tasso di rendimento",
        currentPositions: "Posizione attuale",
        positionQuantity: "Quantità di posizione",
        guarantee: "margine",
        text1: "Call",
        text2: "Put",
        text3: "In attesa di acquistare",
        text4: "Quantità dell'ordine",
        text5: "Il numero di transazioni",
        text6: "Annulla",
        text7: "tempi di consegna",
        text8: "Promemoria di cancellazione",
        text9: "Sei sicuro di voler annullare l'ordine?",
        text10: "Quantità illegale",
        text11: "Promemoria dell'ordine",
        text12: "Sei sicuro di acquistare in modo rialzista?",
        text13: "Sei sicuro di acquistare la put?",
        text14: "Cancellazione avvenuta con successo"
    },
    mining: {
        title1: "La piattaforma di mining di risorse digitali blockchain leader a livello mondiale",
        title2: "Aperto e trasparente · sicuro e stabile",
        title3: "Seleziona la valuta",
        title4: "Rendimento annualizzato minerario",
        title5: "cielo",
        title6: "Periodo minerario",
        title7: "Dettagli del prodotto",
        btn: "Il mio adesso",
        btn2: "chiusura",
        tabs1: "in corso",
        tabs2: "redento",
        table1: "nome del prodotto",
        table2: "Periodo minerario",
        table3: "Tempo di estrazione mineraria",
        table4: "Importo minerario",
        table5: "Data di scadenza",
        table6: "Procede fino alla scadenza",
        table7: "Lo stato dell'ordine",
        egularMining: "Estrazione regolarmente",
        inProgress: "in corso",
        redeemed: "redento",
        redemption: "redenzione",
        regularMining: "Estrazione regolarmente",
        earlyRedemption: "rimborso anticipato",
        need: "Bisogno",
        handlingFee: "commissione di gestione",
        redemptionConfirmationMessage: "Si prega di confermare se riscattare in anticipo?",
        form1: "Rendimento annualizzato minerario",
        form2: "Periodo minerario",
        form3: "Valuta mineraria",
        form4: "Importo dell'abbonamento (minimo/massimo)",
        form5: "Commissione di rimborso anticipato: ",
        form6: "prezzo d'acquisto",
        form7: "Fondi disponibili",
        form8: "prezzo d'acquisto",
        form9: "Fondi disponibili",
        form10: "Inserisci l'importo dell'acquisto",
        form11: "Tutto",
        form12: "Entrate stimate",
        form13: "Fondi disponibili insufficienti",
        form14: "Inserisci l'importo dell'acquisto",
        form15: "Il mio ordine",
        form16: "reddito",
        form17: "dettagli dell'ordine",
        form18: "Istruzioni per il rimborso anticipato:",
        form19: "Importo dell'abbonamento (minimo)",
        form20: "Importo dell'abbonamento (massimo)",
        form21: "Quantità detenuta",
        form22: "Comprare tempo",
        form23: "tempo di riscatto",
        form24: "Sopra",
        form25: "Iscrizione riuscita",
        form26: "Riscatto riuscito",
        form27: "Facile gestione finanziaria con rendimenti superiori al 25%, sovraperformando il mercato, e puoi anche ricevere premi dopo l'acquisto",
        form28: "Invita gli amici a ricevere una ricompensa di 50 USDT e i voucher contrattuali possono anche restituire una commissione del 20%."
    },
    assets: {
        content1: "Patrimonio del conto",
        content2: "I guadagni di oggi",
        content3: "Profilo corrente",
        content4: "Il conto reale rappresenta le risorse effettive del tuo conto, che possono essere utilizzate per transazioni e prelievi.",
        content5: "Ritirare",
        content6: "Deposita",
        content7: "scambio",
        content8: "trasferire",
        content9: "si prega di scegliere",
        content10: "Conto demo",
        content11: "Conto reale",
        content12: "acquisire beni",
        content13: "Sei sicuro di voler passare a un conto reale?",
        content14: "Sei sicuro di voler passare a un conto demo?",
        content15: "ottenere il successo",
        table1: "Valuta",
        table2: "quantità",
        table3: "congelare",
        table4: "Disponibile",
        dialog1: "Dettagli del prodotto",
        dialog2: "1. Questa risorsa può essere utilizzata solo in modalità account demo",
        dialog3: "2. Per evitare abusi è consentito un solo riscatto nell'arco di un mese.",
        dialog4: "3. Quando si ricevono risorse, l'importo delle risorse ricevute verrà aggiunto all'importo originale e lo stato della posizione non verrà modificato.",
        dialog5: "4. Se hai altre domande, contatta il servizio clienti",
        dialog6: "chiusura",
        dialog7: "Ottieni subito risorse demo",
        dialog8: "trasferimento in",
        dialog9: "trasferire fuori",
        dialog10: "interruttore",
        dialog11: "Valutazione",
        dialog12: "Risorse dimostrative",
        dialog13: "il mio conto",
        dialog14: "Totale attivo del conto",
        dialog15: "Panoramica delle risorse",
        dialog16: "Valutazione totale del bene",
        dialog17: "Valutazione delle risorse",
        dialog18: "I guadagni di oggi",
        dialog19: "congelare i fondi",
        dialog20: "Puoi ricevere una quota di risorse simulata ogni mese",
        dialog21: "ricevere",
        dialog22: "Hai ricevuto con successo",
        dialog23: 'Reddito cumulativo di 30 giorni',
        dialog24: "Prodotto finanziario",
        dialog25: "La mia gestione finanziaria",
        dialog26: "Gestione finanziaria storica",
        dialog27: "Registro storia",
        dialog28: "Registri di finanziamento"
    },
    validator: {
        dialog14: "validatore",
        dialog15: "Validatore di associazione",
        dialog16: "interruttore del validatore",
        dialog17: "Codice di verifica",
        dialog18: "Codice QR del validatore",
        dialog19: "chiave di validazione",
        dialog20: "salva",
        dialog21: "Seleziona un validatore",
        dialog22: "copia chiave",
        dialog23: "Salvato con successo",
        dialog24: "inserisci il codice di verifica",
        dialog25: "Promemoria amichevole: prima di confermare il salvataggio, apri l'APP corrispondente e scansiona il codice QR qui sotto o aggiungi la chiave manualmente!",
        dialog26: "Abilitare il tuo autenticatore fornisce la massima sicurezza per il tuo account.",
        dialog27: "Dovrai inserire un codice di verifica univoco a cui potrai accedere tramite il tuo telefono cellulare ogni volta che accedi o prelevi fondi.",
        dialog28: "Configura il validatore",
        dialog29: "aiuto",
        dialog30: "Non hai vincolato il codice di autenticazione. Ottieni una protezione più forte con un autenticatore.",
        dialog31: "Il validatore non è stato ancora configurato, configuralo prima",
        dialog32: "Vai alle impostazioni"
    },
    recharge: {
        title1: "Non sai come acquistare utilizzando la valuta digitale?",
        title2: "Solo tre semplici passaggi!",
        title3: "Seleziona la valuta digitale che desideri acquistare dal menu a discesa e inserisci la quantità o l'importo dell'acquisto.",
        title4: "Scegli il tuo metodo di pagamento preferito",
        title5: "Dopo la conferma del pagamento, la valuta digitale acquistata verrà automaticamente trasferita sul tuo conto.",
        title6: "Seleziona la valuta che desideri ricaricare",
        title7: "Registro del deposito",
        table1: "Valuta",
        table2: "quantità",
        table3: "Nome della catena",
        table4: "stato",
        table5: "motivo",
        table6: "tempo",
        table7: "Visualizza il buono",
        dialog1: "Inserisci il codice di verifica qui sotto",
        dialog2: "codice di pagamento",
        dialog3: "copia",
        dialog4: "indirizzo",
        dialog5: "Importo del deposito",
        dialog6: "Schermata della transazione",
        dialog7: "avviso IMPORTANTE",
        dialog8: "Si prega di inserire l'importo del deposito",
        dialog9: "carica immagine",
        dialog10: "Inserisci il contenuto",
        dialog11: "Si prega di caricare screenshot della ricarica",
        dialog12: "Non recensito",
        dialog13: "respinto",
        dialog14: "Depositato",
        tip1: "Inserito con successo",
        tip2: "Copiato con successo",
        tip3: "Copia non riuscita",
        tip4: "Seleziona la valuta che desideri ricaricare",
        tip5: "1.L'indirizzo di deposito sopra riportato è l'indirizzo di pagamento ufficiale della piattaforma. Cerca l'indirizzo di deposito ufficiale della piattaforma. Sarai responsabile della perdita di fondi causata da un deposito errato;",
        tip6: "2.Assicurati di confermare la sicurezza del tuo computer e browser per evitare che le informazioni vengano manomesse o divulgate;",
        tip7: "3.Dopo aver ricaricato l'indirizzo sopra indicato, l'intero nodo della rete deve confermare prima che l'account possa essere accreditato;",
        tip8: "4.Seleziona il sistema di token e la valuta sopra indicati e trasferisci l'importo corrispondente per il deposito. Non trasferire altre risorse non correlate, altrimenti non verranno recuperate.",
        tip9: "Salva il codice QR",
        tip10: "Riempire",
        tip11: "avviso IMPORTANTE",
        tip12: "Collegamenti fuori sede",
        tip13: "Ricarica il codice QR",
        tip14: "Passo successivo",
        tip15: "Ricaricare il registro",
        tip16: "Dettagli ricarica",
        tip17: "Indirizzo di deposito",
        tip18: "Pagamento rapido",
        tip20: "Equilibrio insufficiente",
        tip22: "Inviato, in attesa di conferma",
        tip23: "In attesa del pagamento",
        tip24: "Quantità",
        tip25: "Il pagamento è stato inviato, in attesa di conferma del blocco",
        tip26: "Pagamento rifiutato",
        tip27: "Pagamento fallito",
        tip28: "Pagamento riuscito",
        bankChannel: "Canale delle carte bancarie",
        tip29: "Ricerche storiche",
        tip30: "Elenco valute",
        tip31: "Seleziona rete",
        tip32: "Quando ricarichi questa valuta, ricarica solo tramite le reti supportate di seguito. Se ricarichi tramite altre reti, i fondi andranno persi.",
        tip33: "Quando ritiri questa valuta, effettua il prelievo solo tramite le reti supportate di seguito. Se prelevi tramite altre reti, i fondi andranno persi.",
        tip34: "Ricarica rapida",
        tip35: "Scansiona il codice per ricaricare",
        tip36: "Ricarica manuale",
        tip37: "Ricarica la rete",

        tip38: "Indirizzo di ricarica",
        tip39: "Importo della ricarica",
        tip40: "Caricamento della ricevuta della transazione",
        tip41: "carica immagine",
        tip42: "confermare l'invio",
        tip43: "confermare il pagamento",
        tip44: "Nota: conferma l'indirizzo da ricaricare, non siamo responsabili per la perdita di denaro se lo inserisci in modo errato.",
        tip45: "ricordare",
        tip46: "Indirizzo copiato con successo!",
        tip47: "Copia dell'indirizzo non riuscita!",
        tip48: "Saldo disponibile insufficiente",
        tip49: "La ricarica è stata inviata con successo ed è in attesa di conferma sulla catena.",
        tip50: "Ricarica non riuscita",
        tip51: "L'importo della ricarica è sbagliato",
        tip52: "Connessione fallita",
        tip53: "Portafoglio non registrato",
        tip54: "Connessione rifiutata",
        tip55: "Inserito correttamente, in attesa di revisione e approvazione da parte del servizio clienti",
        tip56: "Si prega di caricare uno screenshot della ricevuta della transazione",
        tip57: "Invio non riuscito",
        tip58: "Ricaricare il registro",
        tip59: "Registro dei prelievi",
        tip60: "Ricarica valuta",
        tip61: "Valuta di prelievo",
        tip62: "Indirizzo di ricarica",
        tip63: "Lo screenshot mostra",
        tip64: "Fare clic per ingrandire l'immagine",
        tip65: "Tempo di ricarica",
        tip66: "Motivo del rifiuto",
        tip67: "Importo del prelievo",
        tip68: "Quantità ricevuta",
        tip69: "Rete di prelievo contanti",
        tip70: "Indirizzo di ritiro",
        tip71: "Tempo di ritiro",
        tip72: "chiusura",
        tip73: "Schermata della transazione",
        tip74: "Recensione",
        tip75: "respinto",
        tip76: "completato",
        tip77: "respinto",
        tip78: "Controllato",
        tip79: "Erogato",
        tip80: "estratto",
        tip81: "rete di trasferimento",
        tip82: "Indirizzo/fattura",
        tip83: "Ritirare tutto",
        tip84: "Ti preghiamo di non prelevare denaro direttamente all'indirizzo di crowdfunding o ICO, altrimenti non potrai ricevere i token emessi dal crowdfunding o dall'ICO.",
        tip85: "Quantità in arrivo",
        tip86: "Conferma il ritiro",
        tip87: "Indirizzo di ritiro sbagliato",
        tip88: "Importo di prelievo errato",
        tip89: "L'importo da prelevare è almeno",
        tip90: "L'importo massimo di prelievo è",
        tip91: "La richiesta di prelievo è stata inviata",
        tip92: "elenco indirizzi",
        tip93: "Non hai ancora aggiunto un indirizzo",
        tip94: "Aggiungilo adesso",
        tip95: "Aggiungi indirizzo",
        tip96: "Aggiungi un nuovo indirizzo",
        tip97: "si prega di scegliere",
        tip98: "indirizzo",
        tip99: "Seleziona una rete di trasferimento",
        tip100: "Inserisci l'indirizzo",
        tip101: "Indirizzo aggiunto correttamente",
        tip102: "nella transazione",
    },
    fundExchange: {
        title1: "Come scambiare fondi?",
        title2: "Seleziona la valuta appropriata da convertire.",
        title3: "Seleziona la valuta appropriata da convertire.",
        title4: "Inserisci l'importo da scambiare e fai clic su Invia per completare lo scambio di fondi.",
        title5: "da",
        title6: "arrivare",
        title7: "Quantità di scambio",
        title8: "tasso di cambio",
        title9: "Disponibile",
        title10: "Inserisci la quantità di rimborso",
        title11: "Non esiste una coppia di scambio corrispondente per lo scambio.",
        title12: "Inserisci la quantità da riscattare",
        title13: "Riscatto riuscito",
        title14: "Registro di scambio",
        table1: "Usa la valuta",
        table2: "quantità",
        table3: "tasso di cambio",
        table4: "Cambio valuta",
        table5: "Ricevuta",
        table6: "tempo",
        title15: "da",
        title16: "Al momento non ci sono risorse disponibili, selezionane un'altra"
    },
    fundTransfer: {
        title1: "Come trasferire fondi?",
        title2: "Seleziona il conto corrente che deve essere trasferito.",
        title3: "Seleziona l'account che desideri trasferire.",
        title4: "Inserisci l'importo del trasferimento e fai clic su Invia per completare il trasferimento.",
        title5: "Valuta",
        title6: "Quantità di trasferimento",
        title7: "Inserisci l'importo da trasferire",
        title8: "registro del trasferimento",
        title9: "Seleziona il tipo di portafoglio",
        title10: "Seleziona la valuta",
        title11: "Seleziona il tipo di portafoglio",
        title12: "Seleziona la valuta",
        title13: "Inserisci l'importo da trasferire",
        title14: "Inserito con successo",
        table1: "Trasferisci il conto",
        table2: "trasferire valuta",
        table3: "Quantità di trasferimento",
        table4: "conto di trasferimento",
        table5: "tempo",
        table6: "trasferire",
        table7: "Trasferimento di fondi",
        table8: "Trasferire a",
        table9: "registro del trasferimento",
        table10: "Dettagli del trasferimento",
        table11: "Trasferimento riuscito",
        table12: "Trasferimento non riuscito",
        table13: "Il conto corrente non può essere trasferito"
    },
    withdrawal: {
        title1: "avviso IMPORTANTE",
        title2: "1.Per evitare l'arbitraggio, puoi richiedere il ritiro della valuta quando il volume delle transazioni raggiunge la quota.",
        title3: "2.La richiesta di prelievo verrà accreditata entro 24 ore dall'invio.Se il prelievo non viene ricevuto dopo il tempo di prelievo stimato, consultare il servizio clienti online.",
        title4: "3.Dopo aver presentato la richiesta di prelievo, i fondi si trovano in uno stato congelato perché il prelievo è in corso e i fondi sono temporaneamente trattenuti dal sistema. Ciò non significa che hai perso il bene o che c'è un'anomalia con il bene.",
        title5: "Valuta",
        title6: "indirizzo del portafoglio",
        title7: "Quantità di monete ritirate",
        title8: "Registro dei prelievi di monete",
        title9: "Seleziona la valuta",
        title10: "Seleziona il tipo di catena",
        title101: "",
        title11: "prego entra",
        title12: "indirizzo del portafoglio",
        title13: "Inserito con successo",
        title14: "in corso di revisione",
        title15: "Revisione riuscita",
        title16: "Fallimento del controllo",
        title17: "Erogato",
        title18: "Inserisci o incolla l'indirizzo del tuo portafoglio",
        title19: "Inserisci l'importo del prelievo",
        title20: "commissione di gestione",
        title21: "Arrivo effettivo",
        table1: "Valuta",
        table2: "Quantità di monete ritirate",
        table3: "Nome della catena",
        table4: "indirizzo del portafoglio",
        table5: "stato",
        table6: "motivo",
        table7: "tempo di applicazione",
        table8: "Ritirare le monete",
        table81: "Ritirare le monete",
        table9: "Indirizzo di ritiro",
        table10: "Seleziona la valuta che desideri prelevare",
        table11: "Assicurati di entrare",
        table12: 'Indirizzo (di solito inizia con "0x")',
        table13: "Monete che possono essere prelevate (commissioni di gestione detratte):",
        table14: "Seleziona valuta",
        table15: "Incontri problemi? Feedback immediato",
        table16: "bilancia",
        table17: "Dettagli sul prelievo",
        password: "password",
        passwordTips: "Inserisci la password",
        passwordAg: "Conferma password",
        passwordAgTips: "Inserisci nuovamente la password",
        oldPassword: "vecchia password",
        oldPasswordTips: "Inserisci la vecchia password",
        newPassword: "nuova password",
        newPasswordTips: "Inserisci una nuova password",
        passwordNotSame: "Le password non sono coerenti",
        withdrawPasswordSet: "Imposta la password di prelievo",
        withdrawPasswordModify: "Cambia password di prelievo",
        withdrawPassword: "Ritira la password",
        withdrawPasswordTips: "Inserisci la password di prelievo",
        setWithdrawPasswordFirst: "Si prega di impostare prima una password di prelievo",
        withdrawPasswordSetSuccess: "Setup eseguito con successo",
        withdrawPasswordModifySuccess: "Modificato con successo",
        submit: "invia",
    },
    customer: {
        content1: "Servizio Online",
        content2: "assistenza clienti",
        content3: "Inserisci le informazioni di contatto",
        content4: "Inserisci le informazioni di contatto",
        content5: "Inviare",
        content6: "Invio non riuscito, connessione di rete fallita",
        content7: "immagine",
        content8: "il messaggio è vuoto",
        content9: "mia condivisione",
        content10: "condividi il link",
        content11: "salva immagine",
        content12: "I miei guadagni",
        content13: "Descrizione del livello",
        content14: "Dimensione della squadra",
        content15: "Entrate da invito",
        content16: "Descrizione della regola",
        content17: "livello di appartenenza",
        content18: "L'importo della ricarica è maggiore di",
        content19: "Livello di squadra",
        content20: "Livello e reddito della squadra",
        content21: "Per soddisfare le condizioni",
        content22: "Reddito da distribuzione",
        content23: "squadra di livello",
        content24: "livello, invito",
        content25: "membri validi",
        content26: "reddito primario",
        content27: "reddito secondario",
        content28: "Reddito di livello 3",
    },
    standardContract: {
        increaseMargin: "aumentare il margine",
        increaseAmount: "aumentare l'importo",
        Pleaseenterthemargin: "Inserisci l'importo del margine da aumentare",
        Pleaseenterthecorrect: "Inserisci l'importo corretto del deposito",
        Increasemarginsuccessfully: "Aumenta il margine con successo",
        takeProfitPriceWrong: "Sbagliare il prezzo del profitto",
        stoppriceerror: "Interrompere l'errore del prezzo",
        thetakeprofit1: "Il prezzo di take profit deve essere superiore al prezzo di mercato",
        thetakeprofit2: "Il prezzo di stop loss deve essere inferiore al prezzo di mercato",
        thetakeprofit3: "Il prezzo di take profit deve essere inferiore al prezzo di mercato",
        thetakeprofit4: "Il prezzo di stop loss deve essere superiore al prezzo di mercato",
        thetakeprofit5: "Quantità sbagliata",
        estimatedprofitloss: "Profitti e perdite stimati",
        positionAmount: "Numero di posizioni",
        closeAmount: "Quantità di chiusura",
        whenthelatest: "Quando viene raggiunto l'ultimo prezzo",
        whenthelatest2: ", verrà attivato l'ordine di presa del profitto sul prezzo di mercato per chiudere la posizione corrente. Profitti e perdite attesi",
        whenthelatest3: ", verrà attivato l'ordine di stop loss del mercato per chiudere la posizione corrente. Profitti e perdite attesi",
        contract: "contrarre",
        ordercanceledsuccessfully: "Ordine annullato con successo",
        successfullyordered: "ordinato con successo",
        positionopeningreminder: "Promemoria apertura posizione",
        wrongquantity: "Quantità sbagliata",
        quantitycannot: "La quantità non può essere maggiore di",
        modificationstoploss: "Modifica dello stop profit e dello stop loss avvenuta con successo",
        positionclosedsuccessfully: "Posizione stop con successo",
        orderCancellation: "Annullamento ordine",
        orderCancellation2: "Sei sicuro di voler annullare l'ordine?",
        tobeexecuted: "Essere giustiziato",
        expired: "scaduto",
        failed: "fallito",
        contractTransaction: "Contratto perpetuo",
        increase: "Aumento",
        openPosition: "Magazzino",
        isolatedPosition: "Posizione isolata",
        limitOrder: "Limite",
        marketOrder: "Mercato",
        pleaseSelectLeverage: "Seleziona la leva finanziaria",
        pleaseEnterQuantity: "Inserisci la quantità",
        canOpenMore: "Quantità disponibile",
        contractAmount: "Ammontare del contratto",
        openingFee: "commissione di gestione",
        currentPosition: "Posizione",
        historicalPositions: "Transazioni storiche",
        currentCommission: "Ordine attuale",
        historicalCommission: "commissione storica",
        buyPrice: "prezzo di acquisto",
        income: "profitto",
        quantity: "quantità",
        marginRate: "tasso di margine",
        initialMargin: "Margine iniziale",
        estimatedPriceParity: "Prezzo di liquidazione forzata",
        operate: "operare",
        finishQuantity: "Volume delle transazioni",
        averagePrice: "prezzo medio della transazione",
        pleaseEnterLeverage: "Inserisci la leva finanziaria",
        takeProfitAndStopLoss: "TP&SL",
        takeProfit: "Avere un profitto",
        stopLoss: "fermare la perdita",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Posizione stop",
        marketLquidation: "Prezzo mercato piatto",
        cancel: "Annulla Ordine",
        enterBuyPrice: "Inserisci il prezzo di acquisto",
        areYouSureLquidation: "Sei sicuro di chiudere la posizione?",
        confirm: "Sicuro",
        enterStopWinPrice: "Inserisci il prezzo take profit",
        enterStopLossPrice: "Inserisci il prezzo dello stop loss",
        commissionTime: "Tempo della Commissione",
        commissionPrice: "Prezzo della commissione",
        commissionQuantity: "Importo totale delle commissioni",
        volumeAlreadyTraded: "Importo completato",
        areYouSureToWinOrLoss: "Sei sicuro di voler fermare i profitti e fermare le perdite?",
        maxQuantity: "Posizione massima",
        saleQuantity: "Posizione stop",
        salePrice: "prezzo medio di chiusura",
        saleTime: "tempo",
        succeeded: "successo",
        cancelled: "Annullato",
        piece: "aprire",
        triggerPrice: "prezzo di attivazione",
        setWin: "Imposta il take profit",
        setLoss: "Imposta lo stop loss",
        enterWinQuantity: "Inserisci l'importo del take profit",
        enterLossQuantity: "Inserisci l'importo dello stop loss",
        planCommission: "Commissione del piano",
        enterWinTriggerPrice: "Inserisci il prezzo di attivazione del take profit",
        enterLossTriggerPrice: "Inserisci il prezzo di attivazione dello stop loss",
        insufficientQuantity: "Quantità insufficiente",
        enterTriggerPrice: "Inserisci il prezzo limite",
        tips1: "Il prezzo del tuo ordine è superiore al prezzo corrente e verrà eseguito direttamente al prezzo di mercato corrente. Sei sicuro di voler acquistare?",
        tips2: "Il prezzo del tuo ordine è inferiore al prezzo corrente e verrà eseguito direttamente al prezzo di mercato corrente. Sei sicuro di acquistare?",
        type: "tipo",
        increaseQuantity: "Aumentare la posizione",
        reduceQuantity: "Ridurre le posizioni",
        limitCommission: "ordine limite",
        tips3: "Il prezzo di stop loss deve essere inferiore al prezzo di mercato",
        tips4: "Il prezzo di take profit deve essere superiore al prezzo di mercato",
        tips5: "Il prezzo di stop loss deve essere superiore al prezzo di mercato",
        tips6: "Il prezzo di take profit deve essere inferiore al prezzo di mercato",
        tips7: "Il prezzo di stop loss dovrebbe essere inferiore al prezzo di mercato e inferiore al prezzo dell'ordine",
        tips8: "Il prezzo di take profit dovrebbe essere maggiore del prezzo dell'ordine (può essere inferiore al prezzo di mercato)",
        tips9: "Il prezzo di stop loss dovrebbe essere maggiore del prezzo di mercato e maggiore del prezzo dell'ordine",
        tips10: "Il prezzo di take profit dovrebbe essere inferiore al prezzo dell'ordine (può essere maggiore del prezzo di mercato)",
        accountEquity: "Saldo del conto",
        unrealizedPL: "profitti o perdite non realizzati",
        tips11: "Sei sicuro di avere ragione?",
        tips12: "Prezzo di mercato",
        tips13: "Chiudi tutte le posizioni",
        tips14: "Non ricordarmelo dinuovo",
        profitRate: "Tasso di rendimento di chiusura",
        openTime: "Orario di apertura",
        unwindTime: "Orario di chiusura",
        dealTime: "Orario della transazione",
        amountClose: "Importo della posizione chiusa",
        long: "molti",
        short: 'nullo',
        orderQuantity: "Quantità dell'ordine",
        entrustedMargin: "margine affidato",
    },
    spotGoods: {
        spotGoods: "Scambio lampo",
        sellPrice: "prezzo di vendita",
        orderRecord: "Record di riscatto flash",
        transactionTime: "Orario della transazione",
        maxBuy: "disponibile per l'acquisto",
        maxSell: "Disponibile alla vendita",
        purchaseQuantity: "Quantità d'acquisto",
        slippage: "Slittamento",
        convertTips: "Chiuderemo la transazione al prezzo più favorevole con uno slippage dello 0-5%.",
        changePrice: "Prezzo di cambio attuale",
        consume: "consumare",
        get: "Ottenere",
        changeTips: "Sei sicuro di voler riscattare?",
        confirm: "Conferma il riscatto"
    },
    news: {
        title: "Centro notizie",
        redMore: "Per saperne di più",
    },
    credit: {
        credit: "punteggio di credito",
        creditScore: "Punteggio totale",
        latestEntry: "ultimo record",
        liveUpdate: "Live Update",
        systemEvaluation: "Valutazione del sistema",
        time: "data",
        name: "nome",
        reason: "motivo",
        scoreStory: "Record del punteggio di credito"
    },
    borrowing: {
        borrowing: "prestito",
        diversifiedMarket: "Mercati diversificati",
        totalSupply: "Fornitura totale",
        totalBorrowings: "prestiti totali",
        tips1: "Questo è l'importo totale che puoi prendere in prestito a fronte della tua garanzia, fino al limite del prestito.",
        tips2: "Il tuo tasso di interesse stabile rimarrà lo stesso per tutta la durata del tuo prestito. Consigliato per forniture a lungo termine e per utenti che amano la prevedibilità.",
        totalSupplys: "fornitura totale",
        supplyAPR: "tasso di fornitura",
        totalBorrowings: "Prestito totale",
        borrowAPR: "tasso debitore",
        check: "Controllo",
        market: "mercato",
        availableLiquidity: "Liquidità disponibile",
        currencyPrice: "prezzo della valuta corrente",
        supplyAssets: "beni di fornitura",
        tips3: "Nota: il tasso di interesse riportato di seguito è un tasso di interesse fisso. Al termine del periodo di fornitura, il capitale più i guadagni verranno restituiti al tuo account.",
        supplyCycle: "ciclo di approvvigionamento",
        minimumSupply: "fornitura minima",
        supply: "fornitura",
        borrowedAssets: "beni presi in prestito",
        tips4: "Importante da sapere: per prendere in prestito denaro, dovrai fornire eventuali beni collaterali o prove,",
        tips5: "Si prega di consultare la descrizione del bene ipotecario.",
        repaymentCycle: "ciclo di rimborso",
        BorrowableAmount: "Importo del prestito disponibile",
        borrow: "prestito",
        accountInformation: "Informazioni account",
        walletBalance: "saldo del portafoglio",
        canSupply: "Disponibile",
        AmountCanBeBorrowed: "Importo totale del prestito disponibile",
        borrowedAmount: "Importo preso in prestito",
        remainingAmount: "equilibrio restante",
        getBorrowednAmount: "Ottieni il limite del prestito",
        supplyOrder: "ordine di fornitura",
        borrowedOrder: "ordine di prestito",
        supplyAmount: "importo della fornitura",
        expectedProfits: "Ritorno previsto",
        supplyTime: "Tempo di fornitura",
        expirationTime: "Data di scadenza",
        borrowingAmount: "Ammontare del prestito",
        dueRepayment: "Importo del rimborso",
        borrowingTime: "Tempo di rilascio",
        repaymentTime: "Tempo di rimborso",
        repay: "rimborso",
        supplyCurrency: "valuta di offerta",
        buyNow: "Acquista ora",
        supplyRecords: "registri delle forniture",
        borrowRecords: "Registri di prestito",
        completed: "completato",
        borrowNow: "Prendi in prestito adesso",
        borrowCurrency: "Prendere in prestito valuta",
        borrowMin: "più piccolo",
        borrowMax: "massimo",
        borrowRange: "Importo del prestito (minimo/massimo)",
        tips6: "Si prega di rimborsare in tempo.Se è scaduto, verrà addebitato un importo giornaliero aggiuntivo di (capitale + interessi).",
        tips7: "Commissione scaduta.",
        pleaseEnterBorrowAmount: "Inserisci l'importo del prestito",
        interest: "Interesse",
        tips8: "Sei sicuro di voler rimborsare in anticipo?",
        borrowChannel: "Canale di prestito",
        tips9: "Cominciamo conoscendoti",
        name: "nome",
        lastName: "cognome",
        birthDate: "data di nascita",
        tips10: "Qualche informazione in più, qual è il tuo indirizzo?",
        familyAddress: "Indirizzo di casa (compreso il numero dell'unità)",
        city: "Città",
        country: "nazione",
        postalCode: "Codice Postale",
        pleaseEnterYourHomeAddress: "Inserisci il tuo indirizzo di casa",
        tips11: "Raccontaci la tua situazione finanziaria",
        tips12: "Potrebbe essere necessario confermare queste informazioni in un secondo momento, quindi cerca di essere il più accurato possibile",
        yourMonthlyIncome: "il tuo reddito mensile",
        enterYourMonthlyIncome: "Inserisci il tuo reddito mensile",
        extraIncomeOptional: "Reddito aggiuntivo (facoltativo)",
        enterExtraIncome: "Inserisci il tuo reddito aggiuntivo",
        monthlyRentOrMortgagePayment: "Canone mensile o rata mutuo",
        enterMonthlyRent: "Inserisci l'affitto mensile o la rata del mutuo",
        tip13: "Raccontaci se hai un mutuo",
        tip14: "Se non viene selezionata alcuna garanzia ipotecaria, la probabilità di superare la revisione è bassa.",
        isThereMortgage: "C'è un mutuo?",
        selectIsThereMortgage: "Selezionare se è presente un mutuo",
        selectMortgageType: "Seleziona il tipo di mutuo",
        yes: "SÌ",
        no: "NO",
        uploadScreenshotOfTransaction: "Carica gli screenshot della transazione",
        tip15: "Teniamoci in contatto",
        tip16: "Potremmo contattarti a questo numero se abbiamo bisogno di ulteriori informazioni sulla tua richiesta di prestito",
        tip17: "Ho letto e firmato elettronicamente il mio consenso",
        tip18: "Condizioni d'uso e consenso elettronico",
        tip19: ", che include il tuo consenso affinché possiamo inviarti informazioni sui nostri",
        tip20: "Condizioni d'uso del prodotto e del servizio",
        tip21: "Dopo aver inserito le informazioni, attendere la valutazione e la revisione del sistema.",
        phoneNumber: "numero di telefono",
        enterPhoneNumber: "Per favore immetti il ​​tuo numero di telefono",
        tip22: "Seleziona la casella per accettare i termini di utilizzo e il modulo di consenso elettronico",
        inReview: "in corso di revisione",
        applicationAmount: "Importo della domanda",
        reasonForRejection: "Motivo del rifiuto",
        reapply: "Riapplicare",
        applyStatus: "stato dell'applicazione",
        currentOrder: "ordine attuale",
        interestRate: "tasso d'interesse"
    },
    yuebao: {
        filed1: "investire",
        filed2: "portare fuori",
        filed3: "Reddito giornaliero",
        filed4: "accelerazione della squadra",
        filed5: "Depositato",
        filed6: "I guadagni di ieri",
        filed7: "Entrate totali",
        filed8: "Fondi disponibili",
        filed9: "Inserisci l'importo",
        filed10: "Estrazione liquidità",
        filed11: "introduzione",
        field12: "Prezzo minimo di acquisto",
        field13: "Il massimo che puoi comprare",
        filed14: "Sottoscrizione",
        filed15: 'regolare',
        filed16: 'tasso di interesse annualizzato',
    },
    tipsPopover: {
        title: "Installa questa applicazione",
        press: "secondo",
        and: "E",
        scrren: "Aggiungere alla schermata Home",
        hasInstall: "(Se installato, chiudere la finestra)",
    },
    follow: {
        title1: "Piano di follow-up",
        follow_text1: "Avviare un ordine di follow-up",
        follow_text2: "Cronologia delle versioni",
        follow_text3: "titolo",
        follow_text4: 'coppia commerciale',
        follow_text5: "Durata dell'acquisto'",
        follow_text6: "ora dell'ordine'",
        follow_text7: 'tempo di rilascio',
        follow_text8: 'Numero di follower',
        follow_text9: "Ammontare dell'ordine'",
        follow_text10: 'operare',
        follow_text11: 'invitare',
        follow_text12: "Seguire la direzione dell'ordine'",
        follow_text13: "Imposta le condizioni dell'ordine'",
        follow_text14: 'Condizioni per eseguire gli ordini',
        follow_text15: 'importo fisso',
        follow_text16: 'percentuale',
        follow_text17: 'Persone invitate',
        follow_text18: "L'ID dell'altra parte",
        follow_text19: "Conferma per seguire l'ordine",
        follow_text20: "Importo del tuo ordine (facoltativo)",
        follow_text21: "Inserisci un titolo",
        follow_text22: "Seleziona una coppia di trading",
        follow_text23: "Seleziona la direzione dell'ordine",
        follow_text24: "Seleziona la durata dell'acquisto",
        follow_text25: "Seleziona l'orario dell'ordine",
        follow_text26: "Seleziona la quantità di acquisto",
        follow_text27: "La quantità massima di acquisto non può essere inferiore alla quantità minima di acquisto",
        follow_text28: "Buy-in minimo",
        follow_text29: "La maggior parte compra",
        follow_text30: "Crea ordine di copia con successo",
        follow_text31: "Elenco delle coppie di trading",
        follow_text32: "Ho già seguito l'ordine",
        follow_text33: "Inserisci le informazioni sull'invitato",
        follow_text34: "Invitato",
        follow_text35: "mi ha invitato",
        follow_text36: "Copiare la storia",
        follow_text37: "Intervallo di importo dell'ordine",
        follow_text38: "Inserisci codice copia"
    },
    followHistory: {
        title1: 'Cronologia delle versioni',
        title2: "Copiare la storia",
        follow_h_text1: "L'importo totale dell'ordine",
        follow_h_text2: "Numero totale di persone che seguono gli ordini",
        follow_h_text3: "Numero di rilasci",
        follow_h_text4: "tasso di vincita",
        follow_h_text5: "Numero di volte per eseguire gli ordini",
        follow_h_text6: "Guadagna dal seguire gli ordini",
        follow_h_text7: "direzione",
        follow_h_text8: "vincita",
        follow_h_text9: "disavanzo",
    },
    "asset.index.1579E8B41F8D5134": "i miei beni",
    "asset.index.B3F6F05Dfbe431Cd": "simulazione",
    "asset.index.B9Aeab4Ee8F33746": "Valutazione delle risorse",
    "asset.index.583Df8Bd541Eb7E8": "andamento degli utili",
    "asset.index.B1254815D133Cc26": "7 giorni",
    "asset.index.2F615F6C029F01B7": "30 giorni",
    "asset.index.29439Fb58E4Bbe22": "ritirare",
    "asset.index.831Ab31568A78285": "Riempire",
    "asset.index.39F7A0D48D12E8C9": "trasferire",
    "asset.index.B99063C5689Beaec": "Scambio flash",
    "asset.index.E1Dc620F2F038B2A": "Ricevi risorse",
    "asset.index.972A0B4C2B4393D0": "il mio conto",
    "asset.index.9Dfb0Fb334620733": "saldo disponibile",
    "asset.index.Cdc5Fbd26790Cb47": "quantità congelata",
    "asset.index.3D8132D72Ae965Cd": "Valutazione",
    "asset.receive.825503Cc430559Cb": "acquisire beni",
    "asset.receive.54154A0553A535B7": "Risorse dimostrative",
    "asset.receive.F2E57B00A9C5D129": "1.Questa risorsa può essere utilizzata solo in modalità account demo",
    "asset.receive.Ea0D1D52989299Bd": "2.Questa risorsa può essere utilizzata solo in modalità account demo",
    "asset.receive.090E0037Aa235D3D": "3.La ricezione degli asset aggiungerà l'importo degli asset ricevuti sulla base originaria e non modificherà lo stato della posizione.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Se hai altre domande, contatta il servizio clienti",
    "asset.receive.Df5B3A511D986575": "Ottieni subito risorse demo",
    "asset.records.1Fa02E8187A39592": "ultimi tre mesi",
    "asset.records.7B291C37333Fe34D": "da vicino a lontano",
    "asset.records.A476Cd1Ec868Fc5F": "ultimi tre mesi,",
    "asset.records.9D5755A842E55Bb7": "da vicino a lontano,",
    "asset.records.E3Fa05E607203Cdc": "Riempire",
    "asset.records.B4F206C6Bec08Dea": "ritirare",
    "asset.index.Df5B3A511D986576": "Hai ricevuto con successo le risorse demo",
    "deposit.depositSpeedy.Efe81D0E0174239B": "metodo di pagamento",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Moneta fiat",
    "deposit.depositSpeedy.16658A855F07D171": "Quantità",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Sicuro",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Non può essere vuoto",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Ricarica minima",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Ricarica massima",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Deposita USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Ricarica valuta",
    "deposit.depositWallet.B404C543712B71D5": "Ricarica la rete",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Indirizzo di deposito",
    "deposit.depositWallet.D3980D54Acf242Db": "Salva il codice QR",
    "deposit.depositWallet.A06E84801D6582Be": "Importo della ricarica",
    "deposit.depositWallet.Daaaeb1B7B22B126": "suggerimento",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Ricarica riuscita",
    "deposit.depositWallet.4F48Abbafce532Bb": "Inserito correttamente, in attesa della revisione del servizio clienti",
    "deposit.depositWallet.A88C481228811696": "Hai rifiutato di cambiare rete",
    "deposit.depositWallet.C6Ebf27895C21932": "Apri manualmente il plug-in del portafoglio TRON ed effettua l'accesso",
    "deposit.index.B5491C8Dd09D8183": "Seleziona la valuta in cui desideri ricaricare",
    "deposit.index.2F784B2298D39987": "Ricarica rapida",
    "deposit.index.0Daa03Dcdcabd837": "Collegamenti fuori sede",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Conferma di ritiro",
    "withdraw.bottomSheet.A086Fa20082C849D": "Assicurati che le informazioni seguenti siano corrette",
    "withdraw.bottomSheet.Bf8B184D32971501": "Rete di prelievo",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "indirizzo",
    "withdraw.bottomSheet.F9Db93B87E08763B": "quantità",
    "withdraw.bottomSheet.4Ad26475002878C0": "tariffa di gestione",
    "withdraw.index.A96454041169Af6D": "Seleziona valuta",
    "withdraw.index.2A563B7081Dd5B83": "Indirizzo di ritiro",
    "withdraw.index.B189C2Ef713Cfff4": "Quantità di monete ritirate",
    "withdraw.index.558F35140E84B562": "Disponibile:",
    "withdraw.index.F4E759087E12Cb77": "Ritirare tutto",
    "withdraw.index.5Cbd9A6B284D25Ae": "Password del fondo",
    "withdraw.index.B89Cbe4Bfc33Bb41": "tariffa di gestione:",
    "withdraw.index.C886De8D8A27026E": "avviso IMPORTANTE",
    "withdraw.index.Eac7C5A4095F5606": "1.Per evitare l'arbitraggio, puoi richiedere il ritiro della valuta quando il volume delle transazioni raggiunge la quota.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.La richiesta di prelievo verrà accreditata entro 24 ore dall'invio.Se il prelievo non viene ricevuto dopo il tempo di prelievo stimato, consultare il servizio clienti online.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Dopo aver presentato la richiesta di prelievo, i fondi si trovano in uno stato congelato perché il prelievo è in corso e i fondi sono temporaneamente trattenuti dal sistema. Ciò non significa che hai perso il bene o che c'è un'anomalia con il bene.",
    "withdraw.index.4639F3A6E07C00B3": "Passo successivo",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Inserisci o incolla l'indirizzo del tuo portafoglio",
    "withdraw.index.25768141C8Bdea91": "Inserisci l'importo del prelievo",
    "withdraw.index.97A85734Cd1B7Bf0": "Inserisci la password del fondo",
    "withdraw.index.39C7D74B33241C78": "L'importo del prelievo è sbagliato",
    "withdraw.index.86A5D17908197525": "Conferma del prelievo, in attesa della revisione per il rilascio delle monete)",
    "withdraw.index.48Fb603C6638C7F6": "Imposta una password per il fondo",
    "withdraw.index.873Ade9A3Bacc2F7": "suggerimento",
    "bill.index.5Bbb2A6Ac68D6B2E": "Conto reale",
    "bill.index.75751E0Eb8261C40": "Conto demo",
    "bill.index.Cbc853B37Ecc2E97": "Tipo di modifica dell'account",
    "bill.index.4A0Cacddeb2E38B1": "Tipo di account",
    "bill.index.F16217028E098B2F": "Valuta",
    "bill.index.A620Dbe6F06B78Ba": "Tutti i tipi",
    "bill.index.2C3C148Bb94D9B3F": "Tutti i conti",
    "c2C.index.Ab45695E4Bf1E382": "Transazione C2C",
    "c2C.index.2702Dc911Dfea762": "voglio comprare",
    "c2C.index.D737D5B8367A53C4": "voglio vendere",
    "c2C.index.C0C540A3Ba9Abe01": "Volume",
    "c2C.index.D8644E8D60C8Bd8A": "prezzo unitario",
    "c2C.index.Ebb8670B203D3604": "limite",
    "c2C.index.F8C6245A2Ebf1D27": "Tutte le carte bancarie",
    "c2C.index.F8C6245A2Ebf1D28": "raccolta valutaria",
    "c2C.index.F8C6245A2Ebf1D29": "carta bancaria",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Acquisto",
    "c2C.index.Cc3Aa8141Da9Ec9F": "vendere",
    "c2C.index.67A39834582Ad99A": "Nessun dato",
    "c2C.order.04C23810B47F77F7": "per importo",
    "c2C.order.6905365902E0A73D": "per quantità",
    "c2C.order.3F0Ecebbd9D2F23B": "Disponibile",
    "c2C.order.16853Bda54120Bf1": "Tutto",
    "c2C.order.Ad3335796F2C95F7": "forfettario",
    "c2C.order.61092E27A6879318": "Per tutelare la sicurezza dei beni, aumenta la tua sensibilità alla prevenzione!",
    "c2C.order.0F51881F0Fb920B2": "Informazioni commerciali",
    "c2C.order.Dd74Ceca85E5Bc65": "Limite temporale del pagamento",
    "c2C.order.De3341388A59073F": "30 minuti",
    "c2C.order.D334B597A352167F": "Soprannome del venditore",
    "c2C.order.D334B597A352167G": "Soprannome dell'acquirente",
    "c2C.order.9D5F89F99Ced890D": "mezzo di transazione",
    "c2C.order.8Adf755B14Bc6B7C": "Si prega di leggere prima quanto segue",
    "c2C.order.686B16F454A7338B": "Non fare alcun commento quando si effettuano bonifici con carta bancaria, altrimenti il ​​denaro non verrà sbloccato e il conto verrà bloccato direttamente. Dopo il pagamento, è necessario fornire un nuovo diagramma dei dettagli della transazione dopo il pagamento (se il diagramma P o i dettagli della transazione nascosti vengono segnalati alla piattaforma per congelare l'account)",
    "c2C.order.9Bec8Fba867B739B": "Scegli il metodo di pagamento",
    "c2C.order.Cf2D892C419Fea36": "acquisto minimo",
    "c2C.order.7290A6B7456A03E0": "acquisto più grande",
    "c2C.orderConfirm.F7E99B8C203E2B07": "dettagli dell'ordine",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "L'ordine è stato generato",
    "c2C.orderConfirm.0F5433503F3845Ce": "Si prega di pagare il venditore entro il tempo del conto alla rovescia.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " In attesa del pagamento dell'acquirente ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "contatta il venditore",
    "c2C.orderConfirm.8E8140D7Ab607B25": "contattare l'acquirente",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "La valuta digitale del venditore è ora depositata nel conto di deposito a garanzia, non esitate a pagare  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Vero nome dell'acquirente:",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "Assistenza clienti 7x24 ore ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "numero d'ordine",
    "c2C.orderConfirm.726C51702F70C486": "tempo di creazione",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Contattare il Servizio Clienti",
    "c2C.orderConfirm.103408Fbbe30287F": "Annulla Ordine",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Ho confermato il pagamento",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Già pagato",
    "c2C.orderConfirm.387Ca3625B260E4E": "Il tuo ordine pagato",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "annullare la transazione",
    "c2C.orderConfirm.76C86566Cc3751C0": "Ordine annullato",
    "c2C.orderConfirm.205B349751D176B4": "pagamento andato a buon fine",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "metodo di pagamento",
    "c2C.paymentAdd.C87E865Df6800304": "Configurazione del metodo di pagamento",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Tipo di metodo di pagamento",
    "c2C.paymentAdd.06910Bddf002695F": "nome vero e proprio",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Account Paypal",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Numero di conto bancario/numero di carta",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Nome della valuta",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Paga il codice QR",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Fare clic per caricare",
    "c2C.paymentAdd.6Daae340F2E9718A": "nome della banca",
    "c2C.paymentAdd.347751415C3Da489": "Nome della catena",
    "c2C.paymentAdd.15B3627Faddccb1D": "Osservazione",
    "c2C.paymentAdd.86Da96Cbd274058F": "Promemoria: quando vendi valuta digitale, il metodo di pagamento scelto verrà visualizzato all'acquirente. Conferma che le informazioni siano inserite correttamente.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "salva",
    "c2C.paymentAdd.90D2A3A5C07179C0": "per favore inserisci il tuo vero nome",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Perfavore Inserisci il tuo",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Inserisci il nome della tua banca",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Inserisci il nome della tua catena",
    "c2C.paymentAdd.94F034F0E01439E0": "Per favore inserisci il tuo indirizzo",
    "c2C.paymentAdd.B12E035663A42B32": "Tutte le carte bancarie",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "raccolta valutaria",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Per favore carica il codice di pagamento",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Aggiunto con successo",
    "c2C.paymentDetail.86Da96Cbd274058F": "Promemoria: quando vendi valuta digitale, il metodo di pagamento scelto verrà visualizzato all'acquirente. Conferma che le informazioni siano inserite correttamente.",
    "c2C.paymentDetail.C4C613Da60226De4": "Modificato con successo",
    "c2C.paymentMethod.7609F7C8337C7A17": "Metodo di pagamento C2C",
    "c2C.records.9E36893D5736Abb4": "Lista degli ordini",
    "c2C.records.4B53F9A5B13B3235": "In attesa di Pagamento",
    "c2C.records.4B53F9A5B13B3236": "annullare la transazione",
    "c2C.records.4B53F9A5B13B3237": "Già pagato",
    "contract.index.9706Df877458F0Be": "Magazzino",
    "contract.index.623Fc7D3D40Aa479": "Margine iniziale",
    "contract.index.F92Dce1169330387": "Prezzo mercato piatto",
    "contract.index.3598B97Dd28Dbf20": "Inserisci la quantità(张)",
    "contract.index.6E1Fae821Dc0B797": "Sostenibile",
    "contract.index.Eb07Eb0C37C14575": "Consegna",
    "contract.index.Eb6492F19B1C1Fb6": "Dettagli storici della transazione",
    "contract.index.873Ade9A3Bacc2F7": "suggerimento",
    "contract.index.Ff63B0F93D39F50B": "Sei sicuro di avere ragione?",
    "contract.index.Ff63B0F93D39F50D": "Vai a lungo",
    "contract.index.Ff63B0F93D39F50E": "corto",
    "contract.index.Ff63B0F93D39F50F": "condotta",
    "contract.index.Ff63B0F93D39F50G": "Chiudere tutte le posizioni?",
    "customer.dialog.3D410C88E8A24Fe8": "Conferma il soprannome",
    "customer.dialog.4B3340F7872B411F": "Annulla",
    "customer.dialog.549B1456Bb5F98B3": "Per favore inserisci il tuo nickname",
    "customer.dialog.B773B3E45Ccfad00": "Il soprannome è vuoto",
    "customer.index.7308C6B849D159E9": "Dettagli dell'immagine",
    "delivery.callDialog.C74F562C3Fe91Fe3": "ordinato con successo",
    "delivery.callDialog.B8C98F42Caa15Ca5": "direzione",
    "delivery.callDialog.B79A581Dae543974": "acquisto lungo",
    "delivery.callDialog.B79A581Dae543975": "acquisto lungo",
    "delivery.callDialog.3B26F0F7E90773F3": "Prezzo di apertura",
    "delivery.callDialog.65Fb252Bd94E1039": "Entrate stimate",
    "delivery.index.B483Ba4Fce633539": "Consegna",
    "delivery.index.224Dd6Cb58D58249": "periodo di scambio",
    "delivery.index.28158F517E902788": "Buy-in minimo",
    "delivery.index.A1232162B14Bec76": "Ordine di consegna",
    "delivery.index.445B1D3176D9C69D": "Ordini storici",
    "delivery.index.Ab6Cb86E28653C5C": "Consegna",
    "delivery.index.20A7A6F14D3698C0": "Orario di apertura",
    "delivery.index.Ec6821A49395Ddc2": "Orario di chiusura",
    "delivery.index.254Fdc665C391743": "Prezzo di chiusura",
    "delivery.index.2A0C5E68B44A472A": "Profitti e perdite",
    "delivery.index.E05B33309F6D236A": "Inserisci la quantità",
    "delivery.index.B2899E073Cb355D9": "Inserisci la quantità",
    "download.description.869467A93C7Dd4A1": "Note di installazione",
    "download.description.C80C1Edd6Bee12Bc": "1.Consenti il ​​download dei file di descrizione",
    "download.description.2F5Bc180F92642B4": "2.File di descrizione scaricato",
    "download.description.3C3B849F6Ccf712F": "3.Inserisci [Impostazioni] sul tuo telefono e il file di descrizione scaricato verrà visualizzato sotto le informazioni sull'account.",
    "download.description.86Affd862985E045": "4.Fare clic per scaricare il file di descrizione",
    "download.description.32832492Bfc1D95E": "5.Fare clic per installare",
    "download.index.245883C80F181C4A": "Fare clic per installare",
    "download.index.Ef3De8Cb2722937A": "Inizia a fare trading sempre e ovunque",
    "download.index.F4A87Eaeed691638": "Rimani aggiornato sulle ultime novità tramite la nostra APP",
    "download.index.Ad3Aba52D0D39D01": "Scarica il pacchetto di installazione",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Consigli gentili",
    "download.safariGuide.2Cc68Aaadf623477": "Si prega di visitare in Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Stai visitando un sito web decentralizzato, esegui l'applicazione Safari",
    "download.safariGuide.06Da016F3E2F79Bd": "copia link",
    "finance.index.B034B367545D5236": "Progetti globali di alta qualità",
    "finance.index.6Ebfac2C0A9228B0": "Oltre 1,6 milioni di utenti attivi, 122,4 miliardi di dollari",
    "finance.index.52Bdcaf19659363D": "Estrazione liquidità",
    "finance.index.D66924272B031Bf5": "Reddito annualizzato molto alto",
    "finance.index.34789D88Afcefa64": "Visualizza elementi",
    "finance.index.E12676B95654D21E": "Prestito credito",
    "finance.index.4C924Cfd89A11Ac7": "Scopri ulteriori opportunità di trading",
    "finance.index.C251D4B594Da2848": "Quantificazone IA",
    "finance.index.4E53Cf2E5F173Dd3": "Leasing con un clic, transazione intelligente basata sull'intelligenza artificiale",
    "finance.records.Cce168301447D1Ba": "in corso",
    "finance.records.4587B6Bd7Cfa4454": "redento",
    "finance.records.18685418E3A50147": "Estrazione",
    "finance.records.Df9Fd438F97Cc908": "cielo",
    "finance.records.E23Cb03C5Ad359E5": "il termine",
    "finance.records.Bd87Dac6Cba7Eba8": "Quantità d'acquisto",
    "finance.records.2D0Fff99265E8B1A": "Redditizio",
    "finance.records.2D0Fff99265E8B1B": "Utile alla scadenza",
    "finance.records.024F5F0B851782De": "Tempo di estrazione:",
    "finance.records.3143A26D3F983896": "Orario del riscatto:",
    "finance.records.96574Aaf8Db85Ead": "rimborso anticipato",
    "finance.records.531E1829203181Ac": "Sei sicuro di voler riscattare anticipatamente?;",
    "finance.records.9205843B8923Dac3": "Riscatto riuscito;",
    "loan.applyCallback.B9258Ec8746A74D8": "Canale di prestito",
    "loan.applyForm.7B8Ed340400A6E2B": "nome",
    "loan.applyForm.549A44Bae725F4A2": "cognome",
    "loan.applyForm.2A27E61375E7Fea8": "data di nascita",
    "loan.applyForm.89925B019D537738": "indirizzo e-mail",
    "loan.applyForm.Afaeb32Cb4D62E28": "Indirizzo di casa (compreso il numero dell'unità)",
    "loan.applyForm.E05B5D049B64B040": "Città",
    "loan.applyForm.B166E4E8Fe9513Fa": "nazione",
    "loan.applyForm.83Aaede109E395F8": "Codice Postale",
    "loan.applyForm.647Ed21332A4968B": "il tuo reddito mensile",
    "loan.applyForm.Cf0A9B845D34D4B4": "Reddito aggiuntivo (facoltativo)",
    "loan.applyForm.7048F618Acc6A54C": "Canone mensile o rata mutuo",
    "loan.applyForm.15C1Bba18Ae18545": "Raccontaci se hai un mutuo",
    "loan.applyForm.3921E90625A84E36": "Se non viene selezionata alcuna garanzia ipotecaria, la probabilità di superare la revisione è bassa.",
    "loan.applyForm.262Eec4F8906B0F6": "C'è un mutuo?",
    "loan.applyForm.8634Eb21B6910F21": "Seleziona il tipo di mutuo",
    "loan.applyForm.A4897B3A331B5E61": "Voucher di transazione",
    "loan.applyForm.6623384C683De457": "Teniamoci in contatto",
    "loan.applyForm.A8360Bdba86834Cb": "Potremmo contattarti a questo numero se abbiamo bisogno di ulteriori informazioni sulla tua richiesta di prestito",
    "loan.applyForm.C1C373F385A4C7B3": "numero di telefono",
    "loan.applyForm.1B9785Badb91191F": "Dopo aver inserito le informazioni, attendere la valutazione e la revisione del sistema.",
    "loan.applyForm.8755B9C761D4080C": "Ho letto e firmato elettronicamente il mio consenso",
    "loan.applyForm.3E987B787468148C": "Condizioni d'uso e consenso elettronico",
    "loan.applyForm.Bc54E76B61C50Fb9": "Si prega di leggere e accettare l'accordo",
    "loan.applyForm.89E158384F634Eb8": "Ritorna al passaggio precedente",
    "loan.applyForm.Efa5A4548E2F3510": "Indirizzo di casa",
    "loan.applyForm.4Cadf1787541B35D": "SÌ,",
    "loan.applyForm.Fab408D2F4100447": "NO,",
    "loan.applyForm.3Bad7A307480Cc56": "Il formato dell'e-mail è sbagliato;",
    "loan.applyForm.306322F49B36855D": "La richiesta è stata inviata, attendere la revisione del servizio clienti",
    "loan.applyForm.3C6E0E858C995Ff9": "Si prega di caricare il voucher della transazione",
    "loan.applyForm.2Bf443476B271376": "È possibile caricare fino a 4 immagini",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Canale di prestito",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Ottieni il limite del prestito",
    "loan.applyQuota.9E0780F9352C7273": "saldo del portafoglio",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Importo totale del prestito disponibile",
    "loan.applyQuota.033A05462195B080": "Importo preso in prestito",
    "loan.applyQuota.9Aa89B036Ef4821D": "equilibrio restante",
    "loan.applyQuota.5656Fb52Ef17A557": "Ottieni il limite del prestito",
    "loan.applyQuota.6Ff6702B4C917D40": "in corso di revisione",
    "loan.applyQuota.6Ff6702B4C917D41": "respinto",
    "loan.applyQuota.6Ff6702B4C917D42": "successo",
    "loan.applyQuota.0Bad0E857Ea4A952": "tempo di applicazione",
    "loan.applyQuota.3E7E56E2De2Cc546": "Importo della domanda",
    "loan.applyQuota.8F1D170D9E54Af5B": "Avanzamento dell'applicazione",
    "loan.applyQuota.6167Ca035B93Caf3": "motivo",
    "loan.applyQuota.410C7954F9A3981D": "Riapplicare",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Seleziona la valuta",
    "loan.index.4C10307B22E885C3": "Scopri più opportunità di trading e crea ricchezza",
    "loan.index.Ae8D37Dea2B3717B": "tasso d'interesse",
    "loan.index.1Bb54625682E85Db": "Introduzione alla regola",
    "loan.index.5Bd31Fab681C6E9D": "Vedi altro",
    "loan.index.111F95F404814327": "Valuta del prestito",
    "loan.index.6Bc2723B3Bec106D": "prestito",
    "loan.index.3200106280E6E370": "tasso d'interesse",
    "loan.index.049A6Ccb6C81A635": "Importo del prestito (minimo/massimo)",
    "loan.productDetails.5037Ad86Ab4B7E02": "prestito",
    "loan.productDetails.37181C74559Db182": "Ordine ",
    "loan.productDetails.5802Aaf06A1E4755": "Liquidità",
    "loan.productDetails.89Aea9Cf9337C40F": "Quantità disponibile per il prestito",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Quantità da rimborsare",
    "loan.productDetails.C65F329Ef14C4A3D": "Importo delle monete prese in prestito",
    "loan.productDetails.3A74667815Adc8Bf": "massimo",
    "loan.productDetails.Ab851D8351573227": "cielo",
    "loan.productDetails.Ddf82D8A2C8F46A0": "ciclo di rimborso",
    "loan.productDetails.C105E5562005Aeb1": "cielo",
    "loan.productDetails.3F346Be2137F0756": "Importo del prestito (minimo/massimo)",
    "loan.productDetails.2707F78Df864E194": "Interesse",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Si prega di rimborsare in tempo.Se è scaduto, verrà addebitato un importo giornaliero aggiuntivo di (capitale + interessi).",
    "loan.productDetails.B9Dc9B81E726Bdf2": "%per penalità per ritardi",
    "loan.productDetails.4D94Faa033Be7356": "Ho accettato",
    "loan.productDetails.62429A957A22780A": "Contratto di servizio di prestito di monete",
    "loan.productDetails.Bc54E76B61C50Fb9": "Si prega di leggere e accettare l'accordo",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Prendi in prestito adesso",
    "loan.productDetails.59246A0Bf081D398": "meno",
    "loan.productDetails.Ec3F1172B71Cd36C": "quantità minima",
    "loan.productDetails.7Ccc4Cf1D81930B3": "importo maggiore",
    "loan.productDetails.071488598990Aa68": "Si prega di leggere e accettare l'accordo;",
    "loan.productDetails.579Dd216D3339119": "Prestito andato a buon fine;",
    "loan.records.4951B36Fd0D2A831": "Prestito e storia creditizia",
    "loan.records.Bb0Aaa30514B7Cba": "Record attuale",
    "loan.records.0F8B0A7D80769051": "Storia rimborso",
    "loan.records.D4B42E43D88De6F1": "Registro storia",
    "loan.records.Cb1847D6281897E6": "Ammontare del prestito",
    "loan.records.C7Bfcacfc2Adea58": "Importo del rimborso",
    "loan.records.8B369164D5Fea340": "Tempo di prestito:",
    "loan.records.Ab2402Ad3Ffd35E7": "Rimborso anticipato",
    "loan.records.B7A0Ca0C195D0Cd1": "Tempo di rilascio:",
    "loan.records.42014Bdcfd9C411B": "Tempo di rimborso:",
    "loan.records.A0Acfaee54F06458": "completato",
    "loan.records.67A39834582Ad99A": "Nessun dato",
    "loan.records.6A687Ff5B8F1598F": "Sei sicuro di voler rimborsare in anticipo?",
    "loan.records.7C20D7008D0Eae86": "Rimborso riuscito",
    "mining.bottomSheet.Ba91637D32Ccba04": "Estrazione liquidità",
    "mining.bottomSheet.7De3D636A165E008": "tasso di rendimento",
    "mining.bottomSheet.F3003345B189D683": "Periodo minerario",
    "mining.bottomSheet.3E4C0A8C533F5848": "tipo di moneta",
    "mining.bottomSheet.Adcba368993Ae65E": "Importo dell'abbonamento (minimo/massimo)",
    "mining.bottomSheet.94743B24E4Ed4166": "Illimitato",
    "mining.bottomSheet.286944Fedd211257": "Fondi disponibili",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Entrate stimate:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Il mio adesso",
    "mining.bottomSheet.Bb1493C567C114Dd": "Consigli gentili:",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Commissione di rimborso anticipato",
    "mining.index.8B2Bba1422Eed6F0": "Creare decine di miliardi di ricchezza, reddito netto al 100%.",
    "mining.index.42997E300086Ee89": "rendimento più alto",
    "mining.index.510F74F42052Ecce": "Prodotti minerari",
    "mining.index.B853F96626106912": "Vai in miniera",
    "mining.productList.37181C74559Db182": "Ordine",
    "mining.productList.Aac5B810004C46C8": "regolare",
    "mining.productList.D2Fb4F78C387D888": "Hai estratto con successo",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Confermare l'ordine",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Importo del noleggio",
    "quantify.bottomSheet.Fbd931286Be387Aa": "ciclo",
    "quantify.bottomSheet.9Ad84Db481807844": "indefinitamente",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Tasso di rendimento giornaliero",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "data di valuta",
    "quantify.bottomSheet.88008603Ba7Bde64": "Acquisto riuscito",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Ottieni la tua quota di compartecipazione alle entrate a partire da oggi!",
    "quantify.bottomSheet.C778A674C4A054F9": "controllare l'ordine",
    "quantify.bottomSheet.685Dad322B7029B0": "Transazione di reso",
    "quantify.buyForm.Acf8560A04334E95": "prezzo d'acquisto",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Panoramica",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "Tempo di pagamento dei dividendi",
    "quantify.buyForm.Fcda03Adbc5Fca97": "ogni giorno",
    "quantify.buyForm.Afb89504Fc399189": "Inserisci la quantità di acquisto",
    "quantify.buyForm.1435Afdfb4Ec0596": "Quantità minima di acquisto",
    "quantify.buyForm.1B97F1730Bacd839": "Quantità massima di acquisto",
    "quantify.index.32F56C9B7D0E250A": "Periodo: indeterminato",
    "quantify.index.E34F028D00904630": "prezzo",
    "quantify.index.E34F028D00904631": ",Tasso di rendimento giornaliero",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Importo del deposito in garanzia",
    "quantify.productDetail.53635B53Bca31Dfa": "Reddito giornaliero stimato",
    "quantify.productDetail.F9E46449A6269E09": "Reddito cumulativo",
    "quantify.productDetail.98Fc46B1B8C993B2": "Ordina l'hosting",
    "quantify.productDetail.93624Bb045830966": "Noleggia adesso",
    "quantify.records.0C16A18C32B9Cf4A": "Registro dell'ordine",
    "quantify.records.B191F5614B0E2312": "Cronologia acquisti",
    "quantify.records.6A06033B86A73F7C": "Registro riscatto",
    "quantify.records.45E0F7E71611618E": "Reddito cumulativo",
    "quantify.records.45E0F7E71611618F": "Utile alla scadenza",
    "quantify.records.Aae1201E38C4Baaa": "Corsa",
    "flash.index.C41Fd4F2Fdfa594B": "consumare",
    "flash.index.1Ac59Fa2218E3B00": "Disponibile:",
    "flash.index.Bf7233A660821549": "Ottenere",
    "flash.index.548E775Dd67C7472": "Conferma lo scambio",
    "flash.index.066A2449Bd616F03": "Inserisci la quantità di rimborso",
    "flash.index.Fca26Fcf9C06563F": "Redenzione Flash riuscita",
    "message.index.40031524233F4564": "Centro messaggi",
    "message.index.8E5586210Fd44D69": "Promemoria di posizione perpetuo",
    "message.index.Bcd3F470E88C197D": "Nessuna notifica ancora",
    "message.index.23A557Fbce545879": "Avviso contratto perpetuo",
    "message.index.B5E19B36B5125550": "Promemoria di ritiro",
    "message.index.716A981465138334": "Promemoria di ricarica",
    "message.index.884474Ebff2977Ba": "informazioni sul sistema",
    "message.index.08Cc7D24752Ec42C": "Servizio Online",
    "message.index.1D820A6A19Ab025D": "Servizio manuale online 7 X 24 ore",
    "more.index.29881E0B5Df58Bf7": "Più funzionalità",
    "more.index.C77Ee35911D10D9D": "Funzioni comuni",
    "more.index.3558F69B0260Be61": "Funzione commerciale",
    "more.index.7243Fe610Ed98Cec": "conto reale",
    "more.index.7243Fe610Ed98Ced": "Conto demo",
    "more.index.693C7B35Bdf3E3A3": "finanza",
    "more.index.F855997Fac114554": "Centro assistenza",
    "more.index.Ae9A1252278Da742": "aiuto",
    "more.index.Ace012504Bc529E0": "informazione",
    "more.index.05682Dbef5Eeb0A8": "assistenza clienti",
    "more.index.C7Af227A5D5C7Bb9": "Sei passato a un conto live",
    "more.index.D818A65592694786": "Sei passato a un conto demo",
    "notice.details.B360807Dbe727E6E": "chi siamo",
    "notice.details.92F2552F695E70E3": "chi siamo",
    "notice.details.7Aaceaf69B67F3D1": "Introduzione alla regola",
    "notice.index.6128438Be34Bcb9F": "Avviso perpetuo",
    "notice.index.71627F5Eb7128261": "Notifica di ritiro",
    "notice.index.2Dce570Fda841705": "Notifica di ricarica",
    "notice.index.228A7C9C6448A9B1": "notifica del sistema",
    "setting.dialog.8B4F5B74660Deabe": "Configurazione del colore",
    "setting.dialog.Bfc7D25486Ebc1A9": "Il verde sale e il rosso scende",
    "setting.dialog.796Ea0E1A82C320B": "Il rosso sale e il verde scende",
    "setting.index.8Ddc5864E1739466": "Preferenze",
    "setting.index.F5205801D57D6727": "lingua",
    "setting.index.2E2C12Dd0Cc1B63B": "Valuta della lingua",
    "setting.index.322D8496Aec3D918": "numero della versione",
    "setting.index.Aa7E6C204C751Cfa": "disconnessione",
    "share.index.75Bc38Bbf7D2E766": "invita gli amici",
    "share.index.45575Eb5D42213E6": "il mio codice invito",
    "share.index.29F67F0644A78Bf3": "copia",
    "share.index.19Cc65Bb6Bb98Fe7": "Il mio link di invito",
    "share.index.5372712F162Fb78B": "Numero di subordinati diretti",
    "share.index.C88D6B95E3Ab7D6F": "Numero totale di subordinati",
    "share.index.Bb5Fa5471Aeecc62": "Sconto provvigionale per i subordinati diretti",
    "share.index.426B6Bac9A5F3D4F": "sconto totale",
    "share.index.197E4A428F0401C9": "Visualizza i record degli sconti",
    "share.records.88A6B2E2B74B13C4": "Registro della commissione",
    "share.records.B671A35Ffdc93B89": "Sconto",
    "transfer.index.B17617A869C03Ca5": "portafoglio",
    "transfer.index.E4116Dcc6F6C7270": "Trasferimento riuscito",
    "transfer.index.48Fb603C6638C7F6": "Imposta una password per il fondo",
    "transfer.records.Eb0E39Eb5911D418": "Ottenere",
    "transfer.records.977554Be0Aa65052": "Tempo di riscatto Flash:",
    "transfer.records.9Aa33884Aa400B7C": "Tempo di trasferimento:",
    "form1.forget.76636Db8Fae5B124": "la tua casella di posta",
    "form1.forget.35354B9Cca1Bb0E6": "Codice di verifica e-mail",
    "form1.forget.604A0B11E916F387": "parola d'ordine",
    "form1.forget.9Cb1C9C51E7503D6": "Conferma password",
    "form1.forget.3A4F7E04B008453C": "Il tuo numero di telefono",
    "form1.forget.Cf11Dce5B4713B5A": "Codice di verifica del telefono cellulare",
    "form1.forget.3012Edba57D4F297": "Per favore inserisci la tua email",
    "form1.forget.F1Ee1D97E869E447": "inserisci il codice di verifica",
    "form1.forget.3A5C4C9Ae68Fa0F6": "per favore inserisci LA TUA password",
    "form1.forget.C6Cb174Da411D5F7": "Inserisci il tuo cellulare",
    "form1.forget.947B3B21Ee45E582": "Inviare",
    "form1.forget.3E0580F1E8630Df6": "Due password non sono coerenti",
    "form1.forget.C5A61836B8Cc74C6": "La modifica della password è stata reindirizzata con successo...",
    "form1.forget.B96D072Bc8962565": "Invia nuovamente",
    "form1.login.2B72C27Ff828Cfc4": "la tua password",
    "form1.login.D7C6B872Af9Cb17F": "ricorda la mia password",
    "form1.login.0Fff44C827A4F3B6": "Login",
    "form1.login.1B7F2C1Ede3990Cc": "Nessun conto? Registrati ora",
    "form1.login.F657F7Be1Cc33Bd2": "dimenticare la password",
    "form1.login.2074A1Eec4B088B0": "Accesso al portafoglio",
    "form1.login.1B8938Fbcc3C9B56": "Accesso riuscito e reindirizzamento...",
    "form1.login.07162A7Bb9310555": "Installa il portafoglio ERC20",
    "form1.login.766969966Ae151Dc": "Installa il portafoglio TRC20",
    "form1.login.B88171E1Ad4C50Bf": "Hai rifiutato di cambiare rete",
    "form1.register.Aaef0710Cb4Db554": "Immettere nuovamente la password",
    "form1.register.54Dfea0415C708Ff": "Codice invito",
    "form1.register.6839940Dac08C794": "Registrati",
    "form1.register.9901A3B5550Aa58E": "Hai già un account? Login",
    "form1.register.2548Ca53785252Ea": "Inserisci il tuo codice di invito",
    "form1.register.Dd71Fd65900Dd03E": "Si prega di leggere il contratto di servizio di registrazione e di accettarlo,",
    "form1.register.7975613909490B77": "Registrazione riuscita e reindirizzamento...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Scegli il portafoglio",
    "userc.certification.2Af9511640153D2E": "Certificazione Juniores",
    "userc.certification.177B191D8E0Fa84D": "non certificato",
    "userc.certification.601218D4D572E98A": "verificato",
    "userc.certification.6E37C51Ba9012Fc8": "Non è passato",
    "userc.certification.1B9B563E05C96474": "Certificazione avanzata",
    "userc.certification.1418B83803Ab56B4": "Si prega di completare prima la certificazione primaria",
    "userc.creditScore.0B149112601Acc4D": "punteggio di credito",
    "userc.creditScore.88Ac7F84E106C5F6": "Punteggio totale",
    "userc.creditScore.3E1D6E0E7F1E6155": "Valutazione del sistema",
    "userc.index.62Eac58D04Bbca0F": "Centro personale",
    "userc.index.F4Cac53F93Cb2Aff": "negoziazione simulata",
    "userc.index.42F409Fdf3559773": "Centro di sicurezza",
    "userc.index.1B8E211009807Db8": "Autenticazione",
    "userc.index.Bdfcf21Eb8F2Eb70": "Record di modifica dell'account",
    "userc.index.6C18D67F6D19586B": "Introduzione alla piattaforma",
    "userc.index.807A814Ed58A93Bc": "svuota la cache",
    "userc.index.E43Daad59759611C": "non certificato",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Cambia password di accesso",
    "userc.secureCenter.1F37Ba427Debf24A": "Vincolo del conto",
    "userc.secureCenter.F9A5Ffa750664004": "Associa Google Authenticator",
    "userb.certificationForm.7685Af7D30043Cd6": "Certificazione Juniores",
    "userb.certificationForm.7685Af7D30043Cd7": "Certificazione avanzata",
    "userb.certificationForm.70E37C35Abc3F462": "Paese",
    "userb.certificationForm.F92C25A68E4D08C5": "Città",
    "userb.certificationForm.0Ccc6387B38E0318": "Il tuo nome",
    "userb.certificationForm.94C67Bba370Fdd46": "numero di licenza",
    "userb.certificationForm.B2D4C7Fffe79258E": "Posta",
    "userb.certificationForm.57554268761Fb47A": "Carica la parte anteriore del tuo documento d'identità",
    "userb.certificationForm.D79E90201F5319Bd": "Carica il retro del tuo documento d'identità",
    "userb.certificationForm.9C6Bad08Af29E20E": "Carica una foto della tua carta d'identità",
    "userb.certificationForm.F96B62Dfa31Cde45": "Conferma invio",
    "userb.certificationForm.2125D78Ea8C6D287": "Requisiti di caricamento",
    "userb.certificationForm.0D64864D3076A824": "Visualizza esempio",
    "userb.certificationForm.35287Deb72281E87": "1.Deve essere in grado di leggere chiaramente le informazioni della carta d'identità.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.Il contenuto della foto è vero e valido e non è consentita alcuna modifica.",
    "userb.certificationForm.25A90660554319F9": "3.Il numero ID e il nome devono essere chiaramente visibili e supportare JPG/JPEG/PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "prego entra",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Invio riuscito, attendi la revisione",
    "userb.certificationForm.744Bd07D8Abd2513": "Carica la parte anteriore del tuo documento d'identità",
    "userb.certificationForm.6De1C319130F7Fef": "Carica il retro del tuo documento d'identità",
    "userb.certificationForm.79F8E8B172E30C8A": "Per favore carica una foto della tua carta d'identità",
    "userb.certificationForm.46412E16D28A4753": "Caricamento foto non riuscito",
    "userb.changeDialog.676B0Adca1F8Eea4": "cambiare la password",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "numero di telefono",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "interruttore del validatore",
    "userb.modifyInfoDialog.62149E566814154C": "Seleziona validatore",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Apri l'app Autenticazione e fai clic su+",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": `Seleziona "Scansione codice QR" o "Chiave Invio".'`,
    "userb.modifyInfoDialog.93F094E2B4369472": "Scansiona il codice QR o inserisci la chiave",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "chiuso aperto",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Vecchia password del fondo",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Nuova password del fondo",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Conferma la nuova password del fondo",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Conferma la password del fondo",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Inserisci la vecchia password del fondo",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Inserisci la nuova password del fondo",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Inserisci la password del tuo fondo",
    "userb.modifyInfoDialog.258A534498Fe984F": "Conferma la password del tuo fondo",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Vincolo del conto",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Password del fondo",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "Confermare",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Il validatore è chiuso",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "La verifica di Google è stata attivata correttamente",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Password del fondo modificata correttamente",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Imposta la password del fondo correttamente",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Associazione riuscita",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Errore nel codice di verifica",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Esempio di caricamento del certificato",
    "userb.veBottomSheet.6F38A0D5858633Ee": "sfondo scuro e pulito",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Illuminazione sufficiente",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Telaio ausiliario di allineamento",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Le foto sono chiare",
    "userb.veBottomSheet.50B741382E71F103": "Possibili ragioni del fallimento",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "La copertura protettiva della carta d'identità non è stata rimossa",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "Lo sfondo è affetto da disordine",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "La carta d'identità è riflettente",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "La luce è troppo scura/ostruita dall'ombra del telefono cellulare",
    "userb.veBottomSheet.1A75D2Fc2614592B": "in possesso di carta d'identità",
    "home.home.C3525Eb110Db58Aa": "Di più",
    "home.home.29761532Fc8Ebecc": "Commercio",
    "home.home.F15Ea99Bfa6E1848": "Certificazione di sicurezza, acquista con fiducia",
    "home.home.46E1Ab39Bae7182A": "Presto",
    "home.home.Fde2Eb7F6F3Dd800": "Transazioni veloci e funzionamento semplice",
    "home.home.Ea4756Bc1642E0F1": "nome",
    "home.home.B6B1127Ede1C97B4": "ultimo prezzo",
    "home.home.3587Ad870Eb0Ab86": "24H salita discesa",
    "home.home.Cb1A4E14Ec2Cd2B5": "Scoprire",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "Metallo",
    "home.home.5Fc1474Dbf74Ac1A": "Agricoltura",
    "home.home.135318F4664F7773": "energia",
    "kline.kLine.C96Ff250Cf6B8B84": "24H Più alta",
    "kline.kLine.6E8Be64F19D74565": "24H Volume",
    "kline.kLine.08A4Bfbe26A0F262": "24H minimo",
    "kline.kLine.8701Deb075066331": "24H Volume (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 punto",
    "kline.kLine.B49Cca240E013124": "5 punti",
    "kline.kLine.F27A90Be97Ba3D6C": "15 punti",
    "kline.kLine.Ff3E62395B694384": "30 punti",
    "kline.kLine.C1993F9C6Db1A674": "1 ora",
    "kline.kLine.Fd2Cbbae405E284C": "1 giorno",
    "kline.kLine.C9Cd71904395042C": "Gennaio",
    "kline.kLine.7D8127Ec18C7B8C7": "Acquisto",
    "kline.kLine.E3D3Ffdb9B3E5636": "Quantità (pezzi)",
    "kline.kLine.6834Fec1838D8029": "Prezzo (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "vendere",
    "kline.kLine.B9Bea1A9A88D1419": "Vai a lungo",
    "kline.kLine.D36Cfa96Ee2B343B": "Corto",
    "other.languageRateSelect.6897784800834C1B": "impostazione della lingua",
    "other.languageRateSelect.6897784800834C1C": "Seleziona la valuta dei prezzi",
    "other.navigationBar.F6E62F9642018Cbb": "Prima",
    "other.navigationBar.0Ec227161A47B49D": "Citazioni",
    "other.navigationBar.29761532Fc8Ebecc": "Commercio",
    "other.navigationBar.693C7B35Bdf3E3A3": "finanza",
    "other.navigationBar.9734C54620Eb09Af": "risorse",

    "home.index.25Aa4Fc745150030": "Re delle criptovalute",
    "home.index.2D6Ecf8Faaa8B124": "Più veloce, migliore, più forte",
    "home.index.1Db26267D1041223": "Scambia facilmente oltre 1.700 criptovalute",
    "home.index.F8E18C90C3E35618": "Iscriviti ora",
    "home.index.29761532Fc8Ebecc": "Commercio",
    "home.index.D8A95E4506487452": "Contratti popolari",
    "home.index.5Bd31Fab681C6E9D": "Vedi altro",
    "home.index.010Ae07A09Caf221": "coppia commerciale",
    "home.index.F4D875118E6D6Dbb": "Ultimo prezzo",
    "home.index.3Dfdfb3D6599100E": "24H su giù",
    "home.index.28Fb39Bd41E54242": "tendenza",
    "home.index.B47A3911Bacd94C6": "Elenco dei vincitori",
    "home.index.712910D574F43D95": "Reddito stabile",
    "home.index.5507Fd830D7Bf51F": "Le migliori strategie, facili da copiare",
    "home.index.7De3D636A165E008": "tasso di rendimento",
    "home.index.B853F96626106912": "Vai in miniera",
    "home.index.213B5C96Be7Cd1F8": "giorni, tasso di interesse annualizzato",
    "home.index.213B5C96Be7Cd1F9": "Massimo acquistabile",
    "home.index.Dc710Cffe6313Bb5": "controlla i dettagli",
    "home.index.B92Ffc837Ecaa1Dd": "notizia",
    "home.index.6C18D67F6D19586B": "Introduzione alla piattaforma",
    "home.index.6342E3C33Cb48Cd5": "La valuta digitale è un mezzo di scambio che utilizza principi crittografici per garantire la sicurezza delle transazioni. A differenza delle valute fiat come il dollaro USA, le valute digitali non hanno una forma fisica. Le valute digitali più popolari come Bitcoin (BTC), Ethereum (ETH) e Polkadot (DOT) utilizzano la blockchain come tecnologia sottostante per fungere da registro digitale decentralizzato. Tutte le transazioni in valuta digitale sono registrate nella blockchain e non possono essere modificate una volta confermate e verificate. A differenza delle valute tradizionali, dove le banche mantengono registri centralizzati, le transazioni in valuta digitale avvengono su blockchain pubbliche a cui chiunque può accedere.",
    "home.index.198Efc4254683Eef": "Inoltre, secondo il meccanismo di consenso, tutti possono verificare le transazioni in valuta digitale e aggiungerle alla blockchain, ottenendo così la decentralizzazione. Poiché aziende leader a livello mondiale come Samsung, BlackRock, Morgan Stanley e Alphabet hanno investito, le persone credono che la tecnologia blockchain cambierà completamente il sistema finanziario globale. La valuta digitale ti apre le porte al mondo della finanza decentralizzata, permettendoti di esplorare le infinite possibilità offerte dalla tecnologia all'avanguardia.",
    "home.index.E710339D9576292E": "Il nostro vantaggio",
    "home.index.F9Ce8B2C63885B26": "sicuro e stabile",
    "home.index.C4D0A2C9Ff0305Ce": "Un team tecnico di altissimo livello, una protezione di sicurezza completa, ricerca e sviluppo indipendenti di un motore di sintesi delle transazioni ad alta velocità, possono ancora funzionare in modo stabile e affidabile con massicce transazioni simultanee.",
    "home.index.2E8708Ee3F11Cc1A": "Professionale e affidabile",
    "home.index.Bb12Cbcf89C56C5C": "Un team operativo professionale, con molti anni di esperienza nel campo della blockchain e della finanza, è titolare di una licenza di trading di asset digitali conforme e ha una garanzia di riserva del 100%.",
    "home.index.Aa4723Bfbf6808F4": "Servire con cuore",
    "home.index.1Bf0204B7D3Ee230": "Affrontare il mercato globale, supportare più lingue, funzionare 24 ore su 24, 7 giorni su 7, forte supporto della comunità e servizio clienti professionale.",
    "home.index.2E7Dfd53D26F7224": "alte prestazioni",
    "home.index.1C65453103B5Fcc3": "300.000 transazioni al secondo e tempo di risposta agli ordini inferiore a 1 millisecondo.",
    "home.index.85Ac49Ece9Dcc80B": "Fai trading sempre e ovunque!",
    "home.index.2799E18842E4Ebe9": "Apri una posizione istantaneamente, supportata sia dall'app OKEX che dalla pagina web!",
    "home.index.2Bf52Ce75E29Fc88": "Scansiona il codice per scaricarlo subito",
    "home.index.88D1422B33571066": "Androide",
    "home.index.04Ee893775563F21": "Versione Apple",
    "home.index.04Ee893775563F22": "servizio del prodotto",
    "home.index.04Ee893775563F23": "gestione finanziaria",
    "home.index.04Ee893775563F24": "ritorno",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "Acquista Bitcoin, Ethereum e",
	"homepage.text1_2": "oltre 1000 criptovalute",
	"homepage.text2": "Il tuo viaggio nell'esperienza delle criptovalute",
	"homepage.text4": "Gestione finanziaria ad alto tasso di interesse",
	"homepage.text5": "Gestione finanziaria stabile, alto rendimento annuo",
	"homepage.text6": "Condivisione delle ricompense",
	"homepage.text7": "Transazioni veloci, operazioni semplici",
	"homepage.text8": "Scopri di più",
	"homepage.text9": "Scambio istantaneo",
	"homepage.text10": "Certificazioni di sicurezza, acquisti sicuri",
	"homepage.text11": "Team tecnologico di punta, sicurezza completa",
	"homepage.text12": "Protezione, indipendenza",
	"homepage.text13": "Professionale e affidabile",
	"homepage.text14": "Team di gestione professionale con anni di esperienza in blockchain e finanza",
	"homepage.text15": "Servizio dedicato",
	"homepage.text16": "Orientato al mercato globale, supporta molteplici lingue, operativo 24 ore su 24",
	"homepage.text17": "Alta performance",
	"homepage.text18": "Elaborazione fino a 300.000 transazioni al secondo, tempo di risposta dell'ordine inferiore a 1 millisecondo",
    "passwod.limit":"La lunghezza della password deve essere maggiore o uguale a 6 caratteri"
}
