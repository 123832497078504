import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Supaprastinta kinų kalba",
        dialogDetermine: "Žinoma",
        dreivatives: "Pristatymo sutartis",
        markets: "Citatos",
        assets: "turto",
        liquidity: "Likvidumo kasyba",
        helpCentre: "Pagalbos centras",
        announcementCenter: "Skelbimų centras",
        about: "apie",
        bulletinCentre: "Skelbimų centras",
        serviceAgreement: "Paslaugų sutartis",
        support: "parama",
        onlineSupportCustomerService: "internetinė paslauga",
        suggestionsAndFeedback: "Pasiūlymai ir atsiliepimai",
        service: "Tarnauti",
        download: "parsisiųsti",
        coinApplication: "Monetų sąrašo programa",
        privacyStatement: "Privatumo pareiškimas",
        trade: "sutartinė prekyba",
        standardConteact: "Tvarus",
        signOut: "Atsijungti",
        assetWallets: "Turto piniginė",
        financeRecords: "Paskyros keitimo įrašai",
        identification: "Autentifikavimas",
        personalCenter: "Asmeninis centras",
        chooseLanguage: "Pasirinkite kalbą",
        tips: "užuomina",
        dark: "tamsus režimas",
        light: "dienos režimas",
        languageTitle: "kalba",
        convertCurrency: "Konvertuoti valiutą",
        cancel: "Atšaukti",
        aboutUs: "apie mus",
        standard: "Standarto tęstinumas",
        share: "Dalintis",
        introduce: "Platformos įvadas",
        set: "nustatyti",
        changePassword: "Keisti prisijungimo slaptažodį",
        denomination: "Citatos valiuta",
        color: "Spalvų konfigūracija",
        code: "versijos numeris",
        color1: "Raudona pakyla ir žalia krinta",
        color2: "Žalia pakyla, o raudona krinta",
        setLangue: "kalbos nustatymas",
        vip: "VIP privalumai",
        vip2: "Dabartinis VIP lygis",
        safeMenu: "Apsaugos centras",
        conventional: "sutartinis",
        other: "kitas",
        quickTips: "Pridėkite iki 3 sparčiųjų funkcijų mygtukų",
        quickTransaction: "Greitas sandoris",
        safeConvenient: "Saugu ir patogu",
        timeSharing: "laiko pasidalijimas",
        currentLevel: "Dabartinis lygis",
        levelBenefits: "%{level}pajamų lygio",
        teamLevel: "Komandos lygis",
        levelLower: "%{level}pavaldinys",
        rules: "Taisyklės aprašymas",
        bindWithdrawalAddress: "Įrišimo išėmimo adresas",
        tipsBindWithdrawalAddress: "Jūs dar nesusiejote savo atsiėmimo adreso, pirmiausia susiekite jį",
        walletAddressFormatIncorrect: "Piniginės adreso formatas netinkamas",
        tradeRules1: "Numatykite kilimą ir nuosmukį ateityje. Kaina užsakymo metu naudojama kaip pradinė kaina, o kaina paskutinę prognozavimo laikotarpio sekundę – atsiskaitymo kaina kaina yra mažesnė už pradinę kainą, tai reiškia mažėjimą.",
        tradeRules2: "1.Kas yra sutartis?",
        tradeRules3: "Ši platforma teikia USDT pagrindu sudarytas neterminuotas sutartis. Neterminuotos sutartys neturi pristatymo datos ir vartotojai jas gali turėti amžinai.",
        tradeRules4: "2.Kas yra marža",
        tradeRules5: "Virtualioje sutarčių rinkoje vartotojams tereikia sumokėti nedidelę pinigų sumą tam tikra proporcija pagal sutarties kainą kaip finansinę sutarties įvykdymo garantiją, o tada jie gali dalyvauti perkant-parduodant sutartį. Tokios lėšos yra virtualus sutarties indėlis. \nSutartyse šioje platformoje taikomas atskirų sąskaitų maržos modelis. Kiekviena valiuta atitinka sutarties sąskaitą. Skirtingų sutarties valiutų sąskaitos turtas ir pozicijos yra nepriklausomi vienas nuo kito, o pervedimai ir operacijos tarp skirtingų sutarties sąskaitų neturi įtakos. Atidarius vartotojo sutarties sąskaitą, visų sutarties sąskaitoje esančių pozicijų rizika ir nauda bus skaičiuojami kartu.",
        tradeRules6: "3. Kaip apskaičiuoti maržą",
        tradeRules7: "Pozicijos marža = sutarties nominali vertė * nebaigtų sutarčių skaičius / sverto daugiklis\nužšaldyta marža = visų sėkmingai pateiktų pavedimų ir atidaromų pavedimų maržos suma dabartiniame pavedime\neprieinama marža = sąskaitos likutis - pozicijos marža - įšaldyta marža",
        tradeRules8: "4. Kaip apskaičiuoti maržos normą",
        tradeRules9: "Maržos norma yra vartotojo turto rizikos matas. \nMaržos norma = (nerealizuotas pelnas ir nuostolis + pozicijos marža + turima marža + įšaldymo marža - likvidavimo mokestis) / pozicijos marža \nKuo mažesnė maržos norma, tuo didesnė sąskaitos rizika. Kai maržos norma yra mažesnė arba lygi 0%, bus pradėtas priverstinis likvidavimas.",
        tradeRules10: "5. Kokia numatoma priverstinio likvidavimo kaina?",
        tradeRules11: "Numatoma priverstinio likvidavimo kaina, ty kaina, apskaičiuota sistemos, kuri teoriškai sukelia priverstinį likvidavimą, yra tik orientacinė ir gali šiek tiek nukrypti nuo tikrosios vertės.",
        tradeRules12: "6. Koks yra bendras sąskaitos nuosavas kapitalas ir nerealizuotas pelnas ir nuostolis?",
        tradeRules13: "Visas sąskaitos nuosavas kapitalas = sąskaitos likutis + nerealizuotas pelnas ir nuostolis\nNerealizuotas pelnas ir nuostolis yra šiuo metu vartotojo turimos pozicijos pagal valiutos sutartį pelnas ir nuostolis Nerealizuotas pelnas ir nuostolis pasikeis su paskutiniais sandorio kainos pasikeitimais. \nNerealizuotas ilgųjų pozicijų pelnas ir nuostolis = (1/vidutinė pozicijos kaina – 1/paskutinio sandorio kaina) * ilgųjų pozicijų sutarčių skaičius * sutarties nominali vertė * paskutinė sandorio kaina \n trumpųjų pozicijų nerealizuotas pelnas ir nuostolis = (1/ paskutinė sandorio kaina - 1/ Vidutinė pozicijos kaina) * Trumpųjų sutarčių skaičius * Sutarties nominali vertė * Paskutinė sandorio kaina",
        text1: "sąskaitos saugumas",
        text2: "dienos ir nakties režimas",
        text3: "Išvalyti talpyklą",
        text4: "Ar tikrai norite atsijungti?",
        text5: "Ar tikrai norite išvalyti talpyklą?",
        text6: "daugiakalbis",
        text7: "Kad apsaugotumėte savo saugumą, rekomenduojame įjungti bent vieną dviejų veiksnių autentifikavimą"
    },
    login: {
        login: "Prisijungti",
        register: "Registruotis",
        title1: "Prekiaukite bet kada ir bet kur ir peržiūrėkite rinkos sąlygas realiuoju laiku",
        title2: "Prekiaukite bet kada ir bet kur ir peržiūrėkite rinkos sąlygas realiuoju laiku",
        title3: "Patikimiausia kriptovaliutų prekybos platforma",
        formTitle1: "Prisijungimas prie paskyros",
        formTitle2: "Sveikas sugrįžęs! Prisijunkite naudodami savo el. pašto adresą, telefono numerį",
        btn1: "Mobilusis telefonas",
        btn2: "Paštas",
        btn3: "Prisijungti",
        btn4: "Registruotis",
        btn5: "pamiršti slaptažodį?",
        field1: "Telefono numeris",
        field2: "Slaptažodis",
        field3: "Paštas",
        placeholder1: "tauta",
        placeholder2: "Įveskite telefono numerį",
        placeholder3: "Įveskite slaptažodį",
        placeholder4: "prašome įvesti savo el",
        content12: "Įveskite telefono numerį",
        content13: "Įveskite slaptažodį",
        content14: "prašome įvesti savo el",
        content16: "Skaitmeninio turto finansinės paslaugos",
        loginSuccess: "sėkmingas prisijungimas ",
        chooseArea: "Pasirinkite srities kodą",
        placeholder5: "Pasirinkite šalies kodą",
        placeholder6: "prisimink mano slaptažodį",
        placeholder7: "Atsisiųskite APP",
        placeholder8: "Susisiekite su klientų aptarnavimo tarnyba",
        placeholder9: "Pasirinkite šalį arba regioną",
        placeholder10: "vietovės kodas",
        placeholder11: "Prisijungimas prie piniginės",
        placeholder12: "Operacija nepavyko, pereikite prie",
        placeholder13: "piniginė"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "apie",
        aboutCoinbeadCenterDetails: "Ji turi pirmaujančią pasaulyje blockchain turto prekybos platformą ir valdo visą ekosistemą.",
        footDetails1: "dienos prekybos apimtis",
        footDetails2: "operacijų per sekundę",
        footDetails3: "Pagalbos centras",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Tai visame pasaulyje žinoma tarptautinė skaitmeninio turto svetainė, daugiausia teikianti monetas ir skaitmeninio turto išvestinius produktus, tokius kaip Bitcoin (BTC), Litecoin (LTC) ir Ethereum (ETH) pasaulio vartotojams.",
        coinbeadCenterDetailsm: "Taikydami pažangias technologijas, tokias kaip GSLB, tinklo serverius, tinklo operacijas, tinklus, kelių mašinų didelės spartos atminties prekybos variklius, šaltąsias pinigines, išmaniąsias neprisijungus veikiančias karštąsias pinigines ir kt., savo klientus aptarnaujame naudodami kelis terminalus, tokius kaip žiniatinklio mobilieji telefonai ir asmeniniai kompiuteriai. Teikti saugias, stabilias ir patikimas skaitmeninių tarifų operacijų paslaugas. Tuo pačiu metu tobuliname ir atnaujiname produktus ir paslaugas atsižvelgdami į vartotojų pasiūlymus ir poreikius, kad kiekvienam klientui būtų teikiamos geresnės paslaugos, o novatoriškos vartotojo patirties gerinimui nėra galo.",
        infrastructureCenterTitle: "infrastruktūrą",
        infrastructureCenterDetails: "Mūsų misija – teikti infrastruktūros paslaugas visai blockchain ekosistemai",
        freeCenterTitle: "Laisvas",
        freeCenterDetails: "Mūsų vizija – priversti pinigus laisvai tekėti visame pasaulyje. Tikime, kad šios laisvės sklaida gali padaryti pasaulį geresne vieta",
        futureCenterTitle: "Technologijų ateities pionieriai",
        futureCenterDetails: "Open up the future of technology",
    },
    applicationForListing: {
        title: "Monetų sąrašo programa",
        projectName: "projekto pavadinimas",
        tokenName: "Žetono vardas",
        projectDescription: "Projekto aprašymas",
        contractAddress: "Sutarties adresas",
        officialWebsite: "Oficiali svetainė",
        ContactAndEmail: "Kontaktinis Elektroninis paštas",
        ContactAndName: "Kontaktinis vardas",
        file: "Įkelti priedą",
        pleaseEnter: "prašome įvesti",
        enterFile: "Prašome įkelti failus",
        upload1: "Šiuo metu galima pasirinkti tik 1 failą,",
        upload2: "Šį kartą pasirinkau",
        upload3: "failus，",
        upload4: "Iš viso pasirinkta",
        upload5: "failus",
    },
    public: {
        submit: "Pateikti",
        copySuccessfully: "Nukopijuota sėkmingai",
        copyFailed: "Kopijuoti nepavyko",
        noDate: "Nėra duomenų",
        submitSuccessfully: "Sėkmingai pateikta",
        goBack: "grįžti į paskutinį puslapį",
        cancel: "Atšaukti",
        home: "Titulinis lapas",
        loading: "įkeliama...",
        confirm: "patvirtinti",
        loadingFailed: "tinklo anomalija",
        nomore: "ne daugiau",
        tryAgain: "Atnaujinkite puslapį / išeikite iš programos ir įkelkite dar kartą!",
        hot: "Populiarios vietovės",
    },
    inviteFriends: {
        inviteFriends: "Pasidalink su draugais",
        freedomOfWealth: "Tegul turtai būna laisvi kartu",
        myInvitationCode: "mano pakvietimo kodas",
        clickCopy: "Spustelėkite norėdami nukopijuoti",
        myInvitationLink: "Mano kvietimo nuoroda",
        clicktoSaveTheQRCode: "Spustelėkite, kad išsaugotumėte QR kodą",
        saveQRCode: "Pakvieskite QR kodą",
        numberOfSubordinates: "Pavaldinių skaičius",
        rebateIncome: "Nuolaidos pajamos",
        people: "žmonių",
        myPromotionRevenue: "Mano reklamos pajamos",
        numberOfDirectSubordinates: "Tiesioginių pavaldinių skaičius",
        totalNumberOfSubordinates: "Bendras pavaldinių skaičius",
        directSubordinateReturnsCommission: "Komisinių nuolaida tiesioginiams pavaldiniams",
        theTotalCommission: "visa nuolaida",
        checkHistory: "Peržiūrėkite nuolaidų įrašus",
        history: "Komisijos rekordas",
        theLowerAccount: "Pavaldžioji sąskaita",
        type: "tipas:",
        serviceCharge: "Tvarkymo mokestis:",
        percentageofCommissionReturned: "Nuolaidos koeficientas:",
        commissionrebateAmount: "Nuolaidos suma:",
        time: "laikas:",
        typeName1: "Įkrovimas ir komisiniai",
        typeName2: "MT užsakymų tvarkymo mokestis trijų lygių paskirstymas",
        typeName3: "MT pelningas trijų lygių paskirstymas",
        typeName4: "Siuntimo apdovanojimai",
        typeName30: "Įkrovimas ir komisiniai",
        typeName31: "Periodinis prekybos pelno grąžinimas",
        typeName32: "Likvidumo kasybos pelno grąžinimas",
        typeName45: "Sandorio mokesčio grąžinimas",
        typeName46: "Komisinių nuolaida už nuosavo tvarkymo mokestį",
        typeName50: "Pristatymo sutarties tvarkymo mokesčio nuolaida",
        typeName51: "Nuolatinė sutarties tvarkymo mokesčio nuolaida",
        typeName52: "Papildoma komisinių nuolaida už pristatymo sutarties tvarkymo mokesčius",
        typeName53: "Papildoma komisinių nuolaida už neterminuotus sutarties tvarkymo mokesčius",
        typeName54: "Papildomos nuolaidos nuolatiniams sutarties tvarkymo mokesčiams",
        shareRules1: "Kvietimo instrukcijos",
        shareRules2: "Įeikite į kvietimų centrą, nukopijuokite kvietimo nuorodą arba kodą ir pasidalykite juo su draugais. Draugai gali tapti jūsų pavaldiniais užsiregistravę su jūsų pakvietimo kodu.",
        shareRules3: "Uždirbkite nuolaidas",
        shareRules4: "Kai pavaldiniai atlieka sandorius, galite gauti atitinkamus komisinius, palaikomi iki trijų lygių pavaldiniai. Pavyzdžiui, jei pakviečiate draugą A, A kviečia B, o B pakviečia C, tada A, B ir C platformoje gali sudaryti sutartis ir atlikti kitus sandorius, o jūs visi galite gauti atitinkamus komisinius.",
        shareRules5: "Komandos lygis",
        shareRules6: "Kuo daugiau paaukštinsite pirmojo lygio pavaldinių, tuo aukštesnis komandos lygis ir tuo didesnės nuolaidos galėsite mėgautis. Komandos lygiai yra suskirstyti į LV1-LV6. Atnaujinimo taisyklės parodytos toliau esančioje lentelėje, kur „N“ yra pirmojo lygio pavaldinių, kurie įkrovė ir atliko autentifikavimą tikruoju vardu, skaičius.",
        shareRules7: "Kai pavaldiniai stato statymus dėl pristatymo sutarčių, galite gauti komisinius, proporcingus jų statymams.",
        upgradeConditions: "Reikalauti",
        recommendedNumberOfPeople: "Rekomenduojamas žmonių skaičius"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Pasiūlymai ir atsiliepimai",
        text: "Jei naudojimo metu susiduriate su klaidų arba turite produktų pasiūlymų, pateikite atsiliepimą.",
        email: "elektroninio pašto adresas",
        enterEmail: "Įveskite el. pašto adresą",
        enterCorrectEmail: "Įveskite teisingą el. pašto adresą",
        title: "titulą",
        enterTitle: "Įveskite klausimo pavadinimą",
        describeTheProblem: "Apibūdinkite problemą",
        enterDescribeTheProblem: "Įveskite aprašytą problemą",
        submit: "Pateikti",
    },
    help: {
        enterSearch: "Paieška",
        title: "Pagalbos centras",
        search: "Įveskite savo klausimą",
        text1: "Supraskite savo poreikius ir toliau teikite jums patogesnes paslaugas"
    },
    announcement: {
        enterSearch: "Paieška",
        title: "Skelbimų centras",
        search: "Įveskite savo klausimą"
    },
    privacy: {
        title: "Privatumo pareiškimas",
        content1: "1. Kokia vartotojų asmeninė informacija renkama?",
        content2: "Asmeninė informacija renkama siekiant teikti produktus ir paslaugas bei nuolat juos tobulinti.",
        content3: "Toliau pateikiami mūsų renkamos asmeninės informacijos tipai:",
        content4: "Informacija, kurią mums pateikiate: Mes gauname ir saugome bet kokią informaciją, kurią pateikiate, susijusią su Platforma. Galite pasirinkti nepateikti tam tikros informacijos, tačiau dėl to negalėsite naudotis tam tikromis „šios platformos paslaugomis“. Informacijos, kurią pateikiate mums, pavyzdžiai:",
        content5: "vardas",
        content6: "adresu",
        content7: "Pilietybės šalis",
        content8: "ID nuotrauka",
        content9: "Mobiliojo telefono numeris/el",
        content10: "Kita informacija, kuri padeda mums jus identifikuoti",
        content11: "Automatiškai renkama informacija: automatiškai renkame ir saugome tam tikro tipo informaciją apie jūsų naudojimąsi Platformos paslaugomis, kaip ir daugelis svetainių, naudojame slapukus ir kitus unikalius identifikatorius. Mes gauname tam tikros rūšies informaciją, kai jūsų interneto naršyklė arba įrenginys pasiekia platformą. Informacijos, kurią renkame ir analizuojame, pavyzdžiai:",
        content12: "Interneto protokolo (IP) adresas, jungiantis asmeninį kompiuterį prie interneto;",
        content13: "Prisijungimo informacija, el. pašto adresas, slaptažodis ir asmeninio įrenginio ar kompiuterio vieta;",
        content14: "Versijos ir laiko juostos nustatymai;",
        content15: "Sandorių istorija.",
        content16: "Informacija iš kitų šaltinių: galime rinkti informaciją apie jus iš kitų šaltinių, pavyzdžiui, iš kredito biurų kredito istorijos. Šią informaciją naudosime sukčiavimo prevencijai ir aptikimui.",
        content17: "2. Ar vaikai gali juo naudotis?",
        content18: "Ši platforma neleidžia asmenims iki 18 metų naudotis „Paslauga“.",
        content19: "3. Kam naudojami slapukai ir kiti identifikatoriai?",
        content20: "Naudojame slapukus ir panašius įrankius, siekdami optimizuoti vartotojų patirtį, teikti paslaugas ir suprasti, kaip klientai naudojasi mūsų paslaugomis, kad galėtume atlikti tikslinius patobulinimus. Naudojame slapukus siekdami užtikrinti, kad mūsų sistema atpažintų jūsų naršyklę ar įrenginį ir teiktų jums paslaugas.",
        content21: "Naudojame operacinius slapukus ir panašius įrankius (bendrai „slapukai“), kad galėtume teikti paslaugas, tokias kaip:",
        content22: "Norėdami jus identifikuoti, kai prisijungiate ir naudojatės mūsų paslaugomis.",
        content23: "Teikti pritaikytas funkcijas ir paslaugas.",
        content24: "Apsaugokite nuo nesąžiningos veiklos.",
        content25: "Pagerinti saugumą.",
        content26: "Stebėkite savo nuostatas (pvz., valiutą ir kalbą).",
        content27: "Taip pat naudojame slapukus, kad suprastume, kaip vartotojai naudojasi mūsų paslaugomis, kad galėtume atlikti tikslinius patobulinimus.",
        content28: "4. Ar bus dalijamasi mano asmenine informacija?",
        content29: "Vartotojų informacija yra svarbi mūsų verslo dalis, todėl mes neparduosime naudotojų asmeninės informacijos kitiems. Ši platforma dalinsis naudotojų asmenine informacija su savo dukterinėmis įmonėmis arba filialais toliau nurodytomis aplinkybėmis. Šios dukterinės įmonės ar filialai privalo laikytis šio privatumo pareiškimo arba bent jau laikytis tokios pat apsaugos kaip ir šiame privatumo pareiškime aprašytos apsaugos priemonės.",
        content30: "Trečiųjų šalių paslaugų teikėjai: įdarbiname kitas įmones ir asmenis, kad jie atliktų funkcijas mūsų vardu. Tokių funkcijų pavyzdžiai: duomenų analizė, rinkodaros pagalbos teikimas, mokėjimų apdorojimas, turinio perdavimas, kredito rizikos vertinimas ir valdymas. Šie trečiųjų šalių paslaugų teikėjai turi prieigą prie asmeninės informacijos, reikalingos jų funkcijoms atlikti, tačiau negali jos naudoti jokiems kitiems tikslams. Be to, jie turi tvarkyti asmeninę informaciją laikydamiesi šio privatumo pareiškimo ir taikomų duomenų apsaugos įstatymų.",
        content31: "Verslo perkėlimas: mūsų verslui ir toliau augant, galime parduoti arba pirkti kitas įmones ar paslaugas. Atliekant tokias operacijas, naudotojo informacija paprastai yra tarp perduoto verslo turto, tačiau jai galioja pažadai, esantys bet kuriame ankstesniame privatumo pareiškime (nebent, žinoma, vartotojas sutinka kitaip). Be to, jei būtų įsigyta mūsų įmonė ar iš esmės visas jos turtas, informacija apie vartotoją taip pat būtų perduodama. Mūsų įmonės ir kitų apsauga: kai manome, kad paskyros ir kitos asmeninės informacijos atskleidimas yra tinkamas, kad būtų laikomasi įstatymų arba mūsų reguliavimo įsipareigojimų, vykdyti arba taikyti mūsų naudojimo sąlygas ir kitas sutartis arba mūsų teises, nuosavybę ar saugumą. vartotojų ar kitų asmenų Kada mes paskelbsime paskyrą ir kitą asmeninę informaciją. Tai apima keitimąsi informacija su kitomis įmonėmis ir organizacijomis, siekiant apsisaugoti nuo sukčiavimo ir sumažinti kredito riziką.",
        content32: "5. Tarptautinis asmens duomenų perdavimas",
        content33: "Jūsų duomenis galime perkelti už Europos ekonominės erdvės (toliau – EEE) ribų. Įdiegsime tinkamas apsaugos priemones, siekdami užtikrinti, kad toks perdavimas atitiktų taikomas duomenų apsaugos taisykles, nebent Europos Komisija patvirtintų, kad šalis, kuriai perduodami duomenys, užtikrina tinkamą apsaugos lygį.",
        content34: "6. Ar mano asmeninė informacija yra saugi?",
        content35: "Sistemas sukūrėme atsižvelgdami į jūsų saugumą ir privatumą. Siekdami apsaugoti asmeninės informacijos saugumą perdavimo metu, naudojame šifravimo protokolus ir programinę įrangą.",
        content36: "Rinkdami, saugodami ir atskleisdami asmeninę informaciją, visada taikome fizines, elektronines ir procedūrines apsaugos priemones. Mūsų saugumo procedūros reiškia, kad mums gali tekti patikrinti jūsų tapatybę prieš atskleidžiant jums asmeninę informaciją.",
        content37: "Svarbiausias dalykas, kurį galite padaryti, yra apsaugoti savo asmeninės paskyros slaptažodžius nuo neteisėtos prieigos. Rekomenduojame nustatyti unikalų paskyros slaptažodį, kuris skiriasi nuo kitų internetinių paskyrų. Ir būtinai atsijunkite naudodami bendrą kompiuterį.",
        content38: "7. Kaip turėčiau apsaugoti savo asmeninę informaciją?",
        content39: "Jei turite klausimų ar prieštaravimų dėl to, kaip renkame ir apdorojame asmeninę informaciją, susisiekite su klientų aptarnavimo darbuotojais",
        content40: "Sutikę, kad jūsų asmeninę informaciją tvarkytume konkrečiu tikslu, galite bet kada atšaukti savo ketinimą ir mes nustosime tvarkyti jūsų duomenis šiuo tikslu.",
        content41: "Be to, jūs turite teisę prašyti prieigos prie asmens duomenų, juos ištaisyti ir ištrinti bei prašyti duomenų perkeliamumo pagal galiojančius įstatymus. Taip pat galite nesutikti, kad mes tvarkytume jūsų asmens duomenis, arba prašyti, kad tam tikromis aplinkybėmis būtų apribotas jūsų asmens duomenų tvarkymas.",
        content42: "8. ES GDPR ir JK duomenų apsaugos įstatymas – teisinis pagrindas",
        content43: "ES GDPR ir JK duomenų apsaugos įstatymai reikalauja, kad naudotume asmeninę informaciją laikytis teisinio pagrindo. Mūsų pagrindas priklauso nuo konkrečių tikslų, kuriems naudojama asmeninė informacija. Šios bazės apima:",
        content44: "Sutarties vykdymas – teikti arba bendrauti su jumis apie produktus ar paslaugas, įskaitant jūsų asmeninės informacijos naudojimą priimant ir apdoroti užsakymus bei apdoroti mokėjimus.",
        content45: "Mūsų teisėti verslo interesai ir naudotojų interesai – aptinkame ir užkertame kelią sukčiavimui bei piktnaudžiavimui, kad apsaugotume savo naudotojų, savęs ar kitų saugumą.",
        content46: "Jūsų sutikimas – mums reikia jūsų sutikimo, kad galėtume tvarkyti jūsų asmeninę informaciją konkrečiais jums nurodytais tikslais. Suteikę mums sutikimą tvarkyti jūsų asmeninę informaciją konkrečiu tikslu, galite bet kada atšaukti savo sutikimą ir mes nustosime tvarkyti jūsų duomenis šiuo tikslu.",
        content47: "Laikykitės teisinių įsipareigojimų – mes naudojame jūsų asmeninę informaciją, kaip to reikalauja teisės aktai. Pavyzdžiui, tapatybės patvirtinimo tikslais renkame banko sąskaitos informaciją.",
        content48: "Pirmiau nurodyti ir kiti teisiniai pagrindai priklauso nuo konkrečių tikslų, kuriems mes naudojame asmeninę informaciją.",
        content49: "9. Naudojimo sąlygos, pranešimai ir pataisymai",
        content50: "Jūsų naudojimuisi ir bet kokiam ginčui dėl privatumo taikomas šis pareiškimas ir mūsų naudojimo sąlygos. Jei turite klausimų dėl šios platformos privatumo, susisiekite su mumis ir pateikite išsamų paaiškinimą ir mes mielai išspręsime jūsų problemą. Taip pat turite teisę kreiptis į savo vietos duomenų apsaugos instituciją.",
        content51: "Mūsų verslas nuolat keičiasi, taip pat keičiasi ir mūsų privatumo pareiškimas. Turėtumėte dažnai apsilankyti mūsų svetainėje, kad pamatytumėte naujausius pakeitimus. Jei nesutinkate su peržiūrėtu turiniu, turėtumėte nedelsdami nutraukti prieigą. Išleidus atnaujintą Privatumo politikos versiją, jūsų nuolatinė prieiga reiškia, kad sutinkate su atnaujintu turiniu ir sutinkate laikytis atnaujintos privatumo politikos. Jei nenurodyta kitaip, dabartinis privatumo pareiškimas taikomas visai informacijai, kurią turime apie jus ir jūsų paskyrą.",
    },
    serviceAgreement: {
        title: "Paslaugų sutartis",
        content1: "Ši svetainė yra platforma, skirta vartotojams atlikti skaitmeninio turto sandorius ir teikti susijusias paslaugas (toliau vadinama „paslauga“ arba „paslauga“). Kad būtų patogiau apibūdinti šią sutartį, šioje svetainėje šioje sutartyje kartu vartojami „mes“ arba kiti pirmojo asmens įvardžiai. Kol fizinis asmuo ar kitas subjektas, kuris prisijungia prie svetainės, yra šios svetainės vartotojas, kad būtų patogiau išreikšti šią sutartį, toliau bus naudojamas „jūs“ arba kitas antrasis asmuo. Kad būtų patogiau išreikšti šią Sutartį, mes ir jūs šioje Sutartyje kartu vadinami „Šalimis“, o mes arba jūs individualiai vadiname „Šaliai“.",
        content2: "svarbus patarimas:",
        content3: "Ypač primename:",
        content4: "1 Pats skaitmeninis turtas nėra išduotas jokios finansų įstaigos ar įmonės ar šios svetainės",
        content5: "2 Skaitmeninio turto rinka yra nauja, nepatvirtinta ir gali neaugti;",
        content6: "3 Skaitmeniniu turtu daugiausia naudojasi spekuliantai, o mažmeninėse ir komercinėse rinkose jie yra labai rizikingi ir pasaulinės vyriausybės politikos svyravimai;",
        content7: "4 Jei Bendrovė savo nuožiūra nustato, kad jūs pažeidėte šią Sutartį arba kad šios svetainės teikiamos paslaugos ar naudojimasis šios svetainės teikiamomis paslaugomis yra neteisėtas pagal jūsų jurisdikcijos įstatymus, Bendrovė turi teisę sustabdyti arba bet kuriuo metu nutraukti savo teises arba sustabdyti ar nutraukti naudojimąsi šioje svetainėje teikiamomis paslaugomis ar skaitmeninio turto sandoriais. Visiems iš [žemyninės Kinijos, Taivano, Izraelio, Irako, Bangladešo, Bolivijos, Ekvadoro, Kirgizijos, Sevastopolio ir Jungtinės Karalystės (mažmeniniams vartotojams)] draudžiama naudotis šioje svetainėje teikiamomis sutarčių sudarymo paslaugomis. Pirmiau minėtas šalių ar regionų sąrašas pasikeis, atsižvelgiant į skirtingų šalių ar regionų politiką ir produktų tipus. Tuo metu galime jums konkrečiai nepranešti, todėl laiku atkreipkite dėmesį į šios sutarties atnaujinimus.",
        content8: "Prekyba skaitmeniniu turtu kelia itin didelę riziką ir daugeliui žmonių netinka. Jūs suprantate ir suprantate, kad dėl šio sandorio gali būti patirti daliniai arba visiški nuostoliai, todėl sandorio sumą turėtumėte nustatyti atsižvelgdami į tai, kiek nuostolių galite sau leisti. Žinote ir suprantate, kad skaitmeninis turtas generuos išvestinę riziką, todėl kilus klausimams, pirmiausia rekomenduojama kreiptis į profesionalius patarėjus. Be to, be aukščiau paminėtų pavojų, bus ir rizikos, kurių negalima numatyti. Prieš priimdami bet kokį sprendimą pirkti ar parduoti skaitmeninį turtą, turėtumėte atidžiai apsvarstyti ir aiškiai įvertinti savo finansinę padėtį ir aukščiau paminėtą riziką, ir prisiimti už tai patirtus nuostolius.",
        content9: "Atkreipkite dėmesį:",
        content10: "1 Jūs suprantate, kad ši svetainė naudojama tik kaip vieta, kur galite gauti informaciją apie skaitmeninį turtą, rasti sandorio šalis, derėtis ir atlikti sandorius dėl skaitmeninio turto sprendimą nustatyti atitinkamą skaitmeninį turtą ir (arba) informacijos autentiškumą, teisėtumą ir pagrįstumą, ir pati prisiima su tuo susijusią atsakomybę bei nuostolius.",
        content11: "2 Bet kokios nuomonės, naujienos, diskusijos, analizės, kainos, pasiūlymai ir kita informacija šioje svetainėje yra bendri rinkos komentarai ir nėra investicijų patarimai. Mes nesame atsakingi už jokius nuostolius, tiesiogiai ar netiesiogiai atsiradusius dėl pasitikėjimo šia informacija, įskaitant, bet tuo neapsiribojant, bet kokį pelno praradimą.",
        content12: "3 Ši svetainė pasilieka teisę bet kuriuo metu priimti savo sprendimą, keisti ar keisti turinį Mes ėmėmės pagrįstų priemonių, kad užtikrintume svetainės informacijos tikslumą, tačiau negalime garantuoti jos tikslumo ir neprisiimame atsakomybės už jokius. nuostoliai ar žala, atsiradusi dėl šioje svetainėje esančios informacijos arba dėl bet kokios priežasties, tiesiogiai ar netiesiogiai atsiradusi dėl vėlavimo ar nesėkmių prisijungiant prie interneto arba perduodant ar gaunant bet kokius pranešimus ir informaciją.",
        content13: "4 Naudojant internetines prekybos sistemas taip pat kyla rizika, įskaitant programinės įrangos, aparatinės įrangos ir interneto ryšio gedimus, bet tuo neapsiribojant. Kadangi mes nekontroliuojame interneto patikimumo ir prieinamumo, neprisiimame atsakomybės už iškraipymus, vėlavimus ir ryšio sutrikimus.",
        content14: "5 Draudžiama naudoti šią svetainę siekiant užsiimti bet kokia neteisėta prekyba ar neteisėta veikla, tokia kaip pinigų plovimas, kontrabanda, komercinis kyšininkavimas ir pan. Jei bus aptikti įtariami neteisėti sandoriai ar neteisėta veikla, ši svetainė imsis įvairių priemonių, įskaitant bet neapsiribojant sąskaitų įšaldymu, pranešimu atitinkamoms institucijoms ir pan., mes neprisiimame visų iš to kylančių įsipareigojimų ir pasiliekame teisę reikalauti atsakomybės iš atitinkamų asmenų.",
        content15: "6 Draudžiama naudoti šią svetainę siekiant vykdyti piktybines rinkos manipuliacijas, nesąžiningus sandorius ir kitą neetišką prekybos veiklą. uždarymą ir pasilieka teisę reikalauti atitinkamų asmenų atsakomybės.",
        content16: "1. Bendrieji principai",
        content17: "1.1 „Naudotojo sutartis“ (toliau – „ši sutartis“ arba „šios sąlygos“), susideda iš pagrindinio teksto „Privatumo sąlygos“, „Pažink savo klientą ir kovos su pinigų plovimu politika“ ir kitos politikos, kurios buvo paskelbta arba ateityje gali būti paskelbta šioje svetainėje. Jį sudaro įvairios taisyklės, pareiškimai, instrukcijos ir kt.",
        content18: "1.2 Prieš pradėdami naudotis šios svetainės teikiamomis paslaugomis, turėtumėte atidžiai perskaityti šią sutartį. Jei bet kuriuo metu nesutinkate su šia Sutartimi ir (arba) jos pakeitimu, nedelsdami nustokite naudotis šios svetainės teikiamomis paslaugomis arba nebeprisijunkite prie šios svetainės. Kai prisijungiate prie šios svetainės, naudojatės bet kuriomis šios svetainės paslaugomis ar bet kokiu kitu panašiu elgesiu, tai reiškia, kad supratote ir visiškai sutikote su šios sutarties turiniu, įskaitant bet kokius šios svetainės bet kuriuo metu padarytus šios sutarties pakeitimus.",
        content19: "1.3 Šios svetainės nariu (toliau – Narys) galite tapti užpildę atitinkamą informaciją pagal šios svetainės reikalavimus ir sėkmingai užsiregistravę po kitų atitinkamų procedūrų registracijos proceso metu paspaudę mygtuką „Sutinku“. reiškia, kad jūs elektroniniu būdu pasirašote sutartį su įmone arba kai naudojatės šia svetaine paspaudžiate bet kurį mygtuką, pažymėtą „Sutinku“ ar panašia reikšme, arba faktiškai naudojatės šios svetainės teikiamomis paslaugomis kitais šios svetainės leidžiamais būdais, reiškia, kad jūs visiškai suprantate, sutinkate ir sutinkate su visomis šios sutarties sąlygomis. Jūsų parašo ranka nebuvimas neturės įtakos šios Sutarties teisinei galiai.",
        content20: "1.4 Tapę šios svetainės nariu, gausite nario paskyrą ir atitinkamą slaptažodį. Jūs būsite teisiškai atsakingas už visą veiklą ir įvykius, vykdomus pagal Jūsų paskyrą.",
        content21: "1.5 Tik nariai, kurie tampa šios svetainės nariais, gali naudotis šios svetainės teikiama skaitmeninio turto prekybos platforma sandoriams atlikti ir naudotis kitomis paslaugomis, kurios yra prieinamos tik nariams, kaip numatyta šioje svetainėje, prisijungti prie svetainės, naršyti svetainę ir kitas šioje svetainėje nurodytas paslaugas.",
        content22: "1.6 Registruodamiesi ir naudodami bet kokias šios svetainės teikiamas paslaugas ir funkcijas, bus laikoma, kad perskaitėte, supratote ir:",
        content23: "1.6.1 Sutikite su visomis šios Sutarties sąlygomis.",
        content24: "1.6.2 Jūs patvirtinate, kad esate vyresnis nei 18 metų arba turite leistiną amžių sudaryti sutartį pagal skirtingus galiojančius įstatymus, registruodamiesi šioje svetainėje, parduodate ar perkate, skelbiate informaciją ir pan., ir sutinkate su šios svetainės paslaugomis. turi laikytis atitinkamų suverenios šalies ar regiono, kurio jurisdikcija yra jums, įstatymai ir teisės aktai ir turėti visas galimybes sutikti su šiomis sąlygomis, sudaryti sandorius ir naudoti šią svetainę skaitmeninio turto sandoriams atlikti.",
        content25: "1.6.3 Jūs garantuojate, kad visas jums priklausantis skaitmeninis turtas, įtrauktas į sandorį, yra teisėtai įgytas ir turi nuosavybės teisę.",
        content26: "1.6.4 Jūs sutinkate, kad esate visiškai atsakingas už savo prekybinę ar neprekybinę veiklą ir bet kokį pelną ar nuostolius.",
        content27: "1.6.5 Jūs patvirtinate, kad registruojantis pateikta informacija yra teisinga ir tiksli.",
        content28: "1.6.6 Sutinkate laikytis bet kurio atitinkamo įstatymo reikalavimų mokesčių tikslais, įskaitant pranešimus apie bet kokį prekybos pelną.",
        content29: "1.6.7 Jūs sutinkate bet kuriuo metu nedalyvauti elgesyje ar veikloje, kuri kenkia šios svetainės ar įmonės interesams, nesvarbu, ar tai susiję su šios svetainės teikiamomis paslaugomis, ar nesusiję.",
        content30: "1.6.8 Ši sutartis reglamentuoja tik teises ir pareigas tarp jūsų ir mūsų ir neapima teisinių santykių bei teisinių ginčų, kylančių dėl skaitmeninio turto sandorių tarp šios svetainės ir kitų svetainių naudotojų ir jūsų.",
        content31: "2. Sutarties pakeitimai",
        content32: "Mes pasiliekame teisę retkarčiais peržiūrėti šią Sutartį ir apie tai pranešti svetainėje, jums atskirai neįspėję. galioja, kai jis bus paskelbtas svetainėje. Retkarčiais turėtumėte naršyti ir atkreipti dėmesį į šios Sutarties atnaujinimo laiką ir atnaujintą turinį svetainė reiškia, kad sutinkate su pakeista sutartimi ir sutinkate jos laikytis.",
        content33: "3. Registracija",
        content34: "3.1 Registracijos kvalifikacija",
        content35: "Jūs patvirtinate ir pažadate, kad kai baigsite registracijos procesą arba faktiškai naudositės šios svetainės teikiamomis paslaugomis kitais šioje svetainėje leidžiamais būdais, turėtumėte turėti galimybę pasirašyti šią sutartį ir naudotis šios svetainės paslaugomis, kaip tai numato taikomi įstatymai. fizinis asmuo, juridinis asmuo ar kita organizacija. Kai paspausite mygtuką Sutinku registruotis, tai reiškia, kad jūs arba jūsų įgaliotasis atstovas sutiko su sutarties turiniu ir buvo užregistruotas bei naudotasi šios svetainės paslaugomis jo atstovo. Jei neturite aukščiau paminėtų dalykų, jūs ir jūsų įgaliotasis atstovas prisiimate visas su tuo susijusias pasekmes, o įmonė pasilieka teisę anuliuoti arba visam laikui įšaldyti jūsų paskyrą ir patraukti jus bei jūsų įgaliotąjį atstovą atsakomybėn.",
        content36: "3.2 Registracijos tikslas",
        content37: "Jūs patvirtinate ir pažadate, kad jūsų registracija šioje svetainėje nėra skirta įstatymų ir kitų teisės aktų pažeidimui arba skaitmeninio turto operacijų tvarkos šioje svetainėje trikdymui.",
        content38: "3.3 Registracijos procesas",
        content39: "3.3.1 Sutinkate pateikti galiojantį el. pašto adresą, mobiliojo telefono numerį ir kitą informaciją, kurios reikalaujama šios svetainės naudotojo registracijos puslapyje. Galite naudoti jūsų pateiktą ar patvirtintą el. pašto adresą, mobiliojo telefono numerį arba kitus šios svetainės leidžiamus būdus kaip prisijungimo būdas patekti į šią svetainę. Jei reikia, pagal atitinkamus skirtingų jurisdikcijų įstatymus ir kitus teisės aktus, privalote pateikti savo tikrąjį vardą, pavardę, asmens dokumentą ir kitą svarbią informaciją, numatytą įstatymuose ir teisės aktuose, privatumo sąlygose ir kovos su pinigų plovimu sąlygose bei nuolat atnaujinti registracijos informaciją Reikalauti laiku, išsamiai ir tiksliai. Visa iš pradžių įvesta informacija bus nurodyta kaip registracijos informacija. Jūs atsakote už tokios informacijos tikrumą, išsamumą ir tikslumą ir prisiimate bet kokius tiesioginius ar netiesioginius nuostolius ir neigiamas pasekmes, atsirandančias dėl to.",
        content40: "3.3.2 Jei suverenios šalies ar regiono, kuriame esate, įstatymai, nuostatai, taisyklės, įsakymai ir kiti teisės aktai nustato tikrojo vardo reikalavimus mobiliųjų telefonų numeriams, sutinkate, kad pateiktas registruotas mobiliojo telefono numeris buvo užregistruotas pagal tikrąjį Pavadinimas Jei jo nepateiksite pagal taisykles, jūs pateksite bet kokius tiesioginius ar netiesioginius nuostolius ir neigiamas pasekmes.",
        content41: "3.3.3 Jei registracijai reikalingą informaciją pateikiate teisėtai, visiškai ir veiksmingai ir ją patikrinote, turite teisę gauti šios svetainės sąskaitos numerį ir slaptažodį, kai gaunate šios svetainės sąskaitos numerį ir slaptažodį sėkmingai užsiregistruoti ir prisijungti kaip narys šioje svetainėje.",
        content42: "3.3.4 Jūs sutinkate gauti šios svetainės siunčiamus el. laiškus ir (arba) trumpąsias žinutes, susijusias su šios svetainės valdymu ir veikimu.",
        content43: "4. Aptarnavimas",
        content44: "Ši svetainė teikia tik internetinės prekybos platformos paslaugas, skirtas jūsų skaitmeninio turto prekybos veiklai (įskaitant, bet neapsiribojant, skaitmeninio turto prekybą ir kitas paslaugas) per šią svetainę.",
        content45: "4.1 Paslaugos turinys",
        content46: "4.1.1 Jūs turite teisę naršyti realiu laiku šioje svetainėje esančių įvairių skaitmeninio turto produktų rinkos sąlygas ir informaciją apie sandorius, taip pat turite teisę per šią svetainę pateikti skaitmeninio turto sandorių instrukcijas ir užbaigti skaitmeninio turto operacijas.",
        content47: "4.1.2 Jūs turite teisę peržiūrėti savo narystės paskyroje esančią informaciją šioje svetainėje ir naudotis šios svetainės teikiamomis funkcijomis.",
        content48: "4.1.3 Jūs turite teisę dalyvauti šios svetainės organizuojamoje svetainės veikloje pagal šioje svetainėje paskelbtas veiklos taisykles.",
        content49: "4.1.4 Kitos paslaugos, kurias jums žada teikti ši svetainė.",
        content50: "4.2 Paslaugos taisyklės Jūs įsipareigojate laikytis šių šios svetainės paslaugų taisyklių:",
        content51: "4.2.1 Jūs privalote laikytis įstatymų, taisyklių ir politikos reikalavimų, užtikrinti visų paskyroje esančių skaitmeninių išteklių šaltinių teisėtumą ir neužsiimti neteisėta ar kita veikla, kuri kenkia šios svetainės ar trečiųjų šalių teisėms ir interesams. šioje svetainėje arba naudojantis jos paslaugomis, įskaitant, bet neapsiribojant, bet kokios nelegalios, neteisėtos ar kitų teises pažeidžiančios informacijos siuntimą ar gavimą, piramidžių schemų ar kitos žalingos informacijos ar pastabų siuntimą ar gavimą, el. pašto antraštės naudojimą ar klastojimą. informaciją apie šią svetainę be šios svetainės leidimo ir pan.",
        content52: "4.2.2 Turėtumėte laikytis įstatymų ir teisės aktų bei tinkamai naudoti ir saugoti savo sąskaitos numerį, prisijungimo slaptažodį, fondo slaptažodį, mobiliojo telefono numerį, susietą su jumis registruojantis, ir mobiliojo telefono patvirtinimo kodą, gautą iš jūsų mobiliojo telefono. Jūs esate visiškai atsakingas už visas operacijas ir pasekmes, susijusias su paskyros ir prisijungimo slaptažodžio, fondo slaptažodžio ir mobiliojo telefono patvirtinimo kodo naudojimu. Kai nustatote, kad šios svetainės sąskaitos numerį, prisijungimo slaptažodį arba lėšų slaptažodį ar patvirtinimo kodą naudoja trečioji šalis be jūsų leidimo, arba jei kyla kitų paskyros saugumo problemų, turėtumėte nedelsdami ir veiksmingai pranešti šiai svetainei ir paprašyti šios svetainės sustabdyti. šios svetainės paskyros paslauga. Ši svetainė turi teisę imtis veiksmų pagal jūsų prašymą per protingą laiką, tačiau ši svetainė neprisiima jokios atsakomybės už pasekmes (įskaitant, bet neapsiribojant, bet kokius jūsų patirtus nuostolius), atsiradusias prieš imantis veiksmų. Jūs negalite dovanoti, skolintis, išnuomoti, perleisti ar kitaip disponuoti savo paskyra šioje svetainėje kitiems be šios svetainės sutikimo.",
        content53: "4.2.3 Jūs sutinkate būti atsakingi už visą veiklą, atliekamą naudojantis jūsų paskyra ir slaptažodžiu šioje svetainėje (įskaitant, bet neapsiribojant, informacijos atskleidimą, informacijos išleidimą, sutikimą spustelėti internetu arba įvairių taisyklių ir sutarčių pateikimą, sutarčių atnaujinimą ar pirkimą internetu paslaugų ir pan.).",
        content54: "4.2.4 Vykdydami skaitmeninio turto operacijas šioje svetainėje, neturite piktybiškai kištis į normalią skaitmeninio turto operacijų eigą ar trikdyti operacijų eiliškumo, nenaudoti jokių techninių priemonių ar kitų priemonių, trukdančių normaliam jos veikimui svetainėje arba netrukdyti kitiems vartotojams naudotis šios svetainės paslaugomis.",
        content55: "4.2.5 Jei kyla ginčų su kitais vartotojais dėl internetinių operacijų, negalite reikalauti, kad ši svetainė pateiktų atitinkamą informaciją neteisminiais ar administraciniais kanalais.",
        content56: "4.2.6 Jūs tik spręsite ir padengsite visus mokėtinus mokesčius, taip pat visas techninės, programinės įrangos, paslaugų ir kitas išlaidas, patirtas naudojantis šioje svetainėje teikiamomis paslaugomis.",
        content57: "4.2.7 Jūs privalote laikytis šios Sutarties ir kitų paslaugų sąlygų bei veiklos taisyklių, kurias retkarčiais skelbia ir atnaujina ši svetainė, ir turite teisę bet kuriuo metu nutraukti naudojimąsi šios svetainės teikiamomis paslaugomis.",
        content58: "4.3 Produkto taisyklės",
        content59: "4.3.1 Naršyti operacijų informaciją",
        content60: "Naršydami šioje svetainėje informaciją apie sandorius, turėtumėte atidžiai perskaityti visą informaciją apie sandorį, įskaitant kainą, komisinių mastą, tvarkymo mokestį, pirkimo ar pardavimo kryptį, bet tuo neapsiribojant, ir tik tada, kai visiškai sutinkate su visu sandorio informacija Sandoriai gali būti sudaromi vienu mygtuko paspaudimu.",
        content61: "4.3.2 Peržiūrėkite išsamią operacijos informaciją",
        content62: "Atitinkamus operacijų įrašus galite peržiūrėti savo paskyroje.",
        content63: "5. Šios svetainės teisės ir pareigos",
        content64: "5.1 Jei neturite šioje sutartyje nustatytų registracijos sąlygų, ši svetainė turi teisę atmesti jūsų registraciją. Ši svetainė turi teisę atšaukti jūsų narystės paskyrą arba jūsų įgaliotas atstovas, atsakingas. Tuo pat metu ši svetainė pasilieka teisę nuspręsti, ar priimti jūsų registraciją kitomis aplinkybėmis.",
        content65: "5.2 Remiantis šios svetainės nuomone, jei ši svetainė nustato, kad jūs ar jūsų susiję paskyros naudotojai netinkami didelės rizikos investicijoms, ji turi teisę sustabdyti arba nutraukti jūsų paskyrą ir visų susijusių paskyrų naudojimą.",
        content66: "5.3 Kai ši svetainė nustato, kad paskyros naudotojas nėra pirmasis paskyros registruotojas, ji turi teisę sustabdyti arba nutraukti paskyros naudojimą.",
        content67: "5.4 Kai ši svetainė pagrįstai įtaria, kad jūsų pateikta informacija yra neteisinga, netikra, neteisinga arba neišsami atliekant techninius bandymus, rankinį mėginių ėmimą ir kitus testavimo metodus, ji turi teisę įspėti jus, kad ištaisytumėte ar atnaujintumėte informaciją arba sustabdytumėte ar nutrauktumėte teikimą. šios svetainės paslaugų jums.",
        content68: "5.5 Ši svetainė turi teisę taisyti bet kokią šioje svetainėje pateiktą informaciją, kai nustatoma, kad yra akivaizdžių klaidų.",
        content69: "5.6 Ši svetainė pasilieka teisę bet kuriuo metu keisti, sustabdyti arba nutraukti šios svetainės paslaugas šioje svetainėje, nutraukimas bus iš šios svetainės svetainėje. Jis įsigalios pranešimo apie nutraukimą paskelbimo dieną.",
        content70: "5.7 Šioje svetainėje bus įdiegtos reikalingos techninės priemonės ir valdymo priemonės, užtikrinančios normalų šios svetainės veikimą, užtikrinti reikiamą ir patikimą prekybos aplinką ir prekybos paslaugas bei palaikyti skaitmeninio turto operacijų tvarką.",
        content71: "5.8 Jei vienerius metus iš eilės neprisijungsite prie šios svetainės naudodami savo narystės paskyrą ir slaptažodį, ši svetainė turi teisę atšaukti jūsų paskyrą. Panaikinus paskyrą, ši svetainė turi teisę atverti atitinkamą nario vardą kitiems vartotojams registracijai ir naudojimui.",
        content72: "5.9 Ši svetainė užtikrina jūsų skaitmeninio turto saugumą stiprindama investicijas į technologijas, gerindama saugumo priemones ir kitas priemones bei iš anksto įspės jus, kai jūsų paskyroje iškils numatomos saugumo rizikos.",
        content73: "5.10 Ši svetainė turi teisę bet kuriuo metu ištrinti visą informaciją apie šios svetainės turinį, kuri neatitinka įstatymų ir taisyklių arba šios svetainės nuostatų.",
        content74: "5.11 Ši svetainė turi teisę prašyti jūsų pateikti daugiau informacijos ar medžiagos pagal jūsų suverenios šalies ar regiono įstatymus, taisykles, įsakymus ir kitus reguliavimo reikalavimus bei imtis pagrįstų priemonių, kad atitiktumėte jūsų vietinius reguliavimo reikalavimus yra įpareigoti bendradarbiauti, ši svetainė turi teisę sustabdyti arba visam laikui sustabdyti kai kurių ar visų šios svetainės paslaugų teikimą jums pagal jūsų suverenios šalies ar regiono įstatymų, taisyklių, įsakymų ir kitų specifikacijų reikalavimus.",
        content75: "5.12 Ši svetainė pasilieka teisę uždaryti jūsų paskyrą savo nuožiūra, įspėjusi prieš septynias darbo dienas. Todėl turite septynias darbo dienas atšaukti užsakymus ir uždaryti pozicijas. Jei neatšaukėte užsakymo ir neuždarėte pozicijos pasibaigus terminui, mes priverstinai atšauksime užsakymą ir uždarysime poziciją, o likusį skaitmeninį turtą jūsų paskyroje grąžinsime jums.",
        content76: "5.13 Siekdama apsaugoti prekybininkų teises ir interesus, ši svetainė turi teisę koreguoti internetinius produktus esant ypatingoms aplinkybėms (tokioms kaip sistemos gedimas, tinklo gedimas, ekstremalios rinkos sąlygos ir kt.), pvz.: išankstinio pristatymo sutartys. ir atsiskaitymas, sutarčių tipai dėl išankstinio pristatymo ir atsiskaitymo, Sutarties laikotarpis ir atsiskaitymas bei pristatymo kaina skelbiami šioje svetainėje.",
        content77: "6. Kompensacija",
        content78: "6.1 Jokiu būdu mūsų atsakomybė jums už tiesioginę žalą neviršys bendrų paslaugų mokesčių, kuriuos apmokestinome už naudojimąsi šia svetaine tris (3) mėnesius.",
        content79: "6.2 Jei pažeidžiate šią Sutartį ar kitus įstatymus ir nuostatas, turite mums kompensuoti bent 2 mln. JAV dolerių ir padengti visas patirtas išlaidas (įskaitant advokato mokesčius ir kt., jei to nepakanka faktiniams nuostoliams padengti). turi tai kompensuoti.",
        content80: "7. Teisė reikalauti, kad būtų panaikinta teisinė pagalba",
        content81: "Mes ir jūs abu pripažįstame, kad bendrosios teisės gynimo priemonių, susijusių su jūsų sutarties pažeidimu arba galimu sutarties pažeidimu, gali nepakakti kompensuoti visus mūsų patiriamus nuostolius, todėl turime teisę prašyti uždraudimo, kaip tai leidžia bendroji teisė arba teisingumas. jūsų sutarties pažeidimo ar galimo sutarties pažeidimo atveju.",
        content82: "8. Atsakomybės apribojimas ir atsakomybės atsisakymas",
        content83: "8.1 Jūs suprantate ir sutinkate, kad jokiomis aplinkybėmis nebūsime atsakingi už šiuos dalykus:",
        content84: "8.1.1 Negautas pajamas;",
        content85: "8.1.2 Prekybos pelnas arba sutarties nuostoliai;",
        content86: "8.1.3 Nuostoliai, atsiradę dėl verslo nutraukimo;",
        content87: "8.1.4 Tikėtinų piniginių santaupų praradimas;",
        content88: "8.1.5 Nuostoliai dėl informacijos problemų;",
        content89: "8.1.6 Galimybės, prestižo ar reputacijos praradimas;",
        content90: "8.1.7 Duomenų sugadinimas arba praradimas;",
        content91: "8.1.8 Pakaitinių produktų ar paslaugų pirkimo išlaidos;",
        content92: "8.1.9 Bet kokie netiesioginiai, specialūs ar atsitiktiniai nuostoliai ar žala, atsirandanti dėl delikto (įskaitant aplaidumą), sutarties pažeidimo ar bet kokios kitos priežasties, neatsižvelgiant į tai, ar mes galime pagrįstai numatyti tokius nuostolius ar žalą, ar apie tai buvome informuoti, ar ne avansas Tokio praradimo ar sugadinimo galimybė egzistuoja.",
        content93: "8.1.1–8.1.9 straipsniai yra nepriklausomi vienas nuo kito.",
        content94: "8.2 Jūs suprantate ir sutinkate, kad mes nesame atsakingi už jokią žalą, padarytą jums dėl bet kurios iš šių aplinkybių:",
        content95: "8.2.1 Konkrečiuose jūsų sandoriuose gali būti didelių įstatymų arba sutarties pažeidimų.",
        content96: "8.2.2 Įtariama, kad jūsų elgesys šioje svetainėje yra neteisėtas arba amoralus.",
        content97: "8.2.3 Išlaidos ir nuostoliai, patirti perkant ar gavus bet kokius duomenis, informaciją ar atliekant operacijas naudojantis šios svetainės paslaugomis arba atliekant pakaitinius veiksmus.",
        content98: "8.2.4 Jūsų neteisingas šios svetainės paslaugų supratimas.",
        content99: "8.2.5 Bet kokius kitus nuostolius, susijusius su šios svetainės teikiamomis paslaugomis, kurie nėra mūsų kaltės.",
        content100: "8.3 Mes atsakome už bet kokią žalą, padarytą dėl informacinio tinklo įrangos priežiūros, informacinio tinklo ryšio gedimo, kompiuterio, ryšio ar kitos sistemos gedimo, elektros energijos tiekimo gedimo, oro sąlygų, nelaimingų atsitikimų, streikų, darbo ginčų, riaušių, sukilimų, riaušių, nepakankamo produktyvumo ar gamybos. medžiagos, gaisrai, potvyniai, audros, sprogimai, karai, banko ar kitos partnerių priežastys, skaitmeninio turto rinkos žlugimas, vyriausybės veiksmai, teismų ar administracinių agentūrų įsakymai, kiti veiksmai, kurių mes negalime kontroliuoti arba kurių negalime kontroliuoti, arba trečiosios šalies priežastys Mes neprisiimame jokios atsakomybės už paslaugų teikimo negalėjimą ar vėlavimą arba jūsų nuostolius.",
        content101: "8.4 Mes negalime garantuoti, kad visa šioje svetainėje esanti informacija, programos, tekstai ir kt. yra visiškai saugūs ir be jokių virusų, Trojos arklių ir kitų kenkėjiškų programų trikdžių ir žalos. Todėl galite prisijungti ir naudotis bet kokiomis paslaugomis svetainę ar atsisiųsti ir naudoti bet kokias atsisiųstas programas, informaciją, duomenis ir pan., yra jūsų asmeniniai sprendimai, o riziką ir galimus nuostolius prisiimate patys.",
        content102: "8.5 Mes neprisiimame jokių garantijų ar įsipareigojimų dėl bet kokios trečiųjų šalių svetainių, susietų su šia svetaine, informacijos, produktų ir paslaugų ar bet kokio kito turinio, kuris mums nepriklauso, jei naudojatės trečiųjų šalių teikiamomis paslaugomis ir informacija -Šalių svetainės ir produktai yra jūsų asmeniniai sprendimai ir jūs prisiimate visą su tuo susijusią atsakomybę.",
        content103: "8.6 Mes nesuteikiame jokių aiškių ar numanomų garantijų, kad naudojatės šioje svetainėje teikiamomis paslaugomis, įskaitant, bet neapsiribojant, šioje svetainėje teikiamų paslaugų tinkamumą, klaidų ar praleidimų nebuvimą, tęstinumą, tikslumą, patikimumą ir tinkamumą konkretus tikslas. Tuo pačiu metu mes neprisiimame jokių įsipareigojimų ar garantijų dėl technologijos ir informacijos, susijusios su šios svetainės teikiamomis paslaugomis, galiojimo, tikslumo, teisingumo, patikimumo, kokybės, stabilumo, išsamumo ir savalaikiškumo. Ar prisijungti, ar naudotis šios svetainės teikiamomis paslaugomis, yra jūsų asmeninis sprendimas ir jūs prisiimate savo riziką bei galimus nuostolius. Mes nesuteikiame jokių aiškių ar numanomų garantijų dėl skaitmeninio turto rinkos, vertės ir kainos savo asmeninę laisvę rinkitės ir spręskite prisiimdami riziką ir galimus nuostolius.",
        content104: "8.7 Šioje Sutartyje išdėstytos mūsų garantijos ir įsipareigojimai yra vienintelės mūsų garantijos ir pareiškimai, susiję su šia Sutartimi ir šioje svetainėje teikiamomis paslaugomis, ir pakeičia visas garantijas ir įsipareigojimus, atsirandančius bet kokiu kitu būdu ir būdu, nesvarbu, ar raštu, ar žodžiu. , išreikštas ar numanomas. Visos šios garantijos ir pareiškimai reiškia tik mūsų įsipareigojimus ir garantijas ir negarantuoja, kad bet kuri trečioji šalis laikysis šioje Sutartyje nustatytų garantijų ir įsipareigojimų.",
        content105: "8.8 Mes neatsisakome jokių šioje Sutartyje nenurodytų teisių apriboti, pašalinti ar kompensuoti savo atsakomybę už žalą, kiek tai taikoma pagal galiojančius įstatymus.",
        content106: "8.9 Užsiregistravę sutinkate su bet kokiomis operacijomis, kurias atliekame pagal šioje sutartyje nustatytas taisykles, o bet kokią riziką prisiimate jūs.",
        content107: "9. Sutarties nutraukimas",
        content108: "9.1 Ši svetainė turi teisę nutraukti visas šios svetainės paslaugas pagal šią sutartį. Ši sutartis bus nutraukta tą dieną, kai bus nutrauktos visos šios svetainės paslaugos.",
        content109: "9.2 Nutraukus šią Sutartį, jūs neturite teisės reikalauti, kad ši svetainė ir toliau teiktų jai kokias nors paslaugas arba vykdytų bet kokius kitus įsipareigojimus, įskaitant, bet tuo neapsiribojant, reikalavimą, kad ši svetainė saugotų arba atskleistų jums bet kokią informaciją savo pradinėje svetainėje. paskyrą jums arba trečioji šalis persiunčia bet kokią informaciją, kurios ji neperskaitė ar neišsiuntė.",
        content110: "9.3 Šios sutarties nutraukimas neturės įtakos stebinčios šalies reikalavimui prisiimti kitas įsipareigojimus iš pažeidusios šalies.",
        content111: "10. Intelektinės nuosavybės teisės",
        content112: "10.1 Visi intelektiniai laimėjimai, esantys šioje svetainėje, apima svetainių logotipus, duomenų bazes, svetainių dizainą, tekstą ir grafiką, programinę įrangą, nuotraukas, vaizdo įrašus, muziką, garsus ir jų derinius, programinės įrangos rinkinius, susijusius šaltinio kodus ir programinę įrangą (įskaitant mažą, bet tuo neapsiribojant). Programų ir scenarijų intelektinės nuosavybės teisės) priklauso šiai svetainei. Jūs negalite atgaminti, keisti, kopijuoti, siųsti ar naudoti bet kokios anksčiau pateiktos medžiagos ar turinio komerciniais tikslais.",
        content113: "10.2 Visos teisės, nurodytos šios svetainės pavadinime (įskaitant, bet neapsiribojant, prestižą ir prekių ženklus, logotipus) priklauso įmonei.",
        content114: "10.3 Jūsų sutikimas su šia sutartimi bus laikomas jūsų iniciatyva perduoti bet kokios formos informacijos, kurią skelbiate šioje svetainėje, autorių teises, įskaitant, bet neapsiribojant: atgaminimo teises, platinimo teises, nuomos teises, parodos teises, atlikimo teises, peržiūros teises. , transliavimo teisės, informacijos sklaidos informacijos tinkle teisės, filmavimo teisės, pritaikymo teisės, vertimo teisės, kompiliavimo teisės ir kitos perleidžiamos teisės, kuriomis turėtų naudotis autorių teisių savininkas, šiai svetainei yra perduodamos nemokamai nepriklausomą ieškinį bet kuriam subjektui dėl pažeidimo ir gauti visą kompensaciją. Ši sutartis galioja bet kokiam autorių teisių įstatymų saugomam kūriniui, kurį skelbiate šioje svetainėje, nesvarbu, ar turinys suformuotas iki šios sutarties pasirašymo, ar po šios sutarties pasirašymo.",
        content115: "10.4 Naudodamiesi šios svetainės paslaugomis, neteisėtai nenaudosite šios svetainės ar kitų intelektinės nuosavybės teisių ar jomis disponuosite. Jūs negalite skelbti ar leisti kitoms svetainėms (ir žiniasklaidos priemonėms) bet kokia forma naudoti šioje svetainėje paskelbtos informacijos.",
        content116: "11. Informacijos apsauga",
        content117: "Pirmenybę teikia šioje svetainėje atskirai paskelbtų „Privatumo sąlygų“ nuostatos.",
        content118: "12. Skaičiavimas",
        content119: "Visi sandorių skaičiavimo rezultatai buvo mūsų patikrinti, visi skaičiavimo metodai buvo paskelbti svetainėje, tačiau negalime garantuoti, kad naudojimasis svetaine nebus pertraukiamas ar be klaidų.",
        content120: "13. Eksporto kontrolė",
        content121: "Jūs suprantate ir pripažįstate, kad pagal atitinkamus įstatymus jums neleidžiama eksportuoti, reeksportuoti, importuoti ar perkelti jokios medžiagos (įskaitant programinę įrangą) šioje svetainėje, todėl garantuojate, kad aktyviai neįgyvendinsite, nepadėsite ar nedalyvausite aukščiau paminėtą eksportą ar susijusią veiklą, kuri pažeidžia įstatymus ir kitus teisės aktus, jei tokia situacija aptinkama, turėtumėte apie tai mums laiku pranešti ir padėti ją išspręsti.",
        content122: "14. Perdavimas",
        content123: "Šioje Sutartyje numatytos teisės ir pareigos taip pat saistomos šalių, kurios naudojasi tokiomis teisėmis ir pareigomis, perėmėjams, įpėdiniams, vykdytojams ir administratoriams. Negalite jos perduoti jokiai trečiajai šaliai be mūsų sutikimo, tačiau mes galime bet kada perduoti savo teises ir įsipareigojimus pagal šią Sutartį bet kuriai trečiajai šaliai ir pranešti apie tai.",
        content124: "15. Dalomumas",
        content125: "Jei kuri nors šios Sutarties nuostata bet kurio kompetentingos jurisdikcijos teismo bus pripažinta neįgyvendinama, negaliojančia ar neteisėta, tai neturės įtakos likusių šios Sutarties nuostatų galiojimui.",
        content126: "16. Ne agentūriniai santykiai",
        content127: "Nelaikoma, kad jokia šios Sutarties nuostata sukuria, numano ar kitaip nelaiko mus jūsų agentu, patikėtiniu ar kitu atstovu, nebent šioje Sutartyje numatyta kitaip.",
        content128: "17. Susilaikymas",
        content129: "Mūsų ar jūsų bet kurios šalies atsakomybės už sutarties pažeidimą ar kitos atsakomybės, numatytos šioje Sutartyje, atsisakymas negali būti laikomas ir interpretuojamas kaip kitos atsakomybės už sutarties pažeidimą atsisakymas gali būti suprantamas kaip atsisakymas nuo tokių teisių ar teisių gynimo priemonių.",
        content130: "18. Pavadinimas",
        content131: "Visos antraštės skirtos tik susitarimo išraiškos patogumui ir nenaudojamos siekiant išplėsti ar apriboti sutarties sąlygų turinį ar apimtį.",
        content132: "19. Taikytina teisė",
        content133: "19.1 Prieš pareikšdami ginčą ar pretenziją, jūs pripažįstate ir sutinkate susisiekti su šia svetaine el. paštu, kad ginčas būtų išspręstas neoficialiai. Mes stengsimės kuo greičiau išspręsti jūsų ginčą sąžiningų derybų būdu ( diskusijos turinys turi būti konfidencialus ir saugomas pagal galiojančias taisykles, kad nebūtų naudojamas kaip įrodymas bet kokiame teismo procese).",
        content134: '19.2 Jūs pripažįstate ir sutinkate, kad kilus bet kokiam ginčui, nesutarimui, nesutarimui ar pretenzijai, įskaitant šių Sąlygų egzistavimą, galiojimą, aiškinimą, vykdymą, pažeidimą ar nutraukimą arba bet kokį ginčą, kylantį iš šių Sąlygų arba susijusių su jomis ("Ginčas" “), aukščiau nurodytos šalys perduoda ginčą Tarptautinių prekybos rūmų Tarptautiniam arbitražo teismui (toliau – TLK tarptautinis arbitražo teismas), vadovaudamosi tuo metu galiojusiomis „ICC Arbitražo taisyklėmis“. Arbitražo vieta yra Belizas, o taikytina arbitražo teisė nustatoma pagal Tarptautinių prekybos rūmų arbitražo taisykles. Jeigu šalys nesusitaria kitaip, pagal TBT arbitražo taisykles bus skiriamas tik vienas arbitras. Arbitras turi išimtinę teisę priimti sprendimą dėl savo jurisdikcijos, įskaitant, bet neapsiribojant, bet kokius prieštaravimus dėl arbitražinio susitarimo egzistavimo, taikymo srities ar galiojimo arba bet kokio reikalavimo ar priešpriešinio reikalavimo arbitražo. Bet koks arbitražas vyks anglų kalba. Nepriklausomai nuo to, kaip vyksta arbitražas, arbitras priima rašytinį sprendimą, kuris yra pagrįstas ir pakankamas, kad paaiškintų sprendimą ir esmines išvadas, jei tokių yra, kuriomis grindžiamas sprendimas. Arbitražo sprendimas yra galutinis, šalims privalomas ir gali būti vykdomas bet kuriame kompetentingos jurisdikcijos teisme.',
        content135: "20. Sutarties veiksmingumas ir aiškinimas",
        content136: "20.1 Ši sutartis įsigalioja, kai spustelėsite šios svetainės registracijos puslapį, kad sutiktumėte registruotis, užbaigtumėte registracijos procesą ir gautumėte šios svetainės paskyrą bei slaptažodį, ir yra privaloma tiek šiai svetainei, tiek jums.",
        content137: "20.2 Galutinė šios sutarties aiškinimo teisė priklauso šiai svetainei.",
        content138: "Žinokite savo klientų ir pinigų plovimo prevencijos politiką",
        content139: "1. Įvadas",
        content140: "1.1 Pažadame atidžiai laikytis įstatymų ir teisės aktų, susijusių su „Pažink savo klientą“ ir pinigų plovimo prevenciją, ir tyčia nepažeisime „Pažink savo klientą ir kovos su pinigų plovimu politikos“. Savo pagrįstos kontrolės ribose imsimės reikiamų priemonių ir technologijų, kad galėtume teikti jums saugias paslaugas ir kiek įmanoma labiau apsaugoti jus nuo nuostolių, kuriuos sukelia nusikalstama veika įtariamų asmenų pinigų plovimo elgesys.",
        content141: "1.2 Mūsų „Pažink savo klientą“ ir kovos su pinigų plovimu politika yra išsami tarptautinė politikos sistema, apimanti skirtingų teisinių jurisdikcijų, su kuriomis esate susiję, „Pažink savo klientą“ ir kovos su pinigų plovimu politiką.",
        content142: "2. Žinokite, kad jūsų klientų ir pinigų plovimo politika yra tokia:",
        content143: "2.1. Paskelbkite savo kliento pažinimo ir kovos su pinigų plovimu politiką ir retkarčiais ją atnaujinkite, kad atitiktų atitinkamų įstatymų ir kitų teisės aktų nustatytus standartus;",
        content144: "2.2 Paskelbti ir atnaujinti kai kuriuos pagrindinius šios svetainės veikimo principus ir taisykles, o mūsų darbuotojai teiks paslaugas pagal šių principų ir taisyklių gaires;",
        content145: "2.3 Sukurti ir užbaigti vidines sandorių stebėjimo ir kontrolės procedūras, pvz., tapatybės tikrinimas griežtomis priemonėmis ir profesionalios komandos, atsakingos už kovos su pinigų plovimu, sudarymas;",
        content146: "2.4. Naudoti rizikos prevencijos metodus deramam klientų patikrinimui ir nuolatinei priežiūrai atlikti;",
        content147: "2.5 peržiūrėti ir reguliariai tikrinti įvykusias operacijas;",
        content148: "2.6 Pranešti apie įtartinus sandorius kompetentingoms institucijoms;",
        content149: "2.7 Asmens tapatybę patvirtinantys dokumentai, adresą įrodantys dokumentai ir operacijų įrašai bus saugomi mažiausiai šešerius metus ir bus pateikti reguliavimo institucijoms be papildomo įspėjimo.",
        content150: "3. Tapatybės informacija ir patikrinimas",
        content151: "3.1 Tapatybės informacija",
        content152: "3.1.1 Atsižvelgiant į skirtingus teisės aktus įvairiose jurisdikcijose ir skirtingų tipų subjektuose, jūsų renkamos informacijos turinys iš principo bus renkamas iš registruotų asmenų:",
        content153: "Pagrindinė asmeninė informacija: jūsų vardas, pavardė, adresas (ir nuolatinis adresas, jei skiriasi), gimimo data ir pilietybė bei kita turima informacija. Asmens tapatybės patvirtinimas turėtų būti pagrįstas oficialių ar kitų panašių institucijų išduotais dokumentais, tokiais kaip pasai, asmens tapatybės kortelės ar kiti tapatybės dokumentai, kurių reikalaujama ir kuriuos nustato skirtingos jurisdikcijos. Jūsų nurodytas adresas bus patikrintas naudojant atitinkamus metodus, pavyzdžiui, patikrinus keleivinio transporto bilietą ar palūkanų normą arba patikrinus rinkėjų registrą.",
        content154: "Galiojanti nuotrauka: prieš registruodamiesi turite pateikti savo nuotrauką, kurioje laikote asmens dokumentą prie krūtinės;",
        content155: "Kontaktiniai duomenys: telefono / mobiliojo telefono numeris ir (arba) galiojantis el. pašto adresas.",
        content156: "3.1.2 Jei esate įmonė ar kitas juridinis asmuo, mes rinksime šią informaciją, kad galėtume identifikuoti jus arba galutinį patikos sąskaitos gavėją.",
        content157: "Įmonės įstatų ir memorandumo, patvirtinančios įmonės nuosavybės struktūrą, kopijas, įrodančius įgaliotojo atstovaujamojo valdybos nutarimą, kuris lemia sąskaitos atidarymą ir vykdymą šioje įmonėje; direktoriai, kuriuos reikia pateikti pagal poreikį, pagrindinio akcininko ir asmens, įgalioto pasirašyti sąskaitą šioje svetainėje, tapatybę patvirtinančius dokumentus, jei jis skiriasi nuo bendrovės pašto adreso, nurodykite pašto adresą. Jei įmonės vietinis adresas nesutampa su pagrindiniu įmonės adresu, ji laikoma didesnės rizikos klientu ir turės pateikti papildomus dokumentus.",
        content158: "Atsižvelgdami į skirtingus reglamentus įvairiose jurisdikcijose ir skirtingų tipų subjektuose, mums reikia kitų institucijų išduotų sertifikatų ir dokumentų, taip pat dokumentų, kuriuos laikome reikalingais.",
        content159: "3.1.3 Priimame tik anglišką arba kinišką tapatybės informaciją. Jei ne, išverskite savo tapatybės informaciją į anglų kalbą ir patvirtinkite ją notaro.",
        content160: "3.2 Patvirtinimas ir patikrinimas",
        content161: "3.2.1 Reikalaujame, kad pateiktumėte visą savo asmens dokumento puslapio turinį.",
        content162: "3.2.2 Prašome pateikti nuotrauką, kurioje jūs laikote savo asmens dokumentą prie krūtinės.",
        content163: "3.2.3 Patvirtinamųjų dokumentų kopijas paprastai reikia patikrinti su originaliais kvitais. Tačiau kopija yra priimtina, jei tinkamas ir patikimas sertifikuotojas gali patvirtinti, kad kopija yra tiksli ir išsami originalaus dokumento kopija. Tokie sertifikuotojai yra ambasadoriai, teismų komisarai, vietos magistratai ir kt.",
        content164: "3.2.4 Galutinio naudos gavėjo nustatymo ir sąskaitos kontrolės reikalavimai yra nustatyti, kurie asmenys galiausiai priklauso tiesioginiam klientui arba jį kontroliuoja, ir (arba) nustatyti, kad vykdomos operacijos vykdomos kitų vardu. Jei tai verslas, turėtų būti patikrinta pagrindinių akcininkų tapatybė (pavyzdžiui, turinčių 10 % ar daugiau balsavimo akcijų). Paprastai 25 % akcijų turėjimas bus laikomas normalia rizika, o akcininko tapatybė turi būti patikrinta, turintis 10 % akcijų arba turintis daugiau balsavimo teisių ar akcijų, ir yra laikoma didelės rizikos situacija; akcininkas turi būti patikrintas.",
        content165: "4. Stebėkite sandorius",
        content166: "4.1 Mes nustatome ir karts nuo karto koreguojame maksimalius dienos operacijų ir valiutos išėmimo limitus, atsižvelgdami į saugumą ir faktines operacijos sąlygas;",
        content167: "4.2 Jei sandoriai dažnai vyksta su registruotu vartotoju arba yra nepagrįstų aplinkybių, mūsų profesionali komanda įvertins ir nuspręs, ar jos yra įtartinos;",
        content168: "4.3 Jei, remdamiesi mūsų pačių nuomone, nustatome, kad sandoris yra įtartinas, galime imtis ribojančių priemonių, pvz., sustabdyti sandorį, atmesti sandorį arba net kuo greičiau atšaukti sandorį ir pranešti apie tai kompetentingai institucijai, bet jums nepraneš;",
        content169: "4.4 Pasiliekame teisę atmesti registracijos paraiškas iš asmenų, esančių jurisdikcijose, kurios neatitinka tarptautinių kovos su pinigų plovimu standartų, arba asmenų, kurie gali būti laikomi politiškai pažeidžiamais asmenimis. Mes pasiliekame teisę sustabdyti arba nutraukti sandorius, kurie yra įtartini mūsų pačių sandoriai sprendimą bet kuriuo metu, tačiau mes nepažeidžiame jokių įsipareigojimų ar įsipareigojimų jums.",
    },
    register: {
        formTitle1: "Sukurti",
        formTitle2: "Registruokitės naudodami savo el. pašto adresą arba mobiliojo telefono numerį",
        formTitle3: "sąskaita",
        field1: "Patvirtinimo kodas",
        field2: "Gauti",
        field3: "Patvirtinti slaptažodį",
        field4: "Pakvietimo kodas",
        field41: "(neprivaloma)",
        field5: "Perskaiciau ir sutinku",
        field6: "Paslaugų teikimo sąlygos",
        placeholder1: "įveskite patvirtinimo kodą",
        content7: "įveskite patvirtinimo kodą",
        content12: "Įveskite telefono numerį",
        content13: "Įveskite slaptažodį",
        content14: "prašome įvesti savo el",
        content18: "Dar kartą įveskite slaptažodį",
        content19: "Sutikite su paslaugų teikimo sąlygomis",
        content20: "Įveskite teisingą patvirtinimo kodą",
        content23: "Vis dar negavau patvirtinimo kodo？",
        content24: "Toliau įveskite patvirtinimo kodą",
        registerSuccess: "Registracija sėkminga",
        content25: "Slaptažodžiai nenuoseklūs",
        content26: "Įveskite teisingą el. pašto adresą",
        content27: "Slaptažodžio nustatymas iš naujo sėkmingas"
    },
    download: {
        content1: "Prekiaukite bet kada ir bet kur",
        content2: "Puikiai suderinamas su keliais terminalais, bet kuriuo metu tenkinantis įvairių scenarijų operacijų poreikius",
        content3: "Patikrinkite realiojo laiko rinkos sąlygas bet kuriuo metu",
        content4: "Lengvai pirkite ir parduokite BTC, ETH, XRP ir kitą skaitmeninį turtą",
        content5: "Gaukite išankstinius įspėjimus apie jums svarbių kriptovaliutų kainų pokyčius",
        content6: "Peržiūrėkite Bitcoin neatidėliotinų paskolų, ateities paskolų ir pasirinkimo sandorių kainas",
        content7: "Palyginkite kriptovaliutų kainas įvairiose biržose",
        title: "parsisiųsti",
        install: "Diegimo pastabos",
        one: "1. Leiskite atsisiųsti aprašo failus",
        two: "2. Aprašymo failas atsisiųstas",
        three: "3. Telefone įveskite [Nustatymai] ir raskite [Bendra]",
        four: "4. Aprašo failai ir įrenginių tvarkyklė",
        five: "5. Spustelėkite aprašo failą",
    },
    identity: {
        noAuthentication: {
            title: "Jaunesniojo pažymėjimas",
            pleaseSelectYourCountry: "Prašome pasirinkti savo šalįl",
            name: "vardas",
            enterName: "įveskite savo vardą",
            city: "miestas",
            enterCity: "Įveskite savo miestą",
            IDNumber: "asmens tapatybės dokumento numeris",
            enterIDNumber: "Įveskite savo ID numerį",
        },
        noAdvancedAuthentication: {
            title: "Išplėstinis sertifikavimas",
            infoCountry: "šalis / regionas",
            infoName: "vardas",
            infoNumber: "asmens tapatybės dokumento numeris",
            infoAddress: "adresu",
        },
        text: "nesertifikuotas",
        recertification: "pakartotinis sertifikavimas",
        certification: "Sertifikavimas",
        authenticationInProgress: "patikrinta",
        inAuthentication: "Vykdomas sertifikavimas",
        uploadTheFrontOfTheDocument: "Įkelkite priekinę asmens tapatybės dokumento dalį",
        uploadTheReverseSideOfTheDocument: "Įkelkite kitą sertifikato pusę",
        uploadAPhotoOfAHandHeldID: "Įkelkite asmens tapatybės dokumento nuotrauką",
        pleaseUpload: "Prašau ",
        pleaseCompleteOrdinaryCertificationFirst: "Reikia palaukti, kol bus sėkminga pirminė sertifikavimo peržiūra",
        refused: "Atstumtas",
        reason: "priežastis",
        succeeded: "pavyko",
        uploading: "įkėlimas",
        newTitle: "Pasirinkite šalį, kurioje buvo išduotas jūsų ID",
        pleaseOrdinaryCertificationFirst: "Pirmiausia atlikite pirminį sertifikavimą",
        text1: "Keisti avatarą",
        text2: "Slapyvardis",
        text3: "sąskaitą",
        text4: "Slapyvardžio modifikavimas",
        text5: "naujas slapyvardis",
        text6: "Įveskite savo naują slapyvardį",
        text7: "Senas slaptažodis",
        text8: "Įveskite seną slaptažodį",
        text9: "Naujas Slaptažodis",
        text10: "Įveskite naują slaptažodį",
        text11: "Patvirtinti slaptažodį",

        text12: "Dar kartą įveskite naują slaptažodį",
        text13: "nauja pašto dėžutė",
        text14: "Patvirtinimo kodas",
        text15: "įveskite patvirtinimo kodą",
        text16: "savo mobiliojo telefono numerį",
        text17: "Naujas mobiliojo telefono numeris",
        text18: "prašome įvesti savo telefono numerį",
        text19: "Mobiliojo telefono patvirtinimo kodas",
        text20: "Jaunesniojo pažymėjimas",
        text21: "Patvirtinkite savo tapatybės informaciją",
        text22: "Šalis",
        text23: "Miestas",
        text24: "Įveskite savo miestą",
        text25: "Įveskite savo vardą",
        text26: "Jūsų ID numeris",
        text27: "Įveskite savo ID numerį",
        text28: "Asmens tapatybės kortelės priekinė nuotrauka",
        text29: "Spustelėkite, kad įkeltumėte dokumentus",
        text30: "įkelti iš naujo",
        text31: "įkelti",
        text32: "Laikant asmens tapatybės nuotrauką",
        text33: "Spustelėkite, kad įkeltumėte savo ID nuotrauką",
        text34: "Susiejote „Google Authenticator“.",
        text35: "Raktas",
        text36: "Įveskite „Google“ patvirtinimo kodą",
        text37: "siųsti",
        text38: "Negali būti tuščias",
        text39: "El. pašto formatas neteisingas",
        text40: "Du slaptažodžiai nesuderinami",
        text41: "Nukopijuota sėkmingai",
        text42: "Kopijuoti nepavyko",
        text43: "Avataro modifikacija",
        text44: "pakeisti slaptažodį",
        text45: "El. pašto įrišimas",
        text46: "Keisti el",
        text47: "Mobiliojo telefono įrišimas",
        text48: "Modifikuoti mobilųjį telefoną",
        text49: "Google patvirtinimas",
        text50: "Susieti Google Authenticator",
        text51: "Įveskite „Google“ patvirtinimo kodą",
        text52: "sutaupyti",
        text53: "Patvirtinkite pateikimą",
        text54: "Peržiūrėk",
        text55: "privalomas",
        text56: "Išjunkite „Google“ patvirtinimą",
        text57: "Kitas žingsnis",
        text58: "Baigti",
        text59: "„Google“ patvirtinimas yra privalomas",
        text60: "„Google“ patvirtinimas buvo atsietas",
        text61: "Pirminis sertifikatas pateiktas",
        text62: "Išplėstinis sertifikatas pateiktas",
        text63: "Vaizdas neįkeltas",
        text64: "Persiūsti",
        text65: "Įveskite slapyvardį",
        text66: "Slapyvardis sėkmingai išsaugotas",
        text67: "Dar nėra įkeltų vaizdų",
        text68: "Slaptažodžio nustatymas iš naujo baigtas",
        text69: "El. paštas sėkmingai pakeistas",
        text70: "Avataras sėkmingai išsaugotas",
        text71: "Įkelti paveikslėlį",
        text72: "savo pašto dėžutę",
        text73: "Įveskite savo naują el",
        text74: "Tavo vardas",
        text75: "Eikite į autentifikavimą",
        text76: "Patvirtinkite savo asmens tapatybės dokumento nuotrauką"
    },
    retrievePassword: {
        formTitle1: "Atstatyti slaptažodį",
        formTitle2: "Jūsų turto saugumo sumetimais operacijos draudžiamos per 24 valandas po slaptažodžio pakeitimo.",
        formTitle3: "Atkurti slaptažodį",
        formTitle4: "Patvirtinkite pakeitimus",
        formTitle5: "Įveskite seną slaptažodį",
        formTitle6: "Įveskite naują slaptažodį"
    },
    home: {
        title1: "Populiariausia kriptovaliutų prekybos platforma",
        title3: "kodėl ",
        title4: " Tai didelio našumo atvirojo kodo blokų grandinės platforma, sukurta palaikyti ir valdyti saugią, suderinamą ir nuspėjamą skaitmeninę infrastruktūrą.",
        field1: "Įsteigta birža",
        field2: "Skaitmeninio turto finansinės paslaugos",
        field3: "Dešimtys milijonų vartotojų",
        field4: "Įsteigti kelis investuotojų apsaugos fondus",
        field5: "Greita prekyba",
        field6: "”CME Gruop“ gali suderinti pavedimus su itin mažu delsimu, kad gautų geriausią prekybos patirtį.",
        title5: "rinkos tendencija",
        title6: "Žiūrėti daugiau",
        table1: "Valiuta",
        table2: "Naujausia kaina",
        table3: "24h pakilimai ir nuosmukiai",
        table4: "24h prekybos apimtis",
        table5: "24h operacijų apimtis",
        table6: "Citatos",
        title7: "apie ",
        title8: " Esame įsipareigoję vartotojams teikti pažangiausius prekybos įrankius, novatoriškas technologijas ir neprilygstamos kokybės klientų aptarnavimą.",
        title9: "Skaitmeninio turto sritis keičiasi su kiekviena diena. Tik būdami toliaregiai ir sparčiai tobulėdami galime neatsilikti nuo technologinių naujovių tempo.",
        title901: " Pažangiausių skaitmeninio turto prekybos paslaugų teikimas mūsų vartotojams ir pasauliniams likvidumo tiekėjams.",
        title10: "Esame tvirtai įsitikinę, kad kiekvienas vartotojas nusipelno geriausios prekybos patirties.",
        title11: "Saugumas, kuriuo galite pasitikėti",
        title12: "Mūsų sudėtingos saugumo priemonės ir SAFU fondai apsaugo jūsų skaitmeninį turtą nuo visų pavojų. ",
        title13: "Geriausi sandorių tarifai",
        title14: "Lengvatiniai sandorių tarifai, konkurencingos VIP teisės ir mėgaukitės aukščiausios kokybės paslaugomis.",
        title15: "24*7 valandų klientų aptarnavimo palaikymas",
        title16: "24/7 bet kuriuo oru, visu etatu, mes kuo greičiau atsakysime į jūsų užklausas.",
        title17: "0 mokestis prekybai",
        title18: "Norėdami saugiai ir greitai prekiauti skaitmeninėmis valiutomis be nulinių tvarkymo mokesčių, naudokite kelis mokėjimo būdus.",
        title19: "Prekiaukite bet kada ir bet kur",
        title20: "Puikiai suderinamas su keliais terminalais, bet kuriuo metu tenkinantis įvairių scenarijų operacijų poreikius",
        title21: "Patikrinkite realiojo laiko rinkos sąlygas bet kuriuo metu",
        title22: "Lengvai pirkite ir parduokite BTC, ETH, XRP ir kitą skaitmeninį turtą",
        title23: "Gaukite išankstinius įspėjimus apie jums svarbių kriptovaliutų kainų pokyčius",
        title24: "Peržiūrėkite Bitcoin neatidėliotinų paskolų, ateities paskolų ir pasirinkimo sandorių kainas",
        title25: "Palyginkite kriptovaliutų kainas įvairiose biržose",
        title26: "Užsiregistruokite dabar",
        title27: "doleris",
        title28: "24 valandų prekybos apimtis",
        title29: "Integruotas likvidumo tiekėjas",
        title30: "Vartotojas",
        title31: "Daugiau",
        title32: "„Binance Loan“ pridėjo nauju hipotekos turtu CTSI ir KSM ir pradėjo taikyti palūkanų normos mažinimo nuolaidas BUSD ir USDT!",
        title33: "Mūsų pranašumas",
        title34: "saugus ir stabilus",
        title35: "Aukščiausia techninė komanda, visapusiška saugumo apsauga, nepriklausomi tyrimai ir didelės spartos transakcijų sintezės variklio plėtra, vis tiek gali stabiliai ir patikimai veikti atliekant didžiulius vienu metu vykstančius sandorius.",
        title36: "Profesionalus atitikimas",
        title37: "Profesionali operacijų komanda, turinti ilgametę patirtį blockchain ir finansų srityje, turi suderinamą skaitmeninio turto prekybos licenciją ir 100% rezervo garantiją.",
        title38: "Pirmiausia vartotojas",
        title39: "Susidūrimas su pasauline rinka, kelių kalbų palaikymas, darbas 24 valandas per parą, 7 dienas per savaitę, stiprus bendruomenės palaikymas ir profesionalus klientų aptarnavimas.",
        title40: "didelio našumo",
        title41: "300 000 operacijų per sekundę, o užsakymo atsako laikas trumpesnis nei 1 milisekundė.",
        title42: "apie mus",
        title43: "Prekiaukite bet kada ir bet kur",
        title44: "Puikiai suderinamas su keliais terminalais, bet kuriuo metu tenkinantis įvairių scenarijų operacijų poreikius",
        title45: "(Norėdami atsisiųsti, nuskaitykite QR kodą)",
        title46: "Skaitmeninės valiutos naujienos",
        title47: "mūsų verslo partneris",
        title48: "Pralaimėtojų sąrašas",
        title49: "24h apyvarta",
        title50: "24h padidėjimas ir sumažėjimas"
    },
    quotation: {
        marketTrading: "Citatos",
        turnover: "Apimtis",
        optional: "Neprivaloma",
        contractMarket: "sutarčių rinka",
        currencyPair: "valiutų pora",
        latestPrice: "Uždarymo kaina",
        upsAndDowns: "Citatos pakeitimas",
        highest: "Aukščiausias",
        lowest: "Žemiausia",
        transactionAmount: "Sandorio apimtis",
        operate: "veikti",
        buy: "prekyba",
        topTurnovers: "Sandorių sąrašas",
        topGainers: "Gainerių sąrašas",
        tradingPair: "prekybos pora",
        buyOrder: "Pirkti",
        sellOrder: "pardavimo užsakymas"
    },
    accountChange: {
        demo: "modeliavimas",
        realAccount: "faktinė sąskaita",
        demoAccount: "Demonstracinė sąskaita",
        accountRole: "Paskyros vaidmuo",
        pleaseSelect: "Prašome pasirinkti",
        AccountChangeType: "Paskyros keitimo tipas",
        AccountType: "Paskyros tipas",
        currencyType: "Valiutos tipas",
        allType: "Visų rūšių",
        allAccount: "Visos sąskaitos",
        allCoin: "Visos valiutos",
        coin: "Valiuta",
        amount: "Keisti sumą",
        balance: "Sąskaitos balansas",
        time: "laikas",
        title: "Paskyros keitimo įrašai",
        filtrar: "filtras",
        check: "Pereiti prie",
        showOnly: "Rodyti tik valiutos atsargas",
        holdAssets: "Valiutos likutis",
        accountList: "Sąskaitų sąrašas"
    },
    contractTransaction: {
        chart: "diagramą",
        basicEdition: "Bazinė versija",
        depthMap: "Gylio žemėlapis",
        second: "Antra",
        minute: "tašką",
        day: "dangus",
        week: "savaitę",
        month: "mėnulis",
        open: "atviras",
        trade: "prekyba",
        buyLong: "ilgai pirkti",
        buyShort: "trumpas pirkimas",
        cannotBuy: "Ši valiuta nepalaiko pristatymo sutarčių operacijų",
        cannotBuy1: "Ši valiuta nepalaiko nuolatinės prekybos",
        tradingAssets: "prekybos turtas",
        amountAvailable: "Turimas kiekis",
        timeStr: "laikas",
        transactionPeriod: "prekybos laikotarpis",
        quantity: "kiekis",
        enterQuantity: "Įveskite kiekį",
        buyIn: "Eik ilgai",
        latestTransaction: "naujausia operacija",
        time: "laikas",
        direction: "kryptis",
        price: "kaina",
        sell: "trumpas",
        positionOrder: "Pristatymo užsakymas",
        historicalOrders: "Istoriniai užsakymai",
        product: "produktas",
        realAccount: "(tikra sąskaita)",
        demoAccount: "(Demonstracinė paskyra)",
        openingTime: "užsakymo laikas",
        openingPrice: "Atidarymo kaina",
        curretPrice: "Dabartinė kaina",
        liquidationPrice: "Uždarymo kaina",
        profitOrLoss: "Pelnas ir nuostoliai",
        endTime: "Uždarymo laikas",
        areYouSureToBuy: "Ar tikrai pirksite?？",
        areYouSureToLongBuy: "Ar tikrai norite eiti ilgai?？",
        areYouSureToShortBuy: "Ar tikrai norite nusipirkti trumpą?？",
        successfullyBought: "Pirk sėkmingai",
        minBuy: "Minimali įpirka",
        assets: "turto",
        withdraw: "pasitraukti",
        entrustedOrder: "Įsakymas",
        total: "viso",
        continueToPlaceOrder: "Tęskite užsakymą",
        orderConfirmation: "Užsakymo patvirtinimas",
        buyRu: "pirkti",
        sellChu: "parduoti",
        areYouSureToSell: "Ar tikrai parduosite?？",
        operation: "veikti",
        buyCover: "dangtelio padėtis",
        commitSuccess: "Sandoris sėkmingas",
        onlyEnterIntegers: "Galima įvesti tik sveikuosius skaičius",
        trend: "Tendencija",
        lotteryTime: "Užsakymo galutinis terminas",
        countdown: "Atgalinis skaičiavimas",
        period: "Sutrikimas",
        cycle: "Laiko tarpas",
        unlimited: "Neribota",
        put: "Put",
        call: "Call",
        pending: "Komisijoje",
        unopened: "Dar nenupieštas",
        opened: "Ištrauktas prizas",
        settlementPrice: "Atsiskaitymo kaina",
        rateOfReturn: "grąžos norma",
        currentPositions: "Dabartinė padėtis",
        positionQuantity: "Pozicijos kiekis",
        guarantee: "marža",
        text1: "kilti",
        text2: "rudenį",
        text3: "Laukia pirkimo",
        text4: "Užsakymo kiekis",
        text5: "Sandorių skaičius",
        text6: "Atšaukti",
        text7: "pristatymo laikas",
        text8: "Atšaukimo priminimas",
        text9: "Ar tikrai norite atšaukti užsakymą?",
        text10: "Neteisėtas kiekis",
        text11: "Užsakymo priminimas",
        text12: "Ar tikrai perkate bullish?",
        text13: "Ar esate tikri, kad pirksite puodą?",
        text14: "Atšaukimas sėkmingas"
    },
    mining: {
        title1: "Pasaulyje pirmaujanti „blockchain“ skaitmeninio turto kasybos platforma",
        title2: "Atviras ir skaidrus · saugus ir stabilus",
        title3: "Pasirinkite valiutą",
        title4: "Kasybos metinis pajamingumas",
        title5: "dangus",
        title6: "Kasybos laikotarpis",
        title7: "Produkto Aprašymas",
        btn: "Mano dabar",
        btn2: "uždarymas",
        tabs1: "vyksta",
        tabs2: "Išpirkta",
        table1: "produkto pavadinimas",
        table2: "Kasybos laikotarpis",
        table3: "Kasybos laikas",
        table4: "Kasybos kiekis",
        table5: "Galiojimo pabaigos data",
        table6: "Pereina iki brandos",
        table7: "Užsakymo būsena",
        egularMining: "Kasyba reguliariai",
        inProgress: "vyksta",
        redeemed: "Išpirkta",
        redemption: "išpirkimas",
        regularMining: "Kasyba reguliariai",
        earlyRedemption: "ankstyvas išpirkimas",
        need: "reikia",
        handlingFee: "tvarkymo mokestis",
        redemptionConfirmationMessage: "Iš anksto patvirtinkite, ar norite išpirkti？",
        form1: "Kasybos metinis pajamingumas",
        form2: "Kasybos laikotarpis",
        form3: "Kasybos valiuta",
        form4: "Prenumeratos suma (minimali/maksimali)",
        form5: "Išankstinio išpirkimo mokestis: ",
        form6: "pirkimo kaina",
        form7: "Turimos lėšos",
        form8: "pirkimo kaina",
        form9: "Turimos lėšos",
        form10: "Įveskite pirkimo sumą",
        form11: "visi",
        form12: "Numatomos pajamos",
        form13: "Nepakanka lėšų",
        form14: "Įveskite pirkimo sumą",
        form15: "Mano užsakymas",
        form16: "pajamos",
        form17: "užsakymo detalės",
        form18: "Ankstyvo išpirkimo instrukcijos:",
        form19: "Prenumeratos suma (minimali)",
        form20: "Prenumeratos suma (maksimali)",
        form21: "Laikomas kiekis",
        form22: "Pirkimo laikas",
        form23: "išpirkimo laikas",
        form24: "baigta",
        form25: "Prenumerata sėkminga",
        form26: "Išpirkimas sėkmingas",
        form27: "Lengvas finansų valdymas su daugiau nei 25% grąža, pranokstančia rinką, o įsigiję galite gauti atlygį",
        form28: "Pakvieskite draugus gauti 50 USD atlygį, o sutarčių kuponai taip pat gali grąžinti 20% komisinių."
    },
    assets: {
        content1: "Sąskaitos turtas",
        content2: "Šios dienos uždarbis",
        content3: "dabartinė paskyra",
        content4: "Tikroji sąskaita yra jūsų tikrasis sąskaitos turtas, kurį galima naudoti operacijoms ir pinigų išėmimams.",
        content5: "Išsiimti monetas",
        content6: "Įnešti monetas",
        content7: "mainai",
        content8: "perkėlimas",
        content9: "Prašome pasirinkti",
        content10: "Demonstracinė sąskaita",
        content11: "faktinė sąskaita",
        content12: "įsigyti turto",
        content13: "Ar tikrai norite pereiti prie tikros paskyros?",
        content14: "Ar tikrai norite pereiti prie demonstracinės paskyros?",
        content15: "sulaukti sėkmės",
        table1: "Valiuta",
        table2: "kiekis",
        table3: "užšaldyti",
        table4: "Yra",
        dialog1: "Produkto Aprašymas",
        dialog2: "1. Šį turtą leidžiama naudoti tik demonstracinės sąskaitos režimu",
        dialog3: "2. Siekiant išvengti piktnaudžiavimo, per mėnesį leidžiama tik vieną išpirkimą.",
        dialog4: "3. Gaunant turtą gauto turto suma bus pridėta prie pradinės sumos ir pozicijos statusas nebus keičiamas.",
        dialog5: "4. Jei turite kitų klausimų, susisiekite su klientų aptarnavimo tarnyba",
        dialog6: "uždarymas",
        dialog7: "Gaukite demonstracinį turtą dabar",
        dialog8: "perkelti į",
        dialog9: "pervesti",
        dialog10: "jungiklis",
        dialog11: "Vertinimas",
        dialog12: "Demonstracinis turtas",
        dialog13: "Mano sąskaita",
        dialog14: "Visas sąskaitos turtas",
        dialog15: "Turto apžvalga",
        dialog16: "Bendras turto vertinimas",
        dialog17: "Turto vertinimas",
        dialog18: "Šios dienos uždarbis",
        dialog19: "įšaldyti lėšas",
        dialog20: "Kiekvieną mėnesį galite gauti imituojamą turto kvotą",
        dialog21: "gauti",
        dialog22: "Jūs sėkmingai gavote",
        dialog23: '30 dienų sukauptos pajamos',
        dialog24: "Finansinis produktas",
        dialog25: "Mano finansų valdymas",
        dialog26: "Istorinis finansų valdymas",
        dialog27: "istorijos įrašas",
        dialog28: "Finansavimo įrašai"
    },
    validator: {
        dialog14: "tikrintuvas",
        dialog15: "Įrišimo tikrintuvas",
        dialog16: "patvirtinimo jungiklis",
        dialog17: "Patvirtinimo kodas",
        dialog18: "Tikrinimo QR kodas",
        dialog19: "patvirtinimo raktas",
        dialog20: "sutaupyti",
        dialog21: "Pasirinkite tikrintuvą",
        dialog22: "kopijuoti raktą",
        dialog23: "Sėkmingai išsaugota",
        dialog24: "įveskite patvirtinimo kodą",
        dialog25: "Draugiškas priminimas: prieš patvirtindami išsaugojimą atidarykite atitinkamą APP ir nuskaitykite žemiau esantį QR kodą arba pridėkite raktą rankiniu būdu!",
        dialog26: "Autentifikavimo priemonės įjungimas užtikrina maksimalų paskyros saugumą.",
        dialog27: "Kiekvieną kartą prisijungdami arba išėmę lėšas turėsite įvesti unikalų patvirtinimo kodą, pasiekiamą mobiliuoju telefonu.",
        dialog28: "Nustatykite tikrintuvą",
        dialog29: "padėti",
        dialog30: "Jūs nesusiejote autentifikavimo kodo. Gaukite stipresnę apsaugą naudodami autentifikavimo priemonę.",
        dialog31: "Valdymo priemonė dar nenustatyta. Pirmiausia nustatykite jį",
        dialog32: "Eikite į nustatymus"
    },
    recharge: {
        title1: "Nežinau, kaip pirkti skaitmeninę valiutą？",
        title2: "Tik trys paprasti žingsniai!",
        title3: "Išskleidžiamajame meniu pasirinkite norimą įsigyti skaitmeninę valiutą ir įveskite pirkimo kiekį arba sumą.",
        title4: "Pasirinkite pageidaujamą mokėjimo būdą",
        title5: "Patvirtinus mokėjimą, įsigyta skaitmeninė valiuta bus automatiškai pervesta į jūsų sąskaitą.",
        title6: "Pasirinkite valiutą, kurią norite papildyti",
        title7: "Indėlių rekordas",
        table1: "Valiuta",
        table2: "kiekis",
        table3: "Grandinės pavadinimas",
        table4: "valstybė",
        table5: "priežastis",
        table6: "laikas",
        table7: "Žiūrėti kuponą",
        dialog1: "Toliau įveskite patvirtinimo kodą",
        dialog2: "mokėjimo kodas",
        dialog3: "kopija",
        dialog4: "adresu",
        dialog5: "Indėlio suma",
        dialog6: "Sandorio ekrano kopija",
        dialog7: "Svarbus pastebėjimas",
        dialog8: "Įveskite indėlio sumą",
        dialog9: "įkelti paveikslėlį",
        dialog10: "Įveskite turinį",
        dialog11: "Įveskite turinį",
        dialog12: "Neperžiūrėta",
        dialog13: "Atstumtas",
        dialog14: "Deponuotas",
        tip1: "Sėkmingai pateikta",
        tip2: "Nukopijuota sėkmingai",
        tip3: "Kopijuoti nepavyko",
        tip4: "Kopijuoti nepavyko",
        tip5: "1. Aukščiau nurodytas depozito adresas yra oficialus platformos mokėjimo adresas. Jūs būsite atsakingas už lėšų praradimą dėl klaidingo įnašo.",
        tip6: "2. Įsitikinkite, kad jūsų kompiuteris ir naršyklė yra saugūs, kad informacija nebūtų sugadinta ar nutekinta;",
        tip7: "3. Kai įkraunate pirmiau nurodytą adresą, visas tinklo mazgas turi patvirtinti, kad būtų galima gauti paskyrą;",
        tip8: "4. Pasirinkite aukščiau nurodytą žetonų sistemą ir valiutą, tada perveskite atitinkamą sumą už indėlį. Neperveskite jokio kito nesusijusio turto, kitaip jis nebus atgautas.",
        tip9: "Išsaugoti QR kodą",
        tip10: "prisipilti",
        tip11: "Svarbus pastebėjimas",
        tip12: "Svarbus pastebėjimas",
        tip13: "Įkraukite QR kodą",
        tip14: "Kitas žingsnis",
        tip15: "Įkrovimo rekordas",
        tip16: "Įkrovimo detalės",
        tip17: "Indėlio adresas",
        tip18: "Greitas mokėjimas",
        tip20: "Nepakankamas balansas",
        tip22: "Pateikta, laukiama patvirtinimo",
        tip23: "laukia apmokėjimo",
        tip24: "Suma",
        tip25: "Mokėjimas atliktas, laukiama bloko patvirtinimo",
        tip26: "Mokėjimas atmestas",
        tip27: "Mokėjimo nesėkmė",
        tip28: "Mokėjimas sėkmingas",
        bankChannel: "Banko kortelės kanalas",
        tip29: "Istoriniai ieškojimai",
        tip30: "Valiutų sąrašas",
        tip31: "Pasirinkite tinklą",
        tip32: "Kai įkraunate šią valiutą, įkraukite tik per toliau nurodytus palaikomus tinklus.",
        tip33: "Išėmę šią valiutą, išimkite tik per toliau nurodytus palaikomus tinklus.",
        tip34: "Greitas įkrovimas",
        tip35: "Nuskaitykite kodą, kad įkrautumėte",
        tip36: "Rankinis įkrovimas",
        tip37: "Įkrovimo tinklas",

        tip38: "Įkrovimo adresas",
        tip39: "Įkrovimo suma",
        tip40: "Operacijos kvito įkėlimas",
        tip41: "įkelti paveikslėlį",
        tip42: "patvirtinti pateikimą",
        tip43: "patvirtinti mokėjimą",
        tip44: "Pastaba: Patvirtinkite adresą, kurį norite papildyti. Mes neatsakome už pinigų praradimą, jei jį užpildysite neteisingai.",
        tip45: "priminti",
        tip46: "Adresas sėkmingai nukopijuotas!",
        tip47: "Nepavyko nukopijuoti adreso!",
        tip48: "Nepakankamas likutis",
        tip49: "Įkrovimas buvo sėkmingai pateiktas ir laukia patvirtinimo grandinėje.",
        tip50: "Nepavyko įkrauti",
        tip51: "Neteisinga papildymo suma",
        tip52: "Nepavyko prisijungti",
        tip53: "Piniginė neprisijungta",
        tip54: "Ryšio atsisakyta",
        tip55: "Pateikta sėkmingai, laukiama klientų aptarnavimo peržiūros ir patvirtinimo",
        tip56: "Įkelkite operacijos kvito ekrano kopiją",
        tip57: "Pateikimas nepavyko",
        tip58: "Įkrovimo rekordas",
        tip59: "Išėmimų įrašas",
        tip60: "Papildyti valiutą",
        tip61: "Išėmimo valiuta",
        tip62: "Įkrovimo adresas",
        tip63: "Ekrano kopija rodoma",
        tip64: "Spustelėkite norėdami padidinti vaizdą",
        tip65: "Įkrovimo laikas",
        tip66: "Atmetimo priežastis",
        tip67: "Išėmimo suma",
        tip68: "Gauta suma",
        tip69: "Grynųjų pinigų išėmimo tinklas",
        tip70: "Pašalinimo adresas",
        tip71: "Atsiėmimo laikas",
        tip72: "uždarymas",
        tip73: "Sandorio ekrano kopija",
        tip74: "Laukiama peržiūra",
        tip75: "Atstumtas",
        tip76: "baigtas",
        tip77: "Atstumtas",
        tip78: "Audituota",
        tip79: "Išmokėta",
        tip80: "ekstraktas",
        tip81: "perdavimo tinklas",
        tip82: "Adresas / sąskaita faktūra",
        tip83: "Atsiimti visus",
        tip84: "Neimkite pinigų tiesiogiai sutelktinio finansavimo ar ICO adresu, kitaip negalėsite gauti sutelktinio finansavimo ar ICO išduotų žetonų.",
        tip85: "Atvykimo kiekis",
        tip86: "Atvykimo kiekis",
        tip87: "Neteisingas atsiėmimo adresas",
        tip88: "Neteisinga išėmimo suma",
        tip89: "Išsiimti suma yra mažiausiai",
        tip90: "Didžiausia išėmimo suma yra",
        tip91: "Buvo pateiktas prašymas dėl atsisakymo",
        tip92: "adresų sąrašas",
        tip93: "Jūs dar nepridėjote adreso",
        tip94: "Pridėkite jį dabar",
        tip95: "Pridėti adresą",
        tip96: "pridėti naują adresą",
        tip97: "Prašome pasirinkti",
        tip98: "adresu",
        tip99: "Pasirinkite perdavimo tinklą",
        tip100: "Įveskite adresą",
        tip101: "Adresas sėkmingai pridėtas",
        tip102: "sandoryje",
    },
    fundExchange: {
        title1: "Kaip pasikeisti lėšas？",
        title2: "Pasirinkite tinkamą valiutą, kurią norite konvertuoti.",
        title3: "Pasirinkite tinkamą valiutą konvertuoti.",
        title4: "Įveskite keičiamą sumą ir spustelėkite Pateikti, kad užbaigtumėte fondo keitimą.",
        title5: "iš",
        title6: "atvykti",
        title7: "Keitimo kiekis",
        title8: "valiutos kursas",
        title9: "Yra",
        title10: "Įveskite išpirkimo kiekį",
        title11: "Nėra atitinkamos mainų prekybos poros.",
        title12: "Įveskite kiekį, kurį norite išpirkti",
        title13: "Išpirkimas sėkmingas",
        title14: "Mainų įrašas",
        table1: "Naudokite valiutą",
        table2: "kiekis",
        table3: "valiutos kursas",
        table4: "Keisti valiutą",
        table5: "Kvitas",
        table6: "laikas",
        title15: "iš",
        title16: "Šiuo metu nėra laisvų išteklių, pasirinkite kitą"
    },
    fundTransfer: {
        title1: "Kaip pervesti lėšas？",
        title2: "Pasirinkite dabartinę sąskaitą, kurią reikia perkelti.",
        title3: "Pasirinkite paskyrą, kurią norite perkelti.",
        title4: "Įveskite pervedimo sumą ir spustelėkite Pateikti, kad užbaigtumėte pervedimą.",
        title5: "Valiuta",
        title6: "Perduoti kiekį",
        title7: "Įveskite pervestiną sumą",
        title8: "perdavimo įrašas",
        title9: "Pasirinkite piniginės tipą",
        title10: "Pasirinkite valiutą",
        title11: "Pasirinkite piniginės tipą",
        title12: "Pasirinkite valiutą",
        title13: "Įveskite pervestiną sumą",
        title14: "Sėkmingai pateikta",
        table1: "Pervesti sąskaitą",
        table2: "pervedimo valiuta",
        table3: "Perduoti kiekį",
        table4: "pervedimo sąskaita",
        table5: "laikas",
        table6: "perkėlimas",
        table7: "Lėšų pervedimas",
        table8: "Perkelti į",
        table9: "perdavimo įrašas",
        table10: "Perdavimo detalės",
        table11: "Perdavimas sėkmingas",
        table12: "Perdavimas nepavyko",
        table13: "Einamosios sąskaitos pervesti negalima"
    },
    withdrawal: {
        title1: "Svarbus pastebėjimas",
        title2: "1. Norėdami išvengti arbitražo, galite kreiptis dėl valiutos išėmimo, kai operacijos apimtis pasiekia ribą.",
        title3: "2. Pateikus paraišką dėl pinigų išėmimo, pinigai atvyks per 24 val.",
        title4: "3. Pateikus paraišką dėl pinigų išėmimo, lėšos yra įšaldomos, nes lėšos yra laikinai laikomos sistemoje.",
        title5: "Valiuta",
        title6: "piniginės adresą",
        title7: "Išimtų monetų kiekis",
        title8: "Monetų išėmimo įrašas",
        title9: "Pasirinkite valiutą",
        title10: "Pasirinkite grandinės tipą",
        title101: "",
        title11: "prašome įvesti",
        title12: "piniginės adresą",
        title13: "Sėkmingai pateikta",
        title14: "Peržiūrimas",
        title15: "Peržiūra sėkminga",
        title16: "Audito nesėkmė",
        title17: "Išmokėta",
        title18: "Įveskite arba įklijuokite savo piniginės adresą",
        title19: "Įveskite išėmimo sumą",
        title20: "tvarkymo mokestis",
        title21: "Tikrasis atvykimas",
        table1: "Valiuta",
        table2: "Išimtų monetų kiekis",
        table3: "Grandinės pavadinimas",
        table4: "piniginės adresą",
        table5: "valstybė",
        table6: "priežastis",
        table7: "taikymo laikas",
        table8: "Išsiimti monetas",
        table81: "Išsiimti monetas",
        table9: "Pašalinimo adresas",
        table10: "Pasirinkite valiutą, kurią norite atsiimti",
        table11: "Įsitikinkite, kad įvedėte",
        table12: 'Adresas (dažniausiai prasideda "0x")',
        table13: "Monetos, kurias galima išsiimti (atskaitomi tvarkymo mokesčiai):",
        table14: "Pasirinkite valiutą",
        table15: "Susidurti su problemomis? Greitas atsiliepimas",
        table16: "Balansas",
        table17: "Išsiskyrimo detalės",
        password: "Slaptažodis",
        passwordTips: "Įveskite slaptažodį",
        passwordAg: "Patvirtinti slaptažodį",
        passwordAgTips: "Dar kartą įveskite slaptažodį",
        oldPassword: "Senas slaptažodis",
        oldPasswordTips: "Įveskite seną slaptažodį",
        newPassword: "Naujas Slaptažodis",
        newPasswordTips: "Įveskite naują slaptažodį",
        passwordNotSame: "Slaptažodžiai nenuoseklūs",
        withdrawPasswordSet: "Nustatykite išėmimo slaptažodį",
        withdrawPasswordModify: "Pakeiskite išėmimo slaptažodį",
        withdrawPassword: "Atšaukti slaptažodį",
        withdrawPasswordTips: "Įveskite atsiėmimo slaptažodį",
        setWithdrawPasswordFirst: "Pirmiausia nustatykite atsiėmimo slaptažodį",
        withdrawPasswordSetSuccess: "Sąranka sėkminga",
        withdrawPasswordModifySuccess: "Sėkmingai pakeista",
        submit: "Pateikti",
    },
    customer: {
        content1: "internetinė paslauga",
        content2: "klientų aptarnavimas",
        content3: "Įveskite savo kontaktinę informaciją",
        content4: "Įveskite savo kontaktinę informaciją",
        content5: "siųsti",
        content6: "Nepavyko išsiųsti, nepavyko prisijungti prie tinklo",
        content7: "paveikslėlį",
        content8: "žinutė tuščia",
        content9: "mano dalis",
        content10: "pasidalinti nuoroda",
        content11: "išsaugoti paveikslėlį",
        content12: "mano uždarbis",
        content13: "Lygio aprašymas",
        content14: "Komandos dydis",
        content15: "Kvietimo pajamos",
        content16: "Taisyklės aprašymas",
        content17: "narystės lygis",
        content18: "Įkrovimo suma yra didesnė nei",
        content19: "Komandos lygis",
        content20: "Komandos lygis",
        content21: "Kad atitiktų sąlygas",
        content22: "Kad atitiktų sąlygas",
        content23: "lygio komanda",
        content24: "lygis, pakvietimas",
        content25: "galiojantys nariai",
        content26: "pirminių pajamų",
        content27: "antrinių pajamų",
        content28: "3 lygio pajamos",
    },
    standardContract: {
        increaseMargin: "padidinti maržą",
        increaseAmount: "padidinti sumą",
        Pleaseenterthemargin: "Įveskite maržos sumą, kurią norite padidinti",
        Pleaseenterthecorrect: "Įveskite teisingą indėlio sumą",
        Increasemarginsuccessfully: "Sėkmingai padidinkite maržą",
        takeProfitPriceWrong: "Neteisingai įvertinkite pelno kainą",
        stoppriceerror: "Neteisingai įvertinkite pelno kainą",
        thetakeprofit1: "Paėmimo pelno kaina turi būti didesnė už rinkos kainą",
        thetakeprofit2: "Stop loss kaina turi būti mažesnė už rinkos kainą",
        thetakeprofit3: "Paėmimo pelno kaina turi būti mažesnė už rinkos kainą",
        thetakeprofit4: "Stop loss kaina turi būti didesnė už rinkos kainą",
        thetakeprofit5: "Netinkamas kiekis",
        estimatedprofitloss: "Numatomas pelnas ir nuostolis",
        positionAmount: "Pareigų skaičius",
        closeAmount: "Uždarymo kiekis",
        whenthelatest: "Pasiekus naujausią kainą",
        whenthelatest2: ", bus suaktyvintas rinkos kainos take-profit orderis uždaryti esamą poziciją. Numatomas pelnas ir nuostolis",
        whenthelatest3: ", bus suaktyvintas rinkos sustabdymo nuostolių pavedimas, kad būtų uždaryta dabartinė pozicija. Numatomas pelnas ir nuostolis",
        contract: "sutartis",
        ordercanceledsuccessfully: "Užsakymas sėkmingai atšauktas",
        successfullyordered: "sėkmingai užsakyta",
        positionopeningreminder: "Pozicijos atidarymo priminimas",
        wrongquantity: "Netinkamas kiekis",
        quantitycannot: "Netinkamas kiekis",
        modificationstoploss: "Sėkmingai modifikuokite pelną ir sustabdykite nuostolius",
        positionclosedsuccessfully: "Pozicija sėkmingai uždaryta",
        orderCancellation: "Užsakymo atšaukimas",
        orderCancellation2: "Ar tikrai norite atšaukti užsakymą?",
        tobeexecuted: "Kad būtų įvykdyta mirties bausmė",
        expired: "pasibaigęs",
        failed: "nepavyko",
        contractTransaction: "Neterminuota sutartis",
        increase: "Padidinti",
        openPosition: "Pilnas sandėlis",
        isolatedPosition: "Izoliuota padėtis",
        limitOrder: "kainos riba",
        marketOrder: "rinkos kaina",
        pleaseSelectLeverage: "Pasirinkite svertą",
        pleaseEnterQuantity: "Įveskite kiekį",
        canOpenMore: "Įveskite kiekį",
        contractAmount: "Sutarties suma",
        openingFee: "tvarkymo mokestis",
        currentPosition: "Padėtis",
        historicalPositions: "Istoriniai sandoriai",
        currentCommission: "Dabartinis užsakymas",
        historicalCommission: "istorinė komisija",
        buyPrice: "pirkimo kaina",
        income: "pelno",
        quantity: "kiekis",
        marginRate: "maržos norma",
        initialMargin: "Pradinė paraštė",
        estimatedPriceParity: "Priverstinio likvidavimo kaina",
        operate: "veikti",
        finishQuantity: "Sandorio apimtis",
        averagePrice: "vidutinė sandorio kaina",
        pleaseEnterLeverage: "vidutinė sandorio kaina",
        takeProfitAndStopLoss: "Įveskite svertą",
        takeProfit: "Pasiimk pelną",
        stopLoss: "sustabdyti nuostolius",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Uždaryti padėtį",
        marketLquidation: "Rinkos kaina yra vienoda",
        cancel: "Atšaukti užsakymą",
        enterBuyPrice: "Atšaukti užsakymą",
        areYouSureLquidation: "Ar tikrai uždarysite poziciją??",
        confirm: "Žinoma",
        enterStopWinPrice: "Įveskite pelno kainą",
        enterStopLossPrice: "Įveskite nuostolio sustabdymo kainą",
        commissionTime: "Komisijos laikas",
        commissionPrice: "Komiso kaina",
        commissionQuantity: "Bendra komisinių suma",
        volumeAlreadyTraded: "Suma užpildyta",
        areYouSureToWinOrLoss: "Ar tikrai norite sustabdyti pelną ir sustabdyti nuostolius??",
        maxQuantity: "Maksimali padėtis",
        saleQuantity: "Pozicija uždaryta",
        salePrice: "vidutinė uždarymo kaina",
        saleTime: "laikas",
        succeeded: "pavyko",
        cancelled: "Atšauktas",
        piece: "atviras",
        triggerPrice: "startinė kaina",
        setWin: "Nustatykite pelną",
        setLoss: "Nustatykite stop loss",
        enterWinQuantity: "Įveskite pelno sumą",
        enterLossQuantity: "Įveskite sustabdymo nuostolių sumą",
        planCommission: "Plano komisija",
        enterWinTriggerPrice: "Įveskite pelno gavimo pradinę kainą",
        enterLossTriggerPrice: "Įveskite sustabdymo nuostolių pradinę kainą",
        insufficientQuantity: "Nepakankamas kiekis",
        enterTriggerPrice: "Įveskite pradinę kainą",
        tips1: "Jūsų užsakymo kaina yra didesnė už dabartinę kainą ir bus vykdoma tiesiogiai pagal dabartinę rinkos kainą. Ar tikrai norite pirkti?",
        tips2: "Jūsų užsakymo kaina yra mažesnė už dabartinę kainą ir bus vykdoma tiesiogiai pagal esamą rinkos kainą. Ar tikrai pirksite?",
        type: "tipo",
        increaseQuantity: "Padidinti padėtį",
        reduceQuantity: "Sumažinti pozicijas",
        limitCommission: "limitinis pavedimas",
        tips3: "Stop loss kaina turi būti mažesnė už rinkos kainą",
        tips4: "Paėmimo pelno kaina turi būti didesnė už rinkos kainą",
        tips5: "Stop loss kaina turi būti didesnė už rinkos kainą",
        tips6: "Paėmimo pelno kaina turi būti mažesnė už rinkos kainą",
        tips7: "Stop loss kaina turėtų būti mažesnė už rinkos kainą ir mažesnė už užsakymo kainą",
        tips8: "Pelno kaina turi būti didesnė už užsakymo kainą (gali būti mažesnė už rinkos kainą)",
        tips9: "Stop loss kaina turėtų būti didesnė už rinkos kainą ir didesnė už užsakymo kainą",
        tips10: "Pelno kaina turi būti mažesnė už užsakymo kainą (gali būti didesnė už rinkos kainą)",
        accountEquity: "Sąskaitos balansas",
        unrealizedPL: "nerealizuotas pelnas ar nuostolis",
        tips11: "Ar esate tikras, kad esate teisus",
        tips12: "Ar esate tikras, kad esate teisus",
        tips13: "Uždarykite visas pozicijas",
        tips14: "Daugiau nepriminti",
        profitRate: "Uždarymo grąžos norma",
        openTime: "Atidarymo laikas",
        unwindTime: "Uždarymo laikas",
        dealTime: "Sandorio laikas",
        amountClose: "Uždarytos padėties kiekis",
        long: "daug",
        short: 'nulinis',
        orderQuantity: "Užsakymo kiekis",
        entrustedMargin: "patikėta marža",
    },
    spotGoods: {
        spotGoods: "Flash mainai",
        sellPrice: "pardavimo kaina",
        orderRecord: "„Flash“ išpirkimo įrašas",
        transactionTime: "Sandorio laikas",
        maxBuy: "galima įsigyti",
        maxSell: "Galima parduoti",
        purchaseQuantity: "Pirkimo kiekis",
        slippage: "Paslydimas",
        convertTips: "Sandorį užbaigsime palankiausia kaina su 0-5% nuokrypiu.",
        changePrice: "Dabartinė keitimo kaina",
        consume: "vartoti",
        consumeAmount: "vartoti",
        get: "gauti",
        getAmount: "gauti",
        changeTips: "Ar tikrai norite išpirkti?？",
        confirm: "Ar tikrai norite išpirkti?"
    },
    news: {
        title: "Naujienų centras",
        redMore: "Skaityti daugiau",
    },
    credit: {
        credit: "kredito Taškai",
        creditScore: "bendras kredito balas",
        latestEntry: "naujausias rekordas",
        liveUpdate: "Tiesioginis atnaujinimas",
        systemEvaluation: "Sistemos įvertinimas",
        time: "data",
        name: "vardas",
        reason: "priežastis",
        scoreStory: "priežastis"
    },
    borrowing: {
        borrowing: "paskola",
        diversifiedMarket: "Diversifikuotos rinkos",
        totalSupply: "Bendra pasiūla",
        totalBorrowings: "visų skolų",
        tips1: "Tai yra bendra suma, kurią galite pasiskolinti už užstatą, neviršijant skolinimosi limito.",
        tips2: "Jūsų stabili palūkanų norma išliks tokia pati visą paskolos laikotarpį. Rekomenduojamas ilgalaikiam tiekimui ir vartotojams, kurie mėgsta nuspėjamumą.",
        totalSupplys: "visos pasiūlos",
        supplyAPR: "pasiūlos norma",
        totalBorrowings: "Visas skolinimasis",
        borrowAPR: "skolinimosi norma",
        check: "Patikrinti",
        market: "turgus",
        availableLiquidity: "Turimas likvidumas",
        currencyPrice: "dabartinės valiutos kaina",
        supplyAssets: "tiekti turtą",
        tips3: "Pastaba: toliau nurodyta palūkanų norma yra fiksuota palūkanų norma Pasibaigus tiekimo laikotarpiui, pagrindinė suma ir pajamos bus grąžintos į jūsų sąskaitą.",
        supplyCycle: "tiekimo ciklas",
        minimumSupply: "minimali pasiūla",
        supply: "tiekimas",
        borrowedAssets: "skolinto turto",
        tips4: "Svarbu žinoti: norėdami pasiskolinti pinigų, turėsite pateikti užstatą arba įrodymus,",
        tips5: "Žiūrėkite hipotekos turto aprašymą.",
        repaymentCycle: "grąžinimo ciklas",
        BorrowableAmount: "Turima paskolos suma",
        borrow: "paskola",
        accountInformation: "Paskyros informacija",
        walletBalance: "piniginės likutis",
        canSupply: "Yra",
        AmountCanBeBorrowed: "Visa galima paskolos suma",
        borrowedAmount: "Pasiskolinta suma",
        remainingAmount: "esamas likutis",
        getBorrowednAmount: "Gaukite paskolos limitą",
        supplyOrder: "tiekimo tvarka",
        borrowedOrder: "paskolos orderis",
        supplyAmount: "tiekimo kiekis",
        expectedProfits: "Numatoma grąža",
        supplyTime: "Tiekimo laikas",
        expirationTime: "Galiojimo pabaigos data",
        borrowingAmount: "Paskolos suma",
        dueRepayment: "Grąžinimo suma",
        borrowingTime: "Išleidimo laikas",
        repaymentTime: "Grąžinimo laikas",
        repay: "grąžinimas",
        supplyCurrency: "grąžinimas",
        buyNow: "Pirkti dabar",
        supplyRecords: "tiekimo įrašus",
        borrowRecords: "Paskolos įrašai",
        completed: "baigtas",
        borrowNow: "Pasiskolinkite dabar",
        borrowCurrency: "Skolinimosi valiuta",
        borrowMin: "mažiausias",
        borrowMax: "maksimalus",
        borrowRange: "Paskolos suma (minimali/maksimali)",
        tips6: "Grąžinkite laiku, jei vėluojate, bus priskaičiuota papildoma dienos suma (pagrindinė suma + palūkanos).",
        tips7: "Pradelstas mokestis.",
        pleaseEnterBorrowAmount: "Įveskite paskolos sumą",
        interest: "Palūkanos",
        tips8: "Ar tikrai norite grąžinti iš anksto?？",
        borrowChannel: "Skolinimosi kanalas",
        tips9: "Pradėkime nuo pažinties su jumis",
        name: "vardas",
        lastName: "pavardė",
        birthDate: "Gimimo data",
        tips10: "Šiek tiek daugiau informacijos, koks jūsų adresas？",
        familyAddress: "Namų adresas (įskaitant įrenginio numerį)",
        city: "Miestas",
        country: "tauta",
        postalCode: "Pašto kodas",
        pleaseEnterYourHomeAddress: "Įveskite savo namų adresą",
        tips11: "Papasakokite apie savo finansinę padėtį",
        tips12: "Vėliau mums gali tekti patvirtinti šią informaciją, todėl stenkitės būti kuo tikslesni",
        yourMonthlyIncome: "savo mėnesines pajamas",
        enterYourMonthlyIncome: "Įveskite savo mėnesines pajamas",
        extraIncomeOptional: "Papildomos pajamos (neprivaloma)",
        enterExtraIncome: "Įveskite papildomas pajamas",
        monthlyRentOrMortgagePayment: "Mėnesio nuomos arba būsto paskolos įmoka",
        enterMonthlyRent: "Įveskite mėnesinį nuomos arba hipotekos mokėjimą",
        tip13: "Pasakykite mums, jei turite hipoteką",
        tip14: "Jei hipotekos garantija nepasirenkama, tikimybė, kad bus atlikta peržiūra, yra maža.",
        isThereMortgage: "Ar yra hipoteka?",
        selectIsThereMortgage: "Prašome pasirinkti, ar yra hipoteka",
        selectMortgageType: "Pasirinkite hipotekos tipą",
        yes: "taip",
        no: "ne",
        uploadScreenshotOfTransaction: "Įkelkite operacijų ekrano kopijas",
        tip15: "Susisiekime",
        tip16: "Galime susisiekti su jumis šiuo numeriu, jei mums reikės daugiau informacijos apie jūsų paskolos paraišką",
        tip17: "Aš perskaičiau ir elektroniniu būdu pasirašiau savo sutikimą",
        tip18: "Naudojimo sąlygos ir elektroninis sutikimas",
        tip19: ", kuris apima jūsų sutikimą, kad mes atsiųstume jums informaciją apie mūsų",
        tip20: "Produkto ir paslaugų naudojimo sąlygos",
        tip21: "Užpildę informaciją, palaukite sistemos įvertinimo ir peržiūros.",
        phoneNumber: "telefono numeris",
        enterPhoneNumber: "telefono numeris",
        tip22: "Pažymėkite langelį, kad sutiktumėte su naudojimo sąlygomis ir elektronine sutikimo forma",
        inReview: "Peržiūrimas",
        applicationAmount: "Paraiškos suma",
        reasonForRejection: "Atmetimo priežastis",
        reapply: "Pakartotinai kreiptis",
        applyStatus: "paraiškos būsena",
        currentOrder: "dabartinė tvarka",
        interestRate: "palūkanų norma"
    },
	yuebao: {
        filed1: "investicija",
        filed2: "išimti",
        filed3: "Dienos pajamos",
        filed4: "Komandos pagreitis",
        filed5: "Deponuota",
        filed6: "Vakar dienos uždarbis",
        filed7: "Visos pajamos",
        filed8: "Turimos lėšos",
        filed9: "Įveskite sumą",
        filed10: "Likvidumo kasyba",
        filed11: "Įvadas",
        filed12: "minimalus perkamas kiekis",
        filed13: "Maksimalus pirkinys",
        filed14: "Prenumerata",
        filed15: "įprastas",
        filed16: "Metinė palūkanų norma",
    },
    tipsPopover: {
        title: "Įdiekite šią programą",
        press: "pagal",
        and: "ir",
        scrren: "Pridėti prie pagrindinio ekrano",
        hasInstall: "(Jei jau įdiegta, uždarykite langą)",
    },
    follow: {
		title1: "Toliau planas",
        follow_text1: "Pradėkite sekimo tvarką",
        follow_text2: "Išleidimo istorija",
        follow_text3: "Pavadinimas",
        follow_text4: "Prekybos pora",
        follow_text5: "Pirkimo trukmė",
        follow_text6: "Užsakymo laikas",
        follow_text7: "Išleidimo laikas",
        follow_text8: "Žmonių, sekančių užsakymus, skaičius",
        follow_text9: "Sekite užsakymo sumą",
        follow_text10: "Operacija",
        follow_text11: "Kvietimas",
        follow_text12: "Laikykitės nurodymų",
        follow_text13: "Nustatykite sekimo sąlygas",
        follow_text14: "Sąlyginiai šių užsakymų apribojimai",
        follow_text15: "Fiksuota suma",
        follow_text16: "procentai",
        follow_text17: "Kviestasis",
        follow_text18: "Kitos šalies ID",
        follow_text19: "Patvirtinkite, kad laikotės nurodymo",
        follow_text20: "Jūsų ši suma (neprivaloma)",
        follow_text21: "Įveskite pavadinimą",
        follow_text22: "Prašome pasirinkti prekybos porą",
        follow_text23: "Pasirinkite kryptį, kuria norite sekti",
        follow_text24: "Pasirinkite pirkimo trukmę",
        follow_text25: "Pasirinkite užsakymo laiką",
        follow_text26: "Pasirinkite pirkimo kiekį",
        follow_text27: "Didžiausias pirkimo kiekis negali būti mažesnis nei minimalus pirkimo kiekis",
        follow_text28: "Minimali įpirka",
        follow_text29: "Maksimalus pirkimas",
        follow_text30: "Sekite užsakymą sėkmingai sukurtas",
        follow_text31: "Prekybos porų sąrašas",
        follow_text32: "Jau vykdė užsakymą",
        follow_text33: "Įveskite pakviestojo informaciją",
        follow_text34: "Pakviesta",
        follow_text35: "Pakvietė mane",
        follow_text36: "Stebėti užsakymų istoriją",
        follow_text37: "Sekite užsakymo sumos diapazoną",
        follow_text38: "Įveskite užsakymo kodą"
	},
    followHistory: {
		title1: "Išleidimo istorija",
        title2: "Sekama istorija",
        follow_h_text1: "Bendra sekimo užsakymų suma",
        follow_h_text2: "Bendras žmonių, sekančių užsakymus, skaičius",
        follow_h_text3: "Leidimų skaičius",
        follow_h_text4: "Laimėjimo koeficientas",
        follow_h_text5: "Kiek kartų reikia vykdyti užsakymus",
        follow_h_text6: "Gaukite pelną iš šių užsakymų",
        follow_h_text7: "Kryptis",
        follow_h_text8: "laimėti",
        follow_h_text9: "pralaimėjimas",
	},
"asset.index.1579E8B41F8D5134": "Mano turtas",
    "asset.index.B3F6F05Dfbe431Cd": "modeliavimas",
    "asset.index.B9Aeab4Ee8F33746": "turto vertinimas",
    "asset.index.583Df8Bd541Eb7E8": "Uždarbio tendencija",
    "asset.index.B1254815D133Cc26": "7 dienos",
    "asset.index.2F615F6C029F01B7": "30 dienų",
    "asset.index.29439Fb58E4Bbe22": "Paėmimas",
    "asset.index.831Ab31568A78285": "įkrauti",
    "asset.index.39F7A0D48D12E8C9": "Perdavimas",
    "asset.index.B99063C5689Beaec": "greitas keitimas",
    "asset.index.E1Dc620F2F038B2A": "Gauti turtą",
    "asset.index.972A0B4C2B4393D0": "Mano paskyra",
    "asset.index.9Dfb0Fb334620733": "Turimas likutis",
    "asset.index.Cdc5Fbd26790Cb47": "Įšaldyta suma",
    "asset.index.3D8132D72Ae965Cd": "Vertinimas",
    "asset.receive.825503Cc430559Cb": "Gauti turtą",
    "asset.receive.54154A0553A535B7": "Demo Asset",
    "asset.receive.F2E57B00A9C5D129": "1. Šis išteklius gali būti naudojamas tik demonstracinės sąskaitos režimu",
    "asset.receive.Ea0D1D52989299Bd": "2. Kad išvengtumėte piktnaudžiavimo, galite jį gauti tik kartą per mėnesį",
    "asset.receive.090E0037Aa235D3D": "3. Gauto turto suma bus pridėta prie pradinės sumos ir pozicijos būsena nebus keičiama.",
    "asset.receive.7Fd4E1Abc805C0A6": "4. Jei turite kitų klausimų, susisiekite su klientų aptarnavimo tarnyba",
    "asset.receive.Df5B3A511D986575": "Gaukite demonstracinį išteklius dabar",
    "asset.records.1Fa02E8187A39592": "Beveik trys mėnesiai",
    "asset.records.7B291C37333Fe34D": "nuo arti iki toli",
    "asset.records.A476Cd1Ec868Fc5F": "Beveik trys mėnesiai",
    "asset.records.9D5755A842E55Bb7": "Iš arti iki toli,",
    "asset.records.E3Fa05E607203Cdc": "Įkrauti",
    "asset.records.B4F206C6Bec08Dea": "Pasiėmimas",
    "asset.index.Df5B3A511D986576": "Sėkmingai gavote demonstracinius išteklius",
	"deposit.depositSpeedy.Efe81D0E0174239B": "Mokėjimo būdas",
    "deposit.depositSpeedy.B9293Ee96E435F85": "fiat valiuta",
    "deposit.depositSpeedy.16658A855F07D171": "suma",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Gerai",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Negali būti tuščias",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "minimalus įnašas",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Maksimalus įkrovimas",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Indėlis USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Indėlio valiuta",
    "deposit.depositWallet.B404C543712B71D5": "Įkrovimo tinklas",
    "deposit.depositWallet.A484B0F5E94B3F4F": "indėlio adresas",
    "deposit.depositWallet.D3980D54Acf242Db": "Išsaugoti QR kodą",
    "deposit.depositWallet.A06E84801D6582Be": "Indėlio suma",
    "deposit.depositWallet.Daaaeb1B7B22B126": "Prompt",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Įnešimas sėkmingas",
    "deposit.depositWallet.4F48Abbafce532Bb": "Pateikimas sėkmingas, laukiama klientų aptarnavimo peržiūros",
    "deposit.depositWallet.A88C481228811696": "Jūs atsisakėte perjungti tinklus",
    "deposit.depositWallet.C6Ebf27895C21932": "Rankiniu būdu atidarykite TRON piniginės papildinį ir prisijunkite",    
	"deposit.index.B5491C8Dd09D8183": "Pasirinkite valiutą, kurią norite įnešti",
    "deposit.index.2F784B2298D39987": "Greitas įkrovimas",
    "deposit.index.0Daa03Dcdcabd837": "Ne svetainės nuoroda",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Atšaukimo patvirtinimas",
    "withdraw.bottomSheet.A086Fa20082C849D": "Įsitikinkite, kad ši informacija yra teisinga",
    "withdraw.bottomSheet.Bf8B184D32971501": "Atšaukti monetų tinklą",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "Adresas",
    "withdraw.bottomSheet.F9Db93B87E08763B": "Kiekis",
    "withdraw.bottomSheet.4Ad26475002878C0": "tvarkybos mokestis",    
	"withdraw.index.A96454041169Af6D": "Pasirinkti valiutą",
    "withdraw.index.2A563B7081Dd5B83": "Atšaukimo adresas",
    "withdraw.index.B189C2Ef713Cfff4": "Išgryninimo suma",
    "withdraw.index.558F35140E84B562": "Pasiekiamas:",
    "withdraw.index.F4E759087E12Cb77": "Atšaukti viską",
    "withdraw.index.5Cbd9A6B284D25Ae": "Fund slaptažodis",
    "withdraw.index.B89Cbe4Bfc33Bb41": "Tvarkymo mokestis:",
    "withdraw.index.C886De8D8A27026E": "Svarbus pranešimas",
    "withdraw.index.Eac7C5A4095F5606": "1. Kad išvengtumėte arbitražo, galite kreiptis dėl valiutos išėmimo, kai operacijos apimtis pasiekia ribą.",
    "withdraw.index.C3Cc7Fff24504Be9": "2. Valiutos išėmimo paraiška bus įskaityta per 24 valandas nuo pateikimo. Jei valiutos išėmimas nebus gautas po numatyto išėmimo laiko, kreipkitės į klientų aptarnavimo tarnybą internetu.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3. Pateikus valiutos išėmimo paraišką, lėšos įšaldomos, nes vyksta pinigų išėmimas. Lėšos laikinai laikomos sistemoje. Tai nereiškia, kad praradote turtą ar kad turtas pasirodė nenormalus",
    "withdraw.index.4639F3A6E07C00B3": "Kitas veiksmas",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Įveskite arba įklijuokite piniginės adresą",
    "withdraw.index.25768141C8Bdea91": "Įveskite išėmimo sumą",
    "withdraw.index.97A85734Cd1B7Bf0": "Įveskite fondo slaptažodį",
    "withdraw.index.39C7D74B33241C78": "Neteisinga išėmimo suma",
    "withdraw.index.86A5D17908197525": "Atšaukimo patvirtinimas, laukiama peržiūros ir monetų išleidimo",
    "withdraw.index.48Fb603C6638C7F6": "Nustatykite fondo slaptažodį",
    "withdraw.index.873Ade9A3Bacc2F7": "Prompt",    
	"bill.index.5Bbb2A6Ac68D6B2E": "faktinė sąskaita",
    "bill.index.75751E0Eb8261C40": "Demonstracinė paskyra",
    "bill.index.Cbc853B37Ecc2E97": "Paskyros pakeitimo tipas",
    "bill.index.4A0Cacddeb2E38B1": "Paskyros tipas",
    "bill.index.F16217028E098B2F": "Valiuta",
    "bill.index.A620Dbe6F06B78Ba": "Visi tipai",
    "bill.index.2C3C148Bb94D9B3F": "Visos paskyros",
    "c2C.index.Ab45695E4Bf1E382": "C2C sandoris",
    "c2C.index.2702Dc911Dfea762": "Noriu nusipirkti",
    "c2C.index.D737D5B8367A53C4": "Noriu parduoti",
    "c2C.index.C0C540A3Ba9Abe01": "Prekybos apimtis",
    "c2C.index.D8644E8D60C8Bd8A": "Vieneto kaina",
    "c2C.index.Ebb8670B203D3604": "Riba",
    "c2C.index.F8C6245A2Ebf1D27": "Visos banko kortelės",
    "c2C.index.F8C6245A2Ebf1D28": "Monetų kolekcija",
    "c2C.index.F8C6245A2Ebf1D29": "Banko kortelė",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Pirkti",
    "c2C.index.Cc3Aa8141Da9Ec9F": "Parduodama",
    "c2C.index.67A39834582Ad99A": "Duomenų dar nėra",    
	"c2C.order.04C23810B47F77F7": "Pagal kiekį",
    "c2C.order.6905365902E0A73D": "Pagal kiekį",
    "c2C.order.3F0Ecebbd9D2F23B": "Pasiekiamas",
    "c2C.order.16853Bda54120Bf1": "Visi",
    "c2C.order.Ad3335796F2C95F7": "Bendra suma",
    "c2C.order.61092E27A6879318": "Siekdami apsaugoti turto saugumą, padidinkite savo informuotumą apie prevenciją!",
    "c2C.order.0F51881F0Fb920B2": "Operacijos informacija",
    "c2C.order.Dd74Ceca85E5Bc65": "Mokėjimo terminas",
    "c2C.order.De3341388A59073F": "30 minučių",
    "c2C.order.D334B597A352167F": "Pardavėjo slapyvardis",
    "c2C.order.D334B597A352167G": "Pirkėjo slapyvardis",
    "c2C.order.9D5F89F99Ced890D": "Operacijos metodas",
    "c2C.order.8Adf755B14Bc6B7C": "Pirmiausia perskaitykite toliau pateiktą informaciją",
    "c2C.order.686B16F454A7338B": "Nedarykite pastabų pervesdami pinigus banko kortele, antraip pinigai nebus išleisti, o sąskaita bus blokuojama tiesiogiai. Po apmokėjimo reikia pateikti naują operacijos detalių diagramą po apmokėjimo (jei P diagrama arba paslėptos operacijos informacija pranešama Sąskaitos įšaldymo platformai)",
    "c2C.order.9Bec8Fba867B739B": "Pasirinkti mokėjimo metodą",
    "c2C.order.Cf2D892C419Fea36": "Minimalus pirkinys",
    "c2C.order.7290A6B7456A03E0": "Maksimalus pirkimas",    
	"c2C.orderConfirm.F7E99B8C203E2B07": "Išsami užsakymo informacija",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Užsakymas sugeneruotas",
    "c2C.orderConfirm.0F5433503F3845Ce": "Prašome sumokėti pardavėjui per atskaitos laiką.",
    "c2C.orderConfirm.24C3Bcacdb85A451": "Laukiama pirkėjo mokėjimo",
    "c2C.orderConfirm.8E8140D7Ab607B24": "Susisiekite su pardavėju",
    "c2C.orderConfirm.8E8140D7Ab607B25": "Susisiekite su pirkėju",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "Pardavėjo skaitmeninė valiuta dabar yra deponuota sąlyginio deponavimo sąskaitoje, maloniai prašome sumokėti",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Tikrasis pirkėjo vardas:",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "24/7 klientų aptarnavimo palaikymas",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "Užsakymo numeris",
    "c2C.orderConfirm.726C51702F70C486": "Kūrimo laikas",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Susisiekite su klientų aptarnavimo tarnyba",
    "c2C.orderConfirm.103408Fbbe30287F": "Atšaukti užsakymą",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Patvirtinau mokėjimą",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Sumokėta",
    "c2C.orderConfirm.387Ca3625B260E4E": "Sumokėjote už užsakymą",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "Operacija atšaukta",
    "c2C.orderConfirm.76C86566Cc3751C0": "Užsakymas atšauktas",
    "c2C.orderConfirm.205B349751D176B4": "Mokėjimas sėkmingas",    
	"c2C.paymentAdd.6F2A7Dcc66E6Acba": "Mokėjimo būdas",
    "c2C.paymentAdd.C87E865Df6800304": "Mokėjimo metodo konfigūracija",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Mokėjimo būdo tipas",
    "c2C.paymentAdd.06910Bddf002695F": "Tikrasis vardas",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "PayPal sąskaita",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Banko sąskaitos numeris / kortelės numeris",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Valiutos pavadinimas",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Mokėjimo QR kodas",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Spustelėkite, kad įkeltumėte",
    "c2C.paymentAdd.6Daae340F2E9718A": "Banko pavadinimas",
    "c2C.paymentAdd.347751415C3Da489": "Tinklo pavadinimas",
    "c2C.paymentAdd.15B3627Faddccb1D": "Pastabos",
    "c2C.paymentAdd.86Da96Cbd274058F": "Šiltas priminimas: kai parduodate skaitmeninę valiutą, pirkėjui bus rodomas pasirinktas mokėjimo būdas. Patvirtinkite, kad informacija užpildyta tiksliai.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "Išsaugoti",
    "c2C.paymentAdd.90D2A3A5C07179C0": "Įveskite tikrąjį vardą",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Įveskite savo",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Įveskite banko pavadinimą",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Įveskite savo grandinės pavadinimą",
    "c2C.paymentAdd.94F034F0E01439E0": "Įveskite savo adresą",
    "c2C.paymentAdd.B12E035663A42B32": "Visos banko kortelės",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "Moneta mokėjimas",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Įkelkite mokėjimo kodą",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Sėkmingai pridėta",    
	"c2C.paymentDetail.86Da96Cbd274058F": "Šiltas priminimas: kai parduodate skaitmeninę valiutą, pirkėjui bus rodomas pasirinktas mokėjimo būdas. Patvirtinkite, kad informacija užpildyta tiksliai.",
    "c2C.paymentDetail.C4C613Da60226De4": "Modifikavimas sėkmingas",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C mokėjimo metodas",
    "c2C.records.9E36893D5736Abb4": "Užsakymų sąrašas",
    "c2C.records.4B53F9A5B13B3235": "Laukiama mokėjimo",
    "c2C.records.4B53F9A5B13B3236": "Operacija atšaukta",
    "c2C.records.4B53F9A5B13B3237": "Mokama",    
	"contract.index.9706Df877458F0Be": "Visa pozicija",
    "contract.index.623Fc7D3D40Aa479": "Pradinis įnašas",
    "contract.index.F92Dce1169330387": "Rinkos kaina yra vienoda",
    "contract.index.3598B97Dd28Dbf20": "Įveskite kiekį (vnt.)",
    "contract.index.6E1Fae821Dc0B797": "Tvarus",
    "contract.index.Eb07Eb0C37C14575": "Pristatymas",
    "contract.index.Eb6492F19B1C1Fb6": "Istorinė operacijos informacija",
    "contract.index.873Ade9A3Bacc2F7": "Prompt",
    "contract.index.Ff63B0F93D39F50B": "Esate tikras, kad esate teisus",
    "contract.index.Ff63B0F93D39F50D": "Tęskite",
    "contract.index.Ff63B0F93D39F50E": "Trumpas",
    "contract.index.Ff63B0F93D39F50F": "Tęsti",
    "contract.index.Ff63B0F93D39F50G": "Uždaryti visas pozicijas?",
    "customer.dialog.3D410C88E8A24Fe8": "Patvirtinti slapyvardį",
    "customer.dialog.4B3340F7872B411F": "Atšaukti",
    "customer.dialog.549B1456Bb5F98B3": "Įveskite savo slapyvardį",
    "customer.dialog.B773B3E45Ccfad00": "Slapyvardis tuščias",
    "customer.index.7308C6B849D159E9": "Išsami paveikslėlio informacija",    
	"delivery.callDialog.C74F562C3Fe91Fe3": "Užsakymas sėkmingai atliktas",
    "delivery.callDialog.B8C98F42Caa15Ca5": "Kryptis",
    "delivery.callDialog.B79A581Dae543974": "Pirkite ilgai",
    "delivery.callDialog.B79A581Dae543975": "Short Buy",
    "delivery.callDialog.3B26F0F7E90773F3": "Atidarymo kaina",
    "delivery.callDialog.65Fb252Bd94E1039": "Apskaičiuotos pajamos",
    "delivery.index.B483Ba4Fce633539": "pristatymas",
    "delivery.index.224Dd6Cb58D58249": "Sandorio laikotarpis",
    "delivery.index.28158F517E902788": "Minimalus pirkinys",
    "delivery.index.A1232162B14Bec76": "pristatymo užsakymas",
    "delivery.index.445B1D3176D9C69D": "Istoriniai užsakymai",
    "delivery.index.Ab6Cb86E28653C5C": "pristatymas",
    "delivery.index.20A7A6F14D3698C0": "pozicijos atidarymo laikas",
    "delivery.index.Ec6821A49395Ddc2": "Uždarymo laikas",
    "delivery.index.254Fdc665C391743": "Uždaryti kainą",
    "delivery.index.2A0C5E68B44A472A": "pelnas ir nuostoliai",
    "delivery.index.E05B33309F6D236A": "Įveskite kiekį",
    "delivery.index.B2899E073Cb355D9": "minimalus pirkinys",    
	"download.description.869467A93C7Dd4A1": "Įdiegimo instrukcijos",
    "download.description.C80C1Edd6Bee12Bc": "1.Leisti atsisiųsti aprašo failus",
    "download.description.2F5Bc180F92642B4": "2. Aprašymo failas atsisiųstas",
    "download.description.3C3B849F6Ccf712F": "3. Įveskite telefono numerį [Nustatymai] ir atsisiųstas aprašymo failas bus rodomas po paskyros informacija",
    "download.description.86Affd862985E045": "4. Spustelėkite norėdami atsisiųsti aprašo failą",
    "download.description.32832492Bfc1D95E": "5. Spustelėkite, kad įdiegtumėte",
    "download.index.245883C80F181C4A": "Atsisiųsti",
    "download.index.Ef3De8Cb2722937A": "Atidaryti operacijas bet kada ir bet kur",
    "download.index.F4A87Eaeed691638": "Atnaujinkite naujausias naujienas naudodami mūsų APP",
    "download.index.Ad3Aba52D0D39D01": "Atsisiųsti diegimo paketą",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Šiltas priminimas",
    "download.safariGuide.2Cc68Aaadf623477": "Apsilankykite Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Jūs lankotės decentralizuotoje svetainėje, paleiskite ją safari programoje",
    "download.safariGuide.06Da016F3E2F79Bd": "Kopijuoti nuorodą",
    "finance.index.B034B367545D5236": "Visuotiniai aukštos kokybės projektai",
    "finance.index.6Ebfac2C0A9228B0": "1,6 mln.+ aktyvių naudotojų, 122,4 mlrd. USD",
    "finance.index.52Bdcaf19659363D": "Likvidumo kasyba",
    "finance.index.D66924272B031Bf5": "Ypač didelės metinės pajamos",
    "finance.index.34789D88Afcefa64": "Peržiūrėti projektą",
    "finance.index.E12676B95654D21E": "kredito skolinimas",
    "finance.index.4C924Cfd89A11Ac7": "Atraskite daugiau prekybos galimybių",
    "finance.index.C251D4B594Da2848": "AI Quantification",
    "finance.index.4E53Cf2E5F173Dd3": "Lizingas vienu paspaudimu, AI išmanioji operacija",    
	"finance.records.Cce168301447D1Ba": "Vykdoma",
    "finance.records.4587B6Bd7Cfa4454": "Išpirkta",
    "finance.records.18685418E3A50147": "Kasyba",
    "finance.records.Df9Fd438F97Cc908": "天",
    "finance.records.E23Cb03C5Ad359E5": "Terminas",
    "finance.records.Bd87Dac6Cba7Eba8": "Pirkimo kiekis",
    "finance.records.2D0Fff99265E8B1A": "Pelningas",
    "finance.records.2D0Fff99265E8B1B": "Pelnas termino pabaigoje",
    "finance.records.024F5F0B851782De": "Kasybos laikas:",
    "finance.records.3143A26D3F983896": "Išpirkimo laikas:",
    "finance.records.96574Aaf8Db85Ead": "Ankstyvas išpirkimas",
    "finance.records.531E1829203181Ac": "Ar tikrai norite išpirkti anksčiau;",
    "finance.records.9205843B8923Dac3": "Išpirkimas sėkmingas;",    
	"loan.applyCallback.B9258Ec8746A74D8": "Paskolos kanalas",
    "loan.applyForm.7B8Ed340400A6E2B": "vardas",
    "loan.applyForm.549A44Bae725F4A2": "pavardė",
    "loan.applyForm.2A27E61375E7Fea8": "Gimimo data",
    "loan.applyForm.89925B019D537738": "el. pašto adresas",
    "loan.applyForm.Afaeb32Cb4D62E28": "Namų adresas (įskaitant padalinio numerį)",
    "loan.applyForm.E05B5D049B64B040": "miestas",
    "loan.applyForm.B166E4E8Fe9513Fa": "Šalis",
    "loan.applyForm.83Aaede109E395F8": "Pašto kodas",
    "loan.applyForm.647Ed21332A4968B": "Jūsų mėnesio pajamos",
    "loan.applyForm.Cf0A9B845D34D4B4": "Papildomos pajamos (neprivaloma)",
    "loan.applyForm.7048F618Acc6A54C": "Mėnesio nuomos arba hipotekos mokėjimas",
    "loan.applyForm.15C1Bba18Ae18545": "Pasakykite mums, jei turite hipoteką",
    "loan.applyForm.3921E90625A84E36": "Jei nepasirinksite hipotekos garantijos, tikimybė, kad bus atlikta peržiūra, yra maža",
    "loan.applyForm.262Eec4F8906B0F6": "Ar yra hipoteka?",
    "loan.applyForm.8634Eb21B6910F21": "Pasirinkite hipotekos tipą",
    "loan.applyForm.A4897B3A331B5E61": "Operacijos kvitas",
    "loan.applyForm.6623384C683De457": "Palaikykime ryšį",    
	"loan.applyForm.A8360Bdba86834Cb": "Galime susisiekti su jumis šiuo numeriu, jei mums reikės daugiau informacijos apie jūsų paskolos paraišką",
    "loan.applyForm.C1C373F385A4C7B3": "Telefono numeris",
    "loan.applyForm.1B9785Badb91191F": "Užpildę informaciją, palaukite sistemos įvertinimo ir peržiūros.",
    "loan.applyForm.8755B9C761D4080C": "Perskaičiau ir sutinku su savo elektroniniu parašu",
    "loan.applyForm.3E987B787468148C": "Naudojimo sąlygos ir elektroninis sutikimas",
    "loan.applyForm.Bc54E76B61C50Fb9": "Perskaitykite ir sutikite su sutartimi",
    "loan.applyForm.89E158384F634Eb8": "Grįžti į ankstesnį veiksmą",
    "loan.applyForm.Efa5A4548E2F3510": "Namų adresas",
    "loan.applyForm.4Cadf1787541B35D": "Taip",
    "loan.applyForm.Fab408D2F4100447": "Ne,",
    "loan.applyForm.3Bad7A307480Cc56": "El. pašto formatas netinkamas;",
    "loan.applyForm.306322F49B36855D": "Prašymas pateiktas, palaukite klientų aptarnavimo peržiūros",
    "loan.applyForm.3C6E0E858C995Ff9": "Įkelkite operacijos kvitą",
    "loan.applyForm.2Bf443476B271376": "Įkelkite iki 4 nuotraukų",    
	"loan.applyQuota.Ef1D8E23003B9A6F": "Paskolos kanalas",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Gauti paskolos kvotą",
    "loan.applyQuota.9E0780F9352C7273": "Piniginės likutis",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Galima bendra paskolos suma",
    "loan.applyQuota.033A05462195B080": "Paskolinta kvota",
    "loan.applyQuota.9Aa89B036Ef4821D": "Likusi kvota",
    "loan.applyQuota.5656Fb52Ef17A557": "Gauti paskolos kvotą",
    "loan.applyQuota.6Ff6702B4C917D40": "Peržiūrima",
    "loan.applyQuota.6Ff6702B4C917D41": "Atmesta",
    "loan.applyQuota.6Ff6702B4C917D42": "Sėkmingai",
    "loan.applyQuota.0Bad0E857Ea4A952": "Paraiškos pateikimo laikas",
    "loan.applyQuota.3E7E56E2De2Cc546": "Paraiškos kvota",
    "loan.applyQuota.8F1D170D9E54Af5B": "Paraiškos eiga",
    "loan.applyQuota.6167Ca035B93Caf3": "priežastis",
    "loan.applyQuota.410C7954F9A3981D": "Pakartotinai pateikti paraišką",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Pasirinkite valiutą",    
	"loan.index.4C10307B22E885C3": "Atraskite daugiau prekybos galimybių ir sukurkite gerovę",
    "loan.index.Ae8D37Dea2B3717B": "palūkanų norma",
    "loan.index.1Bb54625682E85Db": "Taisyklių įvadas",
    "loan.index.5Bd31Fab681C6E9D": "Žiūrėti daugiau",
    "loan.index.111F95F404814327": "Paskolos valiuta",
    "loan.index.6Bc2723B3Bec106D": "Paskola",
    "loan.index.3200106280E6E370": "palūkanų norma",
    "loan.index.049A6Ccb6C81A635": "Paskolos suma (minimali / maksimali)",    
	"loan.productDetails.5037Ad86Ab4B7E02": "Paskola",
    "loan.productDetails.37181C74559Db182": "Užsakymas",
    "loan.productDetails.5802Aaf06A1E4755": "Likvidumas",
    "loan.productDetails.89Aea9Cf9337C40F": "Galimas paskolos kiekis",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Grąžintinas kiekis",
    "loan.productDetails.C65F329Ef14C4A3D": "Paskolos suma",
    "loan.productDetails.3A74667815Adc8Bf": "Maks.",
    "loan.productDetails.Ab851D8351573227": "天",
    "loan.productDetails.Ddf82D8A2C8F46A0": "Grąžinimo laikotarpis",
    "loan.productDetails.C105E5562005Aeb1": "天",
    "loan.productDetails.3F346Be2137F0756": "Paskolos suma (minimali / maksimali)",
    "loan.productDetails.2707F78Df864E194": "Palūkanos",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Prašome grąžinti laiku. Jei vėluojate, kasdien bus imama papildoma suma (pagrindinė suma + palūkanos)",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% yra pradelsimo mokestis",
    "loan.productDetails.4D94Faa033Be7356": "Sutinku",
    "loan.productDetails.62429A957A22780A": "Paskolos paslaugų sutartis",
    "loan.productDetails.Bc54E76B61C50Fb9": "Perskaitykite ir sutikite su sutartimi",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Paskolinti dabar",
    "loan.productDetails.59246A0Bf081D398": "Minimum",
    "loan.productDetails.Ec3F1172B71Cd36C": "Minimalus kiekis",
    "loan.productDetails.7Ccc4Cf1D81930B3": "Maksimalus kiekis",
    "loan.productDetails.071488598990Aa68": "Perskaitykite ir sutikite su sutartimi;",
    "loan.productDetails.579Dd216D3339119": "Paskola sėkmingai suteikta;",    
	"loan.records.4951B36Fd0D2A831": "Paskolų įrašai",
    "loan.records.Bb0Aaa30514B7Cba": "Dabartinis įrašas",
    "loan.records.0F8B0A7D80769051": "Grąžinimo įrašai",
    "loan.records.D4B42E43D88De6F1": "Istorija",
    "loan.records.Cb1847D6281897E6": "Paskolos suma",
    "loan.records.C7Bfcacfc2Adea58": "Grąžinimo suma",
    "loan.records.8B369164D5Fea340": "Paskolos laikas:",
    "loan.records.Ab2402Ad3Ffd35E7": "Išankstinis paskolos grąžinimas",
    "loan.records.B7A0Ca0C195D0Cd1": "Išdavimo laikas:",
    "loan.records.42014Bdcfd9C411B": "Grąžinimo laikas:",
    "loan.records.A0Acfaee54F06458": "Baigta",
    "loan.records.67A39834582Ad99A": "Duomenų dar nėra",
    "loan.records.6A687Ff5B8F1598F": "Ar tikrai norite grąžinti paskolą iš anksto?",
    "loan.records.7C20D7008D0Eae86": "Grąžinimas sėkmingas",    
	"mining.bottomSheet.Ba91637D32Ccba04": "Liquidity Mining",
    "mining.bottomSheet.7De3D636A165E008": "Ieiga",
    "mining.bottomSheet.F3003345B189D683": "Kasybos laikotarpis",
    "mining.bottomSheet.3E4C0A8C533F5848": "Monetos tipas",
    "mining.bottomSheet.Adcba368993Ae65E": "Prenumeratos suma (minimali / didžiausia)",
    "mining.bottomSheet.94743B24E4Ed4166": "Neribota",
    "mining.bottomSheet.286944Fedd211257": "Turimos lėšos",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Apskaičiuotos pajamos:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Kasyba dabar",
    "mining.bottomSheet.Bb1493C567C114Dd": "Šiltas priminimas:",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Išankstinio išpirkimo mokestis",
    "mining.index.8B2Bba1422Eed6F0": "Sukurkite dešimtis milijardų turto, 100 % grynųjų pajamų",
    "mining.index.42997E300086Ee89": "Didžiausias pelnas",
    "mining.index.510F74F42052Ecce": "Kasybos produktai",
    "mining.index.B853F96626106912": "Eiti į kasybą",
    "mining.productList.37181C74559Db182": "Užsakyti",
    "mining.productList.Aac5B810004C46C8": "Reguliariai",
    "mining.productList.D2Fb4F78C387D888": "Jūs sėkmingai išgavote",    
	"quantify.bottomSheet.9Ea4A318F690Bc95": "Patvirtinti užsakymą",
    "quantify.bottomSheet.6915303B698303B1": "Robotas",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Lizingo suma",
    "quantify.bottomSheet.Fbd931286Be387Aa": "Laikotarpis",
    "quantify.bottomSheet.9Ad84Db481807844": "Neribotą laiką",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Dienos grąžos norma",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "Vertės data",
    "quantify.bottomSheet.88008603Ba7Bde64": "Pirkimas sėkmingas",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Nuo šiol galite gauti pajamų dalį!",
    "quantify.bottomSheet.C778A674C4A054F9": "Peržiūrėti užsakymą",
    "quantify.bottomSheet.685Dad322B7029B0": "Grąžinimo operacija",
    "quantify.buyForm.Acf8560A04334E95": "Pirkinio suma",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Apžvalga",
    "quantify.buyForm.8788Df2B625E97B5": "robotas",
    "quantify.buyForm.50Ca63B45E881D50": "Dividendų mokėjimo laikas",
    "quantify.buyForm.Fcda03Adbc5Fca97": "Kiekvieną dieną",
    "quantify.buyForm.Afb89504Fc399189": "Įveskite pirkimo kiekį",
    "quantify.buyForm.1435Afdfb4Ec0596": "Minimalus pirkimo kiekis",
    "quantify.buyForm.1B97F1730Bacd839": "Maksimalus pirkimo kiekis",    
	"quantify.index.32F56C9B7D0E250A": "Laikotarpis: neribotą laiką",
    "quantify.index.E34F028D00904630": "price",
    "quantify.index.E34F028D00904631": ",Dienos grąžos norma",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Sąlyginio deponavimo suma",
    "quantify.productDetail.53635B53Bca31Dfa": "Apskaičiuotos dienos pajamos",
    "quantify.productDetail.F9E46449A6269E09": "Kumuliacinės pajamos",
    "quantify.productDetail.98Fc46B1B8C993B2": "Užsakyti sąlyginio deponavimo priemonę",
    "quantify.productDetail.93624Bb045830966": "Nuomokite dabar",
    "quantify.records.0C16A18C32B9Cf4A": "Užsakyti įrašus",
    "quantify.records.B191F5614B0E2312": "Pirkimo įrašai",
    "quantify.records.6A06033B86A73F7C": "Išpirkimo įrašai",
    "quantify.records.45E0F7E71611618E": "Kumuliacinės pajamos",
    "quantify.records.45E0F7E71611618F": "Pelnas termino pabaigoje",
    "quantify.records.Aae1201E38C4Baaa": "Bėga",
    "flash.index.C41Fd4F2Fdfa594B": "Sunaudojimas",
    "flash.index.1Ac59Fa2218E3B00": "Galima:",
    "flash.index.Bf7233A660821549": "Gauti",
    "flash.index.548E775Dd67C7472": "Patvirtinti keitimą",
    "flash.index.066A2449Bd616F03": "Įveskite išpirkimo sumą",
    "flash.index.Fca26Fcf9C06563F": "Flash išpirkimas sėkmingas",    
	"message.index.40031524233F4564": "Pranešimų centras",
    "message.index.8E5586210Fd44D69": "Nuolatinis pozicijos priminimas",
    "message.index.Bcd3F470E88C197D": "Dar nėra pranešimo",
    "message.index.23A557Fbce545879": "Pranešimas apie nuolatinį likvidavimą",
    "message.index.B5E19B36B5125550": "Atšaukimo priminimas",
    "message.index.716A981465138334": "Įkrovimo priminimas",
    "message.index.884474Ebff2977Ba": "Sistemos pranešimas",
    "message.index.08Cc7D24752Ec42C": "Klientų aptarnavimas internetu",
    "message.index.1D820A6A19Ab025D": "7 x 24 valandų internetinė žmonių paslauga",
    "more.index.29881E0B5Df58Bf7": "Daugiau funkcijų",
    "more.index.C77Ee35911D10D9D": "Bendrosios funkcijos",
    "more.index.3558F69B0260Be61": "Prekybos funkcija",
    "more.index.7243Fe610Ed98Cec": "Tikroji sąskaita",
    "more.index.7243Fe610Ed98Ced": "Demonstracinė paskyra",
    "more.index.693C7B35Bdf3E3A3": "Finansai",
    "more.index.F855997Fac114554": "Pagalbos centras",
    "more.index.Ae9A1252278Da742": "Pagalba",
    "more.index.Ace012504Bc529E0": "Pranešimas",
    "more.index.05682Dbef5Eeb0A8": "Klientų aptarnavimas",
    "more.index.C7Af227A5D5C7Bb9": "Perėjote į tikrą paskyrą",
    "more.index.D818A65592694786": "Perjungėte į demonstracinę paskyrą",    
	"notice.details.B360807Dbe727E6E": "Apie mus",
    "notice.details.92F2552F695E70E3": "Apie mus",
    "notice.details.7Aaceaf69B67F3D1": "Taisyklės įvadas",
    "notice.index.6128438Be34Bcb9F": "Nuolatinis pranešimas",
    "notice.index.71627F5Eb7128261": "Atšaukimo pranešimas",
    "notice.index.2Dce570Fda841705": "Pranešimas apie įkrovimą",
    "notice.index.228A7C9C6448A9B1": "Sistemos pranešimas",
    "setting.dialog.8B4F5B74660Deabe": "Spalvų konfigūracija",
    "setting.dialog.Bfc7D25486Ebc1A9": "Žalia pakyla ir raudona krinta",
    "setting.dialog.796Ea0E1A82C320B": "Raudona pakyla ir žalia krinta",
    "setting.index.8Ddc5864E1739466": "Nuostatos",
    "setting.index.F5205801D57D6727": "Kalba",
    "setting.index.2E2C12Dd0Cc1B63B": "Pasiūlymo valiuta",
    "setting.index.322D8496Aec3D918": "versijos numeris",
    "setting.index.Aa7E6C204C751Cfa": "Atsijungti",
    "share.index.75Bc38Bbf7D2E766": "Pakviesti draugų",
    "share.index.45575Eb5D42213E6": "Mano pakvietimo kodas",
    "share.index.29F67F0644A78Bf3": "Kopijuoti",
    "share.index.19Cc65Bb6Bb98Fe7": "Mano kvietimo nuoroda",
    "share.index.5372712F162Fb78B": "Tiesioginių pavaldinių skaičius",
    "share.index.C88D6B95E3Ab7D6F": "Bendras pavaldinių skaičius",
    "share.index.Bb5Fa5471Aeecc62": "Komisiniai nuolaidos tiesioginiams pavaldiniams",
    "share.index.426B6Bac9A5F3D4F": "Visa nuolaida",
    "share.index.197E4A428F0401C9": "Peržiūrėti nuolaidų įrašus",    
	"share.records.88A6B2E2B74B13C4": "nuolaidų įrašas",
    "share.records.B671A35Ffdc93B89": "Nuolaida",
    "transfer.index.B17617A869C03Ca5": "piniginė",
    "transfer.index.E4116Dcc6F6C7270": "Perdavimas sėkmingas",
    "transfer.index.48Fb603C6638C7F6": "Nustatykite fondo slaptažodį",
    "transfer.records.Eb0E39Eb5911D418": "Gauti",
    "transfer.records.977554Be0Aa65052": "Keisti laikas:",
    "transfer.records.9Aa33884Aa400B7C": "Perdavimo laikas:",
    "form1.forget.76636Db8Fae5B124": "Jūsų el. paštas",
    "form1.forget.35354B9Cca1Bb0E6": "El. pašto patvirtinimo kodas",
    "form1.forget.604A0B11E916F387": "Slaptažodis",
    "form1.forget.9Cb1C9C51E7503D6": "Patvirtinti slaptažodį",
    "form1.forget.3A4F7E04B008453C": "Jūsų mobiliojo telefono numeris",
    "form1.forget.Cf11Dce5B4713B5A": "Mobiliojo telefono patvirtinimo kodas",
    "form1.forget.3012Edba57D4F297": "Įveskite savo el. pašto adresą",
    "form1.forget.F1Ee1D97E869E447": "Įveskite patvirtinimo kodą",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Įveskite slaptažodį",
    "form1.forget.C6Cb174Da411D5F7": "Įveskite savo mobiliojo telefono numerį",
    "form1.forget.947B3B21Ee45E582": "Siųsti",
    "form1.forget.3E0580F1E8630Df6": "Du slaptažodžiai nesuderinami",
    "form1.forget.C5A61836B8Cc74C6": "Slaptažodis sėkmingai pakeistas ir peradresuotas...",
    "form1.forget.B96D072Bc8962565": "Siųsti dar kartą",    
	"form1.login.2B72C27Ff828Cfc4": "Jūsų slaptažodis",
    "form1.login.D7C6B872Af9Cb17F": "Prisiminti mano slaptažodį",
    "form1.login.0Fff44C827A4F3B6": "Prisijungti",
    "form1.login.1B7F2C1Ede3990Cc": "Dar neturite paskyros? Užsiregistruokite dabar",
    "form1.login.F657F7Be1Cc33Bd2": "Pamiršau slaptažodį",
    "form1.login.2074A1Eec4B088B0": "Piniginės prisijungimas",
    "form1.login.1B8938Fbcc3C9B56": "Sėkmingas prisijungimas ir peradresavimas...",
    "form1.login.07162A7Bb9310555": "Įdiekite ERC20 piniginę",
    "form1.login.766969966Ae151Dc": "Įdiekite TRC20 piniginę",
    "form1.login.B88171E1Ad4C50Bf": "Jūs atsisakėte perjungti tinklus",
    "form1.register.Aaef0710Cb4Db554": "Iš naujo įveskite slaptažodį",
    "form1.register.54Dfea0415C708Ff": "Kvietimo kodas",
    "form1.register.6839940Dac08C794": "Registruotis",
    "form1.register.9901A3B5550Aa58E": "Jau turite paskyrą? Prisijunkite",
    "form1.register.2548Ca53785252Ea": "Įveskite savo pakvietimo kodą",
    "form1.register.Dd71Fd65900Dd03E": "Perskaitykite registracijos paslaugų sutartį ir sutikite,",
    "form1.register.7975613909490B77": "Registracija sėkminga ir nukreipiama...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Pasirinkti piniginę",
    "userc.certification.2Af9511640153D2E": "Pirminis sertifikatas",
    "userc.certification.177B191D8E0Fa84D": "Nesertifikuota",
    "user.certification.601218D4D572E98A": "sertifikuota",
    "userc.certification.6E37C51Ba9012Fc8": "Nepavyko",
    "userc.certification.1B9B563E05C96474": "Išplėstinis sertifikatas",
    "userc.certification.1418B83803Ab56B4": "Pirmiausia užpildykite pirminį sertifikavimą",
    "userc.creditScore.0B149112601Acc4D": "Kredito balas",
    "userc.creditScore.88Ac7F84E106C5F6": "Bendras kredito balas",
    "userc.creditScore.3E1D6E0E7F1E6155": "Sistemos įvertinimas",    
	"userc.index.62Eac58D04Bbca0F": "Asmeninis centras",
    "userc.index.F4Cac53F93Cb2Aff": "Imituota prekyba",
    "userc.index.42F409Fdf3559773": "Saugos centras",
    "userc.index.1B8E211009807Db8": "Tapatybės autentifikavimas",
    "userc.index.Bdfcf21Eb8F2Eb70": "Paskyros pakeitimo įrašas",
    "userc.index.6C18D67F6D19586B": "Platformos įvadas",
    "userc.index.807A814Ed58A93Bc": "Išvalyti talpyklą",
    "userc.index.E43Daad59759611C": "Nepatvirtinta",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Keisti prisijungimo slaptažodį",
    "userc.secureCenter.1F37Ba427Debf24A": "Paskyros susiejimas",
    "userc.secureCenter.F9A5Ffa750664004": "Bind Google Authenticator",
    "userb.certificationForm.7685Af7D30043Cd6": "Pirminis sertifikatas",
    "userb.certificationForm.7685Af7D30043Cd7": "Išplėstinis sertifikatas",
    "userb.certificationForm.70E37C35Abc3F462": "Šalis",
    "userb.certificationForm.F92C25A68E4D08C5": "Miestas",
    "userb.certificationForm.0Ccc6387B38E0318": "Jūsų vardas",
    "userb.certificationForm.94C67Bba370Fdd46": "Sertifikavimo numeris",
    "userb.certificationForm.B2D4C7Fffe79258E": "El. paštas",
    "userb.certificationForm.57554268761Fb47A": "Įkelkite priekinę sertifikato dalį",
    "userb.certificationForm.D79E90201F5319Bd": "Įkelkite savo sertifikato galinę dalį",
    "userb.certificationForm.9C6Bad08Af29E20E": "Įkelkite delninio įrenginio sertifikato paveikslėlį",
    "userb.certificationForm.F96B62Dfa31Cde45": "Patvirtinti pateikimą",    
	"userb.certificationForm.2125D78Ea8C6D287": "Užklausa įkelti",
    "userb.certificationForm.0D64864D3076A824": "Žiūrėti pavyzdį",
    "userb.certificationForm.35287Deb72281E87": "1. Turite aiškiai matyti asmens tapatybės kortelės informaciją.",
    "userb.certificationForm.3007F2E01Efc7B57": "2. Nuotraukos turinys yra tikras ir galiojantis ir jokie pakeitimai neleidžiami.",
    "userb.certificationForm.25A90660554319F9": "3. Sertifikato numeris ir pavadinimas turi būti aiškiai matomi, palaikyti JPG / JPEG / PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "Įveskite",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Pateikimas sėkmingas, palaukite peržiūros",
    "userb.certificationForm.744Bd07D8Abd2513": "Įkelkite priekinę sertifikato pusę",
    "userb.certificationForm.6De1C319130F7Fef": "Įkelkite savo sertifikato nugarėlę",
    "userb.certificationForm.79F8E8B172E30C8A": "Įkelkite savo asmens tapatybės kortelės nuotrauką",
    "userb.certificationForm.46412E16D28A4753": "Nuotraukų įkėlimas nepavyko",
    "userb.changeDialog.676B0Adca1F8Eea4": "Keisti slaptažodį",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "Mobiliojo telefono numeris",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "Autentifikavimo priemonės jungiklis",
    "userb.modifyInfoDialog.62149E566814154C": "Pasirinkite tikrintuvą",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Atidarykite autentifikavimo programą ir spustelėkite +",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": 'Pasirinkite "Nuskaityti QR kodą" arba "Įvesti raktą"',
    "userb.modifyInfoDialog.93F094E2B4369472": "Nuskaitykite QR kodą arba įveskite raktą",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "Uždaryti / atidaryti",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Senas fondo slaptažodis",    
	"userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Naujas fondo slaptažodis",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Patvirtinti naują fondo slaptažodį",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Patvirtinti fondo slaptažodį",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Įveskite savo seną fondo slaptažodį",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Įveskite naują fondo slaptažodį",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Įveskite savo fondo slaptažodį",
    "userb.modifyInfoDialog.258A534498Fe984F": "Patvirtinkite savo fondo slaptažodį",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Paskyros susiejimas",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Fund slaptažodis",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "Patvirtinti",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Tifikatorius išjungtas",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Google patvirtinimas sėkmingai įjungtas",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Sėkmingai pakeisti fondo slaptažodį",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Sėkmingai nustatyti fondo slaptažodį",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Surišimas sėkmingas",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Patvirtinimo kodo klaida",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Dokumento įkėlimo pavyzdys",
    "userb.veBottomSheet.6F38A0D5858633Ee": "Tamsus ir švarus fonas",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Pakankamai šviesos",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Išlygiavimo pagalbinė dėžė",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Nuotrauka išvalyta",
    "userb.veBottomSheet.50B741382E71F103": "Galimos gedimo priežastys",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "Asmens tapatybės kortelės apsauginis dangtelis nebuvo nuimtas",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "Foną paveikė šiukšlės",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "Asmens tapatybės kortelė atspindi",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "Šviesa per tamsi / užstoja mobiliojo telefono šešėlis",
    "userb.veBottomSheet.1A75D2Fc2614592B": "Laikykite asmens tapatybės kortelę",    
	"home.home.C3525Eb110Db58Aa": "daugiau",
    "home.home.29761532Fc8Ebecc": "Operacija",
    "home.home.F15Ea99Bfa6E1848": "Saugos sertifikatas, pirkite drąsiai",
    "home.home.46E1Ab39Bae7182A": "Greitai",
    "home.home.Fde2Eb7F6F3Dd800": "Greita operacija, paprastas valdymas",
    "home.home.Ea4756Bc1642E0F1": "vardas",
    "home.home.B6B1127Ede1C97B4": "Naujausia kaina",
    "home.home.3587Ad870Eb0Ab86": "24H padidėjimas ir sumažėjimas",
    "home.home.Cb1A4E14Ec2Cd2B5": "Discover",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Užsienio valiuta",
    "home.home.Ad65263D034Ea051": "PMs",
    "home.home.5Fc1474Dbf74Ac1A": "Žemės ūkio produktai",
    "home.home.135318F4664F7773": "Energija",
    "kline.kLine.C96Ff250Cf6B8B84": "24h didžiausia kaina",
    "kline.kLine.6E8Be64F19D74565": "24 val. prekybos apimtis",
    "kline.kLine.08A4Bfbe26A0F262": "24 val. mažiausia kaina",
    "kline.kLine.8701Deb075066331": "24 val. prekybos apimtis (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 taškas",
    "kline.kLine.B49Cca240E013124": "5 taškai",
    "kline.kLine.F27A90Be97Ba3D6C": "15 taškų",
    "kline.kLine.Ff3E62395B694384": "30 minučių",
    "kline.kLine.C1993F9C6Db1A674": "1 valanda",
    "kline.kLine.Fd2Cbbae405E284C": "1 diena",
    "kline.kLine.C9Cd71904395042C": "sausis",
    "kline.kLine.7D8127Ec18C7B8C7": "Pirkti",
    "kline.kLine.E3D3Ffdb9B3E5636": "Kiekis (lapai)",
    "kline.kLine.6834Fec1838D8029": "Kaina (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "Parduoti",
    "kline.kLine.B9Bea1A9A88D1419": "Atviras ilgai",
    "kline.kLine.D36Cfa96Ee2B343B": "Trumpai atidaryti",    
	"other.navigationBar.F6E62F9642018Cbb": "Pagrindinis",
    "other.navigationBar.0Ec227161A47B49D": "Citatos",
    "other.navigationBar.29761532Fc8Ebecc": "Operacija",
    "other.navigationBar.693C7B35Bdf3E3A3": "Finansai",
    "other.navigationBar.9734C54620Eb09Af": "Turtas",
    "home.index.25Aa4Fc745150030": "Kriptovaliutos karalius",
    "home.index.2D6Ecf8Faaa8B124": "Greičiau, geriau, stipriau",
    "home.index.1Db26267D1041223": "Lengvai prekiaukite daugiau nei 1700 kriptovaliutų",
    "home.index.F8E18C90C3E35618": "Registruotis dabar",
    "home.index.29761532Fc8Ebecc": "Operacija",
    "home.index.D8A95E4506487452": "Populiarios sutartys",
    "home.index.5Bd31Fab681C6E9D": "Žiūrėti daugiau",
    "home.index.010Ae07A09Caf221": "Prekybos pora",
    "home.index.F4D875118E6D6Dbb": "Naujausia operacijos kaina",
    "home.index.3Dfdfb3D6599100E": "24 valandų padidėjimas arba sumažėjimas",
    "home.index.28Fb39Bd41E54242": "tendencijų diagrama",
    "home.index.B47A3911Bacd94C6": "Augimo sąrašas",
    "home.index.712910D574F43D95": "stabilios pajamos",
    "home.index.5507Fd830D7Bf51F": "Geriausia strategija, lengva kopijuoti",
    "home.index.7De3D636A165E008": "pajamingumas",
    "home.index.B853F96626106912": "Eiti kasyba",
    "home.index.213B5C96Be7Cd1F8": "Dienos, metinė palūkanų norma",    
	"home.index.213B5C96Be7Cd1F9": "Maksimalus pirkinys",
    "home.index.Dc710Cffe6313Bb5": "Peržiūrėti išsamią informaciją",
    "home.index.B92Ffc837Ecaa1Dd": "Naujienos",
    "home.index.6C18D67F6D19586B": "Platformos įvadas",
    "home.index.6342E3C33Cb48Cd5": "Skaitmeninė valiuta yra mainų priemonė, kuri naudoja kriptografinius principus, kad užtikrintų operacijų saugumą. Skirtingai nuo fiat valiutų, tokių kaip JAV doleris, skaitmeninės valiutos neturi fizinės formos. Bitcoin (BTC), Ethereum ( ETH) ir Polkadot Populiarios skaitmeninės valiutos, tokios kaip (DOT), naudoja blokų grandinę kaip pagrindinę technologiją, kuri veikia kaip decentralizuota skaitmeninė knyga. Visos skaitmeninės valiutos operacijos įrašomos į blokų grandinę ir negali būti pakeistos, kai jos patvirtinamos ir patikrintos, kitaip nei tradicinės valiutos, kurios yra Skirtingai nuo centralizuotų knygų, skaitmeninės valiutos operacijos atliekamos viešoje grandinėje ir gali būti prieinamos visiems",
    "home.index.198Efc4254683Eef": "Be to, pagal konsensuso mechanizmą kiekvienas gali patikrinti skaitmeninės valiutos operacijas ir įtraukti jas į blokų grandinę ir taip pasiekti decentralizaciją. Su Samsung, BlackRock, Morgan Stanley Global pirmaujančiomis įmonėmis, tokiomis kaip Alphabet ir kt. Žmonės tiki, kad „blockchain“ technologija visiškai pakeis pasaulinę finansų sistemą",
    "home.index.E710339D9576292E": "Mūsų pranašumai",
    "home.index.F9Ce8B2C63885B26": "Saugus ir stabilus",
    "home.index.C4D0A2C9Ff0305Ce": "Aukščiausia techninė komanda, visapusiška saugumo apsauga, nepriklausomi tyrimai ir didelės spartos transakcijų sintezės variklio, kuris vis tiek gali veikti stabiliai ir patikimai atliekant didžiulius vienu metu vykstančius sandorius, kūrimas",
	"home.index.2E8708Ee3F11Cc1A": "Profesionalus ir patikimas",
    "home.index.Bb12Cbcf89C56C5C": 'Profesionali operacijų komanda, ilgametė patirtis "blockchain" ir finansų pramonėje, turinti reikalavimus atitinkančią skaitmeninio turto prekybos licenciją ir 100% rezervo garantiją.',
    "home.index.Aa4723Bfbf6808F4": "Aptarnavimas nuoširdžiai",
    "home.index.1Bf0204B7D3Ee230": "Pasiekimas su pasauline rinka, palaikymas keliomis kalbomis, veikia 24 valandas per parą, 7 dienas per savaitę, stiprus bendruomenės palaikymas ir profesionalus klientų aptarnavimas",
    "home.index.2E7Dfd53D26F7224": "didelis našumas",
    "home.index.1C65453103B5Fcc3": "300 000 operacijų per sekundę, užsakymo atsako laikas yra trumpesnis nei 1 milisekundė.",
    "home.index.85Ac49Ece9Dcc80B": "Prekiaukite bet kada ir bet kur!",
    "home.index.2799E18842E4Ebe9": "Momentinis pozicijos atidarymas, palaikomas OKEX programėlės ir tinklalapio!",
    "home.index.2Bf52Ce75E29Fc88": "Nuskaitykite kodą, kad galėtumėte atsisiųsti dabar",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple versija",
    "home.index.04Ee893775563F22": "Produktai ir paslaugos",
    "home.index.04Ee893775563F23": "Finansų valdymas",
    "home.index.04Ee893775563F24": "Grąžinti",
    "home.index.04Ee893775563F25": "Laukiama loterijos burtų",    
	"home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "Pirkite „Bitcoin“, „Ethereum“ ir",
	"homepage.text1_2": "daugiau nei 1000 skirtingų kriptovaliutų",
	"homepage.text2": "Jūsų kriptovaliutų patirčių kelionė",
	"homepage.text4": "Aukštos palūkanų finansų valdymas",
	"homepage.text5": "Stabilus finansų valdymas, aukštas metinis pelnas",
	"homepage.text6": "Dalijimasis apdovanojimais",
	"homepage.text7": "Greitas prekybos procesas, paprastas naudojimas",
	"homepage.text8": "Peržiūrėti daugiau",
	"homepage.text9": "Tiesioginis keitimas",
	"homepage.text10": "Saugumo patvirtinimas, pirkite su pasitikėjimu",
	"homepage.text11": "Pirmūnų technologijų komanda, visapusiškas saugumas",
	"homepage.text12": "Apsaugotas, nepriklausomas",
	"homepage.text13": "Profesionalus ir patikimas",
	"homepage.text14": "Profesionali veiklos komanda, turinti daugelio metų blockchain ir finansų patirtį",
	"homepage.text15": "Rūpestingas aptarnavimas",
	"homepage.text16": "Globalus rinkos orientavimas, palaikoma daugelio kalbų, veikia visą parą",
	"homepage.text17": "Aukšta našumas",
	"homepage.text18": "300,000 sandorių per sekundę, užsakymo atsakymo laikas mažesnis nei 1 milisekundė",
    "passwod.limit":"Slaptažodžio ilgis turi būti didesnis arba lygus 6 simboliams"
}
